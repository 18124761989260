import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dollerIcon, phoneIcon, tagIcon } from "../../helpers/Icons";
import './MyOrdersDesign.css';

const OrderSummary = () => {
      const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const message = queryParams.get('message');
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
const navigate = useNavigate();
const [isSize, setSize] = useState({ width: 38, height: 38 });

    const handlegotoOrdersPage = () => {
        navigate("/yourorder")
    }
    useEffect(() => {
        const fetchOrderDetails = async (customerId, referenceNumber) => {
            try {
                const response = await fetch(`https://ompextension.origa.market/sparesapi/get_orders`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        customer_id: "VXNlcjoxNzE=",
                        reference_number: "REF-6147-20240724"
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch order details');
                }

                const data = await response.json();
                if (data.order) {
                    setOrderDetails(data.order);
                } else {
                    setOrderDetails(null);
                }
            } catch (error) {
                console.error('Error fetching order details:', error);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        const searchParams = new URLSearchParams(location.search);
        const orderId = searchParams.get('id');
        const message = searchParams.get('message');

        const customer_id = "VXNlcjoxNzE="; // Replace with actual logic for customer ID retrieval

        if (orderId && message === 'Paid') {
            fetchOrderDetails(customer_id, orderId);
        }
    }, [location.search]);

    if (loading) {
        return <div>Loading...</div>; // Or implement a loading spinner or component
    }

    if (!orderDetails) {
        return <div>No order details found.</div>; // Or handle error state
    }

    return (
        <div className="container-fluid">
  <div className="max-container my-5">
    <div className="summary-wrap">
      <div className="content-wrap pt-5">
        <div className="bottom-wrap">
          <div className="top-heading">
            <div className="heading-600-24 heading-600-24-20 heading-600-24-16 heading-600-24-14 select-heading">
              Confirm Order Summary
            </div>
            <div className="customer-info indofrrrr">
              <div className="select heading-600-20 heading-600-20-16">
                {orderDetails.customer_name}
              </div>
              <div className="text heading-400-14-12">
                Billing Address : {orderDetails.billing_address.address}, {orderDetails.billing_address.city}, {orderDetails.billing_address.state}, {orderDetails.billing_address.zip}
              </div>
              <div className="text heading-400-14-12">
              Shipping  Address : {orderDetails.shipping_address.address}, {orderDetails.shipping_address.city}, {orderDetails.shipping_address.state}, {orderDetails.shipping_address.zip}, {orderDetails.shipping_address.country}
              </div>
              <div className="contact-details">
                <div className="heading-400-14-12 phone">
                  <span>{phoneIcon({ width: 24, height: 24 })}</span>
                  {orderDetails.billing_address.phone && (
                    <span className="heading-400-14-12 op-60">
                      &nbsp;{orderDetails.billing_address.phone}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right bi-process-wrap">
                  {/* Status 1: Started or Completed */}
                  <span className="completed">{tagIcon({ width: isSize.width, height: isSize.height, className: "completed" })}</span>

                  {/* Status 2: Started (with additional content) or Completed */}
                  <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "" })}</span>
                  {/* <div className="status-content1 heading-400-12-10">Advance Paid</div> */}

                  {/* Status 3: Started (with additional content) or Completed */}
                  <span className="completed">{dollerIcon({ width: isSize.width, height: isSize.height, className: "" })}</span>
                  {/* <div className="status-content2 heading-400-12-10">Financing</div> */}

                </div>
          <div className=" ">
            {orderDetails.line_items.map((item, index) => (
              <div className="detail-lists" key={index}>
                <div className="product-wrap produuccctwwwra">
                  <div className="left-wrap akjdbd">
                    <img
                      className="product-img"
                      src={item.thumbnail[0]?.url || "N/A"}
                      alt="chi-img"
                    />
                    <div className="detail-wrap fkdvbbvd">
                      <div className="heading-600-16 title">
                        {item.name}
                      </div>
                      <div className="price-wrap kegbkgebvd">

                        <div className="price-item">
                          <div className="heading-600-14 price">
                            ₹{item.rate}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-wrap hhiiiifff">
                    <div className="heading-600-20 amount-heading">
                      Amount Summary
                    </div>
                    <div className="price-item">
                      <div className="heading-400-16-12 title">Price</div>
                      ₹{orderDetails.total}
                    </div>
                    <div className="price-item">
                      <div className="heading-400-16-12 title">Shipping Bill</div>
                      ₹{orderDetails.shipping_charge}
                    </div>
                    <div className="price-item">
                      <span className="heading-400-16-12 title"> Total Quantity:</span>
                      {orderDetails.total_quantity}
                    </div>
                    <div className="total-wrap">
                      <div className="heading-600-16 title">Total</div>
                      <div className="heading-600-16 total-price">
                        ₹{orderDetails.total}
                      </div>
                    </div>
                  </div>
                
                </div>
             
              </div>
            ))}
     
        </div>
        <div className="delivery-btn-wrap rwbjkn">
                  <button className="button" onClick={handlegotoOrdersPage}>
                    Go to orders
                  </button>
                  <button className="button" onClick={handlegotoOrdersPage}>
                    Download invoice
                  </button>
                </div>
          </div>
      </div>
    </div>
    
  </div>
</div>



    );
};

export default OrderSummary;






