import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";
import "../../Product/SparePage/ViewProduct.css";
import "../../../Buying/Product/SparePage/QuotationPopupStyles.css";
import Loader from "../../../SubComponent/Loader";

const client = secondClient;
const QuotationPopupForSpareAddtoCart = ({ productDetails, onSubmit }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  const [infokey, setInfokey] = useState(0);
  let { name, price, image, packPrice, bcukettSize, brands ,position,productUnits} =
    productDetails || {}; 


  const groupId = queryParams.get("group_id");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);


  const [productDETAILS, setProductDETAILS] = useState({});
  const [allData,setAllData] = useState();
  const [salePriceddss, setsalePriceddss] = useState(null);
  const [productNamedsee,setproductNamedsee]=useState(null);
  const [endQuantity, setEndQuantity] = useState();
  const [barrelQuantity, setBarrelQuantity] = useState(1);
  const [bucketQuantity, setBucketQuantity] = useState(1);
  const [isBarrel, setIsBarrel] = useState(false);
  const [clickedPriceItem, setClickedPriceItem] = useState(null);
  const [bucketPrice, setBucketPrice] = useState(null);
  const [bucketSize, setBucketSize] = useState(null);
  const [bucketId, setBucketId] = useState(null); 
  const [totalPrice, setTotalPrice] = useState(0);
  const [unitVal, setUnitVal] = useState(null);
    useEffect(() => {
          if (selectedProduct && (bucketQuantity || barrelQuantity)) {
            const currentQuantity = isBarrel ? barrelQuantity : bucketQuantity;
            console.log("Selected Product : ", selectedProduct);
            console.log("saleprice : ",selectedProduct?.saleprice);
            // console.log("Selected Quantity : ".selectedQuantity)
            console.log("Product Name : ",selectedProduct?.name)
            console.log("Current barrelQuantity:", barrelQuantity);
            console.log("Current bucketQuantity:", bucketQuantity);
            console.log("Current currentQuantity:", currentQuantity);

              // Store saleprice in the state variable
      if (selectedProduct?.saleprice) {
        setsalePriceddss(selectedProduct.saleprice);
      }

      if (selectedProduct?.name) {
        setproductNamedsee(selectedProduct?.name);
      }
      
            const bucketId = getBucketId(currentQuantity);
          }
        }, [
          selectedQuantity,
          selectedProduct,
          bucketQuantity,
          barrelQuantity,
          isBarrel,
        ]);


        const getBucketId = (quantity, isBarrel) => {
          const priceList = selectedProduct?.price_list || [];
          let bucketId = null;
          let productType = null;
          let endQuantity = null; 
        
          // If in barrel mode, directly get the bucketId for 210L barrels
          if (isBarrel) {
            const barrelPriceItem = priceList.find((item) => item.bucket_size === 210 || item.bucket_size === 180);
            if (barrelPriceItem) {
              bucketId = barrelPriceItem.bucket_id;
              productType = "Barrel";
              endQuantity = barrelPriceItem.end_quantity;
            }
          } else {
            // If in bucket mode, find the applicable bucket
            const bucketPriceItem = priceList.find(
              (item) =>
                item.qty_type === "Bucket" &&
                quantity >= item.start_quantity &&
                (item.end_quantity === null || quantity <= item.end_quantity)
            );
            if (bucketPriceItem) {
              bucketId = bucketPriceItem.bucket_id;
              productType = "Bucket";
              endQuantity = bucketPriceItem.end_quantity;
            }
          }
        
          if (!bucketId) {
            console.log("No matching price item found for the current quantity.");
          }
        
          return { bucketId, productType , endQuantity};
        };

        const [selectedPriceList, setSelectedPriceList] = useState([]);
        const [hoveredIndex, setHoveredIndex] = useState(null);
      

        const calculateTotalValue = () => {
          if (isBarrel) {
            return barrelQuantity * bucketSize * bucketPrice; 
          } else {
            return bucketQuantity * bucketSize * bucketPrice;
          }
        };
        const totalvalue = calculateTotalValue();
      
      

  const handlePopupSubmit = () => {
    console.log("productDetails : ", JSON.stringify(productDetails, null, 2));

    const actualQuantity = isBarrel ? barrelQuantity * 11 : bucketQuantity;
       // Get the correct bucketId for the current mode
  const { bucketId: resolvedBucketId,endQuantity: resolvedEndQuantity } = getBucketId(selectedQuantity, isBarrel);;
    console.log(
      "The clicked productId QuotationPopupForInSpareAddtoCart : ",
      productDetails?.id
    );
    console.log(
      "The clicked Selected Quantity  QuotationPopupForInSpareAddtoCart: ",
      actualQuantity
    );
    console.log("The clicked BucketId : ", resolvedBucketId);
    console.log("product name : ",productDetails?.producttttnameee)
    console.log("product image : ",productDetails?.image);
    console.log("product brand : ",productDetails?.brands)
    console.log("End Quantity: ", resolvedEndQuantity); 
    console.log("Product Sale Price : ", salePriceddss);

    onSubmit({
      productIded: productDetails?.id,
      productNamed:productNamedsee,
      productBranded:productDetails?.brands,
      productImaged:productDetails?.image,
      salePricess : salePriceddss,
      quantityed: actualQuantity,
      bucketIded: resolvedBucketId,
      priceList: selectedPriceList,
      position:position,
      endQuantity: resolvedEndQuantity
    });
  };


    useEffect (()=>{
      const actualQuantity = isBarrel ? barrelQuantity * 11 : bucketQuantity;
       // Get the correct bucketId for the current mode
    const { bucketId: resolvedBucketId } = getBucketId(selectedQuantity, isBarrel);
      console.log("productId : ", productDetails?.id);
      console.log(" Quantity : ", actualQuantity);
      console.log("The clicked BucketId : ", resolvedBucketId);
      console.log("Bucket Size : ",bucketSize);
    },[selectedQuantity,bucketId,bucketSize]);
  

  useEffect(() => {
    if (productDETAILS) {
      const product = Object.values(productDETAILS).find(
        (item) => item.id === productDetails?.id
      );
      if (product) {
        setSelectedProduct(product);
        setSelectedPriceList(product.price_list || []);
        if (product.price_list.length > 0) {
          setClickedPriceItem(0);
          setBucketPrice(product.price_list[0].price);
          setBucketSize(product.price_list[0].bucket_size);
          setSelectedMinPrice(product?.price_list[0]?.min_price)
          setSelectedMaxPrice(product?.price_list[0]?.max_price);
          setBucketId(product.price_list[0].bucket_id);
        }
      }
    }
  }, [productDETAILS, productDetails?.id]);

  // const renderPriceList = () => {
  //   if (selectedPriceList.length > 0) {
  //     return (
  //       <div>
  //         {selectedPriceList.map((priceItem, index) => (
  //           <button
  //             key={index}
  //             className={`buttonclickednodvjbktorbfdg ${
  //               clickedPriceItem === index ? "btn-clicked" : ""
  //             }`}
  //             onClick={() => handlePriceClick(priceItem, index)}
  //           >
  //             <span className="priceItemLabel">{priceItem.price_label}</span>
  //             <br></br>
  //             <span
  //               className="offerrrr"
  //               style={{
  //                 color: clickedPriceItem === index ? "#58cb58" : "#58cb58",
  //                 fontSize: "16px",
  //                 fontWeight: "500"
  //               }}
  //             >
  //               {Math.ceil(
  //                 ((priceItem.mrp - priceItem.price) / priceItem.mrp) * 100
  //               )}
  //               % off
  //             </span>
  //             <br />₹{priceItem.price}/Ltr
  //             <br />
  //             <span
  //               className={`text-xs transition-colors duration-200 ${
  //                 clickedPriceItem === index || hoveredIndex === index ? "text-white" : "text-gray-500"
  //               }`}
  //             >
  //               <s>₹{priceItem.mrp}/Ltr</s>
  //             </span>
  //           </button>
  //         ))}
  //       </div>
  //     );
  //   }
  //   return <p>No price list available</p>;
  // };

  // const handlePriceClick = (priceItem, index) => {
  //   console.log("Clicked price item:", priceItem);
  //   setClickedPriceItem(index);
  //   setBucketPrice(priceItem.price);
  //   setBucketSize(priceItem.bucket_size);
  //   setEndQuantity(priceItem.end_quantity);
  //   setBucketId(priceItem.bucket_id);
  //   if (priceItem.start_quantity) {
  //     setSelectedQuantity(priceItem.start_quantity);
  //   }
  // };

  
  const renderPriceList = () => {
    if (selectedPriceList.length > 0) {
      // Sort the price list by bucket_size
      const sortedPriceList = [...selectedPriceList].sort(
        (a, b) => a?.bucket_size - b?.bucket_size
      );
  
      return (
        <div>
          {sortedPriceList.map((priceItem, index) => (
            <button
              key={index}
              className={`buttonclickednodvjbktorbfdg ${
                clickedPriceItem === index ? "btn-clicked" : ""
              }`}
              onClick={() => handlePriceClick(priceItem, index)}
            >
              <span className="priceItemLabel">{priceItem.price_label}</span>
              <br />
  
              {priceItem.price && (
                <>
                  <span
                    className="offerrrr"
                    style={{
                      color: clickedPriceItem === index ? "#58cb58" : "#58cb58",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {Math.ceil(
                      ((priceItem.mrp - priceItem.price) / priceItem.mrp) * 100
                    )}
                    % off
                  </span>
                  <br />
                </>
              )}
  
                {priceItem.price  && (<> ₹{priceItem.price}/{productUnits} </>)}
                {/* {priceItem?.min_price && (<>₹{priceItem.min_price}/Ltr </>)} */}

                {/*If the min price and max price are same  , it will show a single price 
                   If the min price and max price are different , then it shows a price range  */}
                {priceItem?.min_price && priceItem?.max_price && (
                  <>
                  ₹{priceItem.min_price}{/*Always show the min price first*/}
                  {priceItem.min_price !== priceItem.max_price ? `-₹${priceItem.max_price}`:""}/{productUnits}
                  {/*If min price is not equal to max price then show -  ₹max price 
                     If they are same , it adds nothing just show min_price*/}
                  </>
              )}
              <br />
  
              <span
                className={`text-xs transition-colors duration-200 ${
                  clickedPriceItem === index || hoveredIndex === index
                    ? "text-white"
                    : "text-gray-500"
                }`}
              >
                {priceItem.mrp && <s>₹{priceItem.mrp}/{productUnits}</s>}
              </span>
            </button>
          ))}
        </div>
      );
    }
    return <>
    <Loader /></>;
  };

  
    const [selectedMinPrice, setSelectedMinPrice] = useState(null); 
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(null); 
    const [selectedPriceRange, setSelectedPriceRange] = useState(null);

  const handlePriceClick = (priceItem, index) => {
    setClickedPriceItem(index); // Set the clicked price item
    setBucketPrice(priceItem.price);
    setBucketSize(priceItem.bucket_size);
    console.log("priceItem.min_price : ",priceItem.min_price);
    console.log("priceItem.max_price : ",priceItem.max_price);
    setSelectedMinPrice(priceItem.min_price); 
    setSelectedMaxPrice(priceItem?.max_price);

  
    const isPriceRange = priceItem?.min_price !== priceItem?.max_price;
    setSelectedPriceRange(isPriceRange);
    const isBarrel = priceItem.bucket_size === 210 || priceItem.bucket_size === 180;
  setEndQuantity(priceItem.end_quantity);
    setBucketId(priceItem.bucket_id); // Store bucket_id in state
    setIsBarrel(isBarrel);

    if (isBarrel) {
      setBarrelQuantity(1); // Initialize barrel quantity to 1
      setTotalPrice(priceItem.price * priceItem.bucket_size);
    } else {
      const newQuantity = priceItem.start_quantity;
      setBucketQuantity(newQuantity);
      setSelectedQuantity(newQuantity);

      const newTotalPrice =
        newQuantity * priceItem.price * (priceItem.bucket_size || 1);
      setTotalPrice(newTotalPrice);
    }

   
    setEndQuantity(priceItem.end_quantity);

    // Log the details including the bucket_id for the selected priceItem
    console.log(
      `%cDetails:
      %c- Index:%c ${index} 
      %c- Selected Bucket ID:%c ${priceItem.bucket_id} 
      %c- Bucket Price:%c ₹${priceItem.price} 
      %c- Bucket Size:%c ${priceItem.bucket_size}L 
      %c- MRP:%c ₹${priceItem.mrp}`,
      "color: #3498db; font-weight: bold;", // Header style
      "color: #2ecc71; font-weight: bold;", // Label style for Index
      "color: #27ae60;", // Value style for Index
      "color: #f39c12; font-weight: bold;", // Label style for Bucket ID
      "color: #e74c3c;", // Value style for Bucket ID
      "color: #f39c12; font-weight: bold;", // Label style for Bucket Price
      "color: #d35400;", // Value style for Bucket Price
      "color: #9b59b6; font-weight: bold;", // Label style for Bucket Size
      "color: #8e44ad;", // Value style for Bucket Size
      "color: #e74c3c; font-weight: bold;", // Label style for MRP
      "color: #c0392b;" // Value style for MRP
    );
  };
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    //runs everytime when selectedQuantity or selectedProduct changes
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      //checks if the price_list exists
      const applicablePriceItem = selectedProduct.price_list.find(
        (
          item //searches for the first item that matches the logic in the below
        ) =>
          selectedQuantity >= item.start_quantity && //checks the selectedquantity is within the bucket range
          (item.end_quantity === null || selectedQuantity <= item.end_quantity)
      );

      if (applicablePriceItem) {
        //if valid price item is found then
        setBucketPrice(applicablePriceItem.price); //set the price range to that butcketPrice
        setBucketSize(applicablePriceItem.bucket_size);
        setEndQuantity(applicablePriceItem.end_quantity);
        setClickedPriceItem(
          selectedProduct.price_list.indexOf(applicablePriceItem)
        ); //used to hightlight which priceItem is currently selected :
      }
    }
  }, [selectedQuantity, selectedProduct]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
       
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: "" },
        });
        setProductDETAILS(data?.inventoryItem?.response);
        console.log("data?.inventoryItem?.response ",data?.inventoryItem?.response);
        if (data?.inventoryItem?.response) {
          setAllData(data?.inventoryItem?.response);
          
          // Extract necessary details and store them in localStorage
          const productData = data?.inventoryItem?.response.map((item) => {
            const productDetails = {
              id: item.id,
              name: item.name,
              group_id: item.group_id,
              price_list: item.price_list,
            };
            return productDetails;
          });
  
          // Save to localStorage
          localStorage.setItem("productDetails", JSON.stringify(productData));
        }
        
        setLoading(false);
        updateFormattedPrice();
      } catch (error) {}
    };
    fetchData();
  }, [groupId, productId]);

  const updateFormattedPrice = () => {
    setProductDETAILS((prevDetails) => ({
      ...prevDetails,
      price_details: {
        ...prevDetails.price_details,
        machine_price: formatCurrency(prevDetails.sales_rate),
      },
    }));
  };

  const formatCurrency = (value) => {
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value || 0);
  };

  // const handleQuanttyChange = (increment) => {
  //   setSelectedQuantity((prevQuantity) => prevQuantity + increment);
  // };


  const handleQuantityChange = (increment) => {
    console.log("Handle quantity change triggered");
    console.log("Bucket Quantity", bucketQuantity);
    console.log("Barrel Quantity", barrelQuantity);

    if (isBarrel) {
      // Check if it is barrel mode
      console.log("In barrel mode : ", isBarrel);

      setBarrelQuantity((prev) => {
        const newQuantity = Math.min(Math.max(prev + increment, 1));
        console.log(`Barrel quantity updated from ${prev} to ${newQuantity}`);

        // Calculate total price for barrel
        const barrelPriceItem = selectedProduct.price_list.find(
          (item) => item.bucket_size === 210 || item.bucket_size === 180 // Assuming barrel size is 210L or 180L
        );

        if (barrelPriceItem) {
          const totalPrice = barrelPriceItem.price * newQuantity;
          console.log(`Total price for barrel calculated as: ${totalPrice}`);
          setTotalPrice(totalPrice);
        }

        return newQuantity;
      });
    } else {
      // If it is bucket mode
      console.log("In bucket mode : ", isBarrel);
      setBucketQuantity((prevQuantity) => {
        let newQuantity = Math.max(prevQuantity + increment, 1);

        console.log(
          `Bucket quantity updated from ${prevQuantity} to ${newQuantity}`
        );

        if (newQuantity === 11) {
          console.log("Switching to barrel mode");
          setIsBarrel(true);
          setBucketQuantity(1); // Reset bucketQuantity when switching
          setBarrelQuantity(1); // Initialize barrelQuantity at 1
        }

        setSelectedQuantity(newQuantity);
        console.log(`Selected quantity is now: ${newQuantity}`);

        const priceItem = selectedProduct.price_list.find(
          (item) =>
            newQuantity >= item.start_quantity &&
            (item.end_quantity === null || newQuantity <= item.end_quantity)
        );

        if (priceItem) {
          setBucketPrice(priceItem.price);
          const newTotalPrice =
            newQuantity * priceItem.price * (priceItem.bucket_size || 1);
          setTotalPrice(newTotalPrice);
        }

        return newQuantity;
      });
    }
    };
  const handlesizeandcolour = (index) => {
    const productArray = Object.values(productDETAILS).filter(
      (item) => typeof item === "object" && item.id
    );
    const selectedProduct = productArray[index];

    setInfokey(index);
    setSelectedQuantity(1);
    setSelectedItemId(selectedProduct.id);
    setSelectedProduct(selectedProduct);
  };

  const renderButtons = () => {
    console.log("productDETAILS inside renderButtons :", productDETAILS);

    const productArray = Object.entries(productDETAILS)
      .filter(([key]) => key !== "price_details")
      .map(([_, value]) => value);

    if (productArray.length === 0) {
      console.log("No products to render buttons for.");
      return <p>No product variants available.</p>;
    }

    return productArray
      .map((product, index) => {
        if (!product) {
          console.log(`Product at index ${index} is undefined or null`);
          return null;
        }

        const attribute1 = product.attributes?.attribute1;
        const attribute2 = product.attributes?.attribute2;

        console.log("Attribute 1  : ", attribute1);

        if (!attribute1 && !attribute2) {
          return null;
        }

        return (
          <button
            key={product.id}
            className={`buttonclickednotor ${
              product.id === selectedItemId ? "btn-clicked" : ""
            }`}
            onClick={() => handlesizeandcolour(index)}
          >
            {attribute1 && `${attribute1.options?.name}`}
            {attribute1 && attribute2 && <br />}
            {attribute2 && `${attribute2.options?.name}`}
          </button>
        );
      })
      .filter(Boolean);
  };

  return (
    <>
      <p className="quotationDetailsheading mt-2">Choose Variants </p>

      <div className="totalpopupdiv">
        <div className="popupContentWrapper">
          <div className="Contentisnidepopup">
            <p className="" style={{ fontWeight: "600" }}>
              {brands} - {productNamedsee || "N/A"}
            </p>

            {/* {renderButtons()} */}
            <div className="renderPricListsf mt-1">
              <p style={{ fontWeight: "600" }}> Buying Options </p>
              {renderPriceList()}
              <span style={{display:"flex",fontStyle:"italic",justifyContent:"end",color:"gray",fontWeight:600,paddingTop:"8px"}}>Transport Charges extra *</span>
            </div>

            <p className="mt-1">
              <span style={{ fontWeight: "400" }}>
              {bucketSize && (
                  <span
                    style={{ color: "gray", fontWeight: "500" }}
                    className="update-qty"
                  >
                    {/* {isBarrel ? "Barrel" : "Bucket"} -{" "} */}
                    <span
                      style={{ color: "black", fontWeight: "500" }}
                      className="s"
                    >
                      {bucketSize}{productUnits}{" "}
                    </span>{" "}
                  </span>
                )}(Pack of  {isBarrel ? barrelQuantity : bucketQuantity})
              </span>{" "}
              <br></br>
              <span style={{ fontWeight: "620" }}>
                {" "}
                ₹{totalvalue} -{" "}
                <span style={{ fontWeight: "620" }}>
                (₹{bucketPrice !== null ? bucketPrice : "..."}/{productUnits})
                </span>
              </span>
            </p>
          </div>
          <div className="imageWrapper">
            <img className="imageinpopup" src={image || "N/A"} alt="Product" />
          </div>
        </div>
        <div className="buttonsqautntiyf ">
          <div>
            Qty :{" "}
            <button
              className="butttonplusminus "
              // onClick={() => handleQuanttyChange(-1)}
              // disabled={selectedQuantity === 1}
              onClick={() => handleQuantityChange(-1)}
              disabled={
                isBarrel ? barrelQuantity <= 1 : bucketQuantity <= 1
              }
            >
              -
            </button>
            {/* &nbsp; {selectedQuantity} &nbsp; */}
            &nbsp; {isBarrel ? barrelQuantity : bucketQuantity}{" "} &nbsp;
            <button
              className="butttonplusminus"
              // onClick={() => handleQuanttyChange(1)}
              // disabled={selectedQuantity === 11}
              onClick={() => handleQuantityChange(1)}
              // disabled={
              //   isBarrel ? barrelQuantity >= 11 : bucketQuantity >= 11
              // }
            >
              +
            </button>
          </div>

          <div className="sgjrdkbg">
            <button
              className="button-submit-popup"
              onClick={handlePopupSubmit}
              // disabled={!bucketId}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationPopupForSpareAddtoCart;
