import React from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate from react-router-dom
import "./Breadcrumbs.css";
import { backIcon } from "../../../helpers/Icons";

const BreadCrumbsforSpareRevised = ({ items, backnavi }) => {
    const navigate = useNavigate();  // Initialize navigate function

    // If no backnavi prop is passed, use navigate as fallback
    const handleBackClick = () => {
        if (backnavi) {
            console.log("Back button is being clcked");
            backnavi();
        } else {
            navigate("/store");
            console.log("navigating to store");
        }
    };

    return (
        <div className="d-flex breadcrumbs-wrap">
            <button className="back-btn" onClick={handleBackClick}>
                {backIcon({ width: 30, height: 20 })}
            </button>
            {items.length > 0 && (
                <ul className="breadcrumbs-list">
                    {items.map((item, index) => (
                        <li key={index}>
                            <a href={item.link} className="heading-400-14-12">{item.name}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default BreadCrumbsforSpareRevised;
