import React,{useEffect} from 'react'
import NewBanner from '../NewBanner/NewBanner'
import FinancefirstSection from './FinancefirstSection';
import FinanceThirdSection from './FinanceThirdSection';
import FinanceSecondSection from './FinanceSecondSection';
import FinancefourthSection from './FinancefourthSection';
import Footer from '../Footer/Footer';
import FinaceFifthSection from './FinaceFifthSection';
import { f_loan,f_handsetting,averageSellingTime,f_thumb,f_handshake } from '../../helpers/Icons'

const Finance = () => {
  const product={
    imgurl:"/origaReach.svg",
    heading: "We cater to over 50 industries ",
    para: "Comprehensive Leasing and Loan Solutions for All Your Needs with Origa"};

    const topSliderData = [
      { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
      { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
      { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
      { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
      { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
      { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
      { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
      { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
      { message: "Machine Tool",imgsrc:"/asset/Machine Tool.png" },{ message: "Medical",imgsrc:"/asset/Medical.png" },{ message: "Construction",imgsrc:"/asset/Construction.png" },
      { message: "IT & Technology",imgsrc:"/asset/IT and Technology.png" },{ message: "Automative",imgsrc:"/asset/Automative.png" },{ message: "Food & Beverages",imgsrc:"/asset/Food and Beverages.png" },
      ];
      const bottomSliderData = [
      { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
      { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
      { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
      { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
      { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
      { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
      { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
      { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
      { message: "Industrial Engineering",imgsrc:"/asset/Industrial Engineering.png" },{ message: "Defense",imgsrc:"/asset/Defense.png" },{ message: "Power & Energy",imgsrc:"/asset/Power and Energy.png" },
      { message: "Pharma",imgsrc:"/asset/Pharma.png"  },{ message: "Logistics & Transportation",imgsrc:"/asset/Logistics and Transportation.png" },{ message: "Office",imgsrc:"/asset/Office.png" },
      ];
const items=[{imagesrc:f_handsetting({width:60 ,height:60}),title:"Lease",para:"400 machines leased",navi:`/finance_stepper?Type=Lease`},
{imagesrc:f_loan({width:60, height:60}),title:"Loan",para:"Over INR 200 crores financed",navi:`/finance_stepper?Type=Loan`}
]
const bannerTopSection={title:"India’s Leading Capex Advisory & Leasing Platform",para:"Simplifying the Path to Procuring Your Next Equipment",mw:"mw-603"}
const advantageData = [
  {title: "Fast Processing",description: "ORIGA’s strong networks and financial ties ensures quick  approvlas",icon:averageSellingTime({width:60,height:60})},
  {title: "Easy Procedures",description: "Straightforward documentation and user-friendly interfaces",icon: f_thumb({width:60,height:60})},
  {title: "Reliable Service",description: "Team with more than 2 decades of experience and industry network",icon: f_handshake({width:60,height:60})}
];
const advantageTopSection={imgurl:"/OrigaWhy.svg",title:"Origa Advantage",para:"Choose ORIGA for fast, easy, and reliable equipment leasing or lean solutions"}
useEffect(() => {
window.scrollTo(0,0)
}, [])
const listofdata=[
  {title:"Buy Used Equipment",message:"Find high-quality, pre-owned machines for your business needs at competitive prices.",imageurl:"/asset/f_other_benifit1.svg",btnname:"Buy Now",navigate:`/buy/product-listing?searchInput=${'MSME'}`},
  {title:"Sell Used Equipment",message:"Streamline the process of selling surplus inventory by listing it easily on our website.",imageurl:"/asset/f_other_benifit2.svg",btnname:"Sell Now",navigate:`/sell/machine-detail?Segment=${'MSME'}`},
  {title:"Equipment Service & Maintenance:",message:"Choose from a range of preventive, breakdown, and annual maintenance contracts tailored to your needs",imageurl:"/asset/f_other_benifit3.svg",btnname:"Book Now",navigate:'/service'},
  // {title:"Buy Used Equipment",message:"Find high-quality, pre-owned machines for your business needs at competitive prices.",imageurl:"/asset/f_other_benifit1.svg",btnname:"Buy Now",navigate:`/buy/product-listing?searchInput=${'MSME'}`},
  // {title:"Sell Used Equipment",message:"Streamline the process of selling surplus inventory by listing it easily on our website.",imageurl:"/asset/f_other_benifit2.svg",btnname:"Sell Now",navigate:`/sell/machine-detail?Segment=${'MSME'}`}
]
  return (
    <> 
    <NewBanner items={items} bannerTopSection={bannerTopSection}/>
    <FinanceSecondSection/>
    <FinancefirstSection topSliderData={topSliderData} product={product} bottomSliderData={bottomSliderData}/>
    <FinanceThirdSection advantageData={advantageData} topSectionData={advantageTopSection}/>
    <FinancefourthSection listofdata={listofdata}/>
    <FinaceFifthSection title={"Trusted by Leading Brands"} para={"Partnering with Top Industry Leaders Across the Globe"} searchbtn={"Get financing"}/>
    <Footer/>
    </>
  )
}

export default Finance