import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineDoneOutline } from "react-icons/md";

import Footer from "../Footer/Footer";
import axios from "axios";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../OrigaExtentionAPI/mutations";
import LoginModel from "../Authentication/LoginModel/LoginModel";
import "./CartPageSampleStyles.css";
import {
  ellipsePurpleIcon,
  ellipseWhiteIcon,
  tickIcon,
  vectorLineIcon,
} from "../../helpers/Icons";
import CartItem from "./CartItem";
import CartItemSample from "./CartItemSample";
import CartItemSampleLoggedIn from "./CartItemSampleLoggedIn";

const client = secondClient;
const SummaryCartLoggedNot = ({ summaryData, onProceedPayment }) => {
  const location = useLocation();
  const [infokey, setInfokey] = useState(0);
  
  const [productDETAILS, setProductDETAILS] = useState({});
  const [SuccessPopUp, setSuccessPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [PaymentLink, setPaymentLink] = useState([]);
  const navigate = useNavigate();
  const productId = localStorage.getItem("productIdtoCart");
  const groupId = localStorage.getItem("groupIdtoCart");

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [total, setTotal] = useState(0);

  const [loginPortal, setLoginPortal] = useState(false);
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );
  console.log("summary Data", summaryData);
 // Function to get the total price from cart items
 const getTotalCartPrice = () => {

  return cartItems.reduce((total, item) => total + (item.totalCartPriceocvf || 0), 0);
};

// Calculate total cart price
const totalCartPrice = getTotalCartPrice();



  const uniqueProductIds = [
    ...new Set(cartItems.map((item) => item.productId)),
  ];
  const [loggedin, setLoggedin] = useState(!!localStorage.getItem("userToken"));
  const [quantity, setQuantity] = useState("");
  // Count the number of unique productIds
  // const totalUniqueItems = uniqueProductIds.length;
  // const calculateTotalPrice = () => {
  //   return cartItems.reduce((total, item) => {
  //     return total + item.salePrice;
  //   }, 0);
  // };

  // const totalPrice = calculateTotalPrice();


 const [totalQuantity, setTotalQuantity] = useState(0);
 const [totalPrice, setTotalPrice] = useState(0);

 const getCartItems = () => {
  return JSON.parse(localStorage.getItem("cartItems")) || [];
};

 useEffect(() => {
    const totalItems = getTotalQuantity();
    setTotalQuantity(totalItems);
  }, []);


  const getTotalQuantity = () => {
    const cartItems = getCartItems();
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  useEffect(() => {
    const total = getTotalPrice();
    setTotalPrice(total);
  }, []);


  const getTotalPrice = () => {
    const cartItems = getCartItems();
    return cartItems.reduce((total, item) => total + (item.salePrice * item.quantity), 0);
  };


  const onHidePortal = () => {
    setLoginPortal(false);
  };

  useEffect(() => {
    const checkCartItems = () => {
      // Retrieve cartItems from localStorage
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      const cartItemsLength = cartItems.length;

      // Check if user is logged in
      const loggedIn = !!localStorage.getItem("userToken");
     
      // Show login portal if cartItems length is 0 and user is not logged in
      if (cartItemsLength === 0 && !loggedIn) {
        setLoginPortal(true);
      }
    };

    checkCartItems();
  }, []);

  const cartItemsString = localStorage.getItem("cartItems");
  const cartItemsnow = cartItemsString ? JSON.parse(cartItemsString) : []; // Ensure it's an empty array if null
  const quantities = cartItemsnow.map(item => item.quantity); // Safe to use map now
  // console.log("Quantities from cart:", quantities);




  // console.log("Cart items length:-------------------------------------> ", cartItemsLength);
  //   console.log("Cart Items quantity :  -------------------------------->");

  let draftItems = [];
 

  // useEffect(() => {
  //   const checkIfCartIsEmpty = () => {
  //     console.log("Cart items present: ", cartItems);
  //     if (cartItems.length === 0) {
  //       // Handle case when cart is empty
  //       console.log("Cart is empty.");
  //     } else {
  //       // Handle case when cart is not empty
  //       console.log("Cart has items.");
  //     }
  //   };

  //   checkIfCartIsEmpty();
  // }, [cartItems]);

  const handleIncreaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };
  useEffect(() => {
    const addCartItems = async () => {
      const loggedin = !!localStorage.getItem("userToken");
      const localCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      const ompuserId = localStorage.getItem("id") ;
      const customer_first_name =
        localStorage.getItem("firstName") ;
      const customer_last_name =
        localStorage.getItem("lastName");
      const customer_contact_no =
        localStorage.getItem("number");
      const customer_email =
        localStorage.getItem("emailId") ;

      if (loggedin) {
        const requestBody = {
          items: [], // Initially empty as we'll fetch from API
          ompuserId, //get the id from the localStorage
          customer_first_name,
          customer_last_name,
          customer_contact_no,
          order_status: "Draft",
          order_ref_number: "",
          customer_email,
          input: {
            city: "",
            cityArea: "",
            companyName: "",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "  ",
            phone: "",
            postalCode: "",
            streetAddress1: " ",
            streetAddress2: "",
          },
          businessaddress: {
            city: "",
            cityArea: "",
            companyName: " ",
            country: "",
            countryArea: "",
            firstName: "",
            lastName: "",
            phone: "",
            postalCode: "",
            streetAddress1: "",
            streetAddress2: "",
          },
          gst_no: "",
        };

        try {
          // Fetch draft items
          const draftResponse = await fetch(
            "https://ompextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            }
          );

          if (!draftResponse.ok) {
            throw new Error("Failed to fetch draft items");
          }

          const draftData = await draftResponse.json();
          setCartItems(draftData.line_items || []);

          setSubtotal(draftData.subtotal || 0);
          setTaxTotal(draftData.tax_total || 0);
          setDiscountTotal(draftData.discount_total || 0);
          setDiscountPercent(draftData.discount_percent || 0);
          setShippingCharge(draftData.shipping_charge || 0);
          setTotal(draftData.total || 0);
          let draftItems = draftData.line_items || []; // Assuming draft items are in line_items array

          // Merge localCartItems with draftItems based on item_id
          draftItems = mergeItems(localCartItems, draftItems);

          // Update cartItems state
          setCartItems(draftItems.slice(0, 3)); // Limit to 3 items
        } catch (error) {
          console.error("Error fetching or merging cart items:", error);
        }
      } else {
        setCartItems(localCartItems); // Use local cart items if not logged in
      }
    };

    addCartItems(); // Call the function to add cart items on component mount
  }, []);

  // Function to merge local items with draft items
  const mergeItems = (localItems, draftItems) => {
    const mergedItems = [...draftItems];

    localItems.forEach((localItem) => {
      const foundIndex = mergedItems.findIndex(
        (item) => item.item_id === localItem.item_id
      );
      if (foundIndex !== -1) {
        mergedItems[foundIndex].quantity += localItem.quantity;
      } else {
        mergedItems.push(localItem);
      }
    });

    return mergedItems;
  };

  const handleCreateOrder = async () => {
    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      // Handle case where user is not logged in
      setLoginPortal(true); // Assuming this sets state to show login portal
      return;
    }

    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    console.log("localCartItems :: ", localCartItems);
    

    var itemList = [];
    let bucketId = null;
    for (var item of localCartItems) { // Use 'of' to iterate over array elements
      var obj = {};
      
      obj["item_id"] = item["productId"];
      console.log("Cart Items While Loggin in handleCreateOrder Function in CartitemSampleLoggedin.jsx")
      console.log("Quantity in localCartitems :  --------------> ", item["quantity"]);
      obj["quantity"] = item["quantity"];
      obj["bucket_id"] = item["bucketId"]; // Use bucket_id

      console.log("Bucketid in localCartitems :  --------------> ", item["bucketId"]);

      if (bucketId === null) {
        bucketId = item["bucketId"]; // Set bucketId if it's null
      }

      itemList.push(obj);
    }
    console.log("itemList :: ", itemList);
    const ompuserId = localStorage.getItem("id");
    
    const customer_first_name =
      localStorage.getItem("firstName") ;
    const customer_last_name =
      localStorage.getItem("lastName");
    const customer_contact_no =
      localStorage.getItem("number") ;
    const customer_email =
      localStorage.getItem("emailId") ;

    const requestBody = {
      items: itemList,
      // bucket_id: bucketId, 
      ompuserId, 
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: "Draft",
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "  ",
        phone: "",
        postalCode: "",
        streetAddress1: " ",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: " ",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "",
    };

    try {
      console.log(
        "requestBody  ------------------------------------------------------------- > :: ",
        requestBody
      );
      // Create order request
      const createOrderResponse = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!createOrderResponse.ok) {
        throw new Error("Failed to create order");
      }

      const createOrderData = await createOrderResponse.json();
      const draftItems = createOrderData.line_items || []; 
      console.log("Created order with items:", draftItems);
      setCartItems([]);
      
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  

  const handleProceedPaymentbtn = () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      handleCreateOrder();
      navigate("/manageaddresspart");
    } else {
      setLoginPortal(true);
    }
  };

  const handleModal = async () => {
    const loggedIn = !!localStorage.getItem("userToken");
    if (!loggedIn) {
      navigate("/login");
      return;
    }

    navigate(`/buy/add-address?id=${productId}&sparetools=true`);
  };

  const productName = productDETAILS[0]?.name || "N/A";
  const productBrand = productDETAILS[0]?.brand || "N/A";
  const productImage =
    productDETAILS[0]?.images?.productImages[0]?.url || "N/A";
  const salePrice = productDETAILS[0]?.saleprice || 0;
  const AvailableStock = productDETAILS[0]?.available_stock || "N/A;";


  const calculateTotalQuantity = () => {
    // Assuming cartItems is the array of cart data
    const total = cartItems.reduce((sum, product) => {
      if (product.bucketSize === 210) {
        // For barrels, adjust the quantity
        const barrelQuantity = Math.ceil(product.quantity / 11);
        return sum + barrelQuantity;
      } else {
        // For buckets, use the quantity as is
        return sum + product.quantity;
      }
    }, 0); // Start with 0 as the initial sum
    return total;
  };
  
  // Usage
  const [totalQuantityed, setTotalQuantityed] = useState(0);
  
  useEffect(() => {
    const total = calculateTotalQuantity();
    setTotalQuantityed(total);
    console.log("Total Quantity in Cart:", total);
  }, [cartItems]);
  

  



  return (
    <div>
      <div className="rifhtsidebortertotaldivcontent">
        <p>
          {/* <div class="meter-container">
            <div class="meter">
              <div class="meter-fill" style={{ width: "80%" }}></div>
            </div>
          </div> */}
          <IoCheckmarkDoneSharp className="ioiconscc" size={28} /> &nbsp;
          <span className="orderdetailscart">
            Your order is ready for processing.{" "}
          </span>
          <br></br>
        </p>
        <p className="summaryContent">
          <span className="summaryItem">
            <span className="label labledddd">Summary</span>
            <span  className="value inStocktextcontent">(Excl.Tax)</span>
          </span>
          {/* <span className="summaryItem">
            <span className="label">Total quantity:</span>
            <span className="value">{totalQuantityed}</span>
          </span> */}

  

          <span className="summaryItem">
                <span className="label">Sub Total:</span>
                <span className="value">₹{totalCartPrice}</span>
              </span>

          <span className="summaryItem">
            <span className="label">Our Price:</span>
            <span className="value">
              ₹{totalCartPrice}
            </span>
          </span>
        </p>
      </div>

      <div className="proceddcartbuttondiv">
        <button className="proceedcartbtn" onClick={handleProceedPaymentbtn}>
          Proceed to Buy
        </button>
      </div>
      {loginPortal && <LoginModel onHide={onHidePortal} />}
    </div>
  );
};

export default SummaryCartLoggedNot;
