// import { connectRefinementList } from "react-instantsearch-dom";
// import React, { useState,useEffect } from "react";



// const ConnectedCustomRefinementList = ({ attribute, items, refine, currentRefinement, setIndustryCheckBoxValue, CategoryCheckBoxValue, setCategoryCheckBoxValue }) => {
//     console.log('attribute---->', attribute);
//     console.log('items :: ', items)
//     const regex = /.*?>\s*/g;
//     const [selectedValues, setSelectedValues] = useState([]);
//     const handleCheckboxChange = (event) => {
//         const value = event.currentTarget.value;
//         const newSelectedValues = [...selectedValues];

//         if (event.target.id === "categories.lvl0") {
//             setIndustryCheckBoxValue(newSelectedValues);
//         }

//         if (event.target.id === "categories.lvl1") {
//             if (event.currentTarget.checked) {
//                 setCategoryCheckBoxValue([value]);
//                 refine(value);
//                 return;
//             }
//             else {
//                 setCategoryCheckBoxValue([]);
//                 refine([]);
//                 return
//             }

//         }

//         if (event.currentTarget.checked) {
//             newSelectedValues.push(value);
//         } else {
//             const index = newSelectedValues.indexOf(value);
//             newSelectedValues.splice(index, 1);
//         }

//         setSelectedValues(newSelectedValues);
//         refine(newSelectedValues);
//         }
//     const handleReset = () => {
//         setSelectedValues([]);
//         setIndustryCheckBoxValue([]);
//         setCategoryCheckBoxValue([]);
//         refine([])
//     };
//     useEffect(() => {
    
//         const resetBtn = document.getElementById('ResetBtn');

//         if(selectedValues.length > 0 || CategoryCheckBoxValue.length > 0){
//             resetBtn.style.display = 'block';
//         }
//         if(selectedValues.length === 0 && CategoryCheckBoxValue.length === 0){
//             resetBtn.style.display = 'none';
//         }


//         resetBtn.addEventListener('click', handleReset);

       
//         return () => {
//             resetBtn.removeEventListener('click', handleReset);
//         };
//     }, [selectedValues]);
    
  

//     return (
//         <>
//             {items.length === 0 ? (
//                 <p>No records found.</p>
//             ) : (
//                 items
//                     .filter(item => item.label !== '0' && item.label !== '1900')
//                     .map((item) => (
//                         <div className="checkbox-item" key={item.id} >
//                             <label className="heading-400-16">{item.label.replace(regex, "")} ({item.count})</label>
//                             <input id={attribute} type="checkbox" name={item.name} value={item.label}
//                                 checked={
//                                     attribute === "categories.lvl1"
//                                         ? CategoryCheckBoxValue.includes(item.label)
//                                         : selectedValues.includes(item.label)
//                                 }
//                                 onChange={handleCheckboxChange} />
//                         </div>

//                     ))
//             )}
//         </>
//     );

// };

// export default connectRefinementList(ConnectedCustomRefinementList);




import { connectRefinementList } from "react-instantsearch-dom";
import React, { useState,useEffect } from "react";



//--------------------------CheckBox Filter For Category,Sub-Category--------------(MULTI-SELECT INPUT)--------------------------------


const ConnectedCustomRefinementList = ({
  attribute,
  items,
  refine,
  currentRefinement,
  setIndustryCheckBoxValue,
  CategoryCheckBoxValue,
  setCategoryCheckBoxValue,

  setIsGridView,
  setIsPriceSelected,
  setIsIndustrySelected,
  setIsCategorySelected,
  setIsBrandsSelected,
  setIsModelSelected,
  setIsLocationSelected
  }) => {
    // console.log('attribute---->', attribute);
    // console.log('items :: ', items);
    const regex = /.*?>\s*/g;
    const [selectedValues, setSelectedValues] = useState([]);
    const [visibleItemsCount, setVisibleItemsCount] = useState(4);
    const [isExpanded, setIsExpanded] = useState(false);
    // useEffect(() => {
    //     // console.log(`Values under attribute "${attribute}":`);
    //     console.log(items.map(item => item.label.replace(regex, "")));
    // }, [attribute, items]);
  
    // useEffect(() => {
    //     console.log(`Values under attribute "${attribute}":`);
    //     const firstTwoItems = items.slice(0, 2);
    //     console.log(firstTwoItems.map((item) => item.label.replace(regex, "")));
    //   }, [attribute, items]);
  
    const handleCheckboxChange = (event) => {
      console.log("Clicking this : ");
      const value = event.currentTarget.value;
      const newSelectedValues = [...selectedValues];
  
      console.log(
        `CheckBox changed : ${value} , Checked : ${event.currentTarget.checked}`
      );
  
      if (event.target.id === "categories.lvl0") {
        setIndustryCheckBoxValue(newSelectedValues);
      }
  
      if (attribute === "categories.lvl1") {
        const isChecked = event.currentTarget.checked;

        const newValue = isChecked ? [value] : [];
        console.log("newValue : ",newValue)
        setCategoryCheckBoxValue(newValue);
        refine(newValue);
        return;
      }
  
      
  
    
      if (event.target.id === "categories.lvl1 ") {
        console.log("categories.lvl1 - HealthCare part");
        if (event.currentTarget.checked) {
          console.log("HealthCare is displaying:");
          setCategoryCheckBoxValue([value]);
          refine(value); 
          return;
        } else {
          console.log("Removing from 'categories.lvl1':", value);
          setCategoryCheckBoxValue([]);
          refine([]);
          return;
        }
      }
  
      if (event.currentTarget.checked) {
        newSelectedValues.push(value);
      } else {
        const index = newSelectedValues.indexOf(value);
        newSelectedValues.splice(index, 1);
      }
  
      setSelectedValues(newSelectedValues);
      refine(newSelectedValues); 
    };

    const handleReset = () => {
      setSelectedValues([]);
      setIndustryCheckBoxValue([]);
      setCategoryCheckBoxValue([]);
      refine([]);
    };
  
    useEffect(() => {
      if (attribute === "categories.lvl1") {
        setSelectedValues(CategoryCheckBoxValue);
        console.log(selectedValues);
      }
    }, [CategoryCheckBoxValue, attribute,selectedValues]);
    useEffect(() => {
      // Get the Reset button element
      const resetBtn = document.getElementById("ResetBtn");
  
      if (selectedValues.length > 0 || CategoryCheckBoxValue.length > 0) {
        resetBtn.style.display = "block";
      }
      if (selectedValues.length === 0 && CategoryCheckBoxValue.length === 0) {
        resetBtn.style.display = "none";
      }
  
      // Add a click event listener to the Reset button
      resetBtn.addEventListener("click", handleReset);
  
      // Clean up: remove the event listener when the component unmounts
      return () => {
        resetBtn.removeEventListener("click", handleReset);
      };
    }, [selectedValues]);
  
    // const headDiv = document.querySelector('.head');
    // const existingResetButton = headDiv?.querySelector('.reset-button');
  
    // // Check if a Reset button already exists
    // if (!existingResetButton && currentRefinement.length > 0) {
    //     const resetButton = document.createElement('div');
    //     resetButton.classList.add('clear', 'heading-600-14', 'reset-button');
    //     resetButton.textContent = 'Reset';
    //     resetButton.addEventListener('click', handleReset);
  
    //     headDiv.appendChild(resetButton);
    // } else if (existingResetButton && currentRefinement.length === 0) {
    //     // Remove the existing Reset button if no refinement is applied
    //     existingResetButton.remove();
    // }
  
    const [showAll, setShowAll] = useState(false);
  
    const handleLoadMore = () => {
      setShowAll(true);
    };
  
    const handleShowMore = () => {
      setVisibleItemsCount(items.length);
      setIsExpanded(true);
    };
  
    const handleShowLess = () => {
      setIsExpanded(false);
      setVisibleItemsCount(4);
    };
  
    return (
      <>
        {items.length === 0 ? (
          <p>No records found.</p>
        ) : (
          items
            .filter((item) => item.label !== "0" && item.label !== "1900")
            // .slice(0, showAll ? items.length : 2)
            .slice(0, visibleItemsCount)
            .map((item) => (
              // <div className="checkbox-item" key={item.id}  >
              //     <label className="heading-400-16">{item.label.replace(regex, "")} ({item.count})</label>
              //     <input id={attribute} type="checkbox" name={item.name} value={item.label}
              //         checked={
              //             attribute === "categories.lvl1"
              //                 ? CategoryCheckBoxValue.includes(item.label)
              //                 : selectedValues.includes(item.label)
              //         }
              //         onChange={handleCheckboxChange} />
              // </div>
              <div
                className="checkbox-item"
                key={item.id}
                style={{ justifyContent: "normal" }}
              >
                <input
                  // id={attribute}
                  id={`${attribute}-${item.label}`}
                  type="checkbox"
                  name={item.name}
                  value={item.label}
                  checked={
                    attribute === "categories.lvl1"
                      ? CategoryCheckBoxValue.includes(item.label)
                      : selectedValues.includes(item.label)
                  }
                  onChange={handleCheckboxChange}
                />
                &nbsp;
                <label className="heading-400-16">
                  {item.label.replace(regex, "")}{" "}
                </label>
                ({item.count})
              </div>
            ))
        )}
        {/* {items.length > 2 && !showAll && (
             <div >
               <button  onClick={handleLoadMore} className="load-more-button">
                Load More
              </button>
  
             </div>
            )} */}
  
        {items.length > visibleItemsCount && (
          <div className="loadmorebuttondiv">
            <button onClick={handleShowMore} className="load-more-button">
              + {items.length - visibleItemsCount} more{" "}
              {/* {items.length - visibleItemsCount === 1 ? "item" : "items"} */}
            </button>
          </div>
        )}
  
        {isExpanded && (
          <div className="loadmorebuttondiv">
            <button onClick={handleShowLess} className="load-more-button">
              Show Less
            </button>
          </div>
        )}
      </>
    );
  };
  
  export default connectRefinementList(ConnectedCustomRefinementList);
  