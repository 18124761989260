import React, { useEffect, useState } from "react";
import "./OrderHistory.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../SubComponent/Loader";

//icons
import { MdCircle } from "react-icons/md";
import { LiaNewspaperSolid } from "react-icons/lia"; //Quotation raised
import { PiNewspaperClippingLight } from "react-icons/pi"; //Quotation Approved
import { TbFileInvoice } from "react-icons/tb"; //invoice
import { MdOutlineDone } from "react-icons/md"; //Done               //Shipped
import { CiDeliveryTruck } from "react-icons/ci";
import { BiPackage } from "react-icons/bi";
import { IoCartOutline } from "react-icons/io5";

const OrderHistory = () => {
  //Navigation
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const retrievedData = JSON.parse(localStorage.getItem("filteredHitsToRender"));
  console.log("Retrieved Data from localStorage:", retrievedData);
  const id = queryParams.get("id");
  const message = queryParams.get("message");

  //States
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [error, setError] = useState(null);
  const [statusforcard, setStatusforcard] = useState("Delivered");
  const [isMobileLay, setIsMobileLay] = useState(false);

  // Fetch orders for the customer
  useEffect(() => {
    const fetchOrders = async () => {
      const customer_id = localStorage.getItem("id");
      const requestBody = { customer_id };

      try {
        const response = await fetch(
          "https://ompextension.origa.market/sparesapi/get_orders",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }

        const data = await response.json();
        console.log("API response of all orders :", data);

        if (data.orders) {
          const groupedOrders = data.orders.reduce((acc, order) => {
            if (!acc[order.reference_id]) {
              const uniqueLineItems = order.line_items.reduce(
                (uniqueItems, item) => {
                  if (
                    !uniqueItems.some(
                      (existingItem) => existingItem.id === item.id
                    )
                  ) {
                    uniqueItems.push(item);
                  }
                  return uniqueItems;
                },
                []
              );
              acc[order.reference_id] = {
                ...order,
                line_items: uniqueLineItems,
              };
            }
            return acc;
          }, {});

          setOrders(Object.values(groupedOrders));
        } else {
          setOrders([]);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Fetch order details based on ID and message
  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      console.log("Fetching order details for:", {
        customerId,
        referenceNumber,
      });

      try {
        const response = await fetch(
          "https://ompextension.origa.market/sparesapi/get_orders",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        console.log("API response of individual order id :", data);

        if (data.order) {
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (id && message === "Paid") {
      const customerId = localStorage.getItem("id");
      fetchOrderDetails(customerId, id);
    }
  }, [id, message]);


  const BuyAgain = async (referenceNumber) => {
    console.log("Buy Again clicked for reference number:", referenceNumber);
  
    const customerId = localStorage.getItem("id");
  
    try {
      const response = await fetch(
        "https://ompextension.origa.market/sparesapi/get_orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_id: customerId,
            reference_number: referenceNumber, // Fetch specific order details
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to fetch order details");
      }
  
      const data = await response.json();
      console.log("Full response : ",data);
      if (data.order) {
        console.log("Items in the order:", data.order.line_items);
        const itemIdFromOrder = data.order.line_items[0]?.item_id;
        console.log("Itemid of the order:", itemIdFromOrder);

        const matchedItem = retrievedData.find(item => item.objectID === itemIdFromOrder);

        if (matchedItem) {
          const groupId = matchedItem.group_id;
          console.log("Found group_id:", groupId);
        
          // Navigate to the spares-revised page with the group_id
          navigate(`/buy/spares-revised?group_id=${groupId}`);
        } else {
          console.log("No matching item found for the item_id:", itemIdFromOrder);
        }
      } else {
        console.log("No order found for this reference number.");
      }
    } catch (error) {
      console.error("Error fetching Buy Again order details:", error);
    }
  };

  /*Stepper Logics  :   */
  const stepsforjson1 = [
    {
      label: "Quotation Raised",
      description: `The customer has raised a quotation`,
      icon: <LiaNewspaperSolid className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Quotation Approved",
      description: "Quotation being successfully approved",
      icon: <PiNewspaperClippingLight className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Proforma Invoice",
      description: `Downloadable PDF`,
      icon: <TbFileInvoice className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: ``,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: ``,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];
  
  const stepsforjson2 = [
    {
      label: "Quotation Raised",
      description: `The customer has raised a quotation`,
      icon: <LiaNewspaperSolid className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Quotation Approved",
      description: "Quotation being successfully approved",
      icon: <PiNewspaperClippingLight className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: ``,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: ``,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];
  
  const stepsforjson3 = [
      {
          label: "Proforma Invoice",
          description: `Downloadable PDF`,
          icon: <TbFileInvoice className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Ordered",
          description: `Order being processed`,
          icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Packaged",
          description: `Started to packaging it`,
          icon: <BiPackage className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Shipped",
          description: ``,
          icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Delivered",
          description: ``,
          icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
        },
  ];
  
  const stepsforjson4 = [
      {
          label: "Ordered",
          description: `Order being processed`,
          icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Packaged",
          description: `Started to packaging it`,
          icon: <BiPackage className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Shipped",
          description: ``,
          icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
        },
        {
          label: "Delivered",
          description: ``,
          icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
        },
  ];

  const stepperArray = [stepsforjson1,stepsforjson2,stepsforjson3,stepsforjson4];


  /*Redirecting to the individual orders page */
  const viewDetailsFunctionDummy = (referenceNumber, message, orderIndex) => {
    console.log("viewDetailsFunctionDummy called:");
    console.log("Reference Number:", referenceNumber);
    console.log("Message:", message);
    console.log("Order Index:", orderIndex);
    
    setLoading(true);
    setTimeout(() => {
      console.log("Navigating to ordersummarycartmy...");
      navigate(`/ordersummarycartmy?id=${referenceNumber}&message=${message}`, {
        state: { orderIndex }
      });
      console.log("Loading completed, state set with orderIndex:", orderIndex);
      setLoading(false);
    }, 1000);
  };
  
  // const identifyhowmanyquantitieswithname = (lineItems) => {
  //   if (lineItems.length === 0) {
  //     return "No Items";
  //   }

  //   const firstItemName = lineItems[0]?.name || "Unknown Item";
  //   const additionalItemCount = lineItems.length - 1;

  //   if (additionalItemCount === 0) {
  //     return firstItemName;
  //   }

  //   return `${firstItemName} + ${additionalItemCount}`;
  // };

  /*To show content based on Whether it is delivered  , cancelled or process is ongoing */
  // const renderStatusContent = () => {
  //   switch (statusforcard) {
  //     case "Delivered":
  //       return (
  //         <div>
  //           <MdCircle color="green" size={10} />
  //           &nbsp;
  //           <span>Delivered on Jun 2, 2023</span>
  //           <span style={{ display: "block" }}>
  //             Your items have been delivered.
  //           </span>
  //         </div>
  //       );
  //     case "Cancelled":
  //       return (
  //         <div>
  //           <MdCircle color="red" size={10} />
  //           &nbsp;
  //           <span>Cancelled on Jun 2, 2023</span>
  //         </div>
  //       );
  //     case "Ongoing":
  //       return (
  //         <div>
  //           <MdCircle color="gray" size={10} />
  //           &nbsp;
  //           <span>Process Ongoing</span>
  //         </div>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  

  useEffect(() => {
    const resize = () => {
      setIsMobileLay(window.innerWidth <= 576);
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);



  
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid">
        <div className="custom-margin pt-3">
        {orders.length > 0 ? (
  orders.map((order, orderIndex) => {
    // console.log("Order Index:", orderIndex);
    return (
      <div key={orderIndex} className="individual-order-details-div">
        <>
          {isMobileLay ? (
            <div className="topof-individual-order-details-div">
              {/* Mobile mode of card*/}
              <div className="leftside-of-top-viewindividual-order-details-div d-flex">
                <div>
                  <span className="headingfororders">Order Placed</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    {order.created_at || "N/A"}
                  </span>
                </div>
                <div>
                  <span className="headingfororders">Total</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    ₹{order.subtotal}
                  </span>
                </div>
                <div>
                  <span className="headingfororders">Ship to</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                  {order.customer_name ? order.customer_name.split(/(?=[A-Z])/).join(" ") : "N/A"}
                  </span>
                </div>
              </div>
              <div className="rightside-of-top-viewindividual-order-details-div ">
                <div>
                  <span className="headingfororders"> Order #</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    {order.reference_id}
                  </span>
                </div>

                <div>
                  <span className="headingfororders">
                    {" "}
                    Order Status :
                  </span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    <span className="order-statusofbo">
                      {order.order_status}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="topof-individual-order-details-div">
              {/* Desktop mode of card*/}
              <div className="leftside-of-top-viewindividual-order-details-div d-flex">
                <div>
                  <span className="headingfororders">Order Placed</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    {order.created_at || "N/A"}
                  </span>
                </div>
                <div>
                  <span className="headingfororders">Total</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    ₹{order.subtotal}
                  </span>
                </div>
                <div>
                  <span className="headingfororders">Ship to</span>
                  <span
                    className="textfororders"
                    style={{ display: "block" }}
                  >
                    {/* {order.customer_name || "N/A"} */}
                    {order.customer_name ? order.customer_name.split(/(?=[A-Z])/).join(" ") : "N/A"}
                  </span>
                </div>
              </div>
              <div className="rightside-of-top-viewindividual-order-details-div ">
                <div className="textfororders">
                  Order # {order.reference_id}
                </div>
                <div className="textfororders">
                  Order Status :{" "}
                  <span className="order-statusofbo">
                    {order.order_status}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>

        <div className="bottomof-individual-order-details-div">
          {order.line_items.map((item, itemIndex) => (
            // {console.log("Order items : ",)}
            <div
              key={itemIndex}
              className="d555dddsvbf row justify-content-start align-items-center"
            >
              <div className=" imagedivfirstpart">
                <img
                  className="order-img-1"
                  src={
                    item.thumbnail?.[0]?.url || "placeholder-image.jpg"
                  }
                  alt="Product"
                />
              </div>

              <div className=" namedivsecondpart">
                <h6 style={{ fontWeight: "600", textAlign: "left" }}>
                  {order.line_items[0]?.name}
                  {order.total_quantity > 1 &&
                    ` + ${order.total_quantity - 1}`}
                </h6>
                <span style={{ textAlign: "left", display: "block" }}>
                  {" "}
                  Order Total: ₹{order.subtotal}
                </span>
                <span style={{ textAlign: "left", display: "block" }}>
                  {" "}
                  Total Quantity: {order.total_quantity}
                </span>

                {/* <div className="d-flex align-items-center mt-2">
                  <div style={{ textAlign: "left" }}>
                    {renderStatusContent()}
                  </div>
                </div> */}
              </div>

              <div className=" buttonsdivthirdpart rightside-second-div-of-bottom-individual-order-details-div">
                <button
                  className="button-standard button-view-invoice"
                  onClick={() =>
                    viewDetailsFunctionDummy(order.reference_id, "Paid", orderIndex)
                  }
                >
                  View Details
                </button>
                <button
                  className="button-standard button-view-order-details"
                  // onClick={() => {
                  //   setLoading(true);
                  //   setTimeout(() => {
                  //     setLoading(false);
                  //     navigate("/store");
                  //   }, 1000);
                  // }}
                  onClick={() => BuyAgain(order.reference_id)}
                >
                  Buy Again
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* <ReactStepperExample stepperLogic={currentStepper} /> */}
      </div>
    );
  })
) : (
  <div></div>
)}

        </div>
      </div>
    </>
  );
};

export default OrderHistory;
