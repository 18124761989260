import React, { useState, useEffect } from "react";
import "./ProductListing.css";
import LoginModel from '../../Authentication/LoginModel/LoginModel';
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import Footer from '../../Footer/Footer';
import { shortIcon, filterIcon, heartIcon, closeIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ALGOLIA_ID, ALGOLIA_KEY, ALGOLIA_SELLER_INDEX, ALGOLIA_SPARE_INDEX } from '../../../constants'
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    connectRange,
    connectStateResults
} from "react-instantsearch-dom";
import FilterSection from "./FilterSection";
import Loader from "../../SubComponent/Loader";
import CategoryPopup from "../../Account/Popup/CategoryPopup";
import CustomHits from "./CustomHits"
import SpareHits from "./SpareHits"
import { filterAttribute } from './FilterAttribute';
import Slider from '@mui/material/Slider';
import CustomerInfo from './CustomerInformation/CustomerInfoModel';
import SpareHitse from "./SpareHits/SpareHitse";
import useWishListAddOrUpdate from  "../../SubComponent/useWishListAddOrUpdate"
import { secondClient, GET_SPARE_DETAILS } from '../../OrigaExtentionAPI/mutations'
import { gql } from '@apollo/client';
import QuotationPopupForInSpare from "./SpareHits/QuotationPopupForInSpare";
import QuotationPopupForSpareAddtoCart from "./SpareHits/QuotationPopupForSpareAddtoCart";
import aa from "search-insights";
import { v4 as uuidv4 } from "uuid";


const GET_WISHLIST_DATA = gql`
query userWishlists($userid:String!) {
    userWishlists(userId: $userid) {
      message
      code
      response
    }
  }
  
`

const client = algoliasearch(ALGOLIA_ID,  ALGOLIA_KEY);

const CombinedHits = ({
    hits,
    toggleGridforthree,
    toggleGridforfour,
    deliveryAvailable,
    isGridView,
    isGradeSelected,
    sendEvent,
    S,}) => {
  const location = useLocation();
  const userId = localStorage.getItem('id');
  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get('searchInput');
  const [combinedResults, setCombinedResults] = useState({ sellerResults: [], spareResults: [] });
  const [productDETAILS, setProductDETAILS] = useState({});
  const [quantity, setQuantity] = useState(1);
  const queryParams = new URLSearchParams();
  const [loginPortal, setLoginPortal] = useState(false);
  const productId = queryParams.get('id');//to get the id from url
  const groupId = queryParams.get('group_id');//to get the id from url
  const ompuserId = localStorage.getItem("id");
  const customer_first_name = localStorage.getItem("firstName");
  const customer_last_name = localStorage.getItem("lastName");
  const customer_contact_no = localStorage.getItem("number");
  const customer_email = localStorage.getItem("emailId");
  const [WishListData, setWishListData] = useState([]);
  // console.log('productId---->', productId);



  useEffect(() => {
    console.log("deliveryAvailable in CombinedHits.jsx :", deliveryAvailable);
  }, [deliveryAvailable]);
   const [
      QuotationPopupforAddtoCartinSpare,
      setQuotationPopupforAddtoCartinSpare,
    ] = useState();
  
    const closePopup4 = () => {
      setQuotationPopupforAddtoCartinSpare(false);
      navigate("/store");
    };
  
  useEffect(() => {
    const fetchWishListData = async () => {
        try {
            const { data } = await client.mutate({
                mutation: GET_WISHLIST_DATA,
                variables: {
                    "userid": userId
                }
            });
            if (data) {
                const wishlistItems = data.userWishlists.response[0]?.items;
                //console.log('wishlistdata---->', wishlistItems);
                if (wishlistItems) {
                    const productIds = wishlistItems.map(item => item.product_id);
                    setWishListData(productIds);

                }
            }
        } catch (error) {
            console.error('Error fetching wishlist data:', error);
        }
    };

    fetchWishListData();
}, [userId]);


  const [buymachineId, setBuymachineId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [infokey, setinfokey] = useState(0);
  const productIdtoCart = productDETAILS?.[infokey]?.id;

  
  useEffect(() => {
    const fetchResults = async () => {
      try {
        // Split the search input by spaces into individual terms
        const searchTerms = searchInput.split(" ");
        
        // Create an array of queries for each term in both indices
        const queries = searchTerms.flatMap(term => [
          { indexName: ALGOLIA_SELLER_INDEX, query: term },
          { indexName: ALGOLIA_SPARE_INDEX, query: term }
        ]);

        // Execute all the queries
        const { results } = await client.multipleQueries(queries);

        // Combine results from both indices
        const sellerResults = results.filter(result => result.index === ALGOLIA_SELLER_INDEX && result.hits.length > 0);
        const spareResults = results.filter(result => result.index === ALGOLIA_SPARE_INDEX && result.hits.length > 0);

        setCombinedResults({
          sellerResults: sellerResults.flatMap(result => result.hits),
          spareResults: spareResults.flatMap(result => result.hits)
        });
      } catch (error) {
        console.error("Error fetching combined results:", error);
      }
    };

    if (searchInput) {
      fetchResults();
    }
  }, [searchInput]);


  const findYearDifference = (targetYear) => {
    const currentYear = new Date().getFullYear();
    const yearDifference = currentYear - targetYear;
    return yearDifference;
}
const priceConvert = (price) => {
    price = typeof price === 'string' ? price : String(price);


    let count = 1;
    let comma = 3;
    let formatedPrice = ""
    for (let i = price.length - 1; i >= 0; i--) {
        formatedPrice = price[i] + formatedPrice
        if (count === comma) {
            formatedPrice = "," + formatedPrice
            comma = 2;
            count = 0;
        } count++;

    }
    //console.log("==>>", formatedPrice)
    if (formatedPrice[0] === ",") {
        formatedPrice = formatedPrice.slice(1, formatedPrice.length)
    }
    return formatedPrice;



};
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      let id = localStorage.getItem("id");
      if (id === null) {
        id = "";
      }
      try {
        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          variables: { groupId: groupId || "", itemId: productId || "" },
        });
        // Sets the fetched data to state variable 'productDETAILS'
        setProductDETAILS(data?.inventoryItem?.response);
        // Sets 'loading' state to false after data is fetched
        setLoading(false);
        // Updates formatted price
        updateFormattedPrice();
      } catch (error) {}
      finally{
        setLoading(false);

      }
    };
    fetchData();
  }, [groupId, productId]);
  

  
  const updateFormattedPrice = () => {//is used to update how the price of a product is displayed on a webpage
    setProductDETAILS((prevDetails) => ({//it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
        ...prevDetails,
        price_details: {
            ...prevDetails.price_details,
            //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
            //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
            machine_price: formatCurrency(prevDetails.sales_rate),
        },

    }));
};

const formatCurrency = (value) => { //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value || 0);
};


  const productname = productDETAILS?.[infokey]?.name;
  const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  const productimage =productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  const saleprice = productDETAILS?.[infokey]?.saleprice ;

  const handleCartNow = async (
    result,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("Entire Product : ", result);
    console.log("position : ", position);
    const producttttnameee = result?.name;
    const productImage = result?.thumbnail || "N/A";
    const productBrand = result.brands || "N/A"; 
    const productBucketSize = result.bucketsize || "N/A"; 
    const productPackPrice = result.packprice || 0; 
    const productPriceList = result?.price_list || 0;
    const productSalePrice = result?.saleprice || 0;
    const productUnits = result?.unit || "N/A";
    console.log("Inside handleCartNow - productUnits:", productUnits);
    console.log("Inside handleCartNow - productIdtoCart:", productIdtoCart);
    console.log("Inside handleCartNow - productName:", salePrice);
    console.log("Inside handleCartNow - productBrand:", productBrand);
    console.log("Inside handleCartNow - productBucketSize:", productBucketSize);
    console.log("Inside handleCartNow - productPackPrice:", productPackPrice);
    console.log("Inside handleCartNow - productPackPrice:", productSalePrice);
  

    setQuotationPopupforAddtoCartinSpare(true);

    console.log("Inside handleCartNow - setting product details");
    setProductDETAILS({
      id: productIdtoCart,
      name: producttttnameee,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list:productPriceList,
      productSalePrice : productSalePrice,
      productUnits: productUnits,
      queryID: queryID,
      position: position,
    });
  };

  const handleBuyNow = async (
    result,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("Inside handleBuyNow - id ", result?.objectID);
    console.log("Inside handleBuyNow - productIdtoCart", productIdtoCart);
    console.log("Inside handleBuyNow ", result);
    console.log("Inside handleBuyNow - productName:", result?.productName);

    const productImage = result?.thumbnail || "N/A";
    console.log("Inside handleBuyNow - productImage:", productImage);
    const productBrand = result?.brands || "N/A";
    const productBucketSize = result?.bucketsize || "N/A";
    const productPackPrice = result?.packprice || 0;
    const productPriceList = result?.price_list|| 0;
    const productUnits = result?.unit || "N/A";
    console.log("Inside handleBuyNow - productBrand:", result?.brands);
    console.log(
      "Inside handleBuyNow - productBucketSize:",
      result?.bucketsize
    );
    console.log("Inside handleBuyNow - productPackPrice:", result?.packprice);

    console.log("Opens QuotationPopupForInSpare.jsx file ");
    setQuotationPopupforBuyNowinSpare(true);

    setProductDETAILS({
      id: result?.objectID,
      name: result?.productName,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list: productPriceList,
      productUnits: productUnits,
      queryID: queryID,
      position: position,
    });
  };

const threesellerhits = combinedResults.sellerResults.slice(0, 3);
const threesparehits = combinedResults.spareResults.slice(0, 3);



const [showAllSeller, setShowAllSeller] = useState(false);

const [showAllSpare, setShowAllSpare] = useState(false);


const viewAllforSeller = () => {
    setShowAllSeller(true); // Set to true to show all results
  };
  
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();
 
  const  viewAllforSpare = () => {
    setShowAllSpare(true); // Set to true to show all results
  };
  
  const handleFavorite = (index, value) => {
    const updatedHits = [...combinedResults.spareResults];
    updatedHits[index]["favorite"] = value;
    setCombinedResults(updatedHits);
};

  const onSubmitHandler = (props) => {
    console.log("whish list working", props?.categories.lvl2?.split('>')[2].trim());
    const loggedin = !!localStorage.getItem('userToken');
    if (loggedin) {
        onWishlistHandler(props?.productId, props?.thumbnail, props?.grossPrice, props?.productName, props?.attributes?.Brands, props?.categories?.lvl1?.split('>')[1].trim(), props?.categories.lvl2?.split('>')[2].trim())
    }
}



const handleAvailServiceseller = (result) => {
    const UserId = localStorage.getItem('id');
    //console.log('UserId---->', UserId);
    if (UserId === null) {
        const existingProducts = JSON.parse(localStorage.getItem("SelectedProducts")) || [];
        const isProductInList = existingProducts.some((existingProduct) => existingProduct.productId === result.productId);

        if (!isProductInList) {
            existingProducts.push(result);
            localStorage.setItem("SelectedProducts", JSON.stringify(existingProducts));
        }
    } else if (UserId !== null) {
        const existingProducts = JSON.parse(localStorage.getItem("SelectedProductsWithUser")) || [];
        const isProductInList = existingProducts.some((existingProduct) => existingProduct.productId === result.productId);

        const productWithUserId = { ...result, userId: UserId };

        if (!isProductInList) {
            existingProducts.push(productWithUserId);
            localStorage.setItem("SelectedProductsWithUser", JSON.stringify(existingProducts));
        }
    }



    localStorage.setItem("objectID", result.objectID);
    // Navigate to the service page
    navigate(`/buy/cnc-machine?slug=${result.slug}&id=${result.productId}`);
};

  const handleAvailService = (result, position, queryID,isPincodeAvailableInPriceRange) => {
    const state = {
      group_id: result.group_id,
      objectID: result.objectID || result.id,
      details: result,
      isPincodeAvailableInPriceRange: isPincodeAvailableInPriceRange,
    };
    setSelectedPosition(position); // Store the position in state
    // console.log("Selected Position for state variable:", position);

    const objectIdforsimilartools = result.objectID;
    localStorage.setItem("ObjectIdToolforSimilar", objectIdforsimilartools);
    const timestamp = Date.now();
    const uuidUserToken = uuidv4();

    const postionfor = position;
    localStorage.setItem("uuidUserToken", uuidUserToken);
    localStorage.setItem("Positionforalgolia", postionfor);

    // console.log("UUID User Token stored in localStorage:", uuidUserToken);

    // console.log("object Id : ", product.objectID);
    // console.log("Product Name : ", product.productName);
    // console.log("Query Id : ", queryID);
    // console.log("Positions :", position);
    // console.log("timestamp :", timestamp);
    // console.log("UserToken : ", uuidUserToken);

    localStorage.setItem("queryID", queryID);
    // console.log(
    //   "QueryId in LocalStorage :   ",
    //   localStorage.getItem("queryID")
    // );

    // const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    // const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    // const spareIndex = "zohoinventory_index";

    // sendEvent(
    //   "viewedObjectIDs",
    //   {
    //     eventType: "view",
    //     eventName: "Product Viewed",
    //     index: spareIndex,
    //     objectIDs: [state.objectID],
    //     userToken: uuidUserToken,
    //     queryID: queryID,
    //     timestamp: timestamp,
    //   },
    //   {
    //     headers: {
    //       "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
    //       "X-Algolia-API-Key": ALGOLIA_API_KEYs,
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((response) => {
    //     console.log("Event sent successfully:", response);
    //   })
    //   .catch((error) => {
    //     console.error("Error sending event:", error);
    //   });

    setLoading(true);
    setTimeout(() => {
      if (result?.group_id) {
        navigate(`/buy/spares-revised?group_id=${result.group_id}`, { state });
      } else {
        navigate(`/buy/spares-revised?id=${result.objectID}`, { state });
      }

      console.log(
        `Redirecting to ViewSpareProduct page: Group Id: ${result.group_id}, Item Id: ${state.objectID}`
      );
    }, 1000);
  };

  
  const [loading, setLoading] = useState(true);
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
    const Seller_index = client.initIndex(ALGOLIA_SELLER_INDEX);
    const [filteredHits, setFilteredHits] = useState([]);
   
    const [isMobCategoryShow, setIsMobCategoryShow] = useState(window.innerWidth <= 767);
    const [activeTab, setActiveTab] = useState("machines");
    const [MainFilters, setMainFilters] = useState("");
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({
        industry: [],
        category: [],
        subcategory: [],
        brand: [],
        Model: [],
        Price: [],
        Year: [],
        Controller: [],
        XAXIS: [],
        YAXIS: [],
        ZAXIS: [],
        spindlespeed: [],
        spindletaper: [],
        tailstock: [],
        barcapacity: [],
        chucksize: [],
        maxturninglength: [],
        swingoverbed: [],
        swingovercrossslide: [],
        subSpindle: [],
        liveTooling: [],
        orderBy: "",
        page: 1,
    });
    const token = localStorage.getItem('userToken');
    const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
    const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);
    const [showShortList, setShowShortList] = useState(false);
    const [activeFilters, setActiveFilters] = useState({
        industry: true, category: false, brand: false, model: false, Price: false, year: false, Controller: false,
        XAXIS: false, YAXIS: false, ZAXIS: false, spindlespeed: false, spindletaper: false, tailstock: false, barcapacity: false, chucksize: false, maxturninglength: false,
        swingoverbed: false, swingovercrossslide: false, subSpindle: false, liveTooling: false
    });
   
    const [Sorting, setSorting] = useState(null);
    
    const [deliveryLocation, setDeliveryLocation] = useState("");
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
        setDeliveryLocation(storedLocation);
    }
    const [seeMore, setSeeMore] = useState(false);
    const navigate = useNavigate();
    const breadcrumbsItems = [
        { name: "Tools and Consumables", link: "/" },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { SparefilterConfigurations, filterConfigurations } = filterAttribute(IndustryCheckBoxValue, CategoryCheckBoxValue);

    const handleFilters = (name, value) => {
        setActiveFilters((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeSort = (sort) => {
        setSorting(sort);
        const selectedFilters = Object.assign({}, filters);
        selectedFilters.orderBy = sort;
        selectedFilters.page = 1;
        setFilters(selectedFilters);
        setShowShortList(false);
    };

    const handleSeeMore = (status) => {
        if (status) {
            setSeeMore(status);
            document.body.classList.add('no-overflow');
        } else {
            setSeeMore(false);
            document.body.classList.remove('no-overflow');
        }
    };

    const onGetPinCodeHandler = (pin) => {
        setDeliveryLocation(pin);
    };












 const [successMessage, setSuccessMessage] = useState(null);


 const [selectedPosition, setSelectedPosition] = useState(null);
  const [QuotationPopupforBuyNowinSpare, setQuotationPopupforBuyNowinSpare] = useState();
  const closePopup2 = () => {
    setQuotationPopupforBuyNowinSpare(false);
    navigate("/store");
  };
  









    const toggleDropdown = () => {
        const dropdownElement = document.querySelector('.p-fixed');
        const dropdownElement1 = document.querySelector('.fixed-category-bottom');
        if (dropdownElement) {
            dropdownElement.classList.remove('dropdownHidden');
            dropdownElement1.classList.remove('dropdownHidden');
        }
    };

    

    const handleSubmitForCart = async ({
        productIded,
        quantityed,
        bucketIded,
        productName,
        salePricess,
        priceList,
        position,
        productNamed,
        productImaged,
        productBranded,
        endQuantity
      }) => {
        if (!productIded) {
          console.error("Invalid productIded:", productIded);
          return;
        }
    
    
        const productbrand = productBranded;
        console.log("Product Name : ",productNamed)
        console.log("product Image",productImaged)
        console.log("product brand",productbrand);
        console.log("salePricess : ",salePricess);
    
        // Find the matching price item
        const priceItemed = priceList.find((item) => {
          if (
            quantityed >= item.start_quantity &&
            (item.end_quantity === null || quantityed <= item.end_quantity)
          ) {
            return true;
          }
          return false;
        });
    
        const bucketSizeforall = priceItemed.bucket_size;
        const bucketPriceforall = priceItemed.price;
        const bucketIdforall = priceItemed.bucket_id;
        console.log("The productId  : ", productIded);
        console.log("The quantity : ", quantityed);
        console.log("The productName", productNamed);
        console.table(priceList);
        console.log("productbrand : ", productbrand);
        console.log("Bucket Size:", bucketSizeforall);
        console.log("Bucket Price:", bucketPriceforall);
        console.log("Bucket ID normally:", bucketIdforall);
    
        const priceItem = priceList.find((item) => item?.bucket_id === bucketIded);
        console.log("priceList : ", priceList);
        if (!priceItem) {
          console.error("Price item not found for bucket ID:", bucketIded);
          return;
        }
    
        // const productType =
        //   bucketSizeforall === 210 or 180
        //     ? "Barrel"
        //     : bucketSizeforall === 20 or18
        //     ? "Bucket"
        //     : "";
    
        const productType =
      [210, 180].includes(bucketSizeforall) ? "Barrel" :
      [20, 18].includes(bucketSizeforall) ? "Bucket" :
      "";
    
    
        console.log("productType : ", productType);
    
        const productIdtoCart = productIded;
        const quantitypricevc = quantityed;
        const bucketPrice = bucketPriceforall;
        const bucketSize = bucketSizeforall;
        const bucketId = bucketIdforall;
    
        let adjustedQuanteeity = quantitypricevc;
    
        // If quantitypricevc is greater than 10 and is a multiple of 11 (e.g., 11, 22, 33)
        if (quantitypricevc > 10 && quantitypricevc % 11 === 0) {
          adjustedQuanteeity = quantitypricevc / 11; // This will convert it to 1, 2, 3, etc.
        }
    
        const totalCartPriceoffff = bucketPrice * bucketSize * adjustedQuanteeity;
        console.log("totalCartPriceoffff : ", totalCartPriceoffff);
    
        const totalCartPriceof = bucketPrice * bucketSize * quantitypricevc;
        const queryID = localStorage.getItem("queryID");
        const uuidUserTokessn = localStorage.getItem("uuidUserToken");
        const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
        const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
        const spareIndex = "zohoinventory_index";
        const timestampe = Date.now();
    
        const positionfavd = parseInt(
          localStorage.getItem("Positionforalgolia"),
          10
        );
    
        if (isNaN(positionfavd)) {
          console.error("Invalid position value in localStorage.");
        } else {
          const positions = [positionfavd];
    
          aa(
            "clickedObjectIDs",
            {
              eventType: "click",
              eventName: "Product Clicked",
              index: spareIndex,
              objectIDs: [productIdtoCart],
              positions: positions,
              userToken: uuidUserTokessn,
              queryID: queryID,
              timestamp: timestampe,
            },
            {
              headers: {
                "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
                "X-Algolia-API-Key": ALGOLIA_API_KEYs,
                "Content-Type": "application/json",
              },
            }
          )
            .then((response) => {
              console.log("Click event sent successfully:", response);
            })
            .catch((error) => {
              console.error("Error sending conversion event:", error);
            });
        }
    
        const loggedin = localStorage.getItem("userToken");
        let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    
        const ompuserId = localStorage.getItem("id");
        const customer_first_name = localStorage.getItem("firstName");
        const customer_last_name = localStorage.getItem("lastName");
        const customer_contact_no = localStorage.getItem("number");
        const customer_email = localStorage.getItem("emailId");
        let itemsAlreadyInTheApi = [];
        const storedItems = localStorage.getItem("CartItemsintheapi");
    
        if (storedItems) {
          try {
            itemsAlreadyInTheApi = JSON.parse(storedItems);
          } catch (error) {
            console.error(
              "Error parsing CartItemsintheapi from localStorage:",
              error
            );
            itemsAlreadyInTheApi = [];
          }
        } else {
          localStorage.setItem(
            "CartItemsintheapi",
            JSON.stringify(itemsAlreadyInTheApi)
          );
        }
    
        let itemsToUse =
          itemsAlreadyInTheApi.length > 0 ? itemsAlreadyInTheApi : cartItems;
        console.log("items :", itemsToUse);
    
        if (loggedin) {
          console.log("Here inside Loggedin : ");
          // const existingCartItemIndex = itemsToUse.findIndex(
          //   (item) => item.item_id === productIdtoCart && item.bucket_size === 20 or 18
          // );
    
          // const existingBarrelItemIndex = itemsToUse.findIndex(
          //   (item) => item.item_id === productIdtoCart && item.bucket_size === 210 ot 180
          // );
    
          const existingCartItemIndex = itemsToUse.findIndex(
            (item) => item.item_id === productIdtoCart && [20, 18].includes(item?.bucket_size)
          );
          
          const existingBarrelItemIndex = itemsToUse.findIndex(
            (item) => item.item_id === productIdtoCart && [210, 180].includes(item?.bucket_size)
          );
    
          
          console.log("Now what is the bucket size: ", productType);
    
          const adjustForBarrel = (quantity) => {
            if (quantity >= 11) {
              // Adjust quantity to the nearest multiple of 11
              return Math.floor(quantity / 11) * 11;
            }
            return quantity;
          };
    
          if (productType === "Bucket") {
            if (existingCartItemIndex !== -1) {
              // Update the existing bucket item
    
              console.log("Now to update the quantity of existing item :  ");
              const oldQuantity = itemsToUse[existingCartItemIndex].quantity;
    
              itemsToUse[existingCartItemIndex].quantity = 0;
              localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
    
              // Make the first API call to set quantity to 0
              await fetch(
                "https://ompextension.origa.market/sparesapi/create_order",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    items: [
                      {
                        item_id: productIdtoCart,
                        quantity: 0,
                        bucket_id: itemsToUse[existingCartItemIndex].bucket_id,
                      },
                    ],
                    ompuserId,
                    customer_first_name,
                    customer_last_name,
                    customer_contact_no,
                    order_status: "Draft",
                    order_ref_number: "",
                    customer_email,
                    input: {
                      city: "",
                      cityArea: "",
                      companyName: "",
                      country: "",
                      countryArea: "",
                      firstName: "",
                      lastName: "  ",
                      phone: "",
                      postalCode: "",
                      streetAddress1: " ",
                      streetAddress2: "",
                    },
                    businessaddress: {
                      city: "",
                      cityArea: "",
                      companyName: " ",
                      country: "",
                      countryArea: "",
                      firstName: "",
                      lastName: "",
                      phone: "",
                      postalCode: "",
                      streetAddress1: "",
                      streetAddress2: "",
                    },
                    gst_no: "",
                  }),
                }
              );
              console.log(
                "Item set to 0 quantity in the API. Product ID:",
                productIdtoCart
              );
              itemsToUse[existingCartItemIndex].quantity = 0;
    
              // // localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
              // console.log(
              //   "Cart Items After setting quantity to 0: ",
              //   localStorage.getItem("CartItemsintheapi")
              // );
    
              console.log(
                "If any item is present , delete that and now going to send the updated quantity"
              );
    
              const adjustedQuantity = adjustForBarrel(
                oldQuantity + quantitypricevc
              );
    
              itemsToUse.push({
                item_id: productIdtoCart,
                quantity: adjustedQuantity,
                bucket_id: bucketId,
                bucketPrice: bucketPrice,
                totalCartPriceocvf:
                  bucketPrice * bucketSize * (oldQuantity + quantitypricevc),
                bucket_size: bucketSize,
                productType: "Bucket",
              });
              console.log("itemsToUse : ", itemsToUse);
              console.log("Now updated the cart and save to the localStorage ");
    
              // Update localStorage immediately after modifying the cart
              localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
              console.log(
                "Cart Items After adding a new bucket: ",
                localStorage.getItem("CartItemsintheapi")
              );
            } else {
              console.log("Here adding a new item : ");
              itemsToUse.push({
                item_id: productIdtoCart,
                quantity: quantitypricevc,
                bucket_id: bucketId,
                bucketPrice: bucketPrice,
                totalCartPriceocvf: totalCartPriceof,
                bucket_size: bucketSize,
                productType: productType,
              });
              console.log(" itemsToUse : ", itemsToUse);
              localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
              console.log(
                "Cart Items After adding a bucket: ",
                localStorage.getItem("CartItemsintheapi")
              );
            }
          } else if (productType === "Barrel") {
            if (existingBarrelItemIndex !== -1) {
              // Update the existing barrel item
              itemsToUse[existingBarrelItemIndex].quantity += quantitypricevc;
              itemsToUse[existingBarrelItemIndex].totalCartPriceocvf =
                totalCartPriceof;
    
              // Update localStorage immediately after modifying the cart
              localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
              console.log(
                "Cart Items After adding a new barrel: ",
                localStorage.getItem("CartItemsintheapi")
              );
            } else {
              // Add new barrel item
              itemsToUse.push({
                item_id: productIdtoCart,
                quantity: quantitypricevc,
                bucket_id: bucketId,
                bucketPrice: bucketPrice,
                totalCartPriceocvf: totalCartPriceof,
                bucket_size: bucketSize,
                productType: productType,
              });
    
              // Update localStorage immediately after adding the new item
              localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
              console.log(
                "Cart Items After adding barrel: ",
                localStorage.getItem("CartItemsintheapi")
              );
            }
          }
    
          var itemList = [];
          for (var item of itemsToUse) {
            var obj = {};
    
            obj["item_id"] = item.item_id;
            console.log("Set item_id:", item.item_id);
            // if (item.bucket_size === 210 or 180) {
              if ([210, 180].includes(item?.bucket_size)) {
              obj["quantity"] = item.quantity ;
              console.log("Bucket size is 210 or 180, set quantity to:", obj["quantity"]);
            } else {
              obj["quantity"] = item.quantity;
              console.log(
                "Bucket size is not 210 or 180, set quantity to:",
                obj["quantity"]
              );
            }
            obj["bucket_id"] = item.bucket_id;
            console.log("Set bucket_id:", item.bucket_id);
            itemList.push(obj);
            console.log("Pushed object to itemList:", obj);
          }
    
          console.log("Final itemList:", itemList);
    
          try {
            await fetch(
              "https://ompextension.origa.market/sparesapi/create_order",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  items: itemList,
                  ompuserId,
                  customer_first_name,
                  customer_last_name,
                  customer_contact_no,
                  order_status: "Draft",
                  order_ref_number: "",
                  customer_email,
                  input: {
                    city: "",
                    cityArea: "",
                    companyName: "",
                    country: "",
                    countryArea: "",
                    firstName: "",
                    lastName: "  ",
                    phone: "",
                    postalCode: "",
                    streetAddress1: " ",
                    streetAddress2: "",
                  },
                  businessaddress: {
                    city: "",
                    cityArea: "",
                    companyName: " ",
                    country: "",
                    countryArea: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    postalCode: "",
                    streetAddress1: "",
                    streetAddress2: "",
                  },
                  gst_no: "",
                }),
              }
            );
            console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
          } catch (error) {
            console.error("Error adding item to API cart:", error);
          }
          
        } else {
          // const barrelIndex = itemsToUse.findIndex(
          //   (item) => item.bucketSize === 210 || 180 && item.productId === productIdtoCart
          // );
          const barrelIndex = itemsToUse.findIndex(
            (item) => [210, 180].includes(item?.bucketSize) && item.productId === productIdtoCart
          );
          
    
          if (barrelIndex !== -1) {
            // Remove the barrel
            itemsToUse.splice(barrelIndex, 1);
            console.log("Removed existing barrel for the same product ID.");
          }
          const existingCartItemIndex = itemsToUse.findIndex(
            (item) => item.productId === productIdtoCart
          );
    
          console.log("bucketSize : ", bucketSize);
    
          // if (bucketSize === 20) {
            if ([20, 18].includes(bucketSize)) {
            if (existingCartItemIndex !== -1) {
              console.log(
                "Old Quantity of the id : ",
                itemsToUse[existingCartItemIndex].quantity
              );
              console.log("New Quantity of the id : ", quantitypricevc);
    
              // Combine the quantities
              let totalQuantity =
                itemsToUse[existingCartItemIndex].quantity + quantitypricevc;
              console.log("Combined Quantity : ", totalQuantity);
      // If combined quantity is greater than 11, update accordingly
              if (totalQuantity >= 11) {
                console.log("Total Quantity: ", totalQuantity);
    
                let adjustedQuantity = Math.floor(totalQuantity / 11) * 11;
                console.log("Initial Adjusted Quantity: ", adjustedQuantity);
                if (adjustedQuantity === 0) adjustedQuantity = 11; // Minimum 1 barrel
    
                console.log("Final Adjusted Quantity : ", adjustedQuantity);
    
                // Update the quantity and find corresponding price range
                itemsToUse[existingCartItemIndex].quantity = adjustedQuantity;
                console.log("Updated Quantity in itemsToUse: ", itemsToUse[existingCartItemIndex].quantity);
    
    
                console.log("Now going to iterate over  priceList :")
                const priceListForExisting =
                  itemsToUse[existingCartItemIndex].priceList;
                console.log("Price List of existing item: ", priceListForExisting);
    
    
                console.log("There is already a priceList ");
                let updatedBucketDetails = null;
    
                console.log("Iterating over priceListforExisting...");
                priceListForExisting.forEach((priceRange) => {
                  console.log("Checking price range: ", priceRange);
                  if (
                    priceRange.qty_type === "Barrel" &&
                    adjustedQuantity >= priceRange.start_quantity &&
                    (priceRange.end_quantity === null ||
                      adjustedQuantity <= priceRange.end_quantity)
                  ) {
                    console.log("Price range matched: ", priceRange);
    
                    updatedBucketDetails = {
                      bucketId: priceRange.bucket_id,
                      bucketPrice: priceRange.price,
                      bucketSize: priceRange.bucket_size,
                    };
                    console.log("Updated Bucket Details set: ", updatedBucketDetails);
                  }
                });
    
                if (updatedBucketDetails) {
                  console.log("Updated Bucket Details: ", updatedBucketDetails);
    
      // Update the bucket details
      itemsToUse[existingCartItemIndex].bucketId = updatedBucketDetails.bucketId;
      console.log("Updated Bucket ID: ", updatedBucketDetails.bucketId);
    
      itemsToUse[existingCartItemIndex].bucketPrice = updatedBucketDetails.bucketPrice;
      console.log("Updated Bucket Price: ", updatedBucketDetails.bucketPrice);
    
      itemsToUse[existingCartItemIndex].bucketSize = updatedBucketDetails.bucketSize;
      console.log("Updated Bucket Size: ", updatedBucketDetails.bucketSize);
    
      // Correctly update the totalCartPriceocvf with the new adjusted quantity 
      
      let modifiedquantity = adjustedQuantity;
    
    if(adjustedQuantity % 11 === 0){
    modifiedquantity = adjustedQuantity / 11;
    }
    
                  // Correctly update the totalCartPriceocvf with the new adjusted quantity
                  itemsToUse[existingCartItemIndex].totalCartPriceocvf =
                  updatedBucketDetails.bucketPrice *
                  updatedBucketDetails.bucketSize *
                  modifiedquantity;
    
                   // Set endQuantity to null as it's a barrel
            itemsToUse[existingCartItemIndex].endQuantity = null;
            console.log("Updated endQuantity to null for barrel.");
    
    
            console.log("Updated Total Cart Price (totalCartPriceocvf): ", 
              itemsToUse[existingCartItemIndex].totalCartPriceocvf);
               
                } else {
                  console.log(
                    "No matching barrel range found for the updated quantity."
                  );
                  console.log(
                    "Updated bucket item in cart after quantity > 11:",
                    itemsToUse[existingCartItemIndex]
                  );
                }
    
                console.log(
                  "Updated bucket item in cart:",
                  itemsToUse[existingCartItemIndex]
                );
              } else {
                // If combined quantity is less than or equal to 11, remove barrel
                // const barrelIndex = itemsToUse.findIndex(
                //   (item) =>
                //     item.bucketSize === 210 || 180 && item.productId === productIdtoCart
                // );
                const barrelIndex = itemsToUse.findIndex(
                  (item) => [210, 180].includes(item?.bucketSize) && item.productId === productIdtoCart
                );
                
                if (barrelIndex !== -1) {
                  itemsToUse.splice(barrelIndex, 1); // Remove the barrel
                  console.log("Removed barrel item from cart.");
                }
                itemsToUse[existingCartItemIndex].quantity = totalQuantity;
                const priceListForExisting =
                  itemsToUse[existingCartItemIndex].priceList;
                console.log("Price List of existing item: ", priceListForExisting);
    
                let updatedBucketDetails = null;
    
                // Loop through the price list to find the correct price range
                priceListForExisting.forEach((priceRange) => {
                  if (
                    priceRange.qty_type === "Bucket" &&
                    totalQuantity >= priceRange.start_quantity &&
                    (priceRange.end_quantity === null ||
                      totalQuantity <= priceRange.end_quantity)
                  ) {
                    
                    updatedBucketDetails = {
                      bucketId: priceRange?.bucket_id,
                      bucketPrice: priceRange?.price,
                      bucketSize: priceRange?.bucket_size,
                    };
                  }
                });
    
                itemsToUse[existingCartItemIndex].bucketId =
                  updatedBucketDetails?.bucketId;
                itemsToUse[existingCartItemIndex].bucketPrice =
                  updatedBucketDetails?.bucketPrice;
                itemsToUse[existingCartItemIndex].bucketSize =
                  updatedBucketDetails?.bucketSize;
    
                // Correctly update the totalCartPriceocvf with the new adjusted quantity
                itemsToUse[existingCartItemIndex].totalCartPriceocvf =
                  updatedBucketDetails?.bucketPrice *
                  updatedBucketDetails?.bucketSize *
                  totalQuantity;
    
                // itemsToUse[existingCartItemIndex].totalCartPriceocvf =
                //   itemsToUse[existingCartItemIndex].bucketPrice *
                //   itemsToUse[existingCartItemIndex].bucketSize *
                //   totalQuantity;
    
                console.log(
                  "Updated bucket item in cart (quantity <= 11):",
                  itemsToUse[existingCartItemIndex]
                );
              }
            } else {
              // If the item doesn't exist in the cart, add it as a new item
              itemsToUse.push({
                productId: productIdtoCart,
                productName: productNamed,
                productBrand: productbrand,
                productImage: productImaged,
                salePrice: salePricess,
                quantity: quantitypricevc,
                bucketId: bucketId,
                bucketSize: bucketSize,
                bucketPrice: bucketPrice,
                totalCartPriceocvf: totalCartPriceof,
                endQuantity: endQuantity,
                priceList: priceList,
              });
              console.log("Added new bucket item to cart.");
            }
          } 
          else if ([210, 180].includes(bucketSize)) {
            // Handling Barrel case where bucketSize is 210 or 180
            let matchingBucket = null;
            priceList.forEach((priceRange) => {
              // if (priceRange.bucket_size === 210 || 180) {
                if ([210, 180].includes(priceRange?.bucket_size)) {
                matchingBucket = priceRange.bucket_id;
              }
            });
    
            let adjustedQuantity;
    
            if (quantitypricevc % 11 === 0) {
              // If quantitypricevc is already a multiple of 11, use it as is
              adjustedQuantity = quantitypricevc;
            } else {
              // Otherwise, multiply it by 11
              adjustedQuantity = quantitypricevc * 11;
            }
    
            console.log("Adjusted Quantity: ", adjustedQuantity);
    
            if (matchingBucket) {
              // const existingBucketIndex = itemsToUse.findIndex(
              //   (item) =>
              //     item.bucketSize === 20 && item.productId === productIdtoCart
              // );
              const existingBucketIndex = itemsToUse.findIndex(
                (item) =>
                  [20, 18].includes(item?.bucketSize) && item.productId === productIdtoCart
              );
              
              if (existingBucketIndex !== -1) {
                itemsToUse.splice(existingBucketIndex, 1);
                console.log("Removed existing bucket for the same product ID.");
              }
    
              itemsToUse.push({
                productId: productIdtoCart,
                productName: productNamed,
                productBrand: productbrand,
                productImage: productImaged,
                salePrice: salePricess,
                quantity: adjustedQuantity,
                bucketId: matchingBucket,
                bucketSize: bucketSize,
                bucketPrice: bucketPrice,
                totalCartPriceocvf: totalCartPriceoffff,
                endQuantity: endQuantity,
                priceList: priceList,
              });
              console.log("Added or replaced with barrel item in cart.");
            } else {
              console.log("No matching bucket found for barrel.");
            }
          }
    
          // Saving the updated cart items
          localStorage.setItem("cartItems", JSON.stringify(itemsToUse));
          console.log("Updated cart items:", itemsToUse);
        }
    
      //   setLoading(true);
      //   setTimeout(() => {
      //     window.location.reload();
      //   }, 500);
      // };
    
      setLoading(true);
    
      setTimeout(() => {
          setSuccessMessage("Added to Cart Successfully"); 
          setLoading(false);
    
          setTimeout(() => {
              window.location.reload();
          }, 500);
      }, 500);
    };

    const handleSubmit = async ({
        productId,
        quantity,
        bucketId,
        productName,
        salePrice,
        position,
      }) => {
        console.log(
          "When i click Buy now button in QuotatationPopupForInSpare  , this handleSubmit function is called now "
        );
    
        console.log("Inside handleSubmit in SpareHitsNewDesign.jsx");
        console.log(
          " Inside handleSubmit in SpareHitsNewDesign.jsx Product ID:",
          productId
        );
        console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx:", quantity);
        console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx:", bucketId);
        console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx", salePrice);
    
        const loggedin = !!localStorage.getItem("userToken");
        const queryID = localStorage.getItem("queryID");
        // console.log("User logged in:", loggedin);
        // console.log("queryID : ", queryID);
        // console.log("productId : ", productId);
        // console.log("position : ", position);
        const timestamp = Date.now();
        if (queryID) {
          sendEvent("clickedObjectIDs", {
            eventType: "click",
            eventName: "Product Clicked",
            index: "zohoinventory_index",
            objectIDs: [productId],
            userToken: localStorage.getItem("uuidUserToken"),
            queryID: queryID,
            positions: [position],
            timestamp: timestamp,
          });
        } else {
          console.error("Query ID not found. Click event not sent.");
        }
    
        setQuotationPopupforBuyNowinSpare(false);
        if (!loggedin) {
          console.log("User not logged in, saving pending action to localStorage");
          localStorage.setItem(
            "pendingBuyNow",
            JSON.stringify({
              productId,
              quantity,
              bucketId,
            })
          );
          setLoginPortal(true);
          return;
        }
    
        console.log("Proceeding with Buy Now");
        console.log("Now we go to Address Page : ");
        await proceedWithBuyNow(productId, quantity, bucketId);
      };


       const proceedWithBuyNow = async (productId, quantity, bucketId) => {
          console.log("Inside proceedWithBuyNow in SpareHits.jsx");
          console.log(
            "Inside proceedWithBuyNow in SpareHits.jsx Product ID:",
            productId
          );
          console.log(
            "Inside proceedWithBuyNow in SpareHits.jsx Quantity:",
            quantity
          );
          console.log(
            "Inside proceedWithBuyNow in SpareHits.jsx Bucket ID:",
            bucketId
          );
      
          const userToken = localStorage.getItem("userToken");
          const ompuserId = localStorage.getItem("id");
          const customer_first_name = localStorage.getItem("firstName");
          const customer_last_name = localStorage.getItem("lastName");
          const customer_contact_no = localStorage.getItem("number");
          const customer_email = localStorage.getItem("emailId");
      
          const itemList = [
            {
              item_id: productId,
              quantity: quantity,
              bucket_id: bucketId,
            },
          ];
      
          console.log("Item list for API:", itemList);
      
          console.log(
            "Now calling the api : with orderstatus as buy and hitting the create order api"
          );
          try {
            const response = await fetch(
              "https://ompextension.origa.market/sparesapi/create_order",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  items: itemList,
                  ompuserId,
                  customer_first_name,
                  customer_last_name,
                  customer_contact_no,
                  order_status: "Buy",
                  order_ref_number: "",
                  customer_email,
                  input: {
                    city: "",
                    cityArea: "",
                    companyName: "",
                    country: "IN",
                    countryArea: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    postalCode: "",
                    streetAddress1: "",
                    streetAddress2: "",
                  },
                  businessaddress: {
                    city: "",
                    cityArea: "",
                    companyName: "",
                    country: "IN",
                    countryArea: "",
                    firstName: "",
                    lastName: "",
                    phone: "",
                    postalCode: "",
                    streetAddress1: "",
                    streetAddress2: "",
                  },
                  gst_no: "",
                }),
              }
            );
      
            console.log("Response status:", response.status);
            const responseData = await response.json();
            console.log("Response data:", responseData);
      
            console.log(
              "now in the above i have sucessfully sent the request with order_status as buy"
            );
            console.log(
              "now get the orderId  etc from the response and send it to the api  "
            );
            const {
              order_id: orderId,
              total_quantity: totalQuantity,
              subtotal: subtotttal,
              tax_total: gst,
              discount_total: taxes,
              shipping_charge: shippingCharges,
              line_items: lineItems,
              total,
            } = responseData;
      
            console.log("Order ID:", orderId);
            console.log("Total Quantity:", totalQuantity);
            console.log("Subtotal:", subtotttal);
            console.log("GST Amount:", gst);
            console.log("Discount Total:", taxes);
            console.log("Shipping Charges:", shippingCharges);
            console.log("Total Amount:", total);
      
            // Iterate through line_items to retrieve item details
            lineItems.forEach((item, index) => {
              console.log(`Item ${index + 1}:`);
              console.log("Name:", item.name);
              console.log("Thumbnail URL:", item.thumbnail[0]?.url);
            });
      
            setLoading(true);
      
            setTimeout(() => {
              console.log("Navigating to manage address page");
      
              // Log state values
              console.log("State values being passed:");
              console.log("itemId:", productId);
              console.log("bucketId:", bucketId);
              console.log("quantity:", quantity);
              console.log("orderId:", orderId);
              console.log("quantityTotal:", totalQuantity);
              console.log("subtotalAmount:", subtotttal);
              console.log("gstAmount:", gst);
              console.log("taxesAmount:", taxes);
              console.log("shippingFee:", shippingCharges);
              console.log("totalAmount:", total);
      
              const itemDetails = lineItems.map((item) => ({
                name: item.name,
                thumbnail: item.thumbnail[0]?.url,
                item_id: item.item_id,
                quantity: item.quantity,
                amount: item.amount,
                rate: item.rate,
                bucket_id: item.bucket_id,
                total: item.total,
                subtotal: item.subtotal,
              }));
      
              console.log("Item Details:", itemDetails);
      
              // Set the order status to "buy" and save to localStorage
              const order_Status = "Buy";
              localStorage.setItem("order_Status", order_Status);
              console.log("Order Status set to:", order_Status);
      
              // Navigate with all required state
              navigate("/manageaddresspart", {
                state: {
                  itemId: productId,
                  bucketId,
                  quantity,
                  orderId,
                  quantityTotal: totalQuantity,
                  subtotalAmount: subtotttal,
                  gstAmount: gst,
                  taxesAmount: taxes,
                  shippingFee: shippingCharges,
                  totalAmount: total,
                  itemDetails, // Pass the item details array in the state
                },
              });
      
              window.location.reload();
            }, 400);
          } catch (error) {
            console.error("Error adding item to API cart:", error);
          }
        };

        const loggedin = localStorage.getItem("userToken");
      
        useEffect(() => {
          const pendingBuyNow = localStorage.getItem("pendingBuyNow");
          // console.log("useEffect - pendingBuyNow:", pendingBuyNow);
      
          if (pendingBuyNow) {
            const { productId, quantity, bucketId } = JSON.parse(pendingBuyNow);
            console.log("useEffect - Proceeding with pending Buy Now:", {
              productId,
              quantity,
              bucketId,
            });
      
            proceedWithBuyNow(productId, quantity, bucketId);
      
            localStorage.removeItem("pendingBuyNow");
            console.log("useEffect - Cleared pendingBuyNow from localStorage");
          }
        }, [loggedin]);


    const RangeSlider = ({ min, max, currentRefinement, refine }) => {
        const handleChange = (event, newValue) => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            if (minAllowedYear === newValue[0]) {
                refine({ min: 1900, max: newValue[1] });
            } else {
                refine({ min: newValue[0], max: newValue[1] });
            }
        };
        const [minYear, setMinYear] = useState(1940);

        useEffect(() => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            setMinYear(minAllowedYear);
        }, []);
        const defaultValue = [currentRefinement.min || min, currentRefinement.max || max];
        return (
            <div>
                <Slider
                    min={minYear}
                    max={max}
                    value={defaultValue}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    color="secondary"
                    aria-labelledby="range-slider"
                    getAriaValueText={(value) => `$${value}`}
                />
            </div>
        );
    };

    const CustomRangeInput = connectRange(RangeSlider);
    // const NoResultsFound = ({ searchResults }) => {
    //     const [showCustomerInfoForm, setShowCustomerInfoForm] = useState(false);
    
    //     useEffect(() => {
    //         if (searchResults && searchResults.nbHits === 0) {
    //             // If there are no hits, show the spareIndex instead of CustomerInfoForm
    //             setShowCustomerInfoForm(false);
    //         } else {
    //             // If the search input is not found in any indexes, show the CustomerInfoForm
    //             const allResultsEmpty = searchResults.every(result => result.nbHits === 0);
    //             setShowCustomerInfoForm(allResultsEmpty);
    //         }
    //     }, [searchResults]);
    
    //     if (searchResults && searchResults.nbHits === 0) {
    //         return (
    //             <div className='col col-lg-9 col-md-9 col-12'>
    //                 {/* Show the spareIndex here */}
    //                 <SpareHits />
    //                 <p>No product found.</p>
    //             </div>
    //         );
    //     }
    
    //     if (showCustomerInfoForm) {
    //         return (
    //             <div className='col col-lg-9 col-md-9 col-12'>
    //                 <CustomerInfo setCustomerInfoForm={setShowCustomerInfoForm} />
    //                 <p>No product found.</p>
    //             </div>
    //         );
    //     }
    
    //     return null;
    // };
  // Check if there are no results for both sellerResults and spareResults
  const noResults = combinedResults.sellerResults.length === 0 && combinedResults.spareResults.length === 0;
const [is576px, setIs576px] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIs576px(window.innerWidth < 576);
    };

    checkScreenWidth();

    window.addEventListener("resize", checkScreenWidth);

    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);


  return (
      <>

{loading && <Loader />}
<div className="whole-container-products-section">
<div
          className={`whole-product-section-for-combined-search ${
            isGridView  ? "grid-view-for-combined-search" : ""
          }`}
          
        >
  {(showAllSeller ? combinedResults.sellerResults : combinedResults.sellerResults.slice(0, 6)).map((product, index) => (


<>
  <div className="individual-product" key={index}>
              <img
                style={{ cursor: "pointer" }}
                className="individual-product-imagehere"
                src={product.thumbnail}
                alt={product.name}
              />

              <div>
                <span className="brandsandproductname-wrapper">
                  <span className="brandsandproductname">
                    {product.productName.length > 18
                      ? `${product.productName.substring(0, 18)}...`
                      : product.productName}
                  </span>
                </span>

                <div>
                  {product.attributes &&
                    (product.attributes["Brands"] ||
                      product.attributes["Machine Location"]) && (
                      <div className="location">
                        {product.attributes["Brands"]} |{" "}
                        {product.attributes["Machine Location"]}
                      </div>
                    )}

                  {is576px ? (
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="time">
                        {product.mfgYear === 1900
                          ? "N/A"
                          : `${findYearDifference(product.mfgYear)} Year Old`}
                      </div>
                      <div className="price">
                        {console.log("Product   : ",product)}
                        {product?.grossPrice === 0 ? (<></>) : (<>  ₹{priceConvert(product.grossPrice)}</>)}  
                      </div>
                      {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                    </div>
                  ) : (
                    <div className="price-wrap d-flex align-items-center justify-content-between">
                      <div className="time">
                        {product.mfgYear === 1900
                          ? "N/A"
                          : `${findYearDifference(product.mfgYear)} Year Old`}
                      </div>
                      <div className="price">
                      {/* {console.log("Product   : ",product.grossPrice)} */}
                      {product?.grossPrice === 0 ? (<></>) : (<>  ₹{priceConvert(product.grossPrice)}</>)}
                      </div>
                      {/* <div className="categorie"> {product.categories['lvl0']}</div> */}
                    </div>
                  )}

                  <button
                    className={`heartcolor heart ${
                      WishListData.includes(product.productId) ||
                      product.favorite
                        ? "favorite"
                        : ""
                    }`}
                    type="button"
                    onClick={() =>
                      onSubmitHandler(
                        product,
                        WishListData.includes(product.productId),
                        index
                      )
                    }
                  >
                    {heartIcon({
                      width: 25,
                      onClick: () => handleFavorite(index, !product.favorite),
                    })}
                  </button>
                  <button
                    className="purchase"
                    key={index}
                    onClick={() => handleAvailServiceseller(product)}
                    type="button"
                  >
                    Buy Machine
                  </button>
                </div>
              </div>
            </div>

</>
))}
 

 

        </div>

 {!showAllSeller && combinedResults.sellerResults.length > 3 && (
                                      <button className="buttonviewall" onClick={viewAllforSeller}>View All</button>
                                  )}           
</div>
                          {/* {combinedResults.sellerResults.length > 0 && (
                              <div className="product-wrap">
                                  <div className="products">
                                      {(showAllSeller ? combinedResults.sellerResults : combinedResults.sellerResults.slice(0, 3)).map((result, index) => (
                                          <div className="product" key={index}>
                                              <div className="product-inner">
                                                  <img className="product-img" src={result.thumbnail} alt={result.name} />
                                                  <div className="content">
                                                      <div className="name">
                                                          {result?.productName?.length > 18
                                                              ? `${result?.productName?.substring(0, 18)}...`
                                                              : result?.productName}
                                                      </div>
                                                      {result.attributes && (result.attributes["Brands"] || result.attributes["Machine Location"]) && (
                                            <div className="location">
                                                {result.attributes["Brands"]} | {result.attributes["Machine Location"]}
                                            </div>
                                        )}
                                                   
                                                      <div className="price-wrap">
                                            <div className="time">{result.mfgYear === 1900 ? "N/A" : `${findYearDifference(result.mfgYear)} Year Old`}</div>
                                            <div className="price">₹{priceConvert(result.grossPrice)}</div>
                                         
                                        </div>
                                        <button
                                            className={`heart ${WishListData.includes(result.productId) || result.favorite ? "favorite" : ""}`}
                                            type="button"
                                            onClick={() => onSubmitHandler(result, WishListData.includes(result.productId), index)}
                                        >
                                            {heartIcon({ width: 25, onClick: () => handleFavorite(index, !result.favorite) })}
                                        </button>
                                        <button className="purchase" key={index} onClick={() => handleAvailServiceseller(result)} type="button">Buy Machine</button>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                                 
                              </div>
                          )} */}

                          {/* {combinedResults.spareResults.length > 0 && (
                              <div className="product-wrap">
                                  <div className="products">
                                      {(showAllSpare ? combinedResults.spareResults : combinedResults.spareResults.slice(0, 3)).map((result, index) => (
                                          <div className="product" key={index}>
                                              <div className="product-inner">
                                                  <img className="product-img" src={result.thumbnail} alt={result.name} />
                                                  <div className="content">
                                                      <div className="name">
                                                          {result?.productName?.length > 18
                                                              ? `${result?.productName?.substring(0, 18)}...`
                                                              : result?.productName}
                                                      </div>
                                                      <div className="location">
                                                          {(() => {
                                                              const lvl1Category = result?.categories?.lvl1 || "";
                                                              const parts = lvl1Category.split(" > ");
                                                              const lastPart = parts[parts.length - 1];
                                                              return lastPart;
                                                          })()}
                                                      </div>
                                                      <div className="price-wrap">
                                                          <div className="time">{result?.Phase}</div>
                                                          <div className="price">₹{result["grossPrice"]}</div>
                                                      </div>
                                                      <button className={`heart ${result?.favorite ? "favorite" : ""}`} type="button" onClick={() => onSubmitHandler(result)}>{heartIcon({ width: 25, onClick: () => handleFavorite(index, !result.favorite) })}</button>
                                         <div className="buttonsinoneline">
                                        <button className="purchasef pbtnsb" key={index} onClick={() => handleAvailService(result)} type="button">Add to Cart</button>
                                        <button className="purchasef pbtnsb1" key={index} onClick={() => handleBuyNow(result.objectID, quantity, result.productName, result.grossPrice)} type="button">Buy Now</button>

                                        </div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                                  {!showAllSpare && combinedResults.spareResults.length > 3 && (
                                      <button className="buttonviewall" onClick={viewAllforSpare}>View All</button>
                                  )}
                              </div>
                          )} */}



<br></br>
<br></br>
<div className="whole-container-products-section mt-2">
  <div  className={`whole-product-section-for-combined-search ${
            isGridView   ? "grid-view-for-combined-search" : "nogridscssaplied"
          }`}>
    {combinedResults.spareResults.length > 0 &&
      (showAllSpare ? combinedResults.spareResults : combinedResults.spareResults.slice(0, 3)).map((result, index) => (
        <div
          className="individual-product"
          key={index}
          onClick={() => handleAvailService(result, index + 1, result.__queryID)}
          style={{ cursor: "pointer" }}
        >
          <img className="individual-product-image" src={result.thumbnail} alt={result.name} />
          <div>
            <span className="brandsandproductname-wrapper">
              <span className="brandsandproductname">
                {result?.brands} <br />
                {result?.productName?.length > 18
                  ? `${result?.productName?.substring(0, 18)}...`
                  : result?.productName}
              </span>
            </span>
            <div>
              <div>{result?.Phase}</div>
              <div className="bucketsizepriceandltr">
              {result?.bucketsize}
              {result?.noofpacks && result?.noofpacks > 0 && (
                      <>(Pack of {result?.noofpacks})</>
                    )}
                    <br></br>
                {result?.packprice && result?.packprice > 0 && (
                      <>₹{result?.packprice}</>
                    )}
                    (₹{result?.grossPrice} /{result.unit})


                  <br />
                    <span>
                      {result?.mrp && result?.mrp > 0 && (
                        <s style={{color:"gray"}}>₹{result?.mrp}/{result.unit}</s>
                      )}

                      {result?.mrp &&
                        result?.grossPrice &&
                        result?.mrp > 0 &&
                        result?.grossPrice > 0 && (
                          <span
                            style={{
                              color: "#58cb58",
                              marginLeft: "5px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {Math.ceil(
                              ((result?.mrp - result?.grossPrice) /
                              result?.mrp) *
                                100
                            )}
                            % off
                          </span>
                        )}
                    </span>
              </div>
            </div>
          </div>
          <div>
                {deliveryAvailable ? (
                  <>
                    <div className="totaldiv-addtocard-buynow">
                      <button
                        className="addtocart-btn-oil"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCartNow(
                            result,
                            index + 1,
                            result.__queryID,
                            result.objectID,
                            quantity,
                            result.productName,
                            result,
                            result.thumbnail,
                            result.brands
                          );
                        }}
                      >
                        Add to Cart
                      </button>
                      <button
                        className="buynow-btn-oil"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleBuyNow(
                            result,
                            result + 1,
                            result.__queryID,
                            result.objectID,
                            result.productName,
                            result,
                            result.thumbnail,
                            result.brands
                          );
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAvailService(result, index + 1, result.__queryID);
                    }}
                  >
                    Request for Quotation
                  </button>
                )}
              </div>
        </div>
      ))}

  </div>

  {!showAllSpare && combinedResults.spareResults.length > 3 && (
      <button className="buttonviewall" onClick={viewAllforSpare}>View All</button>
    )}
</div>



<div className="mt-3"></div>



{QuotationPopupforBuyNowinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForInSpare
                productDetails={productDETAILS}
                onSubmit={handleSubmit}
              />
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {QuotationPopupforAddtoCartinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForSpareAddtoCart
                productDetails={productDETAILS}
                onSubmit={handleSubmitForCart}
                position={selectedPosition}
              />
              <div className="close-popup" onClick={closePopup4}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="success-popup">
          <div className="success-popup-content">
            <div className="success-icon">✓</div>
            <div className="success-message">
              <h3>Item added to Cart Successfully</h3>
            </div>
          </div>
        </div>
      )}
                          

{/* {noResults && (
    <NoResultsFound searchResults={[{ nbHits: 0 }, { nbHits: 0 },]} />
)} */}
                      {/* </div> */}
                  {/* </div>
              </div>
          </div> */}
      </>
  );
};

export default CombinedHits;
