import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MyOrdersDesign.css";
import axios from "axios";
import VerticalLinearStepper from "../Buying/MyMachine/Machine/VerticalLinearStepper";
import Loader from "../SubComponent/Loader";

//icons
import { FaCartShopping } from "react-icons/fa6"; //Ordered
import { IoCartOutline } from "react-icons/io5";
import { LiaNewspaperSolid } from "react-icons/lia"; //Quotation raised
import { PiNewspaperClippingLight } from "react-icons/pi"; //Quotation Approved
import { TbFileInvoice } from "react-icons/tb"; //invoice
import { FcPackage } from "react-icons/fc"; //Packaged
import { BiPackage } from "react-icons/bi";
import { FcShipped } from "react-icons/fc"; //Shipped
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineDone } from "react-icons/md"; //Done

const MyOrderSummaryDesigRevisedCode = () => {
  //Navigation
  const location = useLocation();
  const [orderId, setOrderId] = useState("");

  const { orderIndex } = location.state || {};

  console.log("MyOrderSummaryRevised page loaded:");
  console.log("Received orderIndex from state:", orderIndex);

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const message = queryParams.get("message");

  //States
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSize, setSize] = useState({ width: 48, height: 48 });
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  //To show the selectedAddress that the user have choosen
  const selectedAddressIndex =
    parseInt(localStorage.getItem("selectedAddressIndex"), 10) || 0;
  const selectedAddress = data ? data[selectedAddressIndex]?.address : null;

  //Go to orders page when clicked on Your order Button :
  const handlegotoOrdersPage = () => {
    navigate("/yourorder");
  };



  console.log("orderDetails name : ",orderDetails?.customer_name)

  
const formattedName = orderDetails?.customer_name?.replace(/([a-z])([A-Z])/g, '$1 $2');

console.log("Order Details Name: ", formattedName);
  //When download Invoice button is clicked this will help to get the invoice link
  const handleDownloadInvoice = () => {
    console.log("Inside HandleDownloadInvoice Button Function  : ");
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    } else {
      alert("Invoice URL not available");
    }
  };

  //This is used for to fetch all order of a customer
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://ompextension.origa.market/api/fetchcustomeraddress",
          {
            id: localStorage.getItem("id"),
            mobileno: localStorage.getItem("number"),
          }
        );
        console.log("API Response:", response.data);
        setData(response?.data?.response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  /*This Function  : For the related the reference id and the customer id i will send to the api and */
  useEffect(() => {
    const fetchOrderDetails = async (customerId, referenceNumber) => {
      console.log(
        "Correct details : ------------------> ",
        customerId,
        referenceNumber
      );
      try {
        console.log("Fetching order details for:", {
          customerId,
          referenceNumber,
        });

        const response = await fetch(
          `https://ompextension.origa.market/sparesapi/get_orders`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              customer_id: customerId,
              reference_number: referenceNumber,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch order details");
        }

        const data = await response.json();
        console.log("API response:", data);

        if (data.order) {
          setOrderDetails(data.order);

          console.log(`Order Details :
  Order Customer Name  : ${orderDetails?.customer_name},
  Order Billing Address : ${orderDetails?.billing_address?.address},
  Order City : ${orderDetails?.billing_address?.city}
  `);
          // When dowload invoice is clicked it should get the link from the response of the the api
          if (data.order.invoice_info && data.order.invoice_info.length > 0) {
            setInvoiceUrl(data.order.invoice_info[0].s3_url);
          }
        } else {
          setOrderDetails(null);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    //This fetch operation is triggered only if both id and message  = Paid
    if (id && message === "Paid") {
      const customerId = localStorage.getItem("id");
      fetchOrderDetails(customerId, id);
      console.log("Now here : ");
    }
  }, [id, message]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Initial check for the current window size
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /*Stepper Logics  :   */
  const stepsforjson1 = [
    {
      label: "Quotation Raised",
      description: `The customer has raised a quotation`,
      icon: <LiaNewspaperSolid className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Quotation Approved",
      description: "Quotation being successfully approved",
      icon: (
        <PiNewspaperClippingLight className="iconinverticalstepper" size={30} />
      ),
    },
    {
      label: "Proforma Invoice",
      description: `Generation of Invoice`,
      icon: <TbFileInvoice className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: `The item will reach u in 2 days`,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: `Delivered in time `,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];

  const stepsforjson2 = [
    {
      label: "Quotation Raised",
      description: `The customer has raised a quotation`,
      icon: <LiaNewspaperSolid className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Quotation Approved",
      description: "Quotation being successfully approved",
      icon: (
        <PiNewspaperClippingLight className="iconinverticalstepper" size={30} />
      ),
    },
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: `The item will reach u in 2 days`,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: `Delivered in time `,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];

  const stepsforjson3 = [
    {
      label: "Proforma Invoice",
      description: `Generation of Invoice`,
      icon: <TbFileInvoice className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: `The item will reach u in 2 days`,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: `Delivered in time `,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];

  const stepsforjson4 = [
    {
      label: "Ordered",
      description: `Order being processed`,
      icon: <IoCartOutline className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Packaged",
      description: `Started to packaging it`,
      icon: <BiPackage className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Shipped",
      description: `The item will reach u in 2 days`,
      icon: <CiDeliveryTruck className="iconinverticalstepper" size={30} />,
    },
    {
      label: "Delivered",
      description: `Delivered in time `,
      icon: <MdOutlineDone className="iconinverticalstepper" size={30} />,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [selectedStepper, setSelectedStepper] = useState([]);
  const stepperArray = [
    stepsforjson1,
    stepsforjson2,
    stepsforjson3,
    stepsforjson4,
  ];
  // const selectedStepper = stepperArray[orderIndex % stepperArray.length] || stepsforjson1;

  useEffect(() => {
    const stepper = stepperArray[orderIndex % stepperArray.length];
    setSelectedStepper(stepper);

    if (stepper === stepsforjson1) {
      setActiveStep(1);
    } else if (stepper === stepsforjson2) {
      setActiveStep(3);
    } else if (stepper === stepsforjson3) {
      setActiveStep(0);
    } else if (stepper === stepsforjson4) {
      setActiveStep(3);
    }
  }, [orderIndex]);

  // console.log("Selected Stepper:", selectedStepper);
  const referenceId = orderDetails?.reference_id;
  // const [currentStepper, setCurrentStepper] = useState(stepsforjson1);

  if (!orderDetails) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <>
      {/******************************************Old Design Code***************************************/}
      {/* <div className="OrderSummaryTotalPageDived">
        <div className=" ordercontainerrrrr container">
          <div className="row">
            <div className="col-12">
              <div className="totalcontentsummaryedd">
                <div className="ordersummaryh1dived">
                  <h1 className="ordersummaryh1ed">Order Summary :</h1>
                </div>

              </div>

              <div className="BelowOrderSummaryDived">
                <div className="order-summary-wraped">
                  <div className="order-container">
                    {orderDetails.line_items.map((item, index) => (
                      <div className="order-itemed" key={index}>
                        {isMobile ? (
                          <div class="prodyctcardsummary">
                            <div className="prodyctcardsummary-leftdiv">
                              <img
                                className="product-SBFhi"
                                src={item.thumbnail[0]?.url || "N/A"}
                                alt="Product Image"
                              />
                            </div>

                            <div className="prodyctcardsummary-rightdiv">
                              <div className="heading-600-16 title">
                                {item.name}
                              </div>

                              <div className="heading-600-14 price">
                                ₹{item.rate}
                              </div>
                              <div className="heading-600-14 price">
                                Quantity: {item.quantity}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="product-wrapEBedd">
                            <div className="left-wraRSNFFDpeee">
                              <img
                                className="product-SBFhi"
                                src={item.thumbnail[0]?.url || "N/A"}
                                alt="Product Image"
                              />
                              <div className="detail-wrapeee">
                                <div className="heading-600-16 title">
                                  {item.name}
                                </div>
                                <div className="price-wrap">
                                  <div className="heading-600-14 price">
                                    ₹{item.rate}
                                  </div>
                                  <div className="heading-600-14 price">
                                    Quantity: {item.quantity}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="amount-summary">
                    <div className="summary-heading">Amount Summary</div>
                    <div className="summary-item">
                      <span className="summary-label">Total Price:</span>
                      <span className="summary-value">
                        ₹{orderDetails.total}
                      </span>
                    </div>
                    <div className="summary-item">
                      <span className="summary-label">Delivery Charges:</span>
                      <span className="summary-value">
                        ₹{orderDetails.shipping_charge}
                      </span>
                    </div>
                    <div className="summary-item">
                      <span className="summary-label">Total Quantity:</span>
                      <span className="summary-value">
                        {orderDetails.total_quantity}
                      </span>
                    </div>
                    <div className="summary-item">
                      <span className="summary-label">Grand Total:</span>
                      <span className="summary-value">
                        ₹{orderDetails.total + orderDetails.shipping_charge}
                      </span>
                    </div>
                  </div>
                  <div className="somespavebetween"></div>
                  <div className="amount-summary">
                    <div className="summary-label">
                      {selectedAddress && (
                        <>
                          <div className="summary-heading">
                            Delivery Address
                          </div>
                          <span className="headingindelivery">Name : </span>
                          {selectedAddress.input.firstName}{" "}
                          {selectedAddress.input.lastName}
                          <br></br>
                          <span className="headingindelivery">
                            Mobile Number :{" "}
                          </span>
                          {selectedAddress.input.phone}
                          <br></br>
                          <span className="headingindelivery">Gst No : </span>
                          {selectedAddress.gst_no}
                          <br></br>
                          <span className="headingindelivery">
                            Billing Address:{" "}
                          </span>
                          <br></br>
                          {selectedAddress.input.streetAddress1},<br></br>
                          {selectedAddress.input.city},
                          {selectedAddress.input.countryArea},
                          {selectedAddress.countryArea},{" "}
                          {selectedAddress.input.postalCode}
                          <br></br>
                          <span className="headingindelivery">
                            Shipping Address:{" "}
                          </span>
                          <br></br>
                          {
                            selectedAddress.businessaddress?.streetAddress1
                          }, {selectedAddress.businessaddress?.streetAddress2}
                          {selectedAddress.businessaddress.countryArea},
                          <br></br>
                          {selectedAddress.businessaddress?.city},
                          {selectedAddress.businessaddress?.postalCode}
                          <br></br>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="delivery-btn-wrap rwbjkn">
              <button
                className="buttonhandlegotorders"
                onClick={handlegotoOrdersPage}
              >
                Go to orders
              </button>
              <button
                className="buttondownloadinvoice"
                onClick={handleDownloadInvoice}
              >
                Download invoice
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {loading && <Loader />}
      {/******************************************New Design Code***************************************/}
      {/***************************************For Desktop mode - div***********************************/}
      <div className="Whole-dIV-FOR-OrderSummaryDesgin-for-823px ">
        <div className="custom-marginccc pt-3 ">
          <div className="">
            <h1 className="ordersummaryh1ed ">Order # {referenceId}</h1>

            {/*Wrapper for total left and right div*/}
            <div className="NewOrderSummaryDesign d-flex">
              {/*Left Side div - Vertical Line Stepper*/}
              <div className="stepper-rightside">
                <VerticalLinearStepper
                  selectedStepper={selectedStepper}
                  activeStep={activeStep}
                />
              </div>

              {/* Right Side div - Full contents part like (name , address , products , amount summary)*/}
              <div className="products-amountsummary-delivery">
                {/*Name , mobile number and gst */}
                <div className="namemobileleft-gstright d-flex justify-content-between">
                  <div>
                    <span
                      style={{
                        fontSize: "16px",
                        textAlign: "right",
                        fontWeight: "700",
                      }}
                    >
                      &nbsp;&nbsp;Order Details
                    </span>
                    <span style={{ display: "block", fontSize: "14px" }}>
                      &nbsp;&nbsp;{formattedName}
                    </span>
                    <span style={{ display: "block", fontSize: "14px" }}>
                      &nbsp;&nbsp; {orderDetails?.shipping_address?.phone}
                    </span>
                  </div>

                  <div>
                    <span
                      style={{ fontSize: "14px" }}
                      className="gstnoinrightside"
                    >
                      {" "}
                      Gst no:{}
                    </span>
                  </div>
                </div>

                {/*Address  - Billing and Shipping Address  */}
                <div className="addresspart pt-2">
                  {selectedAddress && (
                    <div className="BillingAddressPartandShippingAddressPart">
                      {/* Billing Address */}
                      <div className="sdvvsfv">
                        <span className="headingindelivery">
                          Billing Address:
                        </span>
                        <br />
                        <div className="selectedAddshippingAddress">
                          {" "}
                          {orderDetails?.billing_address?.address},<br />
                          {orderDetails?.billing_address?.street2},{" "}
                          {orderDetails?.billing_address?.city},{" "}
                          {orderDetails?.billing_address?.state},
                          {orderDetails?.billing_address?.zip}
                        </div>

                        <br />
                      </div>

                      {/* Shipping Address */}
                      <div className="vfvjfjjf">
                        <span className="headingindelivery">
                          Shipping Address:
                        </span>
                        <br />
                        {orderDetails?.shipping_address?.address},<br />
                        {orderDetails?.shipping_address?.street2},{" "}
                        {orderDetails?.shipping_address?.city},{" "}
                        {orderDetails?.shipping_address?.state},
                        {orderDetails?.shipping_address?.zip}
                        <br />
                      </div>
                    </div>
                  )}
                </div>

                {/*Displaying individual Order Section  */}
                <div className="order-items-in-summary">
                  <div className="BelowOrderSummaryDived">
                    <div className="order-summary-wraped">
                      <div className="order-container">
                        {orderDetails.line_items.map((item, index) => (
                          <div className="order-itemed" key={index}>
                            {isMobile ? (
                              <div class="prodyctcardsummary">
                                <div className="prodyctcardsummary-leftdiv">
                                  <img
                                    className="product-SBFhi"
                                    src={item.thumbnail[0]?.url || "N/A"}
                                    alt="mage"
                                  />
                                </div>

                                <div className="prodyctcardsummary-rightdiv">
                                  <div className="heading-600-16 title">
                                    {item.name}
                                  </div>

                                  <div className="heading-600-14 price">
                                    ₹{item.amount}
                                  </div>
                                  <div className="heading-600-14 price">
                                    Quantity: {item.quantity}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="product-wrapper">
                                <div className="product-left">
                                  <img
                                    className="product-image"
                                    src={item.thumbnail[0]?.url || "N/A"}
                                    alt="mage"
                                  />
                                  <div className="product-details">
                                    <div className="product-name">
                                      {item.name}
                                    </div>
                                    <div className="product-quantity">
                                      Quantity: {item.quantity}
                                    </div>
                                  </div>
                                </div>
                                <div className="product-right">
                                  {/* <div className="product-quantity">Quantity: {item.quantity}</div> */}
                                  <div className="product-rate">
                                    ₹{item.amount}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/*Displaying Order Amount Summary*/}
                <div className="Order-Amount-Summary ">
                  <div className="summary-heading">Amount Summary</div>
                  <div className="summary-item">
                    <span className="summary-label">Total Price:</span>
                    <span className="summary-value">₹{orderDetails.total}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Tax Total:</span>
                    <span className="summary-value">₹{orderDetails.tax_total}</span>
                  </div>
                  
                  <div className="summary-item">
                    <span className="summary-label">Delivery Charges:</span>
                    <span className="summary-value">
                      ₹{orderDetails.shipping_charge}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Total Quantity:</span>
                    <span className="summary-value">
                      {orderDetails.total_quantity}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Grand Total:</span>
                    <span className="summary-value">
                      ₹{orderDetails.total + orderDetails.shipping_charge}
                    </span>
                  </div>
                </div>
                {/*Buttons in bottom */}
                <div className="d-flex mt-1">
                  <button
                    className="buttonhandlegotorders"
                    onClick={handlegotoOrdersPage}
                  >
                    Go to orders
                  </button>
                  <button
                    className="buttondownloadinvoice"
                    onClick={handleDownloadInvoice}
                  >
                    Download invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/***************************************For mobile mode - div***********************************/}

      <div className="Whole-dIV-FOR-OrderSummaryDesgin-for-824px container-fluid">
        <div className="custom-marginccc pt-3 mb-2">
          <div className="">
            <h1 className="ordersummaryh1ed ">Order #  {referenceId}</h1>
            {/*Wrapper for column for mobile layout*/}

            <div className="NewOrderSummaryDesign d-flex flex-column">
              {/* Full contents part like (name , address , products , amount summary)*/}
              <div class="p-2 ">
                {" "}
                <div className="namemobileleft-gstright d-flex justify-content-between">
                  <div>
                    <span
                      style={{
                        fontSize: "16px",
                        textAlign: "right",
                        fontWeight: "700",
                      }}
                    >
                      &nbsp;&nbsp;Order Details
                    </span>
                    <span style={{ display: "block", fontSize: "14px" }}>
                      &nbsp;&nbsp; {formattedName}
                    </span>
                    <span style={{ display: "block", fontSize: "14px" }}>
                      &nbsp;&nbsp; {orderDetails?.shipping_address?.phone}
                    </span>
                  </div>

                  <div>
                    <span
                      style={{ fontSize: "14px" }}
                      className="gstnoinrightside"
                    >
                      {" "}
                      Gst no : 
                    </span>
                  </div>
                </div>
                {/*Displaying individual Order Section  */}
                <div className="order-items-in-summary">
                  <div className="BelowOrderSummaryDived">
                    <div className="order-summary-wraped">
                      <div className="order-container">
                        {orderDetails.line_items.map((item, index) => (
                          <div className="order-itemed" key={index}>
                            {isMobile ? (
                              <div class="prodyctcardsummary">
                                <div className="prodyctcardsummary-leftdiv">
                                  <img
                                    className="product-SBFhi"
                                    src={item.thumbnail[0]?.url || "N/A"}
                                    alt="mage"
                                  />
                                </div>

                                <div className="prodyctcardsummary-rightdiv">
                                  <div className="heading-600-16 title">
                                    {item.name}
                                  </div>

                                  <div className="heading-600-14 price">
                                    ₹{item.amount}
                                  </div>
                                  <div className="heading-600-14 price">
                                    Quantity: {item.quantity}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="product-wrapper">
                                <div className="product-left">
                                  <img
                                    className="product-image"
                                    src={item.thumbnail[0]?.url || "N/A"}
                                    alt="mage"
                                  />
                                  <div className="product-details">
                                    <div className="product-name">
                                      {item.name}
                                    </div>
                                    <div className="product-quantity">
                                      Quantity: {item.quantity}
                                    </div>
                                  </div>
                                </div>
                                <div className="product-right">
                                  <div className="product-rate">
                                    ₹{item.amount}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 ">
                {/*Vertical Line Stepper*/}
                <VerticalLinearStepper
                  selectedStepper={selectedStepper}
                  activeStep={activeStep}
                />
              </div>
              <div class="p-2 ">
                {/*Address  - Billing and Shipping Address  */}
                <div className="addresspart pt-2">
                  {selectedAddress && (
                    <div className="BillingAddressPartandShippingAddressPart">
                      {/* Billing Address */}
                      <div className="sdvvsfv">
                        <span className="headingindelivery">
                          Billing Address:
                        </span>
                        <br />
                        <div className="selectedAddshippingAddress">
                          {" "}
                          {orderDetails?.billing_address?.address},<br />
                          {orderDetails?.billing_address?.street2},{" "}
                          {orderDetails?.billing_address?.city},{" "}
                          {orderDetails?.billing_address?.state},
                          {orderDetails?.billing_address?.zip}
                        </div>

                        <br />
                      </div>

                      {/* Shipping Address */}
                      <div className="vfvjfjjf">
                        <span className="headingindelivery">
                          Shipping Address:
                        </span>
                        <br />
                        {orderDetails?.shipping_address?.address},<br />
                        {orderDetails?.shipping_address?.street2},{" "}
                        {orderDetails?.shipping_address?.city},{" "}
                        {orderDetails?.shipping_address?.state},
                        {orderDetails?.shipping_address?.zip}
                        <br />
                      </div>
                    </div>
                  )}
                </div>

                {/*Displaying Order Amount Summary*/}
                <div className="Order-Amount-Summary ">
                  <div className="summary-heading">Amount Summary</div>
                  <div className="summary-item">
                    <span className="summary-label">Total Price:</span>
                    <span className="summary-value">₹{orderDetails.total}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Tax Total:</span>
                    <span className="summary-value">₹{orderDetails.tax_total}</span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Delivery Charges:</span>
                    <span className="summary-value">
                      ₹{orderDetails.shipping_charge}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Total Quantity:</span>
                    <span className="summary-value">
                      {orderDetails.total_quantity}
                    </span>
                  </div>
                  <div className="summary-item">
                    <span className="summary-label">Grand Total:</span>
                    <span className="summary-value">
                      ₹{orderDetails.total + orderDetails.shipping_charge}
                    </span>
                  </div>
                </div>

                {/*Buttons in bottom */}
                <div className="d-flex mt-1">
                  <button
                    className="buttonhandlegotorders"
                    onClick={handlegotoOrdersPage}
                  >
                    Go to orders
                  </button>
                  <button
                    className="buttondownloadinvoice"
                    onClick={handleDownloadInvoice}
                  >
                    Download invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrderSummaryDesigRevisedCode;
