import React, { useState, useEffect, useRef } from "react";
import ImageGallery from "react-image-gallery";
import "./ProductPage.css"; // Import the updated CSS file
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";
import Loader from "../../../SubComponent/Loader";
import axios from "axios";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the CSS for react-image-gallery
import { gql } from "graphql-tag";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { closeIcon } from "../../../../helpers/Icons";
import QuotationPopup from "../../Product/SparePage/QuotationPopup";
import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import { locationIcon } from "../../../../helpers/Icons";
import SimilarProductsforTools from "./SimilarProductsforTools";
import SimilarProducts from "../../Product/ProductPage/SimilarProducts";
import SimilarProductsforAlgolia from "./SimilarProductsforAlgolia";
import { FaAngleUp } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import PricesCompetitive from "../../../SubComponent/PricesCompetitive";
import CallCenterIcon from "../../../SubComponent/CallCenterIcon";
import InvoiceIcon from "../../../SubComponent/invoiceIcon";
import { FcPackage } from "react-icons/fc";
import { FcInTransit } from "react-icons/fc";
import { TbTruckDelivery } from "react-icons/tb";
import { FcOvertime } from "react-icons/fc";
import { FcPaid } from "react-icons/fc";
import { AiFillTruck } from "react-icons/ai";
import Footer from "../../../Footer/Footer";
import aa from "search-insights";
import LookingSimilarAlgolia from "./LookingSimilarAlgolia";
import AlgoliaTry from "./AlgoliaTry";
import Breadcrumbs from "../../Breadcrumbs";
import BreadCrumbsforSpareRevised from "../../Breadcrumbs/BreadCrumbsforSpareRevised";

aa("init", {
  appId: "ALGOLIA_ID",
  apiKey: "ALGOLIA_KEY",
});
const CREATE_CONTACT_US = gql`
  mutation CreateConatctUs($contactusinput: ContactUsInput!) {
    createContactUs(contactusinput: $contactusinput) {
      contactus {
        id
        firstname
        lastname
        emailid
        reasonId {
          id
          reasonTitle
          reasonDescription
        }
        queryDescription
      }
      message
      success
    }
  }
`;
const client = secondClient;
const ProductPage = ({
  itemId,
  showDiscount = false,
  showPriceWithTax = true,
  showGST = true,
  showMRP = false,
  showOffers = false,
}) => {
  const location = useLocation();
  const { group_id, objectID } = location.state || {};
  const loggedin = localStorage.getItem("userToken");
  const queryParams = new URLSearchParams(location.search);
  const group_id_url = queryParams.get("group_id");
  const productId = queryParams.get("id");

  const [loading, setLoading] = useState(true);
  const [loginPortal, setLoginPortal] = useState(false);
  const navigate = useNavigate();
  const onHidePortal = () => {
    setLoginPortal(false);
  };

  const [similarProductsItem, setSimilarProductsItem] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);

  const [similaralgoliaproducts, setSimilarAlgoliaProducts] = useState([]);

  /********************************************************************Scrolling Logic*******************************************************************/
  const productImagesRef = useRef(null);
  const productInfoRef = useRef(null);
  const productPricingRef = useRef(null);
  const placeholderRef = useRef(null);
  const [scrollPosition, setScrollPostion] = useState();

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window?.pageYOffset;
      setScrollPostion(position);

      const productImagesHeight = productImagesRef?.current?.offsetHeight;
      const productInfoBottom =
        productInfoRef?.current?.getBoundingClientRect()?.bottom;
      const productPricingHeight = productPricingRef?.current?.offsetHeight;

      if (window.innerWidth > 740) {
        if (
          productInfoBottom > productPricingHeight &&
          productInfoBottom > productImagesHeight
        ) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }

        if (placeholderRef.current) {
          placeholderRef.current.style.height = isFixed
            ? `${productPricingHeight}px`
            : "0px";
        }
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [isFixed]);

  const getClassName = () => {
    if (window.innerWidth <= 740) {
      return "absolute";
    }
    return isFixed ? "fixed" : "absolute";
  };

  /********************************************************************Delivery Location*******************************************************************/
  const [deliveryAvailablefLocal, setDeliveryAvailableLocal] = useState(false);
  const [storedLocation, setStoredLocation] = useState(null);
  const [pincode, setPincode] = useState("");
  const [deliveryDetails, setDeliveryDetails] = useState({
    shippingPrice: 19,
    deliveryDays: 6,
    codAvailable: true,
    deliveryAvailable: null,
  });
  const [showDeliveryMessage, setShowDeliveryMessage] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation) {
      setStoredLocation(storedLocation);
      setPincode(storedLocation);
    
      setLoading(true);

      // Automatically check delivery availability for the prefilled pincode
      checkDeliveryAvailability(storedLocation)
        .then((isAvailable) => {
          setDeliveryDetails({
            ...deliveryDetails,
            deliveryAvailable: isAvailable,
          });
          setShowDeliveryMessage(true); // Show the delivery message
        })
        .catch((error) => {
          console.error("Error checking delivery availability on load:", error);
          setDeliveryDetails({
            ...deliveryDetails,
            deliveryAvailable: false,
          });
          setShowDeliveryMessage(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch(
        "https://ompextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      const isAvailable = data.available_status === "available";

      setDeliveryAvailableLocal(isAvailable);
      console.log("Delivery Available  -----> ",deliveryAvailablefLocal)

      if (isAvailable) {
        localStorage.setItem("deliveryLocation", pincode);
      }

      return isAvailable; // Return the delivery availability status
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailableLocal(false);
      return false; // Return false in case of error
    }
  };

  const handleCheckDelivery = async () => {
  
    try {
      const response = await fetch(
        "https://ompextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      const isAvailable = data.available_status === "available";

      setDeliveryAvailableLocal(isAvailable);
      setDeliveryDetails({
        ...deliveryDetails,
        deliveryAvailable: isAvailable,
      });


      console.log("Delivery Location : ",localStorage.getItem("deliveryLocation"))
      if (isAvailable) {
        
        localStorage.setItem("deliveryLocation", pincode);
        setStoredLocation(pincode); 
        console.log("Stored Location is Local Storage : ",storedLocation);
      }
      setShowDeliveryMessage(true); // Show message after checking delivery

      // ✅ Instead of reloading, trigger a re-fetch by updating state
      setTriggerFetch(prev => !prev); 
    } catch (error) {
      console.error("Error checking delivery:", error);
      setDeliveryAvailableLocal(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
  
    
    if (/^\d{0,6}$/.test(value)) {
      setPincode(value);
      setShowDeliveryMessage(false);

      
    }
    localStorage.setItem("deliveryLocation", value);
    console.log("Pincode stored in localStorage after entering the pincode : ",localStorage.getItem("deliveryLocation"));

  };
  

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // setLoading(true);
      handleCheckDelivery();
    }
  };

  /*----------------------------------------------------------------Getting data from api - Primary----------------------------------------------------------- */
  const [productDETAILS, setProductDETAILS] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true); // Start loading
    const fetchData = async () => {
      try {

        const pincodefromdel = localStorage.getItem("deliveryLocation");
        const pincodefromdeliveryLocation = pincodefromdel ? pincodefromdel : "";

        const { data } = await client.query({
          query: GET_SPARE_DETAILS,
          // variables: { groupId: group_id_url, itemId: "",pincode:pincodefromdeliveryLocation },
          variables: { groupId: group_id_url, itemId: ""},
        });

        console.log("API Response:", data);

        let productData = data?.inventoryItem?.response;

        if (Array.isArray(productData)) {
          setProductDETAILS(productData);

          const selectedIndex = productData.findIndex(
            (item) => item.id === objectID
          );
          if (selectedIndex !== -1) {
            setinfokey(selectedIndex);
            setSelectedItemId(objectID);
          } else {
            setinfokey(0);
            setSelectedItemId(productData[0]?.id);
          }
        } else {
          console.error("Unexpected API response structure");
          setProductDETAILS([]);
        }

        setLoading(false);
        updateFormattedPrice();

            // Keep loading true for 2 seconds before setting it to false
            setTimeout(() => {
              setLoading(false);
          }, 2000);
      } catch (error) {
        console.error("Error fetching product details:", error);
            setTimeout(() => {
                setLoading(false);
            }, 2000);
      }
    };
    fetchData();
  }, [group_id, objectID, triggerFetch]);

  const [infokey, setinfokey] = useState(0);
  const productIdtoCart = productDETAILS?.[infokey]?.id;
  const productName = productDETAILS?.[infokey]?.name;
  const salePrice = productDETAILS[0]?.saleprice || 0;

  const updateFormattedPrice = () => {
    //is used to update how the price of a product is displayed on a webpage
    setProductDETAILS((prevDetails) => ({
      //it takes the current details of the product(prevdetails)and creates a new set of details with an updatted formatted price
      ...prevDetails,
      price_details: {
        ...prevDetails.price_details,
        //amc_cost: formatCurrency(prevDetails.price_details.amc_cost),
        //delivery_charges: formatCurrency(prevDetails.price_details.delivery_charges),
        machine_price: formatCurrency(prevDetails.sales_rate),
      },
    }));
  };

  const formatCurrency = (value) => {
    //like to show in indian rupees it adds commas where needed and shows the right number of decimal place
    const fractionDigits = value % 1 !== 0 ? 2 : 0;
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value || 0);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const closePopup2 = () => {
    setShowPopup2(false);
    navigate("/store");
  };

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [clickedPriceItem, setClickedPriceItem] = useState(0);
  const [bucketPrice, setBucketPrice] = useState(null);
  const [bucketSize, setBucketSize] = useState(null);
  const [displayText, setDisplayText] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  const [activeTab, setActiveTab] = useState("Basic Information");
  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  // const [isExpanded, setIsExpanded] = useState(false);
  // const [expandedContent, setExpandedContent] = useState(false);

  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };

  // const toggleContent = () => {
  //   setExpandedContent(!expandedContent);
  // };

  /********************************************************************Advantages Logic*******************************************************************/


  const [Advantages, setAdvantages] = useState("");

  useEffect(() => {
    const advantagesfromresponse = 
      productDETAILS?.[infokey]?.productDetails?.Advantages?.Advantages || "";
  
    setAdvantages(advantagesfromresponse);
  }, [productDETAILS, infokey]);
  
  console.log("Advantages in state variable:", Advantages);
  

  const [showFullAdvantages, setShowFullAdvantages] = useState(false);
  const [expandedAdvantages, setExpandedAdvantages] = useState({});

  const advantages =
    productDETAILS?.[infokey]?.productDetails?.Advantages || {};
  const advantagesEntries = Object.entries(advantages);

  // console.log("advantagesEntries : ",advantagesEntries);
  const displayedAdvantages = showFullAdvantages
    ? advantagesEntries
    : advantagesEntries.slice(0, 1);

  const toggleAdvantages = () => {
    setShowFullAdvantages(!showFullAdvantages);
  };

  const toggleAdvantage = (key) => {
    setExpandedAdvantages((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const formattedAdvantages = displayedAdvantages.map(([key, value]) => {
    const sections = value.split(
      /(?<=\n)(?=[A-Za-z\s]+:)|(?=\d+\)[A-Za-z\s]+:)/
    );

    return { key, sections };
  });

  const totalSections = formattedAdvantages.reduce(
    (acc, advantage) => acc + advantage.sections.length,
    0
  );


  /********************************************************************Descriptions Logic*******************************************************************/
  
  const [Description, setDescription] = useState("");

  useEffect(() => {
    const descriptionfromresponse =
      productDETAILS?.[infokey]?.productDetails?.Description?.Description || "";
  
    setDescription(descriptionfromresponse);
  }, [productDETAILS, infokey]);
  
  
  const formatDescriptionToBulletPoints = (description) => {
    const bulletPoints = description.split('Advantage-')
      .filter((point) => point.trim()) 
      .map((point, index) => `${index + 1}) ${point.trim()}`); 
  
    return bulletPoints;
  };
  
  useEffect(() => {
    const bulletPoints = formatDescriptionToBulletPoints(Description);
    console.log("Bullet Points:", bulletPoints);
  }, [Description]);
  
  
  
  
  
  
  
  
  
  
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(false);

  
  const descriptions = productDETAILS?.[infokey]?.description || "";
  const descriptionSections = descriptions.split(". ");

  const displayedDescription = showFullDescription
    ? descriptionSections
    : descriptionSections.slice(0, 2);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const toggleSingleDescription = (key) => {
    setExpandedDescription((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const [showFullBasicInfo, setShowFullBasicInfo] = useState(false);
  const [showFullSpecs, setShowFullSpecs] = useState(false);
  const toggleBasicInfo = () => {
    setShowFullBasicInfo(!showFullBasicInfo);
  };

  const toggleSpecs = () => {
    setShowFullSpecs(!showFullSpecs);
  };

  const calculateDiscount = (mrp, price) => {
    if (mrp > 0) {
      return Math.ceil(((mrp - price) / mrp) * 100);
    }
    return 0;
  };

  const [endQuantity, setEndQuantity] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [barrelQuantity, setBarrelQuantity] = useState(1);
  const [bucketQuantity, setBucketQuantity] = useState(1);
  const [isBarrel, setIsBarrel] = useState(false);

  useEffect(() => {
    if (selectedProduct && (bucketQuantity || barrelQuantity)) {
      const currentQuantity = isBarrel ? barrelQuantity : bucketQuantity;
      // console.log("Selected Product : ", selectedProduct);
      // console.log("Selected Quantity : ".selectedQuantity);
      // console.log("Current barrelQuantity:", barrelQuantity);
      // console.log("Current bucketQuantity:", bucketQuantity);
      // console.log("Current currentQuantity:", currentQuantity);

      const bucketId = getBucketId(currentQuantity);
    }
  }, [
    selectedQuantity,
    selectedProduct,
    bucketQuantity,
    barrelQuantity,
    isBarrel,
  ]);

  // Function to get the correct bucket ID based on the current quantity
  const getBucketId = (quantity, bucketSize) => {
    const priceList = selectedProduct?.price_list || [];

    // If priceList is not an array, log and return early
  if (!Array.isArray(priceList)) {
    
    console.log("priceList is not an array or is missing.");
    return { bucketId: null, productType: null };
  }

  
    let bucketId = null;
    let productType = null;

    // const applicablePriceItem = priceList.find((item) => {
      
    //   const isBarrel = item?.bucket_size === 210 || item?.bucket_size === 180;
      

    //   if (isBarrel) {
    //     return item.qty_type === "Barrel" ;
    //   } else {
    //     return (
    //       item.qty_type === "Bucket" &&
    //       quantity >= item.start_quantity &&
    //       (item.end_quantity === null || quantity <= item.end_quantity)
    //     );
    //   }
    // });

    // if (applicablePriceItem) {
    //   bucketId = applicablePriceItem.bucket_id; 
     
    //     productType = [210, 180].includes(applicablePriceItem?.bucket_size) ? "Barrel" : "Bucket"; 
    // } else {
    //   console.log("No matching price item found for the current quantity.");
    // }

    if ([210, 180].includes(bucketSize)) {
      // Find the appropriate Barrel price item
      console.log("Checking for Barrel with bucket size:", bucketSize); // Log bucketSize
      const applicablePriceItem = priceList.find((item) => {
        console.log("Checking item:", item); 
        return (
          item.qty_type === "Barrel" &&
          bucketSize === item.bucket_size
        );
      });
  
      if (applicablePriceItem) {
        bucketId = applicablePriceItem.bucket_id; // Get the barrel bucket ID
        productType = "Barrel";
        console.log("Bucket Id : ",bucketId);
        console.log("productType : ",productType)
      } else {
        console.log("No matching barrel price item found for the current quantity.");
      }
    } else {
      // For other bucket sizes (default case), find the bucket price item based on quantity
      
      console.log("Checking for Bucket with bucket size:", bucketSize); // Log bucketSize
      const applicablePriceItem = priceList.find((item) => {
        console.log("Checking item:", item); 
        return (
          item.qty_type === "Bucket" &&
          quantity >= item.start_quantity &&
          (item.end_quantity === null || quantity <= item.end_quantity)
        );
      });
  
      if (applicablePriceItem) {
        bucketId = applicablePriceItem.bucket_id; // Get the bucket bucket ID
        productType = "Bucket";
      } else {
        console.log("No matching bucket price item found for the current quantity.");
      }
    }

    return { bucketId, productType };
  };

  /***********************************************************************Add to Cart*************************************************************************/
  // const handleCart = async () => {
  //   const productIdtoCart = productDETAILS?.[infokey]?.id;
  //   const productName = productDETAILS?.[infokey]?.name;
  //   const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
  //   const productimage =
  //     productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
  //   const salesPrice = productDETAILS?.[infokey]?.saleprice;
  //   const selectedQuantityToUse = isBarrel ? barrelQuantity : bucketQuantity;
  //   const bucketId = getBucketId(selectedQuantityToUse);

  //   const quantityprice = quantity;
  //   const quantitypricevc = selectedQuantity;

  //   const totalCartPriceof = bucketPrice * bucketSize * quantitypricevc;
  //   const queryID = localStorage.getItem("queryID");
  //   const uuidUserTokessn = localStorage.getItem("uuidUserToken");
  //   console.log("UUID User Token stored in localStorage:", uuidUserTokessn);

  //   const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
  //   const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
  //   const spareIndex = "zohoinventory_index";
  //   const timestampe = Date.now();

  //   const positionfavd = parseInt(
  //     localStorage.getItem("Positionforalgolia"),
  //     10
  //   );

  //   if (isNaN(positionfavd)) {
  //     console.error("Invalid position value in localStorage.");
  //   } else {
  //     const positions = [positionfavd];

  //     aa(
  //       "clickedObjectIDs",
  //       {
  //         eventType: "click",
  //         eventName: "Product Clicked",
  //         index: spareIndex,
  //         objectIDs: [productIdtoCart],
  //         positions: positions,
  //         userToken: uuidUserTokessn,
  //         queryID: queryID,
  //         timestamp: timestampe,
  //       },
  //       {
  //         headers: {
  //           "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
  //           "X-Algolia-API-Key": ALGOLIA_API_KEYs,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //       .then((response) => {
  //         console.log("Click event sent successfully:", response);
  //       })
  //       .catch((error) => {
  //         console.error("Error sending conversion event:", error);
  //       });
  //   }

  //   const loggedin = localStorage.getItem("userToken");
  //   let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   const ompuserId = localStorage.getItem("id");
  //   const customer_first_name = localStorage.getItem("firstName");
  //   const customer_last_name = localStorage.getItem("lastName");
  //   const customer_contact_no = localStorage.getItem("number");
  //   const customer_email = localStorage.getItem("emailId");
  //   const existingCartItemIndex = cartItems.findIndex(
  //     (item) => item.productId === productIdtoCart
  //   );

  //   if (existingCartItemIndex !== -1) {

  //     cartItems[existingCartItemIndex].quantity += quantitypricevc;
  //     cartItems[existingCartItemIndex].totalCartPriceocvf =
  //       bucketPrice * bucketSize * cartItems[existingCartItemIndex].quantity;
  //   } else {

  //     cartItems.push({
  //       productId: productIdtoCart,
  //       productName: productName,
  //       productBrand: productbrand,
  //       productImage: productimage,
  //       salePrice: salesPrice,
  //       quantity: selectedQuantityToUse,
  //       bucketId: bucketId,
  //       bucketSize: bucketSize,
  //       bucketPrice: bucketPrice,
  //       totalCartPriceocvf: totalCartPriceof,
  //       endQuantity: endQuantity,
  //     });
  //   }

  //   if (loggedin) {

  //     try {

  //       var itemList = [];
  //       let bucketId = null;
  //       for (var item of cartItems) {
  //         var obj = {};
  //         obj["item_id"] = item.productId;

  //         obj["quantity"] = quantitypricevc;
  //         console.log("item_id:", obj["item_id"], "quantity:", obj["quantity"]);
  //         obj["bucket_id"] = item["bucketId"]; // Use bucket_id

  //         console.log(
  //           "Bucketid in localCartitems :  --------------> ",
  //           item["bucketId"]
  //         );

  //         if (bucketId === null) {
  //           bucketId = item["bucketId"];
  //         }

  //         itemList.push(obj);
  //       }
  //       console.log("itemList :: ", itemList);
  //       await fetch(
  //         "https://ompextension.origa.market/sparesapi/create_order",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             items: itemList,
  //             bucket_id: bucketId,
  //             ompuserId,
  //             customer_first_name,
  //             customer_last_name,
  //             customer_contact_no,
  //             order_status: "Draft",
  //             order_ref_number: "",
  //             customer_email,
  //             input: {
  //               city: "",
  //               cityArea: "",
  //               companyName: "",
  //               country: "",
  //               countryArea: "",
  //               firstName: "",
  //               lastName: "  ",
  //               phone: "",
  //               postalCode: "",
  //               streetAddress1: " ",
  //               streetAddress2: "",
  //             },
  //             businessaddress: {
  //               city: "",
  //               cityArea: "",
  //               companyName: " ",
  //               country: "",
  //               countryArea: "",
  //               firstName: "",
  //               lastName: "",
  //               phone: "",
  //               postalCode: "",
  //               streetAddress1: "",
  //               streetAddress2: "",
  //             },
  //             gst_no: "",
  //           }),
  //         }
  //       );
  //       console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
  //     } catch (error) {
  //       console.error("Error adding item to API cart:", error);
  //     }
  //   } else {
  //     localStorage.setItem("cartItems", JSON.stringify(cartItems));
  //   }

  //   setLoading(true);
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 500);
  // };

  const handleCart = async () => {
    const productIdtoCart = productDETAILS?.[infokey]?.id;
    const productName = productDETAILS?.[infokey]?.name;
    const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
    const productimage =
      productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
    const salesPrice = productDETAILS?.[infokey]?.saleprice;
    const selectedQuantityToUse = isBarrel ? barrelQuantity : bucketQuantity;
    const { bucketId } = getBucketId(selectedQuantityToUse, bucketSize);
    console.log("Selected Quantity to use  : ",selectedQuantityToUse)
    console.log("bucket size : ", bucketSize);
    console.log("Bucket ID:", bucketId);

    // const productType =
    //   bucketSize === 210 or 180 ? "Barrel" : bucketSize === 20 or 18? "Bucket" : "";

    const productType = [210, 180].includes(bucketSize) 
  ? "Barrel" 
  :  [20, 18].includes(bucketSize)
  ? "Bucket" 
  : "";

    const quantityprice = quantity;
    const quantitypricevc = selectedQuantity;
    const totalCartPriceoffff =
    bucketPrice * bucketSize * selectedQuantityToUse;
      console.log("totalCartPriceoffff : ",totalCartPriceoffff);
  

    const totalCartPriceof = bucketPrice * bucketSize * quantitypricevc;
    const queryID = localStorage.getItem("queryID");
    const uuidUserTokessn = localStorage.getItem("uuidUserToken");
    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";
    const timestampe = Date.now();

    const positionfavd = parseInt(
      localStorage.getItem("Positionforalgolia"),
      10
    );

    if (isNaN(positionfavd)) {
      console.error("Invalid position value in localStorage.");
    } else {
      const positions = [positionfavd];

      aa(
        "clickedObjectIDs",
        {
          eventType: "click",
          eventName: "Product Clicked",
          index: spareIndex,
          objectIDs: [productIdtoCart],
          positions: positions,
          userToken: uuidUserTokessn,
          queryID: queryID,
          timestamp: timestampe,
        },
        {
          headers: {
            "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
            "X-Algolia-API-Key": ALGOLIA_API_KEYs,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log("Click event sent successfully:", response);
        })
        .catch((error) => {
          console.error("Error sending conversion event:", error);
        });
    }

    const loggedin = localStorage.getItem("userToken");
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const priceList = selectedProduct?.price_list || [];
    console.log("Price List to be added:", priceList);
    

    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
    let itemsAlreadyInTheApi = [];
    const storedItems = localStorage.getItem("CartItemsintheapi");

    if (storedItems) {
      try {
        itemsAlreadyInTheApi = JSON.parse(storedItems);
      } catch (error) {
        console.error(
          "Error parsing CartItemsintheapi from localStorage:",
          error
        );
        itemsAlreadyInTheApi = [];
      }
    } else {
      localStorage.setItem(
        "CartItemsintheapi",
        JSON.stringify(itemsAlreadyInTheApi)
      );
    }

    let itemsToUse =
      itemsAlreadyInTheApi.length > 0 ? itemsAlreadyInTheApi : cartItems;
    console.log("items :", itemsToUse);

    if (loggedin) {
      // const existingCartItemIndex = itemsToUse.findIndex(
      //   (item) => item.item_id === productIdtoCart && item?.bucket_size === 20 or 18
      // );

      // const existingBarrelItemIndex = itemsToUse.findIndex(
      //   (item) => item.item_id === productIdtoCart && item?.bucket_size === 210 or 180
      // );


      const existingCartItemIndex = itemsToUse.findIndex(
        (item) => item.item_id === productIdtoCart && [20, 18].includes(item?.bucket_size)
      );

      const existingBarrelItemIndex = itemsToUse.findIndex(
        (item) => item.item_id === productIdtoCart && [210, 180].includes(item?.bucket_size)
      );
      
      console.log("Now what is the bucket size: ", productType);

      const adjustForBarrel = (quantity) => {
        if (quantity >= 11) {
          // Adjust quantity to the nearest multiple of 11
          return Math.floor(quantity / 11) * 11;
        }
        return quantity;
      };
  

      
      
      if (productType === "Bucket") {
        if (existingCartItemIndex !== -1) {
          // Update the existing bucket item
      
          const oldQuantity = itemsToUse[existingCartItemIndex].quantity;

          itemsToUse[existingCartItemIndex].quantity = 0;
          // Update localStorage after the first call to remove the item
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
  
          // Make the first API call to set quantity to 0
          await fetch("https://ompextension.origa.market/sparesapi/create_order", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: [
                {
                  item_id: productIdtoCart,
                  quantity: 0,
                  bucket_id: itemsToUse[existingCartItemIndex].bucket_id,
                },
              ],
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "  ",
                phone: "",
                postalCode: "",
                streetAddress1: " ",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: " ",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          });
          console.log(
            "Item set to 0 quantity in the API. Product ID:",
            productIdtoCart
          );
          itemsToUse[existingCartItemIndex].quantity = 0;
         
        localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
        console.log(
          "Cart Items After setting quantity to 0: ",
          localStorage.getItem("CartItemsintheapi")
        );


          // itemsToUse[existingCartItemIndex].quantity += quantitypricevc;
          const { bucketId: newBucketId } = getBucketId(
            oldQuantity + quantitypricevc,
            bucketSize
          );

           // Adjust the new quantity for barrel if necessary
        const adjustedQuantity = adjustForBarrel(oldQuantity + quantitypricevc);
         

          // itemsToUse[existingCartItemIndex].bucket_id = newBucketId;
          // itemsToUse[existingCartItemIndex].totalCartPriceocvf =
          //   bucketPrice *
          //   bucketSize *
          //   itemsToUse[existingCartItemIndex].quantity;

          // Push the updated item with the new quantity
    itemsToUse.push({
      item_id: productIdtoCart,
      quantity: adjustedQuantity,  // Updated quantity
      bucket_id:newBucketId,  // Keep existing bucket_id
      bucketPrice: bucketPrice,
      totalCartPriceocvf: bucketPrice * bucketSize * (oldQuantity + quantitypricevc),  // Updated price
      bucket_size: bucketSize,
      productType: "Bucket",
    });


          // Update localStorage immediately after modifying the cart
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding a new bucket: ",
            localStorage.getItem("CartItemsintheapi")
          );
        } else {
          // Add new bucket item
          itemsToUse.push({
            item_id: productIdtoCart,
            quantity: quantitypricevc,
            bucket_id: bucketId,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceof,
            bucket_size: bucketSize,
            productType: productType,
          });

          // Update localStorage immediately after adding the new item
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding a bucket: ",
            localStorage.getItem("CartItemsintheapi")
          );
        }
      } else if (productType === "Barrel") {
        if (existingBarrelItemIndex !== -1) {
          // Update the existing barrel item
          itemsToUse[existingBarrelItemIndex].quantity += barrelQuantity;
          itemsToUse[existingBarrelItemIndex].totalCartPriceocvf =
            totalCartPriceof;

          // Update localStorage immediately after modifying the cart
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding a new barrel: ",
            localStorage.getItem("CartItemsintheapi")
          );
        } else {
          // Add new barrel item
          itemsToUse.push({
            item_id: productIdtoCart,
            quantity: barrelQuantity,
            bucket_id: bucketId,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceof,
            bucket_size: bucketSize,
            productType: productType,
          });

          // Update localStorage immediately after adding the new item
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding barrel: ",
            localStorage.getItem("CartItemsintheapi")
          );
          console.log("Bucket Size : ",bucketSize);
        }
      }

      var itemList = [];
      for (var item of itemsToUse) {
        var obj = {};

        obj["item_id"] = item.item_id;
        console.log("Set item_id:", item.item_id);
        // if (item?.bucket_size === 210 or 180) {
          if ([210, 180].includes(item?.bucket_size)) {
          obj["quantity"] = item.quantity ;
          console.log("item.quantity : ",item.quantity);

          console.log("Bucket size is 210 or 180, set quantity to:", obj["quantity"]);
        } else {
          obj["quantity"] = item.quantity;
          console.log(
            "Bucket size is not 210  or 180, set quantity to:",
            obj["quantity"]
          );
        }
        obj["bucket_id"] = item.bucket_id;
        console.log("Set bucket_id:", item.bucket_id);
        itemList.push(obj);
        console.log("Pushed object to itemList:", obj);
      }

      console.log("Final itemList:", itemList);

      try {
        await fetch(
          "https://ompextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: itemList,
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "  ",
                phone: "",
                postalCode: "",
                streetAddress1: " ",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: " ",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          }
        );
        console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
      } catch (error) {
        console.error("Error adding item to API cart:", error);
      }
    } else {
      // const barrelIndex = itemsToUse.findIndex(
      //   (item) => item.bucketSize === 210 or 180 && item.productId === productIdtoCart
      // );
      const barrelIndex = itemsToUse.findIndex(
        (item) => [210, 180].includes(item?.bucketSize) && item.productId === productIdtoCart
      );
      
    
      if (barrelIndex !== -1) {
        // Remove the barrel
        itemsToUse.splice(barrelIndex, 1);
        console.log("Removed existing barrel for the same product ID.");
      }
      const existingCartItemIndex = itemsToUse.findIndex(
        (item) => item.productId === productIdtoCart
      );
    
      console.log("bucketSize : ", bucketSize);
    
      // if (bucketSize === 20) {
        if ([20, 18].includes(bucketSize)) {
        if (existingCartItemIndex !== -1) {
          console.log(
            "Old Quantity of the id : ",
            itemsToUse[existingCartItemIndex].quantity
          );
          console.log("New Quantity  of the id : ", quantitypricevc);
    
          // Combine the quantities
          let totalQuantity = itemsToUse[existingCartItemIndex].quantity + quantitypricevc;
          console.log("Combined Quantity : ", totalQuantity);
    // If combined quantity is greater than 11, update accordingly
    if (totalQuantity >= 11) {
      console.log("Total Quantity: ", totalQuantity);

      let adjustedQuantity = Math.floor(totalQuantity / 11) * 11;
      console.log("Initial Adjusted Quantity: ", adjustedQuantity);
     
      if (adjustedQuantity === 0) {adjustedQuantity = 11; // Minimum 1 barrel
        console.log("Adjusted Quantity set to minimum 11.");}

      console.log("Final Adjusted Quantity : ", adjustedQuantity);

      // Update the quantity and find corresponding price range
      // itemsToUse[existingCartItemIndex].quantity = adjustedQuantity;
      // console.log("Updated Quantity in itemsToUse: ", itemsToUse[existingCartItemIndex].quantity);

      const priceListforExisting =
        itemsToUse[existingCartItemIndex].priceList;
      console.log("Price List of existing item: ", priceListforExisting);

      let updatedBucketDetails = null;


      console.log("Iterating over priceListforExisting...");
      priceListforExisting.forEach((priceRange) => {
        console.log("Checking price range: ", priceRange);
    
        if (
          priceRange.qty_type === "Barrel" &&
          adjustedQuantity >= priceRange.start_quantity &&
          (priceRange.end_quantity === null || adjustedQuantity <= priceRange.end_quantity)
        ) {
          console.log("Price range matched: ", priceRange);
    
          updatedBucketDetails = {
            bucketId: priceRange.bucket_id,
            bucketPrice: priceRange.price,
            bucketSize: priceRange?.bucket_size,
          };
          console.log("Updated Bucket Details set: ", updatedBucketDetails);
        }
      });
    
      if (updatedBucketDetails) {
        console.log("Updated Bucket Details: ", updatedBucketDetails);
    
        // Update the bucket details
        itemsToUse[existingCartItemIndex].bucketId = updatedBucketDetails.bucketId;
        console.log("Updated Bucket ID: ", updatedBucketDetails.bucketId);
    
        itemsToUse[existingCartItemIndex].bucketPrice = updatedBucketDetails.bucketPrice;
        console.log("Updated Bucket Price: ", updatedBucketDetails.bucketPrice);
    
        itemsToUse[existingCartItemIndex].bucketSize = updatedBucketDetails.bucketSize;
        console.log("Updated Bucket Size: ", updatedBucketDetails.bucketSize);
    
        // Correctly update the totalCartPriceocvf with the new adjusted quantity 

let adjustedQuantity = Math.ceil(totalQuantity / 11);
console.log("Final Adjusted Quantity:", adjustedQuantity);

itemsToUse[existingCartItemIndex].quantity = adjustedQuantity;

        itemsToUse[existingCartItemIndex].totalCartPriceocvf =
          updatedBucketDetails.bucketPrice *
          updatedBucketDetails.bucketSize *
          adjustedQuantity;
    
        // Set endQuantity to null as it's a barrel
        itemsToUse[existingCartItemIndex].endQuantity = null;
        console.log("Updated endQuantity to null for barrel.");


        console.log("Updated Total Cart Price (totalCartPriceocvf): ", 
          itemsToUse[existingCartItemIndex].totalCartPriceocvf);
      } else {
        console.log(
          "No matching barrel range found for the updated quantity."
        );
        console.log("Updated bucket item in cart: ", itemsToUse[existingCartItemIndex]);
      }

      console.log(
        "Updated bucket item in cart:",
        itemsToUse[existingCartItemIndex]
      );
    } else {
      // If combined quantity is less than or equal to 11, remove barrel
      // const barrelIndex = itemsToUse.findIndex(
      //   (item) =>
      //     item.bucketSize === 210 or 180 && item.productId === productIdtoCart
      // );
      const barrelIndex = itemsToUse.findIndex(
        (item) => [210, 180].includes(item?.bucketSize) && item.productId === productIdtoCart
      );
      
      if (barrelIndex !== -1) {
        itemsToUse.splice(barrelIndex, 1); // Remove the barrel
        console.log("Removed barrel item from cart.");
      }

      itemsToUse[existingCartItemIndex].quantity = totalQuantity;
      const priceListForExisting =
      itemsToUse[existingCartItemIndex].priceList;
    console.log("Price List of existing item: ", priceListForExisting);

    let updatedBucketDetails = null;

    // Loop through the price list to find the correct price range
    priceListForExisting.forEach((priceRange) => {
      if (
        priceRange.qty_type === "Bucket" &&
        totalQuantity >= priceRange.start_quantity &&
        (priceRange.end_quantity === null ||
          totalQuantity <= priceRange.end_quantity)
      ) {
        updatedBucketDetails = {
          bucketId: priceRange?.bucket_id,
          bucketPrice: priceRange?.price,
          bucketSize: priceRange?.bucket_size,
        };
      }
    });

    itemsToUse[existingCartItemIndex].bucketId =
      updatedBucketDetails?.bucketId;
    itemsToUse[existingCartItemIndex].bucketPrice =
      updatedBucketDetails?.bucketPrice;
    itemsToUse[existingCartItemIndex].bucketSize =
      updatedBucketDetails?.bucketSize;

    // Correctly update the totalCartPriceocvf with the new adjusted quantity
    itemsToUse[existingCartItemIndex].totalCartPriceocvf =
      updatedBucketDetails?.bucketPrice *
      updatedBucketDetails?.bucketSize *
      totalQuantity;

    // itemsToUse[existingCartItemIndex].totalCartPriceocvf =
    //   itemsToUse[existingCartItemIndex].bucketPrice *
    //   itemsToUse[existingCartItemIndex].bucketSize *
    //   totalQuantity;

    console.log(
      "Updated bucket item in cart (quantity <= 11):",
      itemsToUse[existingCartItemIndex]
    );
  }
  } else {
    // If the item doesn't exist in the cart, add it as a new item
    itemsToUse.push({
      productId: productIdtoCart,
      productName: productName,
      productBrand: productbrand,
      productImage: productimage,
      salePrice: salesPrice,
      quantity: selectedQuantityToUse,
      bucketId: bucketId,
      bucketSize: bucketSize,
      bucketPrice: bucketPrice,
      totalCartPriceocvf: totalCartPriceof,
      endQuantity: endQuantity,
      priceList: priceList,
    });
    console.log("Added new bucket item to cart.");
  }
      } 
      // else if (bucketSize === 210 or 180) {
        else if ([210, 180].includes(bucketSize)) {
        // Handling Barrel case where bucketSize is 210 or 180
        let matchingBucket = null;
        priceList.forEach((priceRange) => {
          // if (priceRange?.bucket_size === 210 or 180) {
            if ([210, 180].includes(priceRange?.bucket_size)) {
            matchingBucket = priceRange.bucket_id;
          }
        });
    
        if (matchingBucket) {
          const existingBucketIndex = itemsToUse.findIndex(
            (item) => [20, 18].includes(item?.bucketSize) && item.productId === productIdtoCart
          );
          
          if (existingBucketIndex !== -1) {
            itemsToUse.splice(existingBucketIndex, 1);
            console.log("Removed existing bucket for the same product ID.");
          }
          


          itemsToUse.push({
            productId: productIdtoCart,
            productName: productName,
            productBrand: productbrand,
            productImage: productimage,
            salePrice: salesPrice,
            quantity: selectedQuantityToUse ,
            bucketId: matchingBucket,
            bucketSize: bucketSize,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceoffff,
            endQuantity: endQuantity,
            priceList: priceList,
          });
          console.log("Added or replaced with barrel item in cart.");
        } else {
          console.log("No matching bucket found for barrel.");
        }
      }
     
      // Saving the updated cart items
      localStorage.setItem("cartItems", JSON.stringify(itemsToUse));
      console.log("Updated cart items:", itemsToUse);
    }
    
    

    setLoading(true);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  /***********************************************************************Buy Now****************************************************************************/
  const handleBuyNow = async () => {
    // Log the details of the clicked product

    const productIdtoCart = productDETAILS?.[infokey]?.id;
    const productName = productDETAILS?.[infokey]?.name;
    const productbrand = productDETAILS?.[infokey]?.brand || "N/A";
    const productimage =
      productDETAILS[infokey]?.images?.productImages[infokey]?.url || "N/A";
    const salesPrice = productDETAILS?.[infokey]?.saleprice;

    const bucketId = getBucketId(selectedQuantity);
    console.log("BucketId : ", bucketId);

    const quantityprice = quantity;
    const quantitypricevc = selectedQuantity;

    const loggedin = !!localStorage.getItem("userToken");

    const queryID = localStorage.getItem("queryID");
    const uuidUserTokessn = localStorage.getItem("uuidUserToken");

    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";
    const timestampe = Date.now();
    const positionfavd = localStorage.getItem("Positionforalgolia");
    // console.log("objectId in SparesRevised  : ", productIdtoCart);
    // console.log("Query Id in objectId in SparesRevised  : ", queryID);
    // console.log("timestamp in objectId in SparesRevised  :", timestampe);
    // console.log("UserToken in objectId in SparesRevised  : ", uuidUserTokessn);
    // console.log("Position in objectId in SparesRevised  : ", positionfavd);

    aa(
      "clickedObjectIDs",
      {
        eventType: "click",
        eventName: "Product Clicked",
        index: spareIndex,
        objectIDs: [productIdtoCart],
        userToken: uuidUserTokessn,
        queryID: queryID,
        position: [positionfavd],
        timestamp: timestampe,
      },
      {
        headers: {
          "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
          "X-Algolia-API-Key": ALGOLIA_API_KEYs,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("Conversion event sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending conversion event:", error);
      });

    if (loggedin) {
      const userToken = localStorage.getItem("userToken");
      const ompuserId = localStorage.getItem("id");
      const customer_first_name = localStorage.getItem("firstName");
      const customer_last_name = localStorage.getItem("lastName");
      const customer_contact_no = localStorage.getItem("number");
      const customer_email = localStorage.getItem("emailId");

      const itemList = [
        {
          item_id: productIdtoCart,
          quantity: quantitypricevc,
          bucket_id: bucketId.bucketId,
        },
      ];

      console.log("Item list for API:", itemList);

      try {
        const response = await fetch(
          "https://ompextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: itemList,
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Buy",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "IN",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "IN",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          }
        );
        console.log("Response status:", response.status);
        const responseData = await response.json();
        console.log("Response data:", responseData);

        const {
          order_id: orderId,
          total_quantity: totalQuantity,
          subtotal: subtotttal,
          tax_total: gst,
          discount_total: taxes,
          shipping_charge: shippingCharges,
          total,
          line_items: lineItems,
        } = responseData; // Destructure the needed fields

        console.log("Order ID:", orderId);
        console.log("Total Quantity:", totalQuantity);
        console.log("Subtotal:", subtotttal);
        console.log("GST:", gst);
        console.log("Taxes:", taxes);
        console.log("Shipping Charges:", shippingCharges);
        console.log("Total:", total);

        // Iterate through line_items to retrieve item details
        lineItems.forEach((item, index) => {
          console.log(`Item ${index + 1}:`);
          console.log("Name:", item.name);
          console.log("Thumbnail URL:", item.thumbnail[0]?.url);
        });

        // Navigate to the next page with renamed states

        setLoading(true);

        setTimeout(() => {
          console.log("Navigating to manage address page");

          // Log state values
          console.log("State values being passed:");
          console.log("itemId:", productIdtoCart);
          console.log("bucketId:", bucketId);
          console.log("quantity:", quantity);
          console.log("orderId:", orderId);
          console.log("quantityTotal:", totalQuantity);
          console.log("subtotalAmount:", subtotttal);
          console.log("gstAmount:", gst);
          console.log("taxesAmount:", taxes);
          console.log("shippingFee:", shippingCharges);
          console.log("totalAmount:", total);

          const itemDetails = lineItems.map((item) => ({
            name: item.name,
            thumbnail: item.thumbnail[0]?.url,
            item_id: item.item_id,
            quantity: item.quantity,
            amount: item.amount,
            rate: item.rate,
            bucket_id: item.bucket_id,
            total: item.total,
            subtotal: item.subtotal,
          }));

          console.log("Item Details:", itemDetails);

          // Set the order status to "buy" and save to localStorage
          const order_Status = "Buy";
          localStorage.setItem("order_Status", order_Status);
          console.log("Order Status set to:", order_Status);

          // Navigate with all required state
          navigate("/manageaddresspart", {
            state: {
              itemId: productIdtoCart,
              bucketId,
              quantity,
              orderId,
              quantityTotal: totalQuantity,
              subtotalAmount: subtotttal,
              gstAmount: gst,
              taxesAmount: taxes,
              shippingFee: shippingCharges,
              totalAmount: total,
              itemDetails, // Pass the item details array in the state
            },
          });

          window.location.reload();
        }, 400);
      } catch (error) {
        console.error("Error adding item to API cart:", error);
      }
    } else {
      setLoginPortal(true);
    }
  };

  const handleBuyNowNew = async (  
    productIdtoCart,
    quantity,
    selectedQuantity,
    bucketPrice,bucketSize) => {


      const { bucketId } = getBucketId(selectedQuantity, bucketSize);

      console.log("Selected Quantity : ",selectedQuantity)
      console.log("BucketId : ", bucketId);
      const quantitypricevc = selectedQuantity;

      const loggedin = !!localStorage.getItem("userToken");

      const queryID = localStorage.getItem("queryID");
      const uuidUserTokessn = localStorage.getItem("uuidUserToken");
  
      const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
      const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
      const spareIndex = "zohoinventory_index";
      const timestampe = Date.now();
      const positionfavd = localStorage.getItem("Positionforalgolia");
      // console.log("objectId in SparesRevised  : ", productIdtoCart);
      // console.log("Query Id in objectId in SparesRevised  : ", queryID);
      // console.log("timestamp in objectId in SparesRevised  :", timestampe);
      // console.log("UserToken in objectId in SparesRevised  : ", uuidUserTokessn);
      // console.log("Position in objectId in SparesRevised  : ", positionfavd);
  
      aa(
        "clickedObjectIDs",
        {
          eventType: "click",
          eventName: "Product Clicked",
          index: spareIndex,
          objectIDs: [productIdtoCart],
          userToken: uuidUserTokessn,
          queryID: queryID,
          position: [positionfavd],
          timestamp: timestampe,
        },
        {
          headers: {
            "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
            "X-Algolia-API-Key": ALGOLIA_API_KEYs,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log("Conversion event sent successfully:", response);
        })
        .catch((error) => {
          console.error("Error sending conversion event:", error);
        });
  

        if (!loggedin) {
          console.log("User not logged in, saving pending action to localStorage");
          localStorage.setItem(
            "pendingBuyNow",
            JSON.stringify({
              productIdtoCart,
              quantitypricevc,
              bucketId,
            })
          );
          setLoginPortal(true);
          return;
        }
        console.log("Proceeding with Buy Now");
    console.log("Now we go to Address Page : ");
    await proceedWithBuyNow(productIdtoCart, quantitypricevc, bucketId);

  }


  const proceedWithBuyNow = async (productIdtoCart, quantitypricevc, bucketId) => {
    console.log("Inside proceedWithBuyNow in SpareHits.jsx");
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Product ID:",
      productIdtoCart
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Quantity:",
      quantity
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Bucket ID:",
      bucketId
    );

    const userToken = localStorage.getItem("userToken");
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");

    const itemList = [
      {
        item_id: productIdtoCart,
        quantity: quantitypricevc,
        bucket_id: bucketId,
      },
    ];

    console.log("Item list for API:", itemList);

    console.log(
      "Now calling the api : with orderstatus as buy and hitting the create order api"
    );
    try {
      const response = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: itemList,
            ompuserId,
            customer_first_name,
            customer_last_name,
            customer_contact_no,
            order_status: "Buy",
            order_ref_number: "",
            customer_email,
            input: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            businessaddress: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            gst_no: "",
          }),
        }
      );

      console.log("Response status:", response.status);
      const responseData = await response.json();
      console.log("Response data:", responseData);

      console.log(
        "now in the above i have sucessfully sent the request with order_status as buy"
      );
      console.log(
        "now get the orderId  etc from the response and send it to the api  "
      );
      const {
        order_id: orderId,
        total_quantity: totalQuantity,
        subtotal: subtotttal,
        tax_total: gst,
        discount_total: taxes,
        shipping_charge: shippingCharges,
        line_items: lineItems,
        total,
      } = responseData;

      console.log("Order ID:", orderId);
      console.log("Total Quantity:", totalQuantity);
      console.log("Subtotal:", subtotttal);
      console.log("GST Amount:", gst);
      console.log("Discount Total:", taxes);
      console.log("Shipping Charges:", shippingCharges);
      console.log("Total Amount:", total);

      // Iterate through line_items to retrieve item details
      lineItems.forEach((item, index) => {
        console.log(`Item ${index + 1}:`);
        console.log("Name:", item.name);
        console.log("Thumbnail URL:", item.thumbnail[0]?.url);
      });

      setLoading(true);

      setTimeout(() => {
        console.log("Navigating to manage address page");

        // Log state values
        console.log("State values being passed:");
        console.log("itemId:", productId);
        console.log("bucketId:", bucketId);
        console.log("quantity:", quantity);
        console.log("orderId:", orderId);
        console.log("quantityTotal:", totalQuantity);
        console.log("subtotalAmount:", subtotttal);
        console.log("gstAmount:", gst);
        console.log("taxesAmount:", taxes);
        console.log("shippingFee:", shippingCharges);
        console.log("totalAmount:", total);

        const itemDetails = lineItems.map((item) => ({
          name: item.name,
          thumbnail: item.thumbnail[0]?.url,
          item_id: item.item_id,
          quantity: item.quantity,
          amount: item.amount,
          rate: item.rate,
          bucket_id: item.bucket_id,
          total: item.total,
          subtotal: item.subtotal,
        }));

        console.log("Item Details:", itemDetails);

        // Set the order status to "buy" and save to localStorage
        const order_Status = "Buy";
        localStorage.setItem("order_Status", order_Status);
        console.log("Order Status set to:", order_Status);

        // Navigate with all required state
        navigate("/manageaddresspart", {
          state: {
            itemId: productId,
            bucketId,
            quantity,
            orderId,
            quantityTotal: totalQuantity,
            subtotalAmount: subtotttal,
            gstAmount: gst,
            taxesAmount: taxes,
            shippingFee: shippingCharges,
            totalAmount: total,
            itemDetails, // Pass the item details array in the state
          },
        });

        window.location.reload();
      }, 400);
    } catch (error) {
      console.error("Error adding item to API cart:", error);
    }
  };

  

   useEffect(() => {
      const pendingBuyNow = localStorage.getItem("pendingBuyNow");
      // console.log("useEffect - pendingBuyNow:", pendingBuyNow);
  
      if (pendingBuyNow) {
        const { productIdtoCart, quantitypricevc, bucketId } = JSON.parse(pendingBuyNow);
        console.log("useEffect - Proceeding with pending Buy Now:", {
          productIdtoCart,
          quantitypricevc,
          bucketId,
        });
  
        proceedWithBuyNow(productIdtoCart, quantitypricevc, bucketId);
  
        localStorage.removeItem("pendingBuyNow");
        console.log("useEffect - Cleared pendingBuyNow from localStorage");
      }
    }, [loggedin]);

  /************************************************************************ Request a Quotation *****************************************************************/
  const handleRaiseAQuotation = () => {
    const loggedin = localStorage.getItem("userToken");
    // console.log("Inside HandleRaiseQuotation : ", loggedin);

    const productIdtoCart = productDETAILS?.[infokey]?.id;
    // console.log(
    //   "Inside HandleRaiseQuotation productIdtoCart : ",
    //   productIdtoCart
    // );

    const productName = productDETAILS?.[infokey]?.name;
    // console.log("Inside HandleRaiseQuotation productName : ", productName);

    const productPrice = productDETAILS?.[infokey]?.price;
    // console.log("Inside HandleRaiseQuotation productPrice : ", productPrice);

    const productImage =
      productDETAILS?.[infokey]?.images?.productImages[0]?.url || "N/A";

    // console.log(" Inside HandleRaiseQuotation  Product Image : ", productImage);

    const priceList = productDETAILS?.[infokey]?.price_list || [];
    // console.log("Price list: ", priceList);

 
    let bucketId = null;
    let bucketSize = null;
    let firstPrice = null;

    if (priceList.length > 0) {
      const firstPriceInfo = priceList[0];
    
      bucketId = firstPriceInfo.bucket_id;
      bucketSize = firstPriceInfo?.bucket_size;
      firstPrice = firstPriceInfo.price;

    }

    if (loggedin) {
      setShowPopup(true); 
      setProductDETAILS({
        id: productDETAILS?.[infokey]?.id,
        name: productName,
        price: firstPrice,
        image: productImage,
        priceList:priceList,
        
      });
    } else {
      setLoginPortal(true);
    }
  };

  /************************************************************* When Clicked Submit in QuotationPopup *********************************************************/
  const handleSubmit = async ({ productId, quantity, bucketId ,bucketSizeddd}) => {
    console.log("Product Id:", productId);
    console.log("Quantity:", quantity);
    console.log("Bucket ID:", bucketId);
    console.log("Bucket size : ",bucketSizeddd)


    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");

    const requestBody = {
      items: [
        {
          item_id: productId,
          quantity: parseInt(quantity), 
          bucket_id: bucketId, 
        },
      ],
      ompuserId,
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: "Draft",
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "",
    };

    console.log("Request Body:", requestBody);

    try {
      const response = await fetch(
        "https://ompextension.origa.market/api/request_quotation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.message === "Quotation request saved successfully!") {
        setShowPopup(false);
        setShowThanksPopup(true);
        setTimeout(() => {
          setShowThanksPopup(false);
          navigate("/store");
          window.location.reload();
        }, 4000);
      } else {
        alert("Failed to raise a quotation.");
      }
    } catch (error) {
      console.error("Error submitting quotation:", error);
      alert("Error submitting quotation.");
    }
  };

  /***********************************************************To show the total price , gst, total with tax*********************************************************/
  const calculateTotalValue = () => {
    if (isBarrel) {
      // Calculate for barrel price when isBarrel is true
      const totalValueforBarrel = barrelQuantity * bucketSize * bucketPrice;
      // console.log("totalValueforBarrel : ",totalValueforBarrel)
      return barrelQuantity * bucketSize * bucketPrice; // Use barrelQuantity for the calculation
    } else {
      const totalValueforBucket = bucketQuantity * bucketSize * bucketPrice;
      //  console.log("totalValueforBucket : ",totalValueforBucket)
      // Calculate for bucket price when isBarrel is false
      return bucketQuantity * bucketSize * bucketPrice;
    }
  };
  const totalvalue = calculateTotalValue();

  const calculateGST = (amount) => {
    const calculatedgst = amount * 0.18;
    // console.log("Amount for gst : ",amount )
    // console.log("calculatedgst : ",calculatedgst)
    return amount * 0.18;
  };

  const calculateTotalWithTax = (amount) => {
    return amount + calculateGST(amount);
  };

  const [mrpSize, setmrpSize] = useState(null);
  const [bucketlabel, setBucketLabel] = useState(null); 

  const [selectedMinPrice, setSelectedMinPrice] = useState(null); 
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(null); 
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);

  //Choosing the bucket
  const handlePriceClick = (priceItem, index) => {
    setClickedPriceItem(index); // Set the clicked price item
    setBucketPrice(priceItem.price);
    setBucketSize(priceItem?.bucket_size);
    console.log("priceItem.min_price : ",priceItem.min_price);
    console.log("priceItem.max_price : ",priceItem.max_price);
    setSelectedMinPrice(priceItem.min_price); 
    setSelectedMaxPrice(priceItem?.max_price);

  
    const isPriceRange = priceItem?.min_price !== priceItem?.max_price;
    setSelectedPriceRange(isPriceRange);
  
    // console.log("Bucket Type : ", priceItem.bucket_size);
    // console.log("Bucket Type : ",bucketSize);
    setmrpSize(priceItem.mrp);

    // const isBarrel = priceItem?.bucket_size === 210 or 180;
    const isBarrel = [210,180].includes(priceItem?.bucket_size );
    setIsBarrel(isBarrel);

    if (isBarrel) {
      setBarrelQuantity(1); // Initialize barrel quantity to 1
      setTotalPrice(priceItem.price * priceItem?.bucket_size);
    } else {
      const newQuantity = priceItem.start_quantity;
      setBucketQuantity(newQuantity);
      setSelectedQuantity(newQuantity);

      const newTotalPrice =
        newQuantity * priceItem.price * (priceItem?.bucket_size || 1);
      setTotalPrice(newTotalPrice);
    }

    setDisplayText(
      priceItem.bucket_size ? `Bucket: ${priceItem?.bucket_size}L` : ""
    );
    setEndQuantity(priceItem.end_quantity);

    // Log the details including the bucket_id for the selected priceItem
    console.log(
      `%cDetails:
      %c- Index:%c ${index} 
      %c- Selected Bucket ID:%c ${priceItem.bucket_id} 
      %c- Bucket Price:%c ₹${priceItem.price} 
      %c- Bucket Size:%c ${priceItem?.bucket_size}L 
      %c- MRP:%c ₹${priceItem.mrp}`,
      "color: #3498db; font-weight: bold;", // Header style
      "color: #2ecc71; font-weight: bold;", // Label style for Index
      "color: #27ae60;", // Value style for Index
      "color: #f39c12; font-weight: bold;", // Label style for Bucket ID
      "color: #e74c3c;", // Value style for Bucket ID
      "color: #f39c12; font-weight: bold;", // Label style for Bucket Price
      "color: #d35400;", // Value style for Bucket Price
      "color: #9b59b6; font-weight: bold;", // Label style for Bucket Size
      "color: #8e44ad;", // Value style for Bucket Size
      "color: #e74c3c; font-weight: bold;", // Label style for MRP
      "color: #c0392b;" // Value style for MRP
    );
  };


  const renderPriceDisplay = () => {
    if (selectedMinPrice && selectedMaxPrice) {
        return selectedMinPrice !== selectedMaxPrice
            ? `₹${selectedMinPrice} - ₹${selectedMaxPrice}/Ltr`
            : `₹${selectedMinPrice}/Ltr`;
    }
    return null;
};

const priceText = renderPriceDisplay();


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // useEffect to track the change in bucketSize
  useEffect(() => {
    console.log("Updated Bucket Size : ", bucketSize); // This will log the updated bucket size after the state change
  }, [bucketSize]); // The effect runs every time bucketSize is updated
  //Quantity
  const hasMultipleQuantities = selectedProduct?.price_list?.length > 1;

  //Showing the bucket range :
  const renderPriceList = () => {
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      // Sort the price list by bucket_size
      const sortedPriceList = [...selectedProduct.price_list].sort(
        (a, b) => a?.bucket_size - b?.bucket_size
      );

      return (
        <div className="buttons-in-addmore responsive-buttons-container">
          {sortedPriceList.length > 0 ? (
            sortedPriceList.map((priceItem, index) => (
              <button
                key={index}
                className={`buttonclickednodvjbktor responsive-button ${
                  clickedPriceItem === index ? "btn-clicked" : ""
                }`}
                onClick={() => handlePriceClick(priceItem, index)}
              >
                <span className="priceItemLabel">{priceItem.price_label}</span>
                <br />

                {priceItem.price && (<>
                  <span
                  className="offerrrr"
                  style={{
                    color: clickedPriceItem === index ? "#58cb58" : "#58cb58",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {Math.ceil(
                    ((priceItem.mrp - priceItem.price) / priceItem.mrp) * 100
                  )}
                  % off
                </span> <br /></>)}
               
               
                {priceItem.price  && (<> ₹{priceItem.price}/{productDETAILS?.[infokey]?.unit.charAt(0).toUpperCase() + productDETAILS?.[infokey]?.unit.slice(1)} </>)}
                {/* {priceItem?.min_price && (<>₹{priceItem.min_price}/Ltr </>)} */}

                {/*If the min price and max price are same  , it will show a single price 
                   If the min price and max price are different , then it shows a price range  */}
                {priceItem?.min_price && priceItem?.max_price && (
                  <>
                  ₹{priceItem.min_price}{/*Always show the min price first*/}
                  {priceItem.min_price !== priceItem.max_price ? `-₹${priceItem.max_price}`:""}/{productDETAILS?.[infokey]?.unit.charAt(0).toUpperCase() + productDETAILS?.[infokey]?.unit.slice(1)}
                  {/*If min price is not equal to max price then show -  ₹max price 
                     If they are same , it adds nothing just show min_price*/}
                  </>
              )}
                <br />
                <span
                  className={`text-xs transition-colors duration-200 ${
                    clickedPriceItem === index || hoveredIndex === index
                      ? "text-white"
                      : "text-gray-500"
                  }`}
                >
                 {priceItem.mrp && (<>   <s>₹{priceItem.mrp}/{productDETAILS?.[infokey]?.unit.charAt(0).toUpperCase() + productDETAILS?.[infokey]?.unit.slice(1)}</s></>)}
                </span>
              </button>
            ))
          ) : (
            <p>No price list available</p>
          )}
        </div>
      );
    }
    return <p>Loading...</p>;
  };

  //When page reloads the bucket range will be set to default 0th index
  useEffect(() => {
    // This will run every time productDETAILS or infokey changes
    if (productDETAILS && infokey !== undefined) {
      const product = productDETAILS[infokey];
      setSelectedProduct(product);

      if (
        product &&
        Array.isArray(product.price_list) &&
        product.price_list.length > 0
      ) {
        setClickedPriceItem(0); 
        setSelectedMinPrice(product?.price_list[0]?.min_price)
        setSelectedMaxPrice(product?.price_list[0]?.max_price);
        setBucketPrice(product.price_list[0]?.price); 
        setBucketSize(product.price_list[0]?.bucket_size);
        setBucketLabel(product.price_list[0]?.price_label);
        setBucketQuantity(1);
        setmrpSize(product.price_list[0]?.mrp);
      } else {
        // Reset if no valid price
        setClickedPriceItem(null);
        setBucketPrice(null);
        setBucketSize(null);
        setBucketLabel(null);
        setmrpSize(null);
      }
    }
  }, [productDETAILS, infokey]);

  //When i change a  quantity the related data should be set
  useEffect(() => {
    // Runs every time selectedQuantity or selectedProduct changes
    if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
      const applicablePriceItem = selectedProduct.price_list.find(
        (item) =>
          selectedQuantity >= item.start_quantity &&
          (item.end_quantity === null || selectedQuantity <= item.end_quantity)
      );

      if (applicablePriceItem) {
        setBucketPrice(applicablePriceItem?.price);
        setBucketSize(applicablePriceItem?.bucket_size);
        setEndQuantity(applicablePriceItem?.end_quantity);
        setClickedPriceItem(
          selectedProduct.price_list.indexOf(applicablePriceItem)
        );
        setmrpSize(applicablePriceItem?.mrp);
      }
    }
  }, [selectedQuantity, selectedProduct]);

  useEffect(() => {
    if (selectedProduct && selectedProduct?.price_list) {
      const defaultPriceItem = selectedProduct?.price_list[0];
      // const isBarrel = defaultPriceItem?.bucket_size === 210 or 180;
      const isBarrel = [210, 180].includes(defaultPriceItem?.bucket_size);


      setBucketQuantity(isBarrel ? 1 : defaultPriceItem?.start_quantity);
      setBarrelQuantity(1);
      setSelectedQuantity(isBarrel ? 1 : defaultPriceItem?.start_quantity);
      setIsBarrel(isBarrel);

      // Set the total price based on the default price item
      const newTotalPrice = isBarrel
        ? defaultPriceItem?.price * defaultPriceItem?.bucket_size
        : defaultPriceItem?.start_quantity *
          defaultPriceItem?.price *
          (defaultPriceItem?.bucket_size || 1);
      setTotalPrice(newTotalPrice);
    }
  }, [selectedProduct]); // Trigger when selectedProduct changes

  const handleQuantityChange = (increment) => {
    console.log("Handle quantity change triggered");
    console.log("Bucket Quantity", bucketQuantity);
    console.log("Barrel Quantity", barrelQuantity);

    if (isBarrel) {
      // Check if it is barrel mode
      console.log("In barrel mode : ", isBarrel);

      setBarrelQuantity((prev) => {
        const newQuantity = Math.min(Math.max(prev + increment, 1));
        console.log(`Barrel quantity updated from ${prev} to ${newQuantity}`);

        // Calculate total price for barrel
        // const barrelPriceItem = selectedProduct.price_list.find(
        //   (item) => item.bucket_size === 210 or 180
        // );

        const barrelPriceItem = selectedProduct.price_list.find(
          (item) => [210, 180].includes(item?.bucket_size)
        );
        

        if (barrelPriceItem) {
          const totalPrice = barrelPriceItem.price * newQuantity;
          console.log(`Total price for barrel calculated as: ${totalPrice}`);
          setTotalPrice(totalPrice);
        }

        return newQuantity;
      });
    } else {
      // If it is bucket mode
      console.log("In bucket mode : ", isBarrel);
      setBucketQuantity((prevQuantity) => {
        let newQuantity = Math.max(prevQuantity + increment, 1);

        console.log(
          `Bucket quantity updated from ${prevQuantity} to ${newQuantity}`
        );

        if (newQuantity === 11) {
          console.log("Switching to barrel mode");
          setIsBarrel(true);
          setBucketQuantity(1); // Reset bucketQuantity when switching
          setBarrelQuantity(1); // Initialize barrelQuantity at 1
        }

        setSelectedQuantity(newQuantity);
        console.log(`Selected quantity is now: ${newQuantity}`);

        const priceItem = selectedProduct.price_list.find(
          (item) =>
            newQuantity >= item.start_quantity &&
            (item.end_quantity === null || newQuantity <= item.end_quantity)
        );

        if (priceItem) {
          setBucketPrice(priceItem.price);
          const newTotalPrice =
            newQuantity * priceItem.price * (priceItem.bucket_size || 1);
          setTotalPrice(newTotalPrice);
        }

        return newQuantity;
      });
    }
  };

  /************************************************************************Type : 4000 or 8000 *****************************************************************/
  const productDetailsArray = Array.isArray(productDETAILS)
    ? productDETAILS
    : [productDETAILS];
  const renderAttributes = () => {
    // console.log("**************************RenderAttributes - Function : ********************** ");
    const attribute1 = productDETAILS?.[infokey]?.attributes?.attribute1;
    const attribute2 = productDETAILS?.[infokey]?.attributes?.attribute2;
    // console.log("Attribute 1 :  ".attribute1);
    // console.log("Attribute 2 :  ".attribute2);
    // console.log("Attribute 1 :  ".attribute1?.name);
    // console.log("Attribute 2 :  ".attribute2?.name);
    if (!attribute1 && !attribute2) {
      return null;
    }

    return (
      <div className="box-item">
        <div className="typeheadingfortools heading-600-18">
          {attribute1?.name || ""}
          {attribute2?.name ? `& ${attribute2.name}` : ""}
        </div>
        <div className="heading-400-14-12 light-txt rszbfdntzdg mt-2">
          {renderButtons()}{" "}
        </div>
      </div>
    );
  };
  const renderButtons = () => {
    // Get the array of product details
    const productArray = Object.entries(productDETAILS)
      .filter(([key]) => key !== "price_details")
      .map(([_, value]) => value);

    if (productArray.length === 0) {
      return <p>No product variants available.</p>;
    }

    // Sort the productArray by attribute1.options.name (assuming it contains numbers in string format)
    const sortedProductArray = productArray.sort((a, b) => {
      const nameA = parseInt(a.attributes?.attribute1?.options?.name, 10);
      const nameB = parseInt(b.attributes?.attribute1?.options?.name, 10);
      return nameA - nameB;
    });

    return sortedProductArray.map((product) => {
      const attribute1 = product.attributes?.attribute1;
      const attribute2 = product.attributes?.attribute2;

      return (
        <button
          key={product.id}
          className={`buttonclickednodvjbktor ${
            product.id === selectedItemId ? "btn-clicked" : ""
          }`}
          onClick={() => handlesizeandcolour(product.id)} // Pass `product.id` instead of `index`
        >
          {attribute1 && `${attribute1.options?.name}`}
          {attribute1 && attribute2 && <br />}
          {attribute2 && `${attribute2.options?.name}`}
        </button>
      );
    });
  };

  const handlesizeandcolour = (id) => {
    localStorage.setItem("ObjectIdToolforSimilar", id);

    console.log(
      "Now handleTypeChange function is called ,so that type is changed  "
    );

    const productArray = Object.values(productDETAILS).filter(
      (item) => typeof item === "object" && item.id
    );

    // Find the product with the matching id
    const selectedProduct = productArray.find((product) => product.id === id);

    // Get the index of the selected product in the unsorted `productArray` if you need it
    const selectedIndex = productArray.findIndex(
      (product) => product.id === id
    );

    setinfokey(selectedIndex); // Update based on the found index
    setQuantity(1);
    setSelectedQuantity(1);
    setSelectedItemId(selectedProduct.id);
    setSelectedProduct(selectedProduct);
    setBarrelQuantity(1);
  };
  //changing the type like  - 4000 to 8000
  const handleTypeChange = (type) => {
    console.log(
      "Now handleTypeChange function is called ,so that type is changed  "
    );
    const product = productDETAILS.find(
      (p) => p.attributes.attribute1.options.name === type
    );

    setSelectedProduct(product);
    setSelectedType(type);
    // Update pricing based on the selected variant
    const priceItem =
      product.price_list[clickedPriceItem] || product.price_list[0];
    setBucketPrice(priceItem.price);
    setBucketSize(priceItem.bucket_size);

    // const isBarrel = priceItem.bucket_size === 210 or 180;
    const isBarrel = [210, 180].includes(priceItem?.bucket_size);
    setIsBarrel(isBarrel);
    setSelectedQuantity(isBarrel ? 1 : priceItem.start_quantity);

    // Reset quantities to 1 when switching type
    setBucketQuantity(1);
    setBarrelQuantity(1);

    setTotalPrice(
      isBarrel
        ? priceItem.price * priceItem.bucket_size
        : priceItem.start_quantity *
            priceItem.price *
            (priceItem.bucket_size || 1)
    );
    setDisplayText(
      priceItem.bucket_size ? `Bucket: ${priceItem.bucket_size}L` : ""
    );
  };

  const onShowPortal = async () => {
    const loggedin = !!localStorage.getItem("userToken");
    if (loggedin) {
      //If loggedin is true
      const firstname = localStorage.getItem("firstName");
      const lastName = localStorage.getItem("lastName");
      const email = localStorage.getItem("emailId");
      const phonenumber = localStorage.getItem("number");
      try {
        setLoading(true);
        const { data } = await client.mutate({
          //it sends a message to the people who made the app
          mutation: CREATE_CONTACT_US,
          variables: {
            contactusinput: {
              //these are the details that are sent with the message
              firstname: firstname,
              lastname: lastName,
              emailid: email,
              reasonid: 5,
              queryDescription: `Product:${productDETAILS?.name}, ProductId:${productDETAILS?.id}`,
              phonenumber: phonenumber,
            },
          },
        });
        console.log("API Response==>", data);
        setLoading(false);
        // SetQuationModel(true);
      } catch (error) {
        setLoading(false);
        console.error("API Error==>", error.message);
      }
    } else {
      // setLoginPortal(true);
    }
  };

  if (loading) return <Loader />;

  const hasMultipleVariants = productDETAILS.length > 1;

  /****************************************************************************Image Gallery For Column 1******************************************************/
  const images =
    productDETAILS?.[infokey]?.images?.productImages.map((image) => ({
      original: image.url,
      thumbnail: image.url,
    })) || [];

  /***************************************************************************ORIGA Benefits********************************************************************/

  const product = [
    {
      is_header: true,
      product_name: productDETAILS?.machine_details?.data?.product?.name,
      shortlist: 200,
    },
    {
      is_product_images: true,
      images: productDETAILS?.machine_details?.data?.product?.medias,
    },
    {
      is_product_info: true,
      tabs_section: [{ is_tabs_section: true }],
      product_detail: [
        {
          is_product_detail: true,
          year_purchase: 2019,
          machine_location: "Andheri Mumbai",
        },
      ],
      price_section: [
        {
          is_price_section: false,
          heading: "Looking to purchase products in bulk?",
          desc: "Purchase item in bulk quantity",
          desc1: "Get at best price for your business",
          btn_text: "Get a Quote",
        },
      ],

      token_section: [
        {
          is_token_section: true,
          heading: `ORIGA Benefits`,
          desc: "Competitive Prices.",
          desc1: "Response within 24 hrs.",
          desc2: "GST Invoice Available",
          btn_text: "Pay Token",
          extre_info:
            "If you are interested to purchase the machine and wish to block it so that no one else can purchase it you would need to pay a non refundable token. This will block the machine for 7 days, in this period you would need to either complete or finance the rest of the payment.",
        },
      ],
    },

    {
      is_benefits: false,
      head: [
        {
          heading: "Product Benefits",
          desc: "From Machines to tools to finance everything you need in one place",
          btn_text: "View Detailed Report",
        },
      ],
      benefits_items: [
        {
          id: 1,
          ratio: "04/05",
          label: "Good",
          color: "#D6F518",
          percent: 58,
          title: "External Visual Condition",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 2,
          ratio: "05/05",
          label: "Great",
          color: "#077D55",
          percent: 100,
          title: "Static Geometric Test",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 3,
          ratio: "02/05",
          label: "Workable",
          color: "#E86427",
          percent: 28,
          title: "Electric & Pneumatic Condition",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
        {
          id: 4,
          ratio: "03/05",
          label: "Fair",
          color: "#F6CF41",
          percent: 42,
          title: "Machine Usage History",
          extre_info:
            "The external Machine Condition is rated as 4/5 which means there are barely a few dents and scratches.",
        },
      ],
    },
    {
      is_benefits_layout_2: true,
      head: [
        {
          heading: "Product Benefits",
          desc: "From Machines to tools to finance everything you need in one place",
          btn_text: "View Detailed Report",
        },
      ],
      benefits_layout_2_items: [
        {
          title: "Visual Inspection Remarks",
          desc: "Table T slot broken Normal wear and tear due to ageing and uses",
        },
        {
          title: "Machine Dynamic Remarks",
          desc: "Origa will provide servicing for your machine so you don’t need to worry about anything...",
        },
        {
          title: "Machine Static Geometrical Test",
          desc: "Spindle Runout :- 3 micron TIR with needs dial gauge Axis Backlash :-X axis 10 microns,Y axis 5 microns, Z axis 5 microns",
        },
        {
          title: "Electrical & Pneumatic Condition",
          desc: "Origa can provide a lease and loan to enable you to purchase the machine you need",
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    { name: "Tools and Consumables", link: "/store" },
    { name: productName},

    // { name: "Spares", link: "/store" },
  ];
  const boldtitle = productDETAILS?.machine_details?.data?.product?.name;

  /********************************************************************************Helmet content*************************************************************** */
  const headingforseo = {
    heading: `   ${productDETAILS?.[infokey]?.brand}  ${productDETAILS?.[infokey]?.name}`,
  };

  
  /**************************************************************************Div Structure********************************************************************** */
  return (
    <>
      <Helmet>
        <title>{headingforseo.heading}</title>{" "}
        <meta name="keywords" content={headingforseo.heading} />
      </Helmet>

      {windowWidth < 718 && (
        <div className="breadcrumbsforsparesrevised" style={{fontSize:"12px",paddingTop:"10px"}}>
          <BreadCrumbsforSpareRevised
            items={breadcrumbsItems}
            boldtitle={`${productDETAILS?.machine_details?.data?.product?.name}`}
            backnavi={() => navigate("/store")}
          />
        </div>
      )}


     
      {/*Total Div dpr Product-Page */}
      <div className="product-page" style={{ position: "relative" }}>


        {/***********************First div***************************/}
        <div
          ref={productImagesRef}
          className={`column product-images ${getClassName()}`}
        >
  {windowWidth > 720 && (
        <div className="breadcrumbsforsparesrevised">
          <BreadCrumbsforSpareRevised
            items={breadcrumbsItems}
            boldtitle={`${productDETAILS?.machine_details?.data?.product?.name}`}
            backnavi={() => navigate("/store")}
          />
        </div>
      )}

          <ImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={true}
          />
        </div>

        {/************************Second div ************************/}
        <div className="column product-info" ref={productInfoRef}>
          <h1 className="brandandnameheadingtools heading-600-24-16">
            {productDETAILS?.[infokey]?.brand} -{" "}
            {productDETAILS?.[infokey]?.name}
          </h1>
          {/* <table>
            <tbody>
              <tr>
                <td>Feature 1</td>
                <td> {productDETAILS?.[infokey]?.name}</td>
              </tr>
              <tr>
                <td>Feature 2</td>
                <td>{productDETAILS?.[infokey]?.brand}</td>
              </tr>
            </tbody>
          </table> */}
          {showOffers && (
            <div className="offers">
              <h2>Available Offers </h2>
              <div className="offer">Offer 1</div>
              <div className="offer">Offer 2</div>
            </div>
          )}
          <div className="box-item quantity-box">{renderAttributes()}</div>
          {hasMultipleVariants && (
            <div className="box-item variants-box">
              <div className="heading-600-18 beforeeadinng">Variants</div>
              <div className="heading-400-14-12 light-txt">
                {productDETAILS.map((product, index) => (
                  <div className="buttonistypes">
                    <button
                      key={index}
                      className={`buttonclickednodvjbktor ${
                        selectedType ===
                        product.attributes.attribute1.options.name
                          ? "btn-clicked"
                          : ""
                      }`}
                      onClick={() =>
                        handleTypeChange(
                          product.attributes.attribute1.options.name
                        )
                      }
                    >
                      {product.attributes.attribute1.options.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {hasMultipleQuantities && (
            <div
              className="box-item quantity-box"
              style={{ marginTop: "10px" }}
            >
              <div className="heading-600-18 typeheadingfortools " style={{display:"flex",justifyContent:"space-between"}}>
              
                Add More & Pay Less{" "}  
                {selectedMinPrice && (<>
                <span style={{color:"gray"}}>(Enter picode for exact price) </span></>)}  
                {/* {localStorage.getItem("deliveryLocation") === true ? (<></>) : (<><p>Enter Pincode for exact price</p></>)} */}
             
             
             
              </div>
              <div className="heading-400-14-12 light-txt mt-3">
                {renderPriceList()}
                <span style={{display:"flex",fontStyle:"italic",justifyContent:"end",color:"gray",fontWeight:600,paddingTop:"8px"}}>Transport Charges extra *</span>
              </div>
            </div>
          )}
          {/* Basic Information Section */}
          <div className="basic-info  mt-3">
            <h6
              style={{ fontWeight: "600" }}
              className=" typeheadingfortools rsgfbdz heading-600-18"
            >
              Basic Information
            </h6>
            {/* {console.log("productDETAILS:", productDETAILS)} */}
            {/* Log the productDETAILS structure to the console */}
            {/* {console.log("productDETAILS:", productDETAILS)}
            {console.log(
              "Basic Information:",
              productDETAILS?.[infokey]?.productDetails?.["Basic Information"]
            )}
            {console.log("Group Name:", productDETAILS?.[infokey]?.group_name)} */}

            {/* Map over Basic Information fields */}
            <div className="specification-content mt-3">
              {Object.entries(
                productDETAILS?.[infokey]?.productDetails?.[
                  "Basic Information"
                ] || {}
              ).map(([key, value], index) => (
                <p className="info-row " key={index}>
                  <span className="headingblnan insideheadingfortools">
                    {key}
                  </span>
                  <span className="answerblan insideanswerfortools">
                    {value}
                  </span>
                </p>
              ))}
            </div>
          </div>

          {productDETAILS?.[infokey]?.productDetails?.Specification &&
            Object.keys(productDETAILS[infokey].productDetails.Specification)
              .length > 0 && (
              <div className="basic-info mt-3">
                <h6
                  className="heading-600-18 typeheadingfortools rsgfbdz"
                  style={{ fontWeight: "600" }}
                >
                  Specifications
                </h6>
                <div className="specification-content mt-3">
                  {Object.entries(
                    productDETAILS[infokey].productDetails.Specification
                  ).map(([key, value], index) => (
                    <p key={index} className="info-row mt-2">
                      <span className="headingblnan insideheadingfortools">
                        {key}
                      </span>
                      <span className="answerblan insideanswerfortools">
                        {value}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            )}

          {/*----------------------------------------------Delivery Details : shown only in Mobile ---------------------------------------------- */}
          <div className=" deliverydetailsdivv  basic-info  mt-3">
            <h6
              style={{ fontWeight: "600" }}
              className=" typeheadingfortools rsgfbdz heading-600-18"
            >
              Delivery Details
            </h6>

            <div
              className="input-container "
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={handlePincodeChange}
                onKeyPress={handleKeyPress}
                style={{
                  fontSize: "14px",
                  paddingRight: "90px", // Add padding to the right to make room for the button
                  width: "100%",
                  boxSizing: "border-box",
                }}
              />
              <span
                className="icon"
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {locationIcon({ width: 24, height: 24 })}
              </span>
              <button
                onClick={handleCheckDelivery}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "10px 10px",
                  backgroundColor: "#73509E",
                  color: "#fff",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "600",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {loading ? "Checking..." : "Check"}
              </button>
            </div>

            {showDeliveryMessage &&
              deliveryDetails.deliveryAvailable !== null &&
              (deliveryDetails.deliveryAvailable ? (
                <div>
                  <p
                    style={{
                      color: "#58cb58",
                      fontWeight: "700",
                      fontSize: "14px",
                      fontStyle:"italic"
                    }}
                  >
                    <span>
                      <FcPaid size={22} /> &nbsp;
                    </span>
                    Transport Charges extra *
                  </p>
                  <p>
                    <span>
                      <FcInTransit size={22} /> &nbsp;
                    </span>
                    <span style={{ fontSize: "14px" }}>
                      Delivered to {pincode} in 24-48 hrs
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    <span>
                      <FcOvertime size={22} /> &nbsp;
                    </span>
                    <span style={{ textAlign: "left" }}>
                      Delivery not available at {pincode}
                    </span>
                  </p>
                </div>
              ))}
          </div>
{/* 
          {advantagesEntries.length > 0 && (
            <div className="product-specifications mt-3 bg-white rounded-lg shadow-sm">
              <h6 className="heading-600-18 font-semibold text-gray-800 rsgfbdz typeheadingfortools">
                Advantages
            
              </h6>

          
              <div className="advantage-list list-decimal pl-5">
                {formattedAdvantages
                  .slice(0, showFullAdvantages ? undefined : 5)
                  .map((advantage, index) => (
                    <div key={index} className="adv-row text-gray-600">
                      <span className="adv-value insideanswerfortools mt-3">
                        {advantage.sections.map((section, idx) => (
                          <ul
                            key={idx}
                            className="advantage-description mt-2 list-disc pl-5"
                          >
                            <li>{section.trim()}</li>
                          </ul>
                        ))}
                      </span>
                    </div>
                  ))}
              </div>

           
              {totalSections > 8 && (
                <div>
                  <button className="show-more-btn" onClick={toggleAdvantages}>
                    {showFullAdvantages ? (
                      <>
                        Show Less &nbsp;
                        <FaAngleUp className="w-4 h-4 ml-1" />
                      </>
                    ) : (
                      <>
                        Show More &nbsp;
                        <FaAngleDown className="w-4 h-4 ml-1" />
                      </>
                    )}
                  </button>
                </div>
              )}
            </div>
          )} */}


{Advantages && <>
  <div className="product-specifications mt-3 bg-white rounded-lg shadow-sm">
              <h6 className="heading-600-18 font-semibold text-gray-800 rsgfbdz typeheadingfortools">
                Advantages
            
              </h6>
              <div className="advantage-list list-decimal ">
              {Advantages.split("\n")
  .filter((point) => point.trim()) // Remove empty lines
  .map((point, index) => {
    // Check if the point starts with a number and a closing parenthesis (e.g., 1) or 2))
    const isNumberedList = /^\d+\)/.test(point.trim());
    
    return (
      <div key={index} className="adv-row text-gray-600">
        <span className="adv-value insideanswerfortools mt-3">
          {/* If the point is numbered, display it as is */}
          {isNumberedList ? (
            <div className="advantage-description mt-2">
              <p>{point.trim()}</p>
            </div>
          ) : (
            // Otherwise, display as a bullet point list
            <ul className="advantage-description mt-2 list-disc pl-5">
              <li>{point.replace(/^●\s*/, "").trim()}</li>
            </ul>
          )}
        </span>
      </div>
    );
  })}



              </div>
              </div>

</>}


          {/* {descriptions && (
            <div className="product-specifications mt-3 bg-white rounded-lg shadow-sm">
              <h6 className="heading-600-18 font-semibold text-gray-800 m rsgfbdz typeheadingfortools">
                Description
              </h6>

              <ul className="desc-list list-decimal pl-5">
                {displayedDescription.map((section, index) => (
                  <li key={index} className="desc-row text-gray-600">
                    <span className="desc-value insideanswerfortools mt-3">
                      {expandedDescription || descriptionSections.length <= 1
                        ? section.trim()
                        : section.trim() + "..."}
                    </span>
                  </li>
                ))}

                {descriptionSections.length > 2 && (
                  <div>
                    <button
                      className="show-more-btn"
                      style={{ fontSize: "14px" }}
                      onClick={toggleDescription}
                    >
                      {showFullDescription ? (
                        <>
                          Show Less &nbsp;
                          <FaAngleUp className="w-4 h-4 ml-1" />
                        </>
                      ) : (
                        <>
                          Show More &nbsp;
                          <FaAngleDown className="w-4 h-4 ml-1" />
                        </>
                      )}
                    </button>
                  </div>
                )}
              </ul>
            </div>
          )} */}




          {Description && (
            <>
               <div className="product-specifications mt-3 bg-white rounded-lg shadow-sm">
              <h6 className="heading-600-18 font-semibold text-gray-800 m rsgfbdz typeheadingfortools">
                Description
              </h6>
              <ul className="desc-list list-decimal pl-5">
              {formatDescriptionToBulletPoints(Description).map((point, index) => (
               <li key={index} className="desc-row text-gray-600">
               <span  className="desc-value insideanswerfortools mt-3">{point}</span>
             </li>
           ))}
              </ul>
              </div>
            </>
          )}
{/* 
<div className="bullet-points-container">
    {formatDescriptionToBulletPoints(Description).map((point, index) => (
      <div key={index} className="bullet-point">
        <p>{point}</p>
      </div>
    ))}
  </div> */}
          <div></div>
          {product &&
            product.map(
              (prodcut, index) =>
                prodcut.token_section &&
                prodcut.token_section.map(
                  (token, tokenIndex) =>
                    token.is_token_section && (
                      <div
                        key={tokenIndex}
                        className="product-specifications  mt-3"
                      >
                        <div className="heading-600-18 insideheadingfortools rfsbbzd">
                          {token.heading}
                        </div>
                        <div className="total-origa-benefits mt-2">
                          <div className="pricessss heading-400-14-12 light-txt ">
                            {" "}
                            <PricesCompetitive />
                            <span className="insideanswerfortools">
                              {token.desc}
                            </span>
                          </div>
                          <div className="pricessss heading-400-14-12 light-txt">
                            <CallCenterIcon />
                            <span className="insideanswerfortools">
                              {token.desc1}
                            </span>
                          </div>
                          <div className="pricessss heading-400-14-12 light-txt">
                            <InvoiceIcon />{" "}
                            <span className="insideanswerfortools">
                              {token.desc2}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                )
            )}
        </div>

        {/************************PlacedHolder ************************/}
        <div ref={placeholderRef} style={{ height: "0px" }}></div>

        {/************************Third div ************************/}
        <div
          ref={productPricingRef}
          className={`column product-pricing ${getClassName()}`}
        >
          <div className="hiehgtoftextpart">
            {/* Price: ₹{bucketPrice} */}
            {/* {showPriceWithTax && (
              <p className="price-with-tax">
                ₹{calculateTotalWithTax(totalvalue).toFixed(2)} (Incl. of all
                taxes)
              </p>
            )} */}
            {!priceText && showPriceWithTax && (
  <p className="price-with-tax">
    ₹{calculateTotalWithTax(totalvalue).toFixed(2)} (Incl. of all taxes)
  </p>
)}

            {/* {showGST && (
              <p
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <span style={{ fontSize: "1.7rem", fontWeight: "600" }}>
                  ₹{totalvalue.toFixed(2)}
                </span>
                <span
                  className="price-with-tax"
                  style={{ marginLeft: "0.5rem" }}
                >
                  + ₹{calculateGST(totalvalue).toFixed(2)} GST
                </span>
              </p>
            )} */}

{/* 
<p>{renderPriceDisplay()}</p> */}

{priceText ? (
        // Case 1: Show only priceText section if priceText is present
        <>
          <p
            style={{
   alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span style={{ fontSize: "18px", fontWeight: "600" ,color:"gray",display:"block"}}>
              Enter pincode for exact price
            </span>
            <span style={{display:"block",marginTop:"15px",fontSize:"16px !important"}} className="price-with-tax">Price range - {priceText}</span>
          </p>
         
          
        </>
      ) : (
        // Case 2: Show GST price section if priceText is not present
        showGST && (
          <p
            style={{
              display: "inline-flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <span style={{ fontSize: "1.7rem", fontWeight: "600" }}>
              ₹{totalvalue.toFixed(2)}
            </span>
            <span className="price-with-tax" style={{ marginLeft: "0.5rem" }}>
              + ₹{calculateGST(totalvalue).toFixed(2)} GST
            </span>
          </p>
        )
      )}
            <div className=" d-flex ">
              
              {mrpSize && (<>
                <span className="price-with-tax">
              {mrpSize && (<>MRP ₹<s>{mrpSize}</s></>)} 
              {bucketPrice  && (<>  &nbsp;₹{bucketPrice}/{productDETAILS?.[infokey]?.unit.charAt(0).toUpperCase() + productDETAILS?.[infokey]?.unit.slice(1)}</>)}
              </span>
              </>)}

              {/* MRP : 
               <span className="old-price">₹{mrpSize}</span>
               <span className="per-ltr-price">₹{bucketPrice}/Ltr</span> */}
              {showDiscount && (
                <p>{selectedProduct?.discount_percentage}% OFF</p>
              )}
              <span className="offer-price-fgg">
               {selectedProduct?.price_list[0]?.price && (<>
                {calculateDiscount(
                  selectedProduct?.price_list[0]?.mrp,
                  selectedProduct?.price_list[0]?.price
                )}
                % off
               </>)}
              </span>
            </div>
            <div>
              <div className="buc0ket-ltrsss mt-2">
                {bucketSize && (
                  <span
                    style={{ color: "gray", fontWeight: "500" }}
                    className="update-qty"
                  >
                    {isBarrel ? "Barrel" : "Bucket"} -{" "}
                    <span
                      style={{ color: "black", fontWeight: "500" }}
                      className="s"
                    >
                      {bucketSize}{productDETAILS?.[infokey]?.unit.charAt(0).toUpperCase() + productDETAILS?.[infokey]?.unit.slice(1)}{" "}
                    </span>{" "}
                  </span>
                )}
              </div>
            </div>


            {/* {(isBarrel || barrelQuantity || bucketQuantity) && (
              <>
              </>
            )} */}


            {mrpSize && (<>
              <div className="quantity-for-tools">
              <div className="quantity-for-toolssfbfgn">
                {" "}
                <span className="update-qty"> Update Qty </span>
              </div>
              <div className="quantity-for-toolssfbfgn">
                {" "}
                <button
                  className="quantity-button-for-tools"
                  onClick={() => handleQuantityChange(-1)}
                  disabled={
                    isBarrel ? barrelQuantity <= 1 : bucketQuantity <= 1
                  }
                >
                  -
                </button>
                <span className="quanty-textt">
                  {isBarrel ? barrelQuantity : bucketQuantity}{" "}
                </span>
                <button
                  className="quantity-button-for-tools"
                  onClick={() => handleQuantityChange(1)}
                  // disabled={
                  //   isBarrel ? barrelQuantity >= 11 : bucketQuantity >= 11
                  // }
                >
                  +
                </button>
              </div>
            </div>
            </>)}
           

            {/* <p>Total Price: ₹{calculateTotalWithTax(totalvalue).toFixed(2)}</p> */}
          </div>
          <div className="d-flex gap-2">
            {storedLocation === null || deliveryAvailablefLocal ? (
              <>
                <button
                  type=""
                  onClick={() =>
                    handleCart(
                      productIdtoCart,
                      selectedQuantity,
                      bucketPrice,
                      barrelQuantity,
                      quantity,
                      bucketSize,
                      productName,
                      salePrice,
                      bucketPrice,
                      endQuantity
                    )
                  }
                  disabled={isDisabled}
                  className="fnvnvfnvnvnv"
                >
                  Add to Cart
                </button>
                <button
                  onClick={() =>
                    handleBuyNowNew(
                      productIdtoCart,
                      quantity,
                      selectedQuantity,
                      bucketPrice,
                      bucketSize,
                    )
                  }
                  type=""
                  className="fnvnvfnvnvnvfvsbfv"
                >
                  Buy Now
                </button>
              </>
            ) : null}
          </div>
          <div className="btns-group">
            {loading ? (
              <Loader />
            ) : deliveryAvailablefLocal ? (
              <button
                onClick={() => handleRaiseAQuotation(productIdtoCart, bucketSize,)}
                type="button"
                className="fnvnvfnvnvnv"
                style={{marginTop:"3%"}}
              >
                Raise for Quotation
              </button>
            ) : (
              // Show only the quotation button if delivery is unavailable
              <button
                onClick={() => handleRaiseAQuotation(productIdtoCart, bucketSize,)}
                type="button"
                className="fnvnvfnvnvnv"
                style={{marginTop:"3%"}}
              >
                Raise for Quotation
              </button>
            )}
          </div>
          <div className="delivery-details mt-2">
            <p style={{ fontSize: "16px", fontWeight: "600" }}>
              Delivery Details
            </p>
            <div className="input-container">
              <input
                type="text"
                placeholder="Enter Pincode"
                value={pincode}
                onChange={handlePincodeChange}
                onKeyPress={handleKeyPress}
                style={{ fontSize: "14px" }}
              />
              <span className="icon">
                {locationIcon({ width: 24, height: 24 })}
              </span>
            </div>

            <button className="box-item-btn mt-3" onClick={handleCheckDelivery}>
              {loading ? "Checking..." : "Check"}
            </button>

            {showDeliveryMessage &&
              deliveryDetails.deliveryAvailable !== null &&
              (deliveryDetails.deliveryAvailable ? (
                <div>
                  <p
                    style={{
                      color: "#58cb58",
                      fontWeight: "700",
                      fontSize: "14px",
                      fontStyle:"italic"
                    }}
                  >
                    <span>
                      <FcPaid size={22} /> &nbsp;
                    </span>
                    Transport Charges extra *
                  </p>
                  <p>
                    <span>
                      <FcInTransit size={22} /> &nbsp;
                    </span>
                    <span style={{ fontSize: "14px" }}>
                      Delivered to {pincode} in 24-48 hrs
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p
                    style={{
                      color: "#e74c3c",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    <span>
                      <FcOvertime size={22} /> &nbsp;
                    </span>
                    <span style={{ textAlign: "left" }}>
                      Delivery not available at {pincode}
                    </span>
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* Bottom Sticker for Mobile View */}
        <div className="bottom-sticker">
          <div className="dividing-two-divsleft-and-right">
            <div className="left-info">
              <div className="brand-name">
                {productDETAILS?.[infokey]?.brand}
              </div>
              <div className="product-name">
                {productDETAILS?.[infokey]?.name}
              </div>
            </div>
            <div className="right-info">
              <div className="price-and-pack">
                <span className="pack-info">
                  {bucketSize && (
                    <span
                      style={{ color: "gray", fontWeight: "500" }}
                      className="update-qty"
                    >
                      {isBarrel ? "Barrel" : "Bucket"} -{" "}
                      <span
                        style={{ color: "black", fontWeight: "500" }}
                        className="s"
                      >
                        {bucketSize}L{" "}
                      </span>{" "}
                    </span>
                  )}
                </span>
                <div className="pricelndjbgd">₹{totalvalue.toFixed(2)}</div>
                <div className="discount-price">
                  <span className="old-price">₹{mrpSize}</span>
                  <span className="per-ltr-price">₹{bucketPrice}/Ltr</span>
                  <span className="discount">10%off</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-row">
            <div className="quantity-controls">
              <button
                className="quantity-button-for-tools"
                onClick={() => handleQuantityChange(-1)}
                disabled={isBarrel ? barrelQuantity <= 1 : bucketQuantity <= 1}
              >
                -
              </button>
              <span className="quanty-textt">
                {isBarrel ? barrelQuantity : bucketQuantity}{" "}
              </span>
              <button
                className="quantity-button-for-tools"
                onClick={() => handleQuantityChange(1)}
                disabled={
                  isBarrel ? barrelQuantity >= 11 : bucketQuantity >= 11
                }
              >
                +
              </button>
            </div>

            <div className="btns-group">
              {deliveryAvailablefLocal ? (
                <>
                  <button
                    type=""
                    onClick={() =>
                      handleCart(
                        productIdtoCart,
                        selectedQuantity,
                        bucketPrice,
                        quantity,
                        bucketSize,
                        productName,
                        salePrice,
                        bucketPrice,
                        endQuantity
                      )
                    }
                    disabled={isDisabled}
                    className="threebuttonsinn"
                  >
                    Add to Cart
                  </button>
                  <button
                    onClick={() =>
                      handleBuyNowNew(
                        productIdtoCart,
                        quantity,
                        selectedQuantity,
                        bucketPrice
                      )
                    }
                    type=""
                    className="buy-nowsjdvsf"
                  >
                    Buy Now
                  </button>

                  <button
                    onClick={() => handleRaiseAQuotation(productIdtoCart)}
                    type="button"
                    className="threebuttonsinn"
                  >
                    Raise a quotation
                  </button>
                </>
              ) : (
                <button
                  onClick={() => handleRaiseAQuotation(productIdtoCart)}
                  type="button"
                  className="threebuttonsinn"
                >
                  Raise a quotation
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------Similar Products for Machines ------------------------------------ */}
      {/* <div className="total-product-page">
        <SimilarProductsforTools similarProducts={similarProductsItem} />

        <SimilarProductsforAlgolia
          setSimilarProductsItem={setSimilarProductsItem}
        />
      </div> */}

      {/*------------------------------------Similar Products for Tools ------------------------------------ */}
      <div className="total-product-page">
        {/* <LookingSimilarAlgolia  /> */}
        <AlgoliaTry />
      </div>

      {/*------------------------------------Similar Products for Tools ------------------------------------ */}

      <Footer />
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopup
                productDetails={productDETAILS}
                onSubmit={handleSubmit}
              />
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {showThanksPopup && (
        <div className="  popup-overlay ">
          <div className="popup-banner ">
            <div
              className="popup-content "
              style={{ backgroundColor: "#d2eff1" }}
            >
              <div
                className="differentthankspopupcontnet"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="thanksforyourresponse">
                  Thank you for your quotation request!
                </h3>
                <p className="wewillcontactu">We'll get back to you soon</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowThanksPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loginPortal && <LoginModel onHide={onHidePortal} />}
    </>
  );
};

export default ProductPage;
