import React, { useEffect, useState } from "react";
import "./QuotationPopupStylesForBuyNow.css";
import { closeIcon } from "../../../helpers/Icons";
import { useNavigate } from "react-router-dom";

const QuotationPopupForBuynow = ({
  quantityTotal,
  itemDetails,
  bucketId,
  itemId,
  quantity,
  itemName,
  price,
  thumbnailUrl,
  subtotal,
  total,
  totalAmount,
  totalQuantity,
  subtotalAmount,
}) => {
  if (itemDetails && Array.isArray(itemDetails)) {
    console.log("itemDetails array: ", itemDetails);
    itemDetails.forEach((item, index) => {
      console.log(`Item ${index + 1}:`);
      console.log("Name:", item.name);
      console.log("Thumbnail URL:", item.thumbnail);
      console.log("Price:", item.rate);
      console.log("Total:", item.amount);
      console.log("Bucket Id : ",item.bucket_id);
      console.log("Item Id : ",item.item_id);
      console.log("Quanity : ",item.quantity)
    });
  } else {
    console.log("itemDetails is not an array or is undefined");
  }
  

  const [orderStatus, setOrderStatus] = useState(null);

  useEffect(() => {
    const status = localStorage.getItem("order_Status");
    setOrderStatus(status);
  }, []);

  console.log("totalQuantity :", totalQuantity);

  // console.log("Clicked GetQuotation in SummaryCartPage - itemId : ", itemId);
  // console.log(
  //   "Clicked GetQuotation in SummaryCartPage - quantity : ",
  //   quantity
  // );
  // console.log(
  //   "Clicked GetQuotation in SummaryCartPage - itemName : ",
  //   itemName
  // );
  // console.log("Clicked GetQuotation in SummaryCartPage - price : ", price);
  // console.log("Clicked GetQuotation in SummaryCartPage - total : ", total);
  // console.log(
  //   "Clicked GetQuotation in SummaryCartPage - totalQuantity : ",
  //   totalQuantity
  // );

  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  // const handleSubmitdns = async () => {
  
  //     const orderStatus = localStorage.getItem('order_Status');
  //     console.log("Order Status When Clicked Submit Function  : ",orderStatus);


  //   const loggedin = !!localStorage.getItem("userToken");
  //   const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  //   const ompuserId = localStorage.getItem("id");
  //   const customer_first_name = localStorage.getItem("firstName");
  //   const customer_last_name = localStorage.getItem("lastName");
  //   const customer_contact_no = localStorage.getItem("number");
  //   const customer_email = localStorage.getItem("emailId");


  //   const payload = {
  //     items: [
  //       {
  //         item_id: itemId,
  //         quantity: quantity,
  //         bucket_id: bucketId,
  //       },
  //     ],
  //     ompuserId,
  //     customer_first_name,
  //     customer_last_name,
  //     customer_contact_no,
  //     order_status: orderStatus,
  //     order_ref_number: "",
  //     customer_email,
  //     input: {
  //       city: "",
  //       cityArea: "",
  //       companyName: "",
  //       country: "IN",
  //       countryArea: "",
  //       firstName: "",
  //       lastName: "  ",
  //       phone: "",
  //       postalCode: "",
  //       streetAddress1: " ",
  //       streetAddress2: "",
  //     },
  //     businessaddress: {
  //       city: "",
  //       cityArea: "",
  //       companyName: " ",
  //       country: "IN",
  //       countryArea: "",
  //       firstName: "",
  //       lastName: "",
  //       phone: "",
  //       postalCode: "",
  //       streetAddress1: "",
  //       streetAddress2: "",
  //     },
  //     gst_no: "",
  //   };


   
  //   try {
  //     const response = await fetch(
  //       "https://ompextension.origa.market/api/request_quotation",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       }
  //     );

  //     const data = await response.json();
  //     if (data.message === "Quotation request saved successfully!") {
  //       setShowPopup(false);
  //       setShowThanksPopup(true);
  //       setTimeout(() => {
  //         setShowThanksPopup(false);
  //         navigate("/store");
  //       }, 4000);
  //     } else {
  //       console("Failed to raise a quotation");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting quotation:", error);
  //     alert("Error submitting quotation.");
  //   }
  // };

  
  const handleSubmitdns = async () => {
    console.log("Submit button clicked");

    const orderStatus = localStorage.getItem("order_Status");
    console.log("Order Status from localStorage:", orderStatus);
  

    const loggedin = !!localStorage.getItem("userToken");

  
    const localCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

  
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
  
    console.log("User Information:");
    console.log("OMP User ID:", ompuserId);
    console.log("First Name:", customer_first_name);
    console.log("Last Name:", customer_last_name);
    console.log("Contact Number:", customer_contact_no);
    console.log("Email:", customer_email);
  

    let itemsPayload = [];
  
    if (orderStatus === "Draft") {
      console.log("Order Status is 'Draft'");
      itemsPayload = [
        {
          item_id: itemId,
          quantity: quantity,
          bucket_id: bucketId,
        },
      ];
      console.log("Items Payload for 'Draft':", itemsPayload);
    } else if (orderStatus === "Buy") {
      console.log("Order Status is 'Buy'");
      if (itemDetails && Array.isArray(itemDetails)) {
        itemsPayload = itemDetails.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
          bucket_id: item.bucket_id,
        }));
        console.log("Items Payload for 'Buy':", itemsPayload);
      }
    }
  
    const payload = {
      items: itemsPayload,
      ompuserId,
      customer_first_name,
      customer_last_name,
      customer_contact_no,
      order_status: orderStatus,
      order_ref_number: "",
      customer_email,
      input: {
        city: "",
        cityArea: "",
        companyName: "",
        country: "IN",
        countryArea: "",
        firstName: "",
        lastName: "  ",
        phone: "",
        postalCode: "",
        streetAddress1: " ",
        streetAddress2: "",
      },
      businessaddress: {
        city: "",
        cityArea: "",
        companyName: " ",
        country: "IN",
        countryArea: "",
        firstName: "",
        lastName: "",
        phone: "",
        postalCode: "",
        streetAddress1: "",
        streetAddress2: "",
      },
      gst_no: "",
    };
  
    console.log("Payload being sent:", payload);
  
    try {
      console.log("Making API request...");
      const response = await fetch(
        "https://ompextension.origa.market/api/request_quotation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      const data = await response.json();
      console.log("API Response:", data);
  
      if (data.message === "Quotation request saved successfully!") {
        console.log("Quotation request saved successfully!");
        setShowPopup(false);
        setShowThanksPopup(true);
        setTimeout(() => {
          setShowThanksPopup(false);
          navigate("/store");
        }, 4000);
      } else {
        console.log("Failed to raise a quotation");
      }
    } catch (error) {
      console.error("Error submitting quotation:", error);
      alert("Error submitting quotation.");
    }
  };
  

  return (
    <>


      <div className="totalpopupdiv">
        <h4 className="quotationDetailsheading">Quotation Details</h4>
      
          <>
            {orderStatus === "Draft" && (
              <div className="popupContentWrapper">
                <div className="Contentisnidepopup">
                  <p>
                    Name:
                    <span style={{ fontWeight: "600" }}>
                      {itemName}
                    </span>{" "}
                    <br />
                    Price: <span style={{ fontWeight: "600" }}>
                      ₹{price}
                    </span>{" "}
                    <br />
                    Total:{" "}
                    <span style={{ fontWeight: "600" }}>
                      ₹{subtotal}
                    </span>{" "}
                    <br />
                    Total Quantity:{" "}
                    <span style={{ fontWeight: "600" }}>{quantity}</span> <br />
                  </p>
                  <button
                    className="button-submit-popup"
                    onClick={handleSubmitdns}
                  >
                    Submit
                  </button>
                </div>
                <div className="imageWrapper">
                  <img
                    src={thumbnailUrl}
                    alt={"n/a"}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </div>
            )}
            {orderStatus === "Buy" && itemDetails.length > 0 && (
              <div className="popupContentWrapper">
                <div className="Contentisnidepopup">
                  <p>
                    Name:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {itemDetails[0]?.name}
                    </span>{" "}
                    <br />
                    Price:{" "}
                    <span style={{ fontWeight: "600" }}>
                      ₹{itemDetails[0]?.rate}
                    </span>{" "}
                    <br />
                    Total:{" "}
                    <span style={{ fontWeight: "600" }}>
                      ₹ {totalAmount}
                    </span>{" "}
                    <br />
                    Total Quantity:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {/* {itemDetails[0]?.quantity} */}
                      {itemDetails[0]?.quantity < 10
                    ? itemDetails[0]?.quantity
              :       itemDetails[0]?.quantity % 10}
                    </span>{" "}
                    <br />
                  </p>
                  <button
                    className="button-submit-popup"
                    onClick={handleSubmitdns}
                  >
                    Submit
                  </button>
                </div>
                <div className="imageWrapper">
                  <img
                    src={itemDetails[0]?.thumbnail}
                    alt={itemDetails[0]?.name}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </div>
            )}
          </>
        </div>
   

  

      {showThanksPopup && (
        <div className="  popup-overlay ">
          <div className="popup-banner ">
            <div
              className="popup-content "
              style={{ backgroundColor: "#d2eff1" }}
            >
              <div
                className="differentthankspopupcontnet"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="thanksforyourresponse">
                  Thank you for your quotation request!
                </h3>
                <p className="wewillcontactu">We'll get back to you soon</p>
                <br></br>
                <div
                  className="close-popup"
                  onClick={() => setShowThanksPopup(false)}
                >
                  {closeIcon({ width: 14, height: 14 })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuotationPopupForBuynow;
