import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Button from "../../Button/Button";
import OrderDetails from "./OrderDetails";
import AccountFooter from "../AccountFooter";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import {
  secondClient,
  customerMyAmcplans,
} from "../../OrigaExtentionAPI/mutations";
import MyOrdersPart from "../../Buying/MyMachine/Machine/MyOrdersPart";
import MyOrdersPartTwoCode from "../../Buying/MyMachine/Machine/MyOrdersPartTwoCode";
import OrderHistory from "../../Buying/MyMachine/Machine/OrderHistory";


const YourOrder = () => {
  const [isSmallScreen] = useState(window.innerWidth <= 769);
  const [OrderList, setOrderList] = useState([]);
  useEffect(() => {
    myMachineLists();
  }, []);
  const myMachineLists = async () => {
    try {
      const id = localStorage.getItem("id");
      secondClient
        .query({ query: customerMyAmcplans, variables: { ompUserId: id } })
        .then(({ data }) => {
          console.log("data=>", data?.customerMyAmcplans?.response?.jsondata);
          setOrderList(data?.customerMyAmcplans?.response?.jsondata);
        })
        .catch((error) => {
          console.error("Mutation error:", error);
        });
    } catch (error) {
      console.error("Error  Buy Machine:", error);
    }
  };
  const navigate = useNavigate();
  const heading = "Your Orders";
  const add1 = "My Account";
  const breadcrumbsItems = [
    { name: "Home Page", link: "/" },
    { name: "My Account", link: "/myaccount" },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="max-container pt-3">
          <Breadcrumbs
            items={breadcrumbsItems}
            boldtitle={heading}
            backnavi={() => navigate("/myaccount")}
          />
        </div>
        <div className="max-container pt-3">
          <div className="container-fluid p-0 m-0 row">
            <div className="col heading-600-24 p-0">{heading}</div>
          </div>
        </div>
        <div className="max-container pt-4">
          {OrderList?.map((order, index) => (
            <OrderDetails order={order} />
          ))}
        </div>
        {/* 
<MyOrdersPartTwoCode /> */}
{/* <MyOrdersPart /> */}
<OrderHistory />
      </div>
      <AccountFooter />
    </>
  );
};

export default YourOrder;
