import React, { useState, useEffect, useRef } from "react";
import "./ProductListing.css";
// import "./StorePart.css";
import "./NewStoreDesignStyles.css";
import LoginModel from "../../Authentication/LoginModel/LoginModel";
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import Footer from "../../Footer/Footer";
import { shortIcon, filterIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  ALGOLIA_ID,
  ALGOLIA_KEY,
  ALGOLIA_SELLER_INDEX,
  ALGOLIA_SPARE_INDEX,
} from "../../../constants";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  connectRange,
  connectStateResults,
} from "react-instantsearch-dom";
import FilterSection from "./FilterSection";
import Loader from "../../SubComponent/Loader";
import CategoryPopup from "../../Account/Popup/CategoryPopup";
// import CustomHits from "./CustomHits";
import SpareHits from "./SpareHits";
import { filterAttribute } from "./FilterAttribute";
import Slider from "@mui/material/Slider";
import CustomerInfo from "./CustomerInformation/CustomerInfoModel";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import { TfiLayoutColumn3Alt, TfiLayoutColumn4Alt } from "react-icons/tfi";
import HitsForMachines from "./CustomHits/HitsForMachines";

const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);

const ProductListing = () => {
  const [isSticky, setIsSticky] = useState(true);

  const scrollPositionLimit = 12308.2000122070312;

  const handleScroll = () => {
    //   console.log("Current Scroll Position:", window.scrollY);

    if (window.scrollY >= scrollPositionLimit) {
      setIsSticky(false);
      // console.log("Scrolled beyond the defined position");
    } else {
      setIsSticky(true);
      // console.log("Scrolled above the defined position");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
  const Seller_index = client.initIndex(ALGOLIA_SELLER_INDEX);
  const [filteredHits, setFilteredHits] = useState([]);
  const location = useLocation();
  const [isMobCategoryShow, setIsMobCategoryShow] = useState(
    window.innerWidth <= 767
  );
  const [activeTab, setActiveTab] = useState("machines");
  const [MainFilters, setMainFilters] = useState("");
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    industry: [],
    category: [],
    subcategory: [],
    brand: [],
    Model: [],
    Price: [],
    Year: [],
    Controller: [],
    XAXIS: [],
    YAXIS: [],
    ZAXIS: [],
    spindlespeed: [],
    spindletaper: [],
    tailstock: [],
    barcapacity: [],
    chucksize: [],
    maxturninglength: [],
    swingoverbed: [],
    swingovercrossslide: [],
    subSpindle: [],
    liveTooling: [],
    orderBy: "",
    page: 1,
  });
  const token = localStorage.getItem("userToken");
  const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
  const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);
  const [showShortList, setShowShortList] = useState(false);
  // const [activeFilters, setActiveFilters] = useState({
  //   industry: true,
  //   category: false,
  //   brand: false,
  //   model: false,
  //   Price: false,
  //   year: false,
  //   Controller: false,
  //   XAXIS: false,
  //   YAXIS: false,
  //   ZAXIS: false,
  //   spindlespeed: false,
  //   spindletaper: false,
  //   tailstock: false,
  //   barcapacity: false,
  //   chucksize: false,
  //   maxturninglength: false,
  //   swingoverbed: false,
  //   swingovercrossslide: false,
  //   subSpindle: false,
  //   liveTooling: false,
  // });
  const searchParams = new URLSearchParams(location.search);
  const searchInput = searchParams.get("searchInput");
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [Sorting, setSorting] = useState(null);
  const storedLocation = localStorage.getItem("deliveryLocation");
  if (storedLocation && !deliveryLocation) {
    setDeliveryLocation(storedLocation);
  }
  // const searchInput = location.state?.searchInput;
  // // //console.log('searchInput------>',searchInput);
  // console.log("Search Input in Machines : ",searchInput);
  const [seeMore, setSeeMore] = useState(false);
  const navigate = useNavigate();
  const breadcrumbsItems = [{ name: "Buy Machines", link: `/` }];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { SparefilterConfigurations, filterConfigurations } = filterAttribute(
    IndustryCheckBoxValue,
    CategoryCheckBoxValue
  );

  const initialActiveFilters = {
    ...SparefilterConfigurations.reduce((acc, config) => {
      acc[config.filterType] = true; // Set each SparefilterConfigurations filter as active
      return acc;
    }, {}),
    ...filterConfigurations.reduce((acc, config) => {
      acc[config.filterType] = true; // Set each filterConfigurations filter as active
      return acc;
    }, {}),
  };

  const [activeFilters, setActiveFilters] = useState(initialActiveFilters);
  // console.log("filterConfigurations ----------------------------------------->",filterConfigurations);
  // console.log("SparefilterConfigurations ----------------------------------------->",SparefilterConfigurations);
  //   const handleFilters = (name, value) => {
  //     setActiveFilters((prevState) => ({ ...prevState, [name]: value }));
  //   };

  const [showScrollbar, setShowScrollbar] = useState(false);

  useEffect(() => {
    const activeCount = Object.values(activeFilters).filter(Boolean).length;
    setShowScrollbar(activeCount >= 2);
  }, [activeFilters]);

  const handleFilters = (name, value) => {
    setActiveFilters((prevState) => {
      const newState = { ...prevState, [name]: value };
      const activeCount = Object.values(newState).filter(Boolean).length;
      setShowScrollbar(activeCount >= 2);
      return newState;
    });
  };
  const handleChangeSort = (sort) => {
    setSorting(sort);

    const selectedFilters = Object.assign({}, filters);
    selectedFilters.orderBy = sort;
    selectedFilters.page = 1;
    setFilters(selectedFilters);
    setShowShortList(false);
  };
  const handleSeeMore = (status) => {
    if (status) {
      setSeeMore(status);
      document.body.classList.add("no-overflow");
    } else {
      setSeeMore(false);
      document.body.classList.remove("no-overflow");
    }
  };

  const onGetPinCodeHandler = (pin) => {
    setDeliveryLocation(pin);
  };

  const toggleDropdown = () => {
    const dropdownElement = document.querySelector(".p-fixed");
    const dropdownElement1 = document.querySelector(".fixed-category-bottom");
    if (dropdownElement) {
      dropdownElement.classList.remove("dropdownHidden");
      dropdownElement1.classList.remove("dropdownHidden");
    }
  };

  const RangeSlider = ({ min, max, currentRefinement, refine }) => {
    const handleChange = (event, newValue) => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      // console.log('newValue---->',newValue);
      if (minAllowedYear === newValue[0]) {
        refine({ min: 1900, max: newValue[1] });
      } else {
        refine({ min: newValue[0], max: newValue[1] });
      }
    };
    const [minYear, setMinYear] = useState(1940);

    useEffect(() => {
      const currentYear = new Date().getFullYear();
      const minAllowedYear = currentYear - 25;
      setMinYear(minAllowedYear);
    }, []);
    const defaultValue = [
      currentRefinement.min || min,
      currentRefinement.max || max,
    ];
    return (
      <div>
        <Slider
          min={minYear}
          max={max}
          //step={1000}
          value={defaultValue}
          onChange={handleChange}
          valueLabelDisplay="auto"
          color="secondary"
          aria-labelledby="range-slider"
          getAriaValueText={(value) => `$${value}`}
          sx={{
            width: "89%",
            position: "relative", // Ensures that `left` can be applied
            left: "6%", // Add left offset
          }}
        />
      </div>
    );
  };

  const CustomRangeInput = connectRange(RangeSlider);

  const images = [
    { original: "/Listing-Banner_optimized.jpg" },
    { original: "/Listing-Banner-2.jpg" },
    { original: "/Listing-Banner-3.jpg" },
    { original: "/Listing-Banner-4.jpg" },
  ];

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3, itemsFit: "contain" },
    1610: { items: 4 },
  };

  const carouselRef = useRef(null);
  const items = images.map((image, index) => (
    <div className="custom-image-container" key={index}>
      <div className="custom-image-wrapper">
        <img
          src={image.original}
          alt="Product"
          className="custom-image"
          draggable="false"
        />
      </div>
    </div>
  ));

  const [isGridView, setIsGridView] = useState(false);
  const [isPriceSelected, setIsPriceSelected] = useState(false);
  const [isIndustrySelected, setIsIndustrySelected] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [isBrandsSelected, setIsBrandsSelected] = useState(false);
  const [isModelSelected, setIsModelSelected] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const toggleGridView = (value) => {
    console.log("The grid button being clicked ", value);
    setIsGridView(value);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Step 2: Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine hitsPerPage based on windowWidth
  const getHitsPerPage = () => {
    if (windowWidth > 2179) {
      return 16;
    } else if (windowWidth > 1920) {
      return 21;
    } else if (windowWidth > 1661) {
      return 18;
    } else if (windowWidth > 1400) {
      return 20;
    } else {
      return 16;
    }
  };

  const hitsPerPage = getHitsPerPage();

  useEffect(() => {
    console.log("isPriceSelected in Store.jsx:", isPriceSelected);
    console.log("isIndustrySelected in Store.jsx:", isIndustrySelected);
    console.log("isCategorySelected in Store.jsx: ", isCategorySelected);
    console.log("isBrandsSelected in Store.jsx:", isBrandsSelected);
    console.log("isModelSelected in Store.jsx:", isModelSelected);
    console.log("isLocationSelected in Store.jsx: ", isLocationSelected);
  }, [
    isPriceSelected,
    isIndustrySelected,
    isCategorySelected,
    isBrandsSelected,
    isModelSelected,
    isLocationSelected,
  ]);

  return (
    <>
      {loading && <Loader />}
      {seeMore && (
        <DeliveryLocation
          modalAction={handleSeeMore}
          pinvalue={onGetPinCodeHandler}
        />
      )}
      <div className="container-fluid col-cust">
        {/* <div className="max-container my-3"> */}
        <div className={isMobile ? "max-container my-3" : "mx-5 my-3"}>
          {activeTab === "machines" && (
            <>
              <InstantSearch
                searchClient={client}
                indexName={ALGOLIA_SELLER_INDEX}
                insights={true}
              >
                <Configure
                  maxValuesPerFacet={50}
                  query={searchInput}
                  filters={MainFilters}
                  facetFilters={[
                    `categories.lvl0:${
                      searchInput === "MSME" || searchInput === "HealthCare"
                        ? searchInput
                        : ""
                    }`,
                  ]}
                  hitsPerPage={hitsPerPage}
                />
                {isMobCategoryShow && (
                  <CategoryPopup
                    filterConfigurations={filterConfigurations}
                    activeFilters={activeFilters}
                    setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                    CategoryCheckBoxValue={CategoryCheckBoxValue}
                    setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                    MainFilters={MainFilters}
                    setMainFilters={setMainFilters}
                  />
                )}

                {/* BreadCrumbs */}
                <div className="Breadcrumbsanddeliverlocation">
                  <div>
                    <Breadcrumbs
                      backnavi={() => navigate(`/`)}
                      boldtitle={"Machines"}
                      items={breadcrumbsItems}
                    />
                  </div>
                </div>

                {/* <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <Breadcrumbs
                      backnavi={() => navigate(`/buy/product-listing?searchInput=${'MSME'}`)}
                      boldtitle={"Machine Listing"}
                      items={breadcrumbsItems}
                    />
                  </div>
                  <div>
                    <div className="head-wrap">
                      <div className="btn-wrap">
                        <button
                          onClick={() => {
                            setShowShortList(false);
                            handleSeeMore(true);
                          }}
                          className="btn location"
                        >
                          {deliveryLocation
                            ? `Delivery Location : ${deliveryLocation}`
                            : "Set Delivery Location"}{" "}
                        </button>
                        {isMobCategoryShow && (
                          <div className="short-wrap">
                            <button
                              className="btn short-btn"
                              onClick={toggleDropdown}
                            >
                              {filterIcon({
                                width: 22,
                                height: 22,
                                fill: "#73509E",
                              })}
                            </button>
                          </div>
                        )}
                        <div className="short-wrap">
                          <button
                            className="btn short-btn"
                            onClick={() => setShowShortList(!showShortList)}
                          >
                            {shortIcon({ width: 22, height: 22 })}
                            <span>
                              {filters.orderBy
                                ? filters.orderBy
                                : "Sort Result"}
                            </span>
                          </button>
                          {showShortList && (
                            <div className="shorts">
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort Alphabetically (A - Z)"
                                  )
                                }
                              >
                                Sort Alphabetically (A - Z)
                              </span>
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort by Price (Highest to Lowest)"
                                  )
                                }
                              >
                                Sort by Price (Highest to Lowest)
                              </span>
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort by Price (Lowest to Highest)"
                                  )
                                }
                              >
                                Sort by Price (Lowest to Highest)
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="tab-wrap">
                                    <button onClick={() => setActiveTab("machines")} className={activeTab === "machines" ? "active btn" : "btn"} type="button">Machines</button>
                                    <button onClick={() => setActiveTab("store")} className={activeTab === "store" ? "active btn" : "btn"} type="button">Store</button>
                                </div> */}

                {/* <div className="head-wrap">
                  <div className="heading-wrap">
                                        <div className="sub-heading heading-400-16">Search Result for</div>
                                        <div className="heading heading-600-20">{searchInput}</div>
                                    </div>
                  <div className="btn-wrap">
                                        <button onClick={() => { setShowShortList(false); handleSeeMore(true) }} className="btn location">{deliveryLocation ? `Delivery Location : ${deliveryLocation}` : 'Set Delivery Location'} </button>
                                        {isMobCategoryShow && <div className="short-wrap">
                                            <button className="btn short-btn" onClick={toggleDropdown}>{filterIcon({ width: 22, height: 22, fill: "#73509E" })}</button>
                                        </div>}
                                        <div className="short-wrap">
                                            <button className="btn short-btn" onClick={() => setShowShortList(!showShortList)}>
                                                {shortIcon({ width: 22, height: 22 })}
                                                <span>{filters.orderBy ? filters.orderBy : "Sort Result"}</span>
                                            </button>
                                            {showShortList && (
                                                <div className="shorts">
                                                    <span className="item" onClick={() => handleChangeSort("Sort Alphabetically (A - Z)")}>Sort Alphabetically (A - Z)</span>
                                                    <span className="item" onClick={() => handleChangeSort("Sort by Price (Highest to Lowest)")}>Sort by Price (Highest to Lowest)</span>
                                                    <span className="item" onClick={() => handleChangeSort("Sort by Price (Lowest to Highest)")}>Sort by Price (Lowest to Highest)</span>
                                                   
                                                </div>
                                            )}
                                        </div>
                                    </div>
                </div> */}

                {/*----------------------------------- Delivery Location button adn Filters button---------------------------- */}
                <div className="filterAndLocationNotForDesktop d-flex ">
                  <div className=" filterAndLocationWrapper head-wrap d-flex justify-content-end  ">
                    <div className="btn-wrap">
                      <div>
                        {" "}
                        <button
                          onClick={() => handleSeeMore(true)}
                          className="filterAndLocationWrapper delveryloactionn"
                        >
                          {deliveryLocation
                            ? `Delivery Location : ${deliveryLocation}`
                            : "Set Delivery Location"}
                        </button>
                      </div>
                      <div className="short-wrap">
                        <button
                          className=" filterAndLocationWrapper btn short-btn "
                          onClick={toggleDropdown}
                        >
                          Filters
                          {filterIcon({
                            width: 22,
                            height: 22,
                            fill: "#73509E",
                          })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filterAndLocationWrapper d-flex justify-content-center align-items-center  ">
                  <div className="gridButtonformobile"></div>
                  {/* */}
                  <div
                    className={`gridcontainerbuttonformobile ${
                      !isGridView ? "active" : ""
                    }`}
                    onClick={() => toggleGridView(false)}
                  >
                    <div
                      className={`icon-box ${!isGridView ? "icon-active" : ""}`}
                    ></div>
                  </div>
                  &nbsp;
                  <div
                    className={`gridcontainerbuttonformobile ${
                      isGridView ? "active" : ""
                    }`}
                    onClick={() => toggleGridView(true)}
                  >
                    <div
                      className={`icon-box ${isGridView ? "icon-active" : ""}`}
                    ></div>
                    <div
                      className={`icon-box ${isGridView ? "icon-active" : ""}`}
                    ></div>
                  </div>
                </div>
                <div
                  className={`row layout-wrap ${isGridView ? "grid-view" : ""}`}
                  style={{ position: "relative" }}
                >
                  <div
                    className={`filtersectioncse col col-lg-2 col-md-3 col-12`}
                    id="filterSection"
                  >
                    <div
                      className={`filter-wrap ${
                        showScrollbar ? "scrollable" : ""
                      }`}
                      style={{
                        position: isSticky ? "sticky" : "relative",
                        top: isSticky ? "205px" : "auto",
                      }}
                    >
                      <div className="head">
                        <div className="heading heading-600-20">
                          {filterIcon({ width: 18, height: 12 })}
                          Filters
                        </div>
                        <div
                          id="ResetBtn"
                          className="heading-600-14 curser-pointer"
                        >
                          Reset
                        </div>
                      </div>

                      {filterConfigurations.map((config, index) => {
                        if (config.filterType === "Year") {
                          return (
                            <div key={config.filterType}>
                              <span className="heading-600-16">
                                {config.title}
                              </span>
                              <CustomRangeInput attribute={config.attribute} />
                            </div>
                          );
                        } else {
                          return (
                            <FilterSection
                              key={config.filterType}
                              title={config.title}
                              attribute={config.attribute}
                              filterType={config.filterType}
                              activeFilters={activeFilters}
                              setIndustryCheckBoxValue={
                                setIndustryCheckBoxValue
                              }
                              CategoryCheckBoxValue={CategoryCheckBoxValue}
                              setCategoryCheckBoxValue={
                                setCategoryCheckBoxValue
                              }
                              handleFilters={handleFilters}
                              MainFilters={MainFilters}
                              setMainFilters={setMainFilters}
                              setIsGridView={setIsGridView}
                              setIsPriceSelected={setIsPriceSelected}
                              setIsIndustrySelected={setIsIndustrySelected}
                              setIsCategorySelected={setIsCategorySelected}
                              setIsBrandsSelected={setIsBrandsSelected}
                              setIsModelSelected={setIsModelSelected}
                              setIsLocationSelected={setIsLocationSelected}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                  <NoResultsFound />
                  <div
                    //   className="col col-lg-9 col-md-9 col-12"
                    className={`productcardsectioncse col col-lg-10 col-md-9 col-12`}
                  >
                    {/* <AliceCarousel
                      ref={carouselRef}
                      mouseTracking
                      items={items}
                      responsive={responsive}
                      controlsStrategy="alternate"
                      infinite
                      autoPlay
                      autoPlayInterval={3000}
                      disableDotsControls
                      disableButtonsControls
                      disableDragging={true}
                    /> */}

                    {/*--------This div is for showing three structures in desktop such as - Delivery Location , grid and sort------*/}

                    <div className="filters-delivery-grid d-flex justify-content-end align-items-center mt-1 mr-1">
                      {/* Div 1 - Align to the left */}
                      <div className="d-flex thegirdbuttonlogic ">
                        {/* <span className="gridtextfordesktop">Grid - &nbsp;</span>  */}
                        {/*Grid buttons 1 and 2 for small laptop screens */}
                        <div
                          className={`gridcontainerbuttonforsmallestdesktop ${
                            isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(true)}
                        >
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        &nbsp;
                        <div
                          className={`gridcontainerbuttonforsmallestdesktop ${
                            !isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(false)}
                        >
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        {/*Grid buttons 2 and 3 for middle laptop screens */}
                        <div
                          className={`gridcontainerbuttonforsmalldesktop ${
                            isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(true)}
                        >
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        &nbsp;
                        <div
                          className={`gridcontainerbuttonforsmalldesktop ${
                            !isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(false)}
                        >
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        {/*Grid buttons 3 and 4 for middle laptop screens */}
                        <div
                          className={`gridcontainerbuttonfordesktop ${
                            isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(true)}
                        >
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        &nbsp;
                        <div
                          className={`gridcontainerbuttonfordesktop ${
                            !isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(false)}
                        >
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        {/*Grid buttons 4 and 5 for large laptop screens */}
                        <div
                          className={`gridcontainerbuttonforbigdesktop ${
                            isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(true)}
                        >
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                        &nbsp;
                        <div
                          className={`gridcontainerbuttonforbigdesktop ${
                            !isGridView ? "active" : ""
                          }`}
                          onClick={() => toggleGridView(false)}
                        >
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                          <div
                            className={`icon-box ${
                              !isGridView ? "icon-active" : ""
                            }`}
                          ></div>
                        </div>
                      </div>

                      {/* Div 2 and Div 3 - Align to the right */}

                      {/* Div 2 */}

                      <div className="individual-div-filters-delivery-grid">
                        <button
                          onClick={() => handleSeeMore(true)}
                          className="delveryloactionned"
                        >
                          {deliveryLocation
                            ? `Delivery Location : ${deliveryLocation}`
                            : "Set Delivery Location"}
                        </button>
                      </div>

                      {/* Div 3 */}
                      <div className="individual-div-filters-delivery-grid sortbutton">
                        <div style={{ position: "relative" }}>
                          <button
                            className="sortforstorejsxed"
                            onClick={() => setShowShortList(!showShortList)}
                          >
                            {shortIcon({ width: 22, height: 22 })}
                            <span>
                              {filters.orderBy
                                ? filters.orderBy
                                : "Sort Result"}
                            </span>
                          </button>
                          {showShortList && (
                            <div className="shorts">
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort Alphabetically (A - Z)"
                                  )
                                }
                              >
                                Sort Alphabetically (A - Z)
                              </span>
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort by Price (Highest to Lowest)"
                                  )
                                }
                              >
                                Sort by Price (Highest to Lowest)
                              </span>
                              <span
                                className="item"
                                onClick={() =>
                                  handleChangeSort(
                                    "Sort by Price (Lowest to Highest)"
                                  )
                                }
                              >
                                Sort by Price (Lowest to Highest)
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="filters-delivery-grid d-flex justify-content-between align-items-center mt-1">

  <div className="d-flex thegirdbuttonlogic ">
                    <div
                      className={`gridcontainerbuttonfordesktop ${
                        isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(true)}
                    >
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    &nbsp;
                    <div
                      className={`gridcontainerbuttonfordesktop ${
                        !isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(false)}
                    >
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                  </div>

  <div className="d-flex align-items-center">
    
    <div className="individual-div-filters-delivery-grid">
      <button
        onClick={() => handleSeeMore(true)}
        className="delveryloactionn"
      >
        {deliveryLocation
          ? `Delivery Location : ${deliveryLocation}`
          : "Set Delivery Location"}
      </button>
    </div>

    
    <div className="individual-div-filters-delivery-grid sortbutton">
      <div style={{ position: "relative" }}>
        <button
          className="sortforstorejsx"
          onClick={() => setShowShortList(!showShortList)}
        >
          {shortIcon({ width: 22, height: 22 })}
          <span>
            {filters.orderBy ? filters.orderBy : "Sort Result"}
          </span>
        </button>
        {showShortList && (
          <div className="shorts">
            <span
              className="item"
              onClick={() => handleChangeSort("Sort Alphabetically (A - Z)")}
            >
              Sort Alphabetically (A - Z)
            </span>
            <span
              className="item"
              onClick={() =>
                handleChangeSort("Sort by Price (Highest to Lowest)")
              }
            >
              Sort by Price (Highest to Lowest)
            </span>
            <span
              className="item"
              onClick={() =>
                handleChangeSort("Sort by Price (Lowest to Highest)")
              }
            >
              Sort by Price (Lowest to Highest)
            </span>
          </div>
        )}
      </div>
    </div>
  </div>
                    </div> */}
                    {/* <CustomHits
                      setLoading={setLoading}
                      loading={loading}
                      Sorting={Sorting}
                      query={searchInput}
                      isGridView={isGridView}
                    /> */}

                    <HitsForMachines
                      setLoading={setLoading}
                      loading={loading}
                      Sorting={Sorting}
                      query={searchInput}
                      isGridView={isGridView}
                      isPriceSelected={isPriceSelected}
                      isIndustrySelected={isIndustrySelected}
                      isCategorySelected={isCategorySelected}
                      isBrandsSelected={isBrandsSelected}
                      isModelSelected={isModelSelected}
                      isLocationSelected={isLocationSelected}
                    />
                  </div>
                </div>
              </InstantSearch>
            </>
          )}
          {activeTab === "store" && (
            <>
              <InstantSearch
                searchClient={client}
                indexName={ALGOLIA_SPARE_INDEX}
                insights={true}
              >
                <Configure
                  maxValuesPerFacet={50}
                  query={""}
                  filters={MainFilters}
                  hitsPerPage={12}
                />
                <Breadcrumbs
                  backnavi={() => navigate(`/`)}
                  boldtitle={"Machine Listing"}
                  items={breadcrumbsItems}
                />
                <div className="tab-wrap">
                  <button
                    onClick={() => setActiveTab("machines")}
                    className={activeTab === "machines" ? "active btn" : "btn"}
                    type="button"
                  >
                    Machines
                  </button>
                  <button
                    onClick={() => setActiveTab("store")}
                    className={activeTab === "store" ? "active btn" : "btn"}
                    type="button"
                  >
                    Store
                  </button>
                </div>

                <div className="head-wrap">
                  <div className="heading-wrap"></div>
                  <div className="btn-wrap"></div>
                </div>

                <div className="row layout-wrap">
                  <div className="col col-lg-3 col-md-3 col-12">
                    <div className="filter-wrap">
                      <div className="head">
                        <div className="heading heading-600-20">
                          {filterIcon({ width: 18, height: 12 })}
                          Filters
                        </div>
                      </div>
                      {SparefilterConfigurations?.map((config, index) => (
                        <FilterSection
                          key={config.filterType}
                          title={config.title}
                          attribute={config.attribute}
                          filterType={config.filterType}
                          activeFilters={activeFilters}
                          setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                          CategoryCheckBoxValue={CategoryCheckBoxValue}
                          setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                          handleFilters={handleFilters}
                          setIsGridView={setIsGridView}
                          setIsPriceSelected={setIsPriceSelected}
                          setIsIndustrySelected={setIsIndustrySelected}
                          setIsCategorySelected={setIsCategorySelected}
                          setIsBrandsSelected={setIsBrandsSelected}
                          setIsModelSelected={setIsModelSelected}
                          setIsLocationSelected={setIsLocationSelected}
                        />
                      ))}
                    </div>
                  </div>
                  <NoResultsFound />
                  <div className="col col-lg-9 col-md-9 col-12">
                    <SpareHits
                      setLoading={setLoading}
                      Sorting={Sorting}
                      hitComponent={filteredHits}
                      query={searchInput}
                    />
                  </div>
                </div>
              </InstantSearch>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProductListing;

const NoResultsFound = connectStateResults(({ searchResults }) => {
  const [CustomerInfoForm, setCustomerInfoForm] = useState(false);
  useEffect(() => {
    if (searchResults && searchResults.nbHits === 0) {
      setCustomerInfoForm(true);
    } else {
      setCustomerInfoForm(false);
    }
  }, [searchResults]);
  if (CustomerInfoForm) {
    return (
      <div className="col col-lg-9 col-md-9 col-12">
        <CustomerInfo setCustomerInfoForm={setCustomerInfoForm} />
        <p>No product found.</p>
      </div>
    );
  }
  return null;
});

/*
 Previously in ShowShortList
 <span className="item" onClick={() => handleChangeSort("Location (Nearest to Furthest)")}>Sort by Location (Nearest to Furthest)</span>
                                                <span className="item" onClick={() => handleChangeSort("Location (Furthest to Nearest)")}>Sort by Location (Furthest to Nearest)</span> 
                                                */
