
import React, { useState, useEffect } from 'react'
import MALeftsection from './MALeftsection';
import Button from '../../Button/Button';
import ButtonOutline from '../../Button/ButtonOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountFooter from '../AccountFooter';
import Breadcrumbs from '../../SubComponent/Breadcrumbs';
import useAddAddress from '../../SubComponent/useAddAddress';
import { locationIcon } from '../../../helpers/Icons';
import Loader from '../../SubComponent/Loader';
import axios from "axios";
import Button2 from '../../Button/Button2';
import ErrorModal from './ErrorModal'; // Import the modal component

const MyAccAddAddress = () => {

    const location = useLocation();
    const [loadinged, setLoadinged] = useState(false);
    const address = location.state?.address;
   
    const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(false);
     const[errorMessage,setErrorMessage]= useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [showModal, setShowModal] = useState(false); 
    
    const { itemId, bucketId, quantity, orderId,quantityTotal,
      subtotalAmount,
      gstAmount,
      taxesAmount,
      shippingFee,
      totalAmount, } = location.state || {};
      // console.log("Item ID:", itemId);
      // console.log("Bucket ID:", bucketId);
      // console.log("Quantity:", quantity);
      // console.log("Order Identifier:", orderId);
      // console.log("Quantity Total:", quantityTotal);
      // console.log("Subtotal Amount:", subtotalAmount);
      // console.log("GST Amount:", gstAmount);
      // console.log("Taxes Amount:", taxesAmount);
      // console.log("Shipping Fee:", shippingFee);
      // console.log("Total Amount:", totalAmount);
    
    const handleBillingSameAsShipping = (e) => {
      setIsBillingSameAsShipping(e.target.checked);
      if (e.target.checked) {
        setAddress1(secondaddress1);
        setAddress2(secondaddress2);
        setPinCode(secondpinCode);
        setState(secondstate);
        setCity(secondcity);
      } else {
        setAddress1('');
        setAddress2('');
        setPinCode('');
        setState('');
        setCity('');
      }
    };
    const [data, setData] = useState(null);
   
    const [companyName ,setCompanyName ] = useState("");
    const [factoryName ,setFactoryName ] = useState("");

    const [email, setEmail] = useState('');
    const [firstname, setfirstname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    
    const queryParams = new URLSearchParams(location.search);


    const AddressId = queryParams.get('AddressId');
    const [alterPhoneNumber, setAlterPhoneNumber] = useState('');
    const [lastname, setlastname] = useState("");

    
    //seperate for billing addresss
    const [pinCode, setPinCode] = useState("");
    const [state, setState] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");

 
        //seperate for billing addresss
    const [secondpinCode, secondsetPinCode] = useState("");
    const [secondstate, secondsetState] = useState("");
    const [secondaddress1, secondsetAddress1] = useState("");
    const [secondaddress2, secondsetAddress2] = useState("");
    const [secondcity, secondsetCity] = useState("");

    const [secondgstno, secondsetgstno] = useState("");

    const[error,setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoader, setIsLoader] = useState(false)
  
    const [isSmallScreen] = useState(window.innerWidth <= 576);
  
    const [validation, setvalidaion] = useState({
      fvalidation: false,
      lvalidation: false,
      evalidation: false,
      MAdd1validation: false,
      MPinValidation: false,
      pvalidation: false,
      alterphovalidation: false,
      statevalidation: false,
      cityvalidation: false,
      companyNameValidation:false,
      factoryNameValidation:false
    });
    const [secondvalidation, setSecondValidation] = useState({
      secondfvalidation: false,
      secondlvalidation: false,
      secondevalidation: false,
      secondMAdd1validation: false,
      secondMPinValidation: false,
      secondpvalidation: false,
      secondalterphovalidation: false,
      secondstatevalidation: false,
      secondcityvalidation: false,
      secondgstnovalidation: false,
    });
  


    const onSecondGSTChange = (e) => {
      const newInputString = e.target.value;
    
      // Allow alphanumeric characters and limit the length to 14 characters
      const sanitizedInput = newInputString
          .replace(/[^a-zA-Z0-9]/g, '') // Allow only alphanumeric characters
          .slice(0, 14); // Limit to 14 characters
    
      // Update the state with the sanitized input
      secondsetgstno(sanitizedInput);
    };
    
  
  
    const onCompanyNameChange = (e) => {
     setCompanyName(e.target.value);
    };
    const onfactoryNameChange = (e) => {
      setFactoryName(e.target.value);
    };
  
 
    const [stateOptions, setStateOptions] = useState([
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
    ]);
    const navigate = useNavigate();
    const boldtitle = location.state.status;
    const { createCustomerAddress, response,  } = useAddAddress(boldtitle);
   
// const { createCustomerAddress } = useAddAddress(boldtitle);
// if (boldtitle === "Edit Address") {
//     createCustomerAddress(formdataUpdate);
// } else {
//     createCustomerAddress(formdataCreate);
// }


    const matchStateName = (apiState) => {
      return stateOptions.find(
        (option) =>
          option.replace(/\s+/g, "").toLowerCase() ===
          apiState.replace(/\s+/g, "").toLowerCase()
      );
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            "https://ompextension.origa.market/api/fetchcustomeraddress",
            { 
               id: localStorage.getItem("id"),
               mobileno: localStorage.getItem("number") 
            }
          );
         
          const addresses = response?.data?.response || [];
          console.log("API Response Addresses:", addresses);
          console.log("AddressId:", AddressId);
    
          const address = addresses.find(addr => addr.mp_address_id === Number(AddressId));
          console.log("Selected Address:", address);

          if (address) {
            const input = address.address.input;
            const businessaddress = address.address.businessaddress;
          
            setEmail(localStorage.getItem("emailId") || '');
            setfirstname(input.firstName || '');
            setPhoneNumber(input.phone || '');
            setAlterPhoneNumber(input.alternativePhone); 
            setlastname(input.lastName || '');
    
            setCompanyName(input.companyName || '');
            setFactoryName(address.address.factoryName || '');

            setState(input.countryArea || '');
            setPinCode(input.postalCode || '');
            setAddress1(input.streetAddress1 || '');
            setAddress2(input.streetAddress2 || '');
            setCity(input.city || '');
    
            // Shipping address details
            secondsetPinCode(businessaddress?.postalCode || '');
            secondsetState(businessaddress?.countryArea || '');
            secondsetAddress1(businessaddress?.streetAddress1 || '');
            secondsetAddress2(businessaddress?.streetAddress2 || '');
            secondsetCity(businessaddress?.city || '');
    
            secondsetgstno(address.address.gst_no || ''); 

            // console.log("email:",email );
            // console.log("firstname:", firstname);
            // console.log("lastname:", lastname);  
            // console.log("pinCode:", pinCode);
            // console.log("state:", state);
            // console.log("address1:", address1);
            // console.log("address2:", address2);
            // console.log("city:", city);


            // console.log("secondpinCode:", secondpinCode);
            // console.log("secondstate:", secondstate);
            // console.log("secondaddress1:", secondaddress1);
            // console.log("secondaddress2:", secondaddress2);
            // console.log("city:", city);
            // console.log("secondcity:", secondcity);

            // console.log("secondgstno:", secondgstno);
          }
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
    
      fetchData();
    }, [AddressId]);
    
    useEffect(() => {
      const fetchData = async () => {
        if (pinCode && pinCode.length === 6) { // Check if pinCode is defined and has a length of 6
          try {
            const response = await fetch(`https://ompextension.origa.market/api/getpincodestate`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ "pincode": pinCode }),
            });
    
            const responseData = await response.json();
    
            // Handle responseData as needed
            // console.log('responseData---->', responseData);
            setState(responseData.state);
            setCity(responseData.city);
    
          } catch (error) {
            // Handle errors if any
            console.error('Error fetching data:', error.message);
          }
        }
      };
    
      // Only fetch data if pinCode is valid
      if (pinCode && pinCode.length === 6) { // Check if pinCode is defined before checking length
        fetchData();
      }
    }, [pinCode]); // Trigger the effect when pinCode changes
    
    useEffect(() => {
      const fetchSecondData = async () => {
        if (secondpinCode && secondpinCode.length === 6) {
          try {
            const response = await fetch(`https://ompextension.origa.market/api/getpincodestate`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ "pincode": secondpinCode }),
            });
  
            const responseData = await response.json();
  
            // Handle responseData as needed
            // console.log('responseData for secondpinCode:', responseData);
            secondsetState(responseData.state);
            secondsetCity(responseData.city);
          } catch (error) {
            // Handle errors if any
            console.error('Error fetching data for secondpinCode:', error.message);
          }
        }
      };
  
      fetchSecondData();
    }, [secondpinCode]); 



    const onAddressOneChange = (e) => {
      setAddress1(e.target.value);
    };

    const SecondonAddressOneChange = (e) => {
      secondsetAddress1(e.target.value);
    };
    
    const onAddressTwoChange = (e) => {
      setAddress2(e.target.value);
    };

    const SecondonAddressTwoChange = (e) => {
      secondsetAddress2(e.target.value);
    };
  

    const onCityChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if (newInputString === '' || sanitizedInput === newInputString) {
        setCity(sanitizedInput);
      }
    };
  
    const onSecondCityChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if (newInputString === '' || sanitizedInput === newInputString) {
        secondsetCity(sanitizedInput);
      }
    };
  
    // const onSecondGSTChange = (e) => {
    //   const newInputString = e.target.value;
    //   const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');
  
    //   // Update the state only if the input is empty or contains valid characters
    //   if (newInputString === '' || sanitizedInput === newInputString) {
    //     secondsetgstno(sanitizedInput);
    //   }
    // };

 
  
    const onStateChange = (e) => {
      setState(e);
    };

    const onSecondStateChange = (e) => {
      secondsetState(e);
    };
    const onPinCodeChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 6) {
        setPinCode(sanitizedInput)
      }
  
    };

    const onSecondPinCodeChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 6) {
        secondsetPinCode(sanitizedInput)
      }
  
    };

 
    const onEmailChange = (e) => {
      setEmail(e.target.value);
    };

 
  
    const onFirstnameChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if (newInputString === '' || sanitizedInput === newInputString) {
        setfirstname(sanitizedInput);
      }
    };

    
   
    const onPhoneNumberChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 10) {
        setPhoneNumber(sanitizedInput)
      }
    };
    const onAlterPhoneNumberChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^0-9]/g, '');
  
      // Update the state only if the input is empty or contains valid characters
      if ((newInputString === '' || sanitizedInput === newInputString) && sanitizedInput.length <= 10) {
        setAlterPhoneNumber(sanitizedInput)
      }
    };
  
    const onLastnameChange = (e) => {
      const newInputString = e.target.value;
      const sanitizedInput = newInputString.replace(/[^a-zA-Z]/g, '');
  
      if (newInputString === '' || sanitizedInput === newInputString) {
        setlastname(sanitizedInput);
      }
    };
    // const isAddress= !!localStorage.getItem('userToken');
    const breadcrumbsItems = [{ name: "Home Page", link: "/" }, { name: "My Account", link: "/myaccount" }, { name: "Manage Address", link: '/manageaddress' }];
  
  
    const title1 = "Point of Contact"
    const title2 = "Billing Address"
    const title3 = "Shipping Address"
    const para1 = "We'd love to get in touch with you! Kindly share your contact details, and we'll reach out to discuss your machine"
    const para2 = "Please share the address where you'd like your machine to be delivered"
    const para3="Please share the address where you'd like your billing information to be sent";
  
    const onCancelHandler = () => {
      navigate(location.state.cancelNavi)
    }
    const onSubmitHandler = () => {
      setErrorMessage("");
      const errors = [];
      const formattedPhoneNumber = phoneNumber && phoneNumber.startsWith("+91")
      ? phoneNumber.substring(3)
      : phoneNumber || '';
    
    const formattedAlterPhoneNumber = alterPhoneNumber && alterPhoneNumber.startsWith("+91")
      ? alterPhoneNumber.substring(3)
      : alterPhoneNumber || '';
    

  if (
      firstname === "" ||
      lastname === "" ||
      formattedPhoneNumber === "" ||
      formattedPhoneNumber.length !== 10 ||
      pinCode === "" ||
      pinCode.length !== 6 ||
      secondpinCode === "" ||
      secondpinCode.length !== 6 ||
      state === "" ||
      secondstate === "" ||
      city === "" ||
      secondcity === "" ||
     //  secondgstno === "" ||
      address1 === "" ||
      secondaddress1 === "" ||
      companyName === "" ||
      factoryName === ""
  ) {
    if (firstname === "") {
      setvalidaion((prev) => ({ ...prev, fvalidation: true }));
      errors.push("First Name is required.");
    }
    if (lastname === "") {
      setvalidaion((prev) => ({ ...prev, lvalidation: true }));
      errors.push("Last Name is required.");
    }
    if (formattedPhoneNumber === "" || formattedPhoneNumber.length !== 10) {
      setvalidaion((prev) => ({ ...prev, pvalidation: true }));
      errors.push("Phone Number must be 10 digits.");
    }
    if (pinCode === "" || pinCode.length !== 6) {
      setvalidaion((prev) => ({ ...prev, MPinValidation: true }));
      errors.push("Pin Code must be 6 digits.");
    }
    if (state === "") {
      setvalidaion((prev) => ({ ...prev, statevalidation: true }));
      errors.push("State is required.");
    }
    if (address1 === "") {
      setvalidaion((prev) => ({ ...prev, MAdd1validation: true }));
      errors.push("Address 1 is required.");
    }
    if (city === "") {
      setvalidaion((prev) => ({ ...prev, cityvalidation: true }));
      errors.push("City is required.");
    }
    if (secondpinCode === "" || secondpinCode.length !== 6) {
      setSecondValidation((prev) => ({
        ...prev,
        secondMPinValidation: true,
      }));
      errors.push("Second Pin Code must be 6 digits.");
    }
    if (secondstate === "") {
      setSecondValidation((prev) => ({
        ...prev,
        secondstatevalidation: true,
      }));
      errors.push("Second State is required.");
    }
    if (secondaddress1 === "") {
      setSecondValidation((prev) => ({
        ...prev,
        secondMAdd1validation: true,
      }));
      errors.push("Second Address 1 is required.");
    }
    if (secondcity === "") {
      setSecondValidation((prev) => ({
        ...prev,
        secondcityvalidation: true,
      }));
      errors.push("Second City is required.");
    }
    if (companyName === "") {
      setvalidaion((prev) => ({
        ...prev,
        companyNameValidation: true,
      }));
      errors.push("Company Name is required.");
    }
    if (factoryName === "") {
      setvalidaion((prev) => ({
        ...prev,
        factoryNameValidation: true,
      }));
      errors.push("Factory Name is required.");
    }
  
    // If there are any errors, set the error messages and show the modal
    if (errors.length > 0) {
      setErrorMessages(errors); // Set collected error messages
      setShowModal(true); // Show the modal
      return; // Exit the function
    }
  


      return;
  }

  if (formattedAlterPhoneNumber !== "") {
      if (formattedAlterPhoneNumber.length !== 10) {
          setvalidaion((prev) => ({ ...prev, alterphovalidation: true }));
          return;
      }
  }
      var id;
      var customer_id;
      if (boldtitle === "Edit Address") {
        id = AddressId;
        customer_id = localStorage.getItem("id");
        console.log("Edit Address Mode, ID:", id, "Customer ID:", customer_id);
      } else {
        id = localStorage.getItem("id");
        console.log("New Address Mode, ID:", id);
      }
     // Prepare form data based on the mode
     const formdata = boldtitle === "Edit Address"
     ? {
         input: {
             city: city,
             cityArea: "",
             companyName: companyName,
             country: "IN",
             countryArea: state,
             firstName: firstname,
             lastName: lastname,
             phone: formattedPhoneNumber,
             postalCode: pinCode,
             streetAddress1: address1,
             streetAddress2: address2
         },
         businessaddress: {
             city: secondcity,
             cityArea: "",
             companyName: companyName,
             country: "IN",
             countryArea: secondstate,
             firstName: firstname,
             lastName: lastname,
             phone: formattedPhoneNumber,
             postalCode: secondpinCode,
             streetAddress1: secondaddress1,
             streetAddress2: secondaddress2
         },
         gst_no: secondgstno,
         factoryName: factoryName,
         mp_address_id: AddressId 
     }
     : {
         id: id,
         input: {
             city: city,
             cityArea: "",
             companyName: companyName,
             country: "IN",
             countryArea: state,
             firstName: firstname,
             lastName: lastname,
             phone: formattedPhoneNumber,
             postalCode: pinCode,
             streetAddress1: address1,
             streetAddress2: address2
         },
         businessaddress: {
             city: secondcity,
             cityArea: "",
             companyName: companyName,
             country: "IN",
             countryArea: secondstate,
             firstName: firstname,
             lastName: lastname,
             phone: formattedPhoneNumber,
             postalCode: secondpinCode,
             streetAddress1: secondaddress1,
             streetAddress2: secondaddress2
         },
         gst_no: secondgstno,
         factoryName: factoryName
     };

    
      if (boldtitle === "Edit Address") {
        formdata.customer_id = customer_id;
      }
      
      console.log("Form Data to Submit:", formdata);
    
      createCustomerAddress(formdata);
      

     
      setLoadinged(true);
      setTimeout(() => {
        navigate("/manageaddress");
    }, 2000); 
    
    
   
    };
    useEffect(() => {
      if (location.state.edit_address) {
        console.log("Location state has edit_address:", location);
        setfirstname(location.state.edit_address?.firstName);
        setlastname(location.state.edit_address?.lastName);
        const phoneNumber = location.state.edit_address?.phone;
        const truncatedPhoneNumber = phoneNumber && phoneNumber.startsWith("+91")
        ? phoneNumber.substring(3)
        : phoneNumber || "";  // Fallback to an empty string or handle undefined value gracefully
      
      setPhoneNumber(truncatedPhoneNumber);
      
        console.log("Now truncated Phone number is:", truncatedPhoneNumber);
        setCity(location.state.edit_address?.city);
        setState(location.state.edit_address?.countryArea);
        setPinCode(location.state.edit_address?.postalCode);
        setAddress1(location.state.edit_address?.streetAddress1);
        setAddress2(location.state.edit_address?.streetAddress2);
  
        // Add second address details here
        secondsetPinCode(location.state.edit_address?.secondpinCode);
        secondsetState(location.state.edit_address?.secondstate);
        secondsetAddress1(location.state.edit_address?.secondaddress1);
        secondsetAddress2(location.state.edit_address?.secondaddress2);
        secondsetCity(location.state.edit_address?.secondcity);
        secondsetgstno(location.state.edit_address?.secondgstno);
      }
    }, []);
    
    const getLocation = async () => {
  
      try {
        if (navigator.geolocation) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          const { latitude, longitude } = position.coords;
          const response = await fetch(`https://ompextension.origa.market/api/getlatlngpincode`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ latitude, longitude }),
          });
  
          const responseData = await response.json();
          setPinCode(responseData?.postal_code)
          setState(responseData?.state)
          setCity(responseData?.city)
          //setFormData((prevData) => ({ ...prevData, pin: responseData?.postal_code }));
          //setFormData(...formData,formData.pin = responseData?.postal_code)
          //setDeliveryLocation(responseData?.postal_code)
          console.log("API response:", responseData?.postal_code)
  
          // Handle response data here
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error getting location:', error.message);
      }
    };

    const getLocationpart = async () => {
  
      try {
        if (navigator.geolocation) {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          const { latitude, longitude } = position.coords;
          const response = await fetch(`https://ompextension.origa.market/api/getlatlngpincode`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ latitude, longitude }),
          });
  
          const responseData = await response.json();
          secondsetPinCode(responseData?.postal_code)
          secondsetState(responseData?.state)
          secondsetCity(responseData?.city)
          //setFormData((prevData) => ({ ...prevData, pin: responseData?.postal_code }));
          //setFormData(...formData,formData.pin = responseData?.postal_code)
          //setDeliveryLocation(responseData?.postal_code)
          console.log("API response:", responseData?.postal_code)
  
          // Handle response data here
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error('Error getting location:', error.message);
      }
    };
  
    return (<>
       {loadinged && <Loader />}
      <div className='container-fluid'>
      
        <div className='max-container pt-5'>
          <div className='container-fluid p-0 m-0 row pb-4'>
  
            <div className={`col col-md-10 col-12 ${isSmallScreen ? "" : "pl-5 pr-5"} bg-F9F9F9 mx-auto`}>
              <div className='container pt-5 pb-5'>
                <div className='row'>
                  <div className='col heading-600-24 p-0'>{boldtitle}</div>
                </div>

                  {/* Fourth Section */}
                  <div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading="Company Details" para="Please share the company name and factory name" />
                  </div>
                  <div className="col col-lg-7 col-12 p-0">
                    <div className="row">
                      <div className="col col-12">
                        <div className={`bi-form-group ${validation.companyNameValidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="company-name"
                            id="company-name"
                            className={`bi-form-field bg-white ${validation.companyNameValidation ? "error-red" : ""}`}
                            value={companyName}
                            onChange={onCompanyNameChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, companyNameValidation: false }))}
                            placeholder="Company Name"
                          />
                          <label htmlFor="company-name" className="heading-400-14-12 bi-form-label">
                            Company Name{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>

                      <div className="col col-12">
                        <div className={`bi-form-group ${validation.factoryNameValidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="factory-name"
                            id="factory-name"
                            className={`bi-form-field bg-white ${validation.factoryNameValidation ? "error-red" : ""}`}
                            value={factoryName}
                            onChange={onfactoryNameChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, factoryNameValidation: false }))}
                            placeholder="Factory Name"
                          />
                          <label htmlFor="factory-name" className="heading-400-14-12 bi-form-label">
                            Factory Name{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>

                      <div className="col col-12">
                        <div className={`bi-form-group ${secondvalidation.secondgstnovalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="gstno"
                            id="gstno"
                            className={`bi-form-field bg-white ${secondvalidation.secondgstnovalidation ? "error-red" : ""}`}
                            value={secondgstno}
                            onChange={onSecondGSTChange}
                            onClick={() => setSecondValidation((prev) => ({ ...prev, secondgstnovalidation: false }))}
                            placeholder="GST No"
                          />
                          <label htmlFor="gstno" className="heading-400-14-12 bi-form-label">
                            GST No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* First Section */}
                <div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading="Point of Contact" para="We'd love to get in touch with you! Kindly share your contact details, and we'll reach out to discuss your machine" />
                  </div>
                  <div className="col col-lg-7 col-12 p-0">
                    <div className="row">
                      <div className="col col-md-6 col-12">
                        <div className={`bi-form-group ${validation.fvalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="fname"
                            id="fname"
                            className={`bi-form-field bg-white ${validation.fvalidation ? "error-red" : ""}`}
                            placeholder="First Name"
                            value={firstname}
                            onChange={onFirstnameChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, fvalidation: false }))}
                          />
                          <label htmlFor="fname" className="heading-400-14-12 bi-form-label">
                            First Name{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                       
      
                      </div>
                      <div className="col col-md-6 col-12">
                        <div className={`bi-form-group ${validation.lvalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="lname"
                            id="lname"
                            className={`bi-form-field bg-white ${validation.lvalidation ? "error-red" : ""}`}
                            placeholder="Last Name"
                            value={lastname}
                            onChange={onLastnameChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, lvalidation: false }))}
                          />
                          <label htmlFor="lname" className="heading-400-14-12 bi-form-label">
                            Last Name{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col col-md-6 col-12">
                        <div className={`bi-form-group ${validation.pvalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            className={`bi-form-field bg-white ${validation.pvalidation ? "error-red" : ""}`}
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={onPhoneNumberChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, pvalidation: false }))}
                          />
                          <label htmlFor="phone" className="heading-400-14-12 bi-form-label">
                            Phone Number{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-md-6 col-12">
                        <div className={`bi-form-group ${validation.alterphovalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="altphone"
                            id="altphone"
                            className={`bi-form-field bg-white ${validation.alterphovalidation ? "error-red" : ""}`}
                            placeholder="Alternative Phone Number"
                            value={alterPhoneNumber}
                            onChange={onAlterPhoneNumberChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, alterphovalidation: false }))}
                          />
                          <label htmlFor="altphone" className="heading-400-14-12 bi-form-label">
                            Alternative Phone Number
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Shipping Address Section */}
                <div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading="Shipping Address" para="Please share the address where you'd like your machine to be delivered" />
                  </div>
                  <div className="col col-lg-7 col-12 p-0">
                    <div className="row">
                      <div className="col col-12">
                        <div className={`bi-form-group ${secondvalidation.secondMAdd1validation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${secondvalidation.secondMAdd1validation ? "error-red" : ""}`}
                            value={secondaddress1}
                            onChange={SecondonAddressOneChange}
                            onClick={() => setSecondValidation((prev) => ({ ...prev, secondMAdd1validation: false }))}
                            placeholder="Address Line 1"
                          />
                          <label htmlFor="machine-add1" className="heading-400-14-12 bi-form-label">
                            Address Line 1{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group `}>
                          <input
                            type="text"
                            name="machine-add2"
                            id="machine-add2"
                            className={`bi-form-field bg-white`}
                            value={secondaddress2}
                            onChange={SecondonAddressTwoChange}
                            placeholder="Address Line 2"
                          />
                          <label htmlFor="machine-add2" className="heading-400-14-12 bi-form-label">
                            Address Line 2
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group  ${secondvalidation.secondMPinValidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            className={`bi-form-field bg-white ${secondvalidation.secondMPinValidation ? "error-red" : ""}`}
                            value={secondpinCode}
                            onChange={onSecondPinCodeChange}
                            onClick={() => setSecondValidation((prev) => ({ ...prev, secondMPinValidation: false }))}
                            placeholder="Pin Code"
                          />
                          <label htmlFor="pincode" className="heading-400-14-12 bi-form-label">
                            Pin Code{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                          <div onClick={getLocationpart} className="text-end p-r heading-600-14 heading-600-14-12 curser-pointer">
                            Use My Current Location
                            <span style={{ position: "absolute", top: "-40px", right: "0" }}>
                              {locationIcon({ width: 32, height: 32 })}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group ${secondvalidation.secondstatevalidation ? "error-red" : ""}`}>
                          <select
                            className={`bi-form-field bi-select-dropdown ${secondstate ? "" : "empty"}`}
                            value={secondstate}
                            placeholder="state"
                            onChange={(e) => onSecondStateChange(e.target.value)}
                            autoCapitalize="off"
                          >
                            <option value=""></option>
                            <option value="ANDHRA PRADESH">Andhra Pradesh</option>
<option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
<option value="ASSAM">Assam</option>
<option value="BIHAR">Bihar</option>
<option value="CHHATTISGARH">Chhattisgarh</option>
<option value="GOA">Goa</option>
<option value="GUJARAT">Gujarat</option>
<option value="HARYANA">Haryana</option>
<option value="HIMACHAL PRADESH">Himachal Pradesh</option>
<option value="JHARKHAND">Jharkhand</option>
<option value="KARNATAKA">Karnataka</option>
<option value="KERALA">Kerala</option>
<option value="MADHYA PRADESH">Madhya Pradesh</option>
<option value="MAHARASHTRA">Maharashtra</option>
<option value="MANIPUR">Manipur</option>
<option value="MEGHALAYA">Meghalaya</option>
<option value="MIZORAM">Mizoram</option>
<option value="NAGALAND">Nagaland</option>
<option value="ODISHA">Odisha</option>
<option value="PUNJAB">Punjab</option>
<option value="RAJASTHAN">Rajasthan</option>
<option value="SIKKIM">Sikkim</option>
<option value="TAMIL NADU">Tamil Nadu</option>
<option value="TELANGANA">Telangana</option>
<option value="TRIPURA">Tripura</option>
<option value="UTTAR PRADESH">Uttar Pradesh</option>
<option value="UTTARAKHAND">Uttarakhand</option>
<option value="WEST BENGAL">West Bengal</option>

                          </select>
                          <label className="heading-400-14-12 bi-form-label">
                            State{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group ${secondvalidation.secondcityvalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`bi-form-field bg-white ${secondvalidation.secondcityvalidation ? "error-red" : ""}`}
                            value={secondcity}
                            onChange={onSecondCityChange}
                            onClick={() => setSecondValidation((prev) => ({ ...prev, secondcityvalidation: false }))}
                            placeholder="City"
                          />
                          <label htmlFor="city" className="heading-400-14-12 bi-form-label">
                            City{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className=" d-flex align-items-center mt-4">
                      <input
                            className="form-check-input "
                            type="checkbox"
                            id="billingSameAsShipping"
                            checked={isBillingSameAsShipping}
                            // onClick={handleBillingSameAsShipping}
                            onChange={handleBillingSameAsShipping}
                          />
                        &nbsp;
                        <span className='billingaddresssameas '>
                        Billing address same as shipping address
                          </span></div>



                      
                      
                    </div>
                  </div>
                </div>

                {/* Billing Address Section */}
                <div className="row pb-5 d-flex justify-content-between border-bottom">
                  <div className="col col-lg-4 col-12 pl-0 pt-30px">
                    <MALeftsection heading="Billing Address" para="Please share the address where you'd like your billing information to be sent" />
                  </div>
                  <div className="col col-lg-7 col-12 p-0">
                    <div className="row">
                     
                      <div className="col col-12">
                        <div className={`bi-form-group ${validation.MAdd1validation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="machine-add1"
                            id="machine-add1"
                            className={`bi-form-field bg-white ${validation.MAdd1validation ? "error-red" : ""}`}
                            value={address1}
                            onChange={onAddressOneChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, MAdd1validation: false }))}
                            placeholder="Address Line 1"
                          />
                          <label htmlFor="machine-add1" className="heading-400-14-12 bi-form-label">
                            Address Line 1{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group `}>
                          <input
                            type="text"
                            name="machine-add2"
                            id="machine-add2"
                            className={`bi-form-field bg-white`}
                            value={address2}
                            onChange={onAddressTwoChange}
                            placeholder="Address Line 2"
                          />
                          <label htmlFor="machine-add2" className="heading-400-14-12 bi-form-label">
                            Address Line 2
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group  ${validation.MPinValidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            className={`bi-form-field bg-white ${validation.MPinValidation ? "error-red" : ""}`}
                            value={pinCode}
                            onChange={onPinCodeChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, MPinValidation: false }))}
                            placeholder="Pin Code"
                          />
                          <label htmlFor="pincode" className="heading-400-14-12 bi-form-label">
                            Pin Code{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                          <div onClick={getLocation} className="text-end p-r heading-600-14 heading-600-14-12 curser-pointer">
                            Use My Current Location
                            <span style={{ position: "absolute", top: "-40px", right: "0" }}>
                              {locationIcon({ width: 32, height: 32 })}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group ${validation.statevalidation ? "error-red" : ""}`}>
                          <select
                            className={`bi-form-field bi-select-dropdown ${state ? "" : "empty"}`}
                            value={state}
                            placeholder="state"
                            onChange={(e) => onStateChange(e.target.value)}
                            autoCapitalize="off"
                          >
                            <option value=""></option>
                            <option value="ANDHRA PRADESH">Andhra Pradesh</option>
<option value="ARUNACHAL PRADESH">Arunachal Pradesh</option>
<option value="ASSAM">Assam</option>
<option value="BIHAR">Bihar</option>
<option value="CHHATTISGARH">Chhattisgarh</option>
<option value="GOA">Goa</option>
<option value="GUJARAT">Gujarat</option>
<option value="HARYANA">Haryana</option>
<option value="HIMACHAL PRADESH">Himachal Pradesh</option>
<option value="JHARKHAND">Jharkhand</option>
<option value="KARNATAKA">Karnataka</option>
<option value="KERALA">Kerala</option>
<option value="MADHYA PRADESH">Madhya Pradesh</option>
<option value="MAHARASHTRA">Maharashtra</option>
<option value="MANIPUR">Manipur</option>
<option value="MEGHALAYA">Meghalaya</option>
<option value="MIZORAM">Mizoram</option>
<option value="NAGALAND">Nagaland</option>
<option value="ODISHA">Odisha</option>
<option value="PUNJAB">Punjab</option>
<option value="RAJASTHAN">Rajasthan</option>
<option value="SIKKIM">Sikkim</option>
<option value="TAMIL NADU">Tamil Nadu</option>
<option value="TELANGANA">Telangana</option>
<option value="TRIPURA">Tripura</option>
<option value="UTTAR PRADESH">Uttar Pradesh</option>
<option value="UTTARAKHAND">Uttarakhand</option>
<option value="WEST BENGAL">West Bengal</option>

                          </select>
                          <label className="heading-400-14-12 bi-form-label">
                            State{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                      <div className="col col-12">
                        <div className={`bi-form-group ${validation.cityvalidation ? "error-red" : ""}`}>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className={`bi-form-field bg-white ${validation.cityvalidation ? "error-red" : ""}`}
                            value={city}
                            onChange={onCityChange}
                            onClick={() => setvalidaion((prev) => ({ ...prev, cityvalidation: false }))}
                            placeholder="City"
                          />
                          <label htmlFor="city" className="heading-400-14-12 bi-form-label">
                            City{<span style={{ color: "#CB1923" }}>*</span>}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className={`buttontwoinaddresspage gap-4 pt-4`}>
                      <Button2 message={"Save"} callFunction={onSubmitHandler} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
  
          <div className='p-r pb-5 d-flex justify-content-end'>
            <img className='p-a pt-2 right-0' src='asset/Frame1000004018.png' alt='Frame1000004018.png' />
          </div>
        </div>
  
      </div>
      <AccountFooter />

      {showModal && (
  <ErrorModal
    messages={errorMessages}
    onClose={() => {
      setShowModal(false);
      setErrorMessages([]); // Clear messages when modal is closed
    }}
  />
)}


    </>
    )
  }


export default MyAccAddAddress