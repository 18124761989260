import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { secondClient, GET_SPARE_DETAILS } from "../../../OrigaExtentionAPI/mutations";
import './QuotationPopupStyles.css';

const client = secondClient;

const QuotationPopup = ({ quantity, setQuantity, onSubmit, productDetails }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  const [infokey, setInfokey] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { name, price, image,priceList } = productDetails;
  console.log("Name in Quotation Popup : ",productDetails?.name);
  console.table("productDetails priceList in Quotation Popup : ",productDetails?.priceList);
  const groupId = queryParams.get("group_id");
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productDETAILS, setProductDETAILS] = useState({});
  const [endQuantity, setEndQuantity] = useState();
  const [clickedPriceItem, setClickedPriceItem] = useState(null);
  const [bucketPrice, setBucketPrice] = useState(null);
  const [bucketSize, setBucketSize] = useState(null);
  const [bucketId, setBucketId] = useState(null); 
  const [isBarrel, setIsBarrel] = useState(false);
  const [barrelQuantity, setBarrelQuantity] = useState(1);
  const [bucketQuantity, setBucketQuantity] = useState(1);
  const [displayText, setDisplayText] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

        const [selectedMinPrice, setSelectedMinPrice] = useState(null); 
        const [selectedMaxPrice, setSelectedMaxPrice] = useState(null); 
        const [selectedPriceRange, setSelectedPriceRange] = useState(null);


  useEffect(() => {
      if (productDETAILS && infokey !== undefined) {
          const product = productDETAILS[infokey];
          setSelectedProduct(product);
          if (product && Array.isArray(product.price_list) && product.price_list.length > 0) {
              setClickedPriceItem(0);
              setBucketPrice(product.price_list[0].price);
              setBucketSize(product.price_list[0].bucket_size);
              setBucketId(product.price_list[0].bucket_id); // Initialize bucketId
              setSelectedMinPrice(product?.price_list[0]?.min_price)
              setSelectedMaxPrice(product?.price_list[0]?.max_price);
          } else {
              setClickedPriceItem(null);
              setBucketPrice(null);
              setBucketSize(null);
              setBucketId(null);
          }
      }
  }, [productDETAILS, infokey]);
 
const handlePriceClick = (priceItem, index) => {
  setClickedPriceItem(index); // Set the clicked price item
  setBucketPrice(priceItem.price);
  setBucketSize(priceItem.bucket_size);

  console.log("priceItem.min_price : ",priceItem.min_price);
  console.log("priceItem.max_price : ",priceItem.max_price);
  setSelectedMinPrice(priceItem.min_price); 
  setSelectedMaxPrice(priceItem?.max_price);


  const isPriceRange = priceItem?.min_price !== priceItem?.max_price;
  setSelectedPriceRange(isPriceRange);

  // console.log("Bucket Type : ", priceItem.bucket_size);
  // console.log("Bucket Type : ",bucketSize);

  const isBarrel = priceItem.bucket_size === 210 || priceItem.bucket_size === 180;

  setIsBarrel(isBarrel);

  if (isBarrel) {
    setBarrelQuantity(1); // Initialize barrel quantity to 1
    setTotalPrice(priceItem.price * priceItem.bucket_size);
  } else {
    const newQuantity = priceItem.start_quantity;
    setBucketQuantity(newQuantity);
    setSelectedQuantity(newQuantity);

    const newTotalPrice =
      newQuantity * priceItem.price * (priceItem.bucket_size || 1);
    setTotalPrice(newTotalPrice);
  }


  setEndQuantity(priceItem.end_quantity);

  // Log the details including the bucket_id for the selected priceItem
  console.log(
    `%cDetails:
    %c- Index:%c ${index} 
    %c- Selected Bucket ID:%c ${priceItem.bucket_id} 
    %c- Bucket Price:%c ₹${priceItem.price} 
    %c- Bucket Size:%c ${priceItem.bucket_size}L 
    %c- MRP:%c ₹${priceItem.mrp}`,
    "color: #3498db; font-weight: bold;", // Header style
    "color: #2ecc71; font-weight: bold;", // Label style for Index
    "color: #27ae60;", // Value style for Index
    "color: #f39c12; font-weight: bold;", // Label style for Bucket ID
    "color: #e74c3c;", // Value style for Bucket ID
    "color: #f39c12; font-weight: bold;", // Label style for Bucket Price
    "color: #d35400;", // Value style for Bucket Price
    "color: #9b59b6; font-weight: bold;", // Label style for Bucket Size
    "color: #8e44ad;", // Value style for Bucket Size
    "color: #e74c3c; font-weight: bold;", // Label style for MRP
    "color: #c0392b;" // Value style for MRP
  );
};

    
      useEffect(() => {
        if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
        
          const applicablePriceItem = selectedProduct.price_list.find(
            (
              item 
            ) =>
              selectedQuantity >= item.start_quantity && 
              (item.end_quantity === null || selectedQuantity <= item.end_quantity)
          );
    
          if (applicablePriceItem) {
            setBucketPrice(applicablePriceItem.price); 
            setBucketSize(applicablePriceItem.bucket_size);
            setEndQuantity(applicablePriceItem.end_quantity);
            setClickedPriceItem(
              selectedProduct.price_list.indexOf(applicablePriceItem)
            ); 
          }
        }
      }, [selectedQuantity, selectedProduct]);
    const renderPriceList = () => {
      if (selectedProduct && Array.isArray(selectedProduct.price_list)) {
        return (
          <div className="renderPriceListinRaiseForQuotationPopup">
            {selectedProduct.price_list.length > 0 ? (
              selectedProduct.price_list.map((priceItem, index) => (
                <button
                  key={index}
                  className={`buttonclickednodvjbktorforqutotation ${
                    clickedPriceItem === index ? "btn-clicked" : ""
                  }`}
                  onClick={() => handlePriceClick(priceItem, index)}
                >
                  {priceItem.price_label && (<>
                    <span className="priceItemLabel">{priceItem.price_label}</span><br></br>
                  </>)} 


            {priceItem.price && (
              <>
                <span
                  className="offerrrr"
                  style={{
                    color: clickedPriceItem === index ? "#58cb58" : "#58cb58",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {Math.ceil(
                    ((priceItem.mrp - priceItem.price) / priceItem.mrp) * 100
                  )}
                  % off
                </span>
                <br />
              </>
            )}
               {priceItem.price  && (<> ₹{priceItem.price}/Ltr                   <br /> </>)}
                {priceItem.mrp && (<>
                  <span
                 className={`text-xs transition-colors duration-200 ${
                  clickedPriceItem === index || hoveredIndex === index ? "text-white" : "text-gray-500"
                }`}
                >
                  <s>₹{priceItem.mrp}/Ltr</s>
                </span>
                </>)}

                {priceItem?.min_price && priceItem?.max_price && (
                <>
                ₹{priceItem.min_price}{/*Always show the min price first*/}
                {priceItem.min_price !== priceItem.max_price ? `-₹${priceItem.max_price}`:""}/Ltr
                {/*If min price is not equal to max price then show -  ₹max price 
                   If they are same , it adds nothing just show min_price*/}
                </>
            )}
              </button>
              ))
            ) : (
              <p>No price list available</p>
            )}
          </div>
        );
      }
      return <p>Loading...</p>;
    };

  useEffect(() => {
      window.scrollTo(0, 0);
      const fetchData = async () => {
          let id = localStorage.getItem("id");
          if (id === null) {
              id = "";
          }
          try {
                     const pincodefromdel = localStorage.getItem("deliveryLocation");
                     const pincodefromdeliveryLocation = pincodefromdel ? pincodefromdel : "";
                     const { data } = await client.query({
                              query: GET_SPARE_DETAILS,
                              variables: { groupId: groupId || "", itemId: productId || "",pincode:pincodefromdeliveryLocation },
                            });
              setProductDETAILS(data?.inventoryItem?.response);
              setLoading(false);
              updateFormattedPrice();
          } catch (error) {}
      };
      fetchData();
  }, [groupId, productId]);

  const updateFormattedPrice = () => {
      setProductDETAILS((prevDetails) => ({
          ...prevDetails,
          price_details: {
              ...prevDetails.price_details,
              machine_price: formatCurrency(prevDetails.sales_rate),
          },
      }));
  };

  const formatCurrency = (value) => {
      const fractionDigits = value % 1 !== 0 ? 2 : 0;
      return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
      }).format(value || 0);
  };


  const getBucketById = (quantity, bucketSize) => {
    console.log("Now entering in getbucketById:");
  
    let bucketId = null;
    let productType = null;
  
    // Check for Barrel type with specific bucket sizes
    if ([210, 180].includes(bucketSize)) {
      console.log("Checking for Barrel with bucket size:", bucketSize); // Log bucketSize
      const applicablePriceItem = priceList.find((item) => {
        console.log("Checking item:", item);
        return (
          item.qty_type === "Barrel" &&
          bucketSize === item.bucket_size
        );
      });
  
      if (applicablePriceItem) {
        bucketId = applicablePriceItem.bucket_id; // Get the barrel bucket ID
        productType = "Barrel";
        console.log("Bucket Id:", bucketId);
        console.log("Product Type:", productType);
      } else {
        console.log("No matching barrel price item found for the current quantity.");
      }
    } else {
      // Check for Bucket type with quantity range
      console.log("Checking for Bucket with bucket size:", bucketSize); // Log bucketSize
      const applicablePriceItem = priceList.find((item) => {
        console.log("Checking item:", item);
        return (
          item.qty_type === "Bucket" &&
          quantity >= item.start_quantity &&  // Ensure quantity falls within the range
          (item.end_quantity === null || quantity <= item.end_quantity)  // Check for null end_quantity
        );
      });
  
      if (applicablePriceItem) {
        bucketId = applicablePriceItem.bucket_id; // Get the bucket bucket ID
        productType = "Bucket";
        console.log("Bucket Id:", bucketId);
        console.log("Product Type:", productType);
      } else {
        console.log("No matching bucket price item found for the current quantity.");
      }
    }
  
    return { bucketId, productType };
  };
  
  const handlePopupSubmit = () => {
    console.log("Now clicked Submit in The Quotation Popup ");
    console.log("Product Id in Quotation Popup", productDetails.id);
    console.log("Quantity in Quotation Popup", selectedQuantity);
    console.log("Bucket id in Quotation Popup", bucketId);
  
    // Get the bucketId and productType based on the selected quantity and bucketSize
    const { bucketId: newBucketId, productType } = getBucketById(selectedQuantity, bucketSize);
    console.log("newBucketId:", newBucketId);
  
    // Call onSubmit with the proper values
    onSubmit({
      productId: productDetails.id,
      quantity: selectedQuantity,
      bucketId: newBucketId, // Pass the newly obtained bucketId
      bucketSize: bucketSize  // Pass the bucket size as is
    });
  };
  


  

  
    const handleQuantityChange = (increment) => {
      console.log("Handle quantity change triggered");
      console.log("Bucket Quantity", bucketQuantity);
      console.log("Barrel Quantity", barrelQuantity);
  
      if (isBarrel) {
        // Check if it is barrel mode
        console.log("In barrel mode : ", isBarrel);
  
        setBarrelQuantity((prev) => {
          const newQuantity = Math.min(Math.max(prev + increment, 1), 11);
          console.log(`Barrel quantity updated from ${prev} to ${newQuantity}`);
  
          // Calculate total price for barrel
          const barrelPriceItem = selectedProduct.price_list.find(
            (item) => item.bucket_size === 210 || item.bucket_size === 180 
          );
  
          if (barrelPriceItem) {
            const totalPrice = barrelPriceItem.price * newQuantity;
            console.log(`Total price for barrel calculated as: ${totalPrice}`);
            setTotalPrice(totalPrice);
          }
  
          return newQuantity;
        });
      } else {
        // If it is bucket mode
        console.log("In bucket mode : ", isBarrel);
        setBucketQuantity((prevQuantity) => {
          let newQuantity = Math.max(prevQuantity + increment, 1);
  
          console.log(
            `Bucket quantity updated from ${prevQuantity} to ${newQuantity}`
          );
  
          if (newQuantity === 11) {
            console.log("Switching to barrel mode");
            setIsBarrel(true);
            setBucketQuantity(1); // Reset bucketQuantity when switching
            setBarrelQuantity(1); // Initialize barrelQuantity at 1
          }
  
          setSelectedQuantity(newQuantity);
          console.log(`Selected quantity is now: ${newQuantity}`);
  
          const priceItem = selectedProduct.price_list.find(
            (item) =>
              newQuantity >= item.start_quantity &&
              (item.end_quantity === null || newQuantity <= item.end_quantity)
          );
  
          if (priceItem) {
            setBucketPrice(priceItem.price);
            const newTotalPrice =
              newQuantity * priceItem.price * (priceItem.bucket_size || 1);
            setTotalPrice(newTotalPrice);
          }
  
          return newQuantity;
        });
      }
    };

  return (
      <div className="totalpopupdiv">
          <h4 className="quotationDetailsheading">Quotation Details</h4>
          <div className="popupContentWrapper">
              <div className="Contentisnidepopup">
                  <p className="">Name: {name || "N/A"}</p>
                  <p>Price: {bucketPrice ? `₹${bucketPrice} / Ltr` : ""}
                    
                     {selectedMinPrice ? `₹${selectedMinPrice}/Ltr ` : ""} </p>
                  Quantity&nbsp;:&nbsp; {isBarrel ? barrelQuantity : bucketQuantity}{" "}
                  <br />
                  <br />
                  {/* {renderPriceList()}
                  <button
                      className="button-submit-popup"
                      onClick={handlePopupSubmit}
                      // disabled={!bucketId}
                  >
                      Submit
                  </button> */}
              </div>
              <div className="imageWrapper">
                  <img
                      className="imageinpopup"
                      style={{maxHeight:"100px"}}
                      src={image || "N/A"}
                      alt="Product"
                  />
              </div>
          </div>
          <div>
          {renderPriceList()}
          <span style={{display:"flex",fontStyle:"italic",justifyContent:"end",color:"gray",fontWeight:600,paddingTop:"8px"}}>Transport Charges extra *</span>
          
          <div className="buttonsqautntiyf ">
        <div>
          Qty :{" "}
          <button
            className="butttonplusminus "
            onClick={() => handleQuantityChange(-1)}
                disabled={
                  isBarrel ? barrelQuantity <= 1 : bucketQuantity <= 1
                }
          >
            -
          </button>
          &nbsp; {isBarrel ? barrelQuantity : bucketQuantity}{" "} &nbsp;
          <button
            className="butttonplusminus"
            onClick={() => handleQuantityChange(1)}
            disabled={
              isBarrel ? barrelQuantity >= 11 : bucketQuantity >= 11
            }
          >
            +
          </button>
        </div>

     
      </div>

{/* 
{bucketSize} */}



      <div 
      style={{display:"flex",justifyContent:"flex-end"}}>
      <button 

                      className="mt-2 button-submit-popup"
                      onClick={handlePopupSubmit}
                      // disabled={!bucketId}
                  >
                      Submit
                  </button>

      </div>
                 
          </div>
      </div>
  );
};

export default QuotationPopup;

