import React from "react";
import ReactDOM from "react-dom";
import CustomerInforModelPageforMachines from "./CustomerInforModelPageforMachines";
const CustomerInfoModelforMachines = ({ FullUrl, machinename, setCustomerInfoForm ,setShowThanksPopup}) => {
  const portalAddress = document.getElementById("Overlay");
  return ReactDOM.createPortal(
    <CustomerInforModelPageforMachines
      FullUrl={FullUrl}
      machinename={machinename}
      setCustomerInfoForm={setCustomerInfoForm}
      setShowThanksPopup={setShowThanksPopup}
    />,
    portalAddress
  );
};

export default CustomerInfoModelforMachines;
