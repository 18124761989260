import React,{useState,useEffect} from 'react'
import Menu from './Menu/Menu'
import Nav from './Nav/Nav'
import '../../component/Header/Nav/Nav.css'
import NewMenu from './Menu/NewMenu'

const Header = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      // You can adjust this value based on when you want the navbar to become sticky
      const isStickyNow = offset > 0;

      setSticky(isStickyNow);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 500);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // without-sticky-header ${isSticky ? "sticky-header":""} 
  return (
    

   
<>

   <div className={`container-fluid bg-white z-100 `}  style={{ position: "sticky",top: 0,boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.1)",minHeight:"56px"}} >
       <div className="top-banner">
       <a href="tel:+918828099099" className="phone-link">Call Us: +91-8828099099</a>
       </div>
      {/* <div className="max-container p-0" >
         <Nav/>
  
   <NewMenu/>
     </div> */}
          {isScreenSmall ? (
        <div className="mx-2 p-0">
          <Nav />
          <NewMenu />
        </div>
      ) : (
        <div className="mx-5 p-0">
          <Nav />
          <NewMenu />
        </div>
      )}
    </div>
  
  </>
    
//   <div className={`container-fluid bg-white z-100 `}  style={{ position: "sticky",top: 0,boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.1)",minHeight:"56px"}} >
//   <div className="top-banner">
//   <a href="tel:+918828099099" className="phone-link">Call Us: +91-8828099099</a>
//   </div>

//  {isScreenSmall ? (
//     <div className="mx-2 p-0">
//       <Nav />
//       <NewMenu />
//     </div>
//   ) : (
//     <div className="mx-5 p-0">
//       <Nav />
//       <NewMenu />
//     </div>
//   )}
// </div>
  )
}

export default Header


 /* 
 
   <div className={`container-fluid bg-white z-100 `}  style={{ position: "sticky",top: 0,boxShadow:"0px 4px 8px rgba(0, 0, 0, 0.1)",minHeight:"56px"}} >
  <div className="top-banner">
  <a href="tel:+918828099099" className="phone-link">Call Us: +91-8828099099</a>
  </div>
  
  <div className="max-container p-0" > 
  <div className="mx-3 my-3" > 
   <Menu/>
  <div className="mx-5 p-0" >
    <Menu/> 
    <Nav/>
    <NewMenu/>
</div> 

 {isScreenSmall ? (
    <div className="mx-2 p-0">
      <Nav />
      <NewMenu />
    </div>
  ) : (
    <div className="mx-5 p-0">
      <Nav />
      <NewMenu />
    </div>
  )}
</div>
*/