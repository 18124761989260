
import { connectHits } from "react-instantsearch-dom";
import { heartIcon, botIcon, closeIcon } from "../../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
// import CustomPagination from "../CustomPagination";
import React, { useState, useEffect } from "react";
import useWishListAddOrUpdate from "../../../SubComponent/useWishListAddOrUpdate";
import CustomPagination from "../CustomPagination";
import {
  secondClient,
  GET_SPARE_DETAILS,
} from "../../../OrigaExtentionAPI/mutations";

import LoginModel from "../../../Authentication/LoginModel/LoginModel";
import Loader from "../../../SubComponent/Loader";
import QuotationPopupForInSpare from "./QuotationPopupForInSpare";
import QuotationPopupForSpareAddtoCart from "./QuotationPopupForSpareAddtoCart";
import "../../Product/SparePage/ViewProduct.css";
import aa from "search-insights";
import {
  ALGOLIA_ID,
  ALGOLIA_KEY,
  ALGOLIA_SELLER_INDEX,
  ALGOLIA_SPARE_INDEX,
} from "../../../../constants";
import { sendAlgoliaEvent } from "search-insights";
import { v4 as uuidv4 } from "uuid";
//----------------------------------------Showing Information Of the Product Based On Result Hits-------------------------------------------------------

const client = secondClient;
const SpareHitNotWithDoubleAPIS = ({
  loading,
  setLoading,
  hits,
  toggleGridforthree,
  toggleGridforfour,
  Sorting,
  query,
  deliveryAvailable,
  isGridView,
  isGradeSelected,
  isIndustrySelected,
  isCategorySelected,
  sendEvent,
  S,
}) => {
  const [productDETAILS, setProductDETAILS] = useState({});

  const [successMessage, setSuccessMessage] = useState(null);

  // console.log("deliveryAvailable in SpareHitNotWithDoubleAPIS.jsx : ",deliveryAvailable)

  const [
    QuotationPopupforAddtoCartinSpare,
    setQuotationPopupforAddtoCartinSpare,
  ] = useState();

  const closePopup4 = () => {
    setQuotationPopupforAddtoCartinSpare(false);
    navigate("/store");
  };

  const closePopup5 = () => {
    setSuccessMessage(false);
    navigate("/store");
  };

  const [quantity, setQuantity] = useState(1);
  const loggedin = localStorage.getItem("userToken");
  const queryParams = new URLSearchParams();
  const [loginPortal, setLoginPortal] = useState(false);
  const productId = queryParams.get("id");
  const groupId = queryParams.get("group_id");
  const [buymachineId, setBuymachineId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [infokey, setinfokey] = useState(0);

  const [QuotationPopupforBuyNowinSpare, setQuotationPopupforBuyNowinSpare] =
    useState();
  const closePopup2 = () => {
    setQuotationPopupforBuyNowinSpare(false);
    navigate("/store");
  };

  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //     const fetchData = async () => {
  //       let id = localStorage.getItem("id");
  //       if (id === null) {
  //         id = "";
  //       }
  //       try {

  //         const { data } = await client.query({
  //           query: GET_SPARE_DETAILS,
  //           variables: { groupId: groupId || "", itemId: productId || "" },
  //         });

  //         setProductDETAILS(data?.inventoryItem?.response);

  //         setLoading(false);

  //         updateFormattedPrice();
  //       } catch (error) {}
  //     };
  //     fetchData();
  //   }, [groupId, productId]);

  const handleCartNow = async (
    product,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("Entire Product : ", product);
    console.log("position : ", position);
    const producttttnameee = product?.name;
    const productImage = product?.thumbnail || "N/A";
    const productBrand = product.brands || "N/A";
    const productBucketSize = product.bucketsize || "N/A";
    const productPackPrice = product.packprice || 0;
    const productPriceList = product?.price_list || 0;
    const productSalePrice = product?.saleprice || 0;
    const productUnits = product?.unit || "N/A";
    console.log("Inside handleCartNow - productUnits:", productUnits);
    console.log("Inside handleCartNow - productIdtoCart:", productIdtoCart);
    console.log("Inside handleCartNow - productName:", salePrice);
    console.log("Inside handleCartNow - productBrand:", productBrand);
    console.log("Inside handleCartNow - productBucketSize:", productBucketSize);
    console.log("Inside handleCartNow - productPackPrice:", productPackPrice);
    console.log("Inside handleCartNow - productPackPrice:", productSalePrice);

    setQuotationPopupforAddtoCartinSpare(true);

    console.log("Inside handleCartNow - setting product details");
    setProductDETAILS({
      id: productIdtoCart,
      name: producttttnameee,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list: productPriceList,
      productSalePrice: productSalePrice,
      productUnits: productUnits,
      queryID: queryID,
      position: position,
    });
  };

  const handleBuyNow = async (
    product,
    position,
    queryID,
    productIdtoCart,
    productName,
    salePrice,
    productThumbnail
  ) => {
    console.log("Inside handleBuyNow - id ", product?.objectID);
    console.log("Inside handleBuyNow - productIdtoCart", productIdtoCart);
    console.log("Inside handleBuyNow ", product);
    console.log("Inside handleBuyNow - productName:", product?.productName);

    const productImage = product?.thumbnail || "N/A";
    console.log("Inside handleBuyNow - productImage:", productImage);
    const productBrand = product?.brands || "N/A";
    const productBucketSize = product?.bucketsize || "N/A";
    const productPackPrice = product?.packprice || 0;
    const productPriceList = product?.price_list || 0;
    const productUnits = product?.unit || "N/A";
    console.log("Inside handleBuyNow - productBrand:", product?.brands);
    console.log(
      "Inside handleBuyNow - productBucketSize:",
      product?.bucketsize
    );
    console.log("Inside handleBuyNow - productPackPrice:", product?.packprice);

    console.log("Opens QuotationPopupForInSpare.jsx file ");
    setQuotationPopupforBuyNowinSpare(true);

    setProductDETAILS({
      id: product?.objectID,
      name: product?.productName,
      image: productImage,
      brands: productBrand,
      packPrice: productPackPrice,
      bcukettSize: productBucketSize,
      price_list: productPriceList,
      productUnits: productUnits,
      queryID: queryID,
      position: position,
    });
  };

  const handleSubmit = async ({
    productId,
    quantity,
    bucketId,
    productName,
    salePrice,
    position,
  }) => {
    console.log(
      "When i click Buy now button in QuotatationPopupForInSpare  , this handleSubmit function is called now "
    );

    console.log("Inside handleSubmit in SpareHitsNewDesign.jsx");
    console.log(
      " Inside handleSubmit in SpareHitsNewDesign.jsx Product ID:",
      productId
    );
    console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx:", quantity);
    console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx:", bucketId);
    console.log(" Inside handleSubmit in SpareHitsNewDesign.jsx", salePrice);

    const loggedin = !!localStorage.getItem("userToken");
    const queryID = localStorage.getItem("queryID");
    // console.log("User logged in:", loggedin);
    // console.log("queryID : ", queryID);
    // console.log("productId : ", productId);
    // console.log("position : ", position);
    const timestamp = Date.now();
    if (queryID) {
      sendEvent("clickedObjectIDs", {
        eventType: "click",
        eventName: "Product Clicked",
        index: "zohoinventory_index",
        objectIDs: [productId],
        userToken: localStorage.getItem("uuidUserToken"),
        queryID: queryID,
        positions: [position],
        timestamp: timestamp,
      });
    } else {
      console.error("Query ID not found. Click event not sent.");
    }

    setQuotationPopupforBuyNowinSpare(false);
    if (!loggedin) {
      console.log("User not logged in, saving pending action to localStorage");
      localStorage.setItem(
        "pendingBuyNow",
        JSON.stringify({
          productId,
          quantity,
          bucketId,
        })
      );
      setLoginPortal(true);
      return;
    }

    console.log("Proceeding with Buy Now");
    console.log("Now we go to Address Page : ");
    await proceedWithBuyNow(productId, quantity, bucketId);
  };

  const proceedWithBuyNow = async (productId, quantity, bucketId) => {
    console.log("Inside proceedWithBuyNow in SpareHits.jsx");
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Product ID:",
      productId
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Quantity:",
      quantity
    );
    console.log(
      "Inside proceedWithBuyNow in SpareHits.jsx Bucket ID:",
      bucketId
    );

    let adjustedQuantity;
    if (quantity % 11 === 0) {
      console.log("Now quantity is being % 11");
      adjustedQuantity = quantity / 11;
    } else {
      console.log("Now quantity is not % 11");
      adjustedQuantity = quantity;
    }

    console.log("adjustedQuantity ", adjustedQuantity);

    const userToken = localStorage.getItem("userToken");
    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");

    const itemList = [
      {
        item_id: productId,
        quantity: adjustedQuantity,
        bucket_id: bucketId,
      },
    ];

    console.log("Item list for API:", itemList);

    console.log(
      "Now calling the api : with orderstatus as buy and hitting the create order api"
    );
    try {
      const response = await fetch(
        "https://ompextension.origa.market/sparesapi/create_order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: itemList,
            ompuserId,
            customer_first_name,
            customer_last_name,
            customer_contact_no,
            order_status: "Buy",
            order_ref_number: "",
            customer_email,
            input: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            businessaddress: {
              city: "",
              cityArea: "",
              companyName: "",
              country: "IN",
              countryArea: "",
              firstName: "",
              lastName: "",
              phone: "",
              postalCode: "",
              streetAddress1: "",
              streetAddress2: "",
            },
            gst_no: "",
          }),
        }
      );

      console.log("Response status:", response.status);
      const responseData = await response.json();
      console.log("Response data:", responseData);

      console.log(
        "now in the above i have sucessfully sent the request with order_status as buy"
      );
      console.log(
        "now get the orderId  etc from the response and send it to the api  "
      );
      const {
        order_id: orderId,
        total_quantity: totalQuantity,
        subtotal: subtotttal,
        tax_total: gst,
        discount_total: taxes,
        shipping_charge: shippingCharges,
        line_items: lineItems,
        total,
      } = responseData;

      console.log("Order ID:", orderId);
      console.log("Total Quantity:", totalQuantity);
      console.log("Subtotal:", subtotttal);
      console.log("GST Amount:", gst);
      console.log("Discount Total:", taxes);
      console.log("Shipping Charges:", shippingCharges);
      console.log("Total Amount:", total);

      // Iterate through line_items to retrieve item details
      lineItems.forEach((item, index) => {
        console.log(`Item ${index + 1}:`);
        console.log("Name:", item.name);
        console.log("Thumbnail URL:", item.thumbnail[0]?.url);
      });

      setLoading(true);

      setTimeout(() => {
        console.log("Navigating to manage address page");

        // Log state values
        console.log("State values being passed:");
        console.log("itemId:", productId);
        console.log("bucketId:", bucketId);
        console.log("quantity:", quantity);
        console.log("orderId:", orderId);
        console.log("quantityTotal:", totalQuantity);
        console.log("subtotalAmount:", subtotttal);
        console.log("gstAmount:", gst);
        console.log("taxesAmount:", taxes);
        console.log("shippingFee:", shippingCharges);
        console.log("totalAmount:", total);

        const itemDetails = lineItems.map((item) => ({
          name: item.name,
          thumbnail: item.thumbnail[0]?.url,
          item_id: item.item_id,
          quantity: item.quantity,
          amount: item.amount,
          rate: item.rate,
          bucket_id: item.bucket_id,
          total: item.total,
          subtotal: item.subtotal,
        }));

        console.log("Item Details:", itemDetails);

        // Set the order status to "buy" and save to localStorage
        const order_Status = "Buy";
        localStorage.setItem("order_Status", order_Status);
        console.log("Order Status set to:", order_Status);

        // Navigate with all required state
        navigate("/manageaddresspart", {
          state: {
            itemId: productId,
            bucketId,
            quantity,
            orderId,
            quantityTotal: totalQuantity,
            subtotalAmount: subtotttal,
            gstAmount: gst,
            taxesAmount: taxes,
            shippingFee: shippingCharges,
            totalAmount: total,
            itemDetails, // Pass the item details array in the state
          },
        });

        window.location.reload();
      }, 400);
    } catch (error) {
      console.error("Error adding item to API cart:", error);
    }
  };

  useEffect(() => {
    const pendingBuyNow = localStorage.getItem("pendingBuyNow");
    // console.log("useEffect - pendingBuyNow:", pendingBuyNow);

    if (pendingBuyNow) {
      const { productId, quantity, bucketId } = JSON.parse(pendingBuyNow);
      console.log("useEffect - Proceeding with pending Buy Now:", {
        productId,
        quantity,
        bucketId,
      });

      proceedWithBuyNow(productId, quantity, bucketId);

      localStorage.removeItem("pendingBuyNow");
      console.log("useEffect - Cleared pendingBuyNow from localStorage");
    }
  }, [loggedin]);

  const navigate = useNavigate();

  const [filteredHitsToRender, setFilteredHitsToRender] = useState(hits);
  const { onWishlistHandler, heartColor } = useWishListAddOrUpdate();

  //   const updateFormattedPrice = () => {
  //     setProductDETAILS((prevDetails) => ({
  //      ...prevDetails,
  //       price_details: {
  //         ...prevDetails.price_details,
  //         machine_price: formatCurrency(prevDetails.sales_rate),
  //       },
  //     }));
  //   };

  //   const formatCurrency = (value) => {
  //     const fractionDigits = value % 1 !== 0 ? 2 : 0;
  //     return new Intl.NumberFormat("en-IN", {
  //       style: "currency",
  //       currency: "INR",
  //       minimumFractionDigits: fractionDigits,
  //       maximumFractionDigits: fractionDigits,
  //     }).format(value || 0);
  //   };

  //   const findYearDifference = (targetYear) => {
  //     const currentYear = new Date().getFullYear();
  //     const yearDifference = currentYear - targetYear;
  //     return yearDifference;
  //   };
  //   const priceConvert = (price) => {
  //     price = typeof price === "string" ? price : String(price);

  //     let count = 1;
  //     let comma = 3;
  //     let formatedPrice = "";
  //     for (let i = price.length - 1; i >= 0; i--) {
  //       formatedPrice = price[i] + formatedPrice;
  //       if (count === comma) {
  //         formatedPrice = "," + formatedPrice;
  //         comma = 2;
  //         count = 0;
  //       }
  //       count++;
  //     }

  //     if (formatedPrice[0] === ",") {
  //       formatedPrice = formatedPrice.slice(1, formatedPrice.length);
  //     }
  //     return formatedPrice;
  //   };

  useEffect(() => {
    let sortedHits = [...hits];

    if (Sorting !== null) {
      sortedHits = [...hits].sort((a, b) => {
        if (Sorting === "Pricelow_High") {
          return a["grossPrice"] - b["grossPrice"];
        } else if (Sorting === "PriceHigh_low") {
          return b["grossPrice"] - a["grossPrice"];
        } else if (Sorting === "AlphabeticallyAtoZ") {
          return a["productName"].localeCompare(b["productName"]);
        }
        return 0;
      });
    }

    setFilteredHitsToRender(sortedHits);
  }, [Sorting, hits]);

  const [showToast, setShowToast] = useState(false);

  const handleSubmitForCart = async ({
    productIded,
    quantityed,
    bucketIded,
    productName,
    salePricess,
    priceList,
    position,
    productNamed,
    productImaged,
    productBranded,
    endQuantity,
  }) => {
    if (!productIded) {
      console.error("Invalid productIded:", productIded);
      return;
    }

    const productbrand = productBranded;
    console.log("Product Name : ", productNamed);
    console.log("product Image", productImaged);
    console.log("product brand", productbrand);
    console.log("salePricess : ", salePricess);

    // Find the matching price item

    let priceItemed;

   
if (quantityed >= 11) {
  console.log("Quantity is 11 or more, selecting Barrel details.");

  // Find the Barrel details from priceList
  priceItemed = priceList.find((item) => item.qty_type === "Barrel");

  if (!priceItemed) {
    console.error("No Barrel pricing found in priceList.");
    return;
  }


} else {
  console.log("Quantity is within the Bucket range, selecting Bucket details.");

  // Find the Bucket details based on quantity range
  priceItemed = priceList.find((item) => 
    item.qty_type === "Bucket" &&
    quantityed >= item.start_quantity &&
    (item.end_quantity === null || quantityed <= item.end_quantity)
  );

  if (!priceItemed) {
    console.error("No matching Bucket pricing found in priceList.");
    return;
  }
}
    const bucketSizeforall = priceItemed.bucket_size;
    const bucketPriceforall = priceItemed.price;
    const bucketIdforall = priceItemed.bucket_id;
    console.log("The productId  : ", productIded);
    console.log("The quantity : ", quantityed);
    console.log("Selected Type:", priceItemed.qty_type);
    console.log("The productName", productNamed);
    console.table(priceList);
    console.log("productbrand : ", productbrand);
    console.log("Bucket Size:", bucketSizeforall);
    console.log("Bucket Price:", bucketPriceforall);
    console.log("Bucket ID normally:", bucketIdforall);

    const priceItem = priceList.find((item) => item?.bucket_id === bucketIded);
    console.log("priceList : ", priceList);
    if (!priceItem) {
      console.error("Price item not found for bucket ID:", bucketIded);
      return;
    }

    // const productType =
    //   bucketSizeforall === 210 OR 180
    //     ? "Barrel"
    //     : bucketSizeforall === 20 OR 18
    //     ? "Bucket"
    //     : "";

    const productType = [210, 180].includes(bucketSizeforall)
      ? "Barrel"
      : [20, 18].includes(bucketSizeforall)
      ? "Bucket"
      : "";

    console.log("productType : ", productType);

    const productIdtoCart = productIded;
    const quantitypricevc = quantityed;
    const bucketPrice = bucketPriceforall;
    const bucketSize = bucketSizeforall;
    const bucketId = bucketIdforall;

    let adjustedQuanteeity = quantitypricevc;

    // If quantitypricevc is greater than 10 and is a multiple of 11 (e.g., 11, 22, 33)
    if (quantitypricevc > 10 && quantitypricevc % 11 === 0) {
      adjustedQuanteeity = quantitypricevc / 11; // This will convert it to 1, 2, 3, etc.
    }

    const totalCartPriceoffff = bucketPrice * bucketSize * adjustedQuanteeity;
    console.log("totalCartPriceoffff : ", totalCartPriceoffff);

    const totalCartPriceof = bucketPrice * bucketSize * quantitypricevc;
    const queryID = localStorage.getItem("queryID");
    const uuidUserTokessn = localStorage.getItem("uuidUserToken");
    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";
    const timestampe = Date.now();

    const positionfavd = parseInt(
      localStorage.getItem("Positionforalgolia"),
      10
    );

    if (isNaN(positionfavd)) {
      console.error("Invalid position value in localStorage.");
    } else {
      const positions = [positionfavd];

      aa(
        "clickedObjectIDs",
        {
          eventType: "click",
          eventName: "Product Clicked",
          index: spareIndex,
          objectIDs: [productIdtoCart],
          positions: positions,
          userToken: uuidUserTokessn,
          queryID: queryID,
          timestamp: timestampe,
        },
        {
          headers: {
            "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
            "X-Algolia-API-Key": ALGOLIA_API_KEYs,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log("Click event sent successfully:", response);
        })
        .catch((error) => {
          console.error("Error sending conversion event:", error);
        });
    }

    const loggedin = localStorage.getItem("userToken");
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const ompuserId = localStorage.getItem("id");
    const customer_first_name = localStorage.getItem("firstName");
    const customer_last_name = localStorage.getItem("lastName");
    const customer_contact_no = localStorage.getItem("number");
    const customer_email = localStorage.getItem("emailId");
    let itemsAlreadyInTheApi = [];
    const storedItems = localStorage.getItem("CartItemsintheapi");

    if (storedItems) {
      try {
        itemsAlreadyInTheApi = JSON.parse(storedItems);
      } catch (error) {
        console.error(
          "Error parsing CartItemsintheapi from localStorage:",
          error
        );
        itemsAlreadyInTheApi = [];
      }
    } else {
      localStorage.setItem(
        "CartItemsintheapi",
        JSON.stringify(itemsAlreadyInTheApi)
      );
    }

    let itemsToUse =
      itemsAlreadyInTheApi.length > 0 ? itemsAlreadyInTheApi : cartItems;
    console.log("items :", itemsToUse);

    if (loggedin) {
      console.log("Here inside Loggedin : ");
      // const existingCartItemIndex = itemsToUse.findIndex(
      //   (item) => item.item_id === productIdtoCart && item.bucket_size === 20
      // );

      // const existingBarrelItemIndex = itemsToUse.findIndex(
      //   (item) => item.item_id === productIdtoCart && item.bucket_size === 210
      // );

      const existingCartItemIndex = itemsToUse.findIndex(
        (item) =>
          item.item_id === productIdtoCart &&
          [20, 18].includes(item?.bucket_size)
      );

      const existingBarrelItemIndex = itemsToUse.findIndex(
        (item) =>
          item.item_id === productIdtoCart &&
          [210, 180].includes(item?.bucket_size)
      );

      console.log("Now what is the bucket size: ", productType);

      const adjustForBarrel = (quantity) => {
        if (quantity >= 11) {
          // Adjust quantity to the nearest multiple of 11
          return Math.floor(quantity / 11) * 11;
        }
        return quantity;
      };

      if (productType === "Bucket") {
        if (existingCartItemIndex !== -1) {

          console.log("itemstouse : ",itemsToUse);
          // Update the existing bucket item



          console.log("Now to update the quantity of existing item :  ");
          const oldQuantity = itemsToUse[existingCartItemIndex].quantity;

          itemsToUse[existingCartItemIndex].quantity = 0;
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));

          // Make the first API call to set quantity to 0
          await fetch(
            "https://ompextension.origa.market/sparesapi/create_order",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                items: [
                  {
                    item_id: productIdtoCart,
                    quantity: 0,
                    bucket_id: itemsToUse[existingCartItemIndex].bucket_id,
                  },
                ],
                ompuserId,
                customer_first_name,
                customer_last_name,
                customer_contact_no,
                order_status: "Draft",
                order_ref_number: "",
                customer_email,
                input: {
                  city: "",
                  cityArea: "",
                  companyName: "",
                  country: "",
                  countryArea: "",
                  firstName: "",
                  lastName: "  ",
                  phone: "",
                  postalCode: "",
                  streetAddress1: " ",
                  streetAddress2: "",
                },
                businessaddress: {
                  city: "",
                  cityArea: "",
                  companyName: " ",
                  country: "",
                  countryArea: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  postalCode: "",
                  streetAddress1: "",
                  streetAddress2: "",
                },
                gst_no: "",
              }),
            }
          );
          console.log(
            "Item set to 0 quantity in the API. Product ID:",
            productIdtoCart
          );
          itemsToUse[existingCartItemIndex].quantity = 0;

          // // localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          // console.log(
          //   "Cart Items After setting quantity to 0: ",
          //   localStorage.getItem("CartItemsintheapi")
          // );

          console.log(
            "If any item is present , delete that and now going to send the updated quantity"
          );

          // const adjustedQuantity = adjustForBarrel(
          //   oldQuantity + quantitypricevc
          // );

          
          // itemsToUse.push({
          //   item_id: productIdtoCart,
          //   quantity: adjustedQuantity,
          //   bucket_id: bucketIded,
          //   bucketPrice: bucketPrice,
          //   totalCartPriceocvf:
          //     bucketPrice * bucketSize * (oldQuantity + quantitypricevc),
          //   bucket_size: bucketSize,
          //   productType: "Bucket",
          // });
          // console.log("itemsToUse : ", itemsToUse);
          // console.log("Now updated the cart and save to the localStorage ");

          // localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          // console.log(
          //   "Cart Items After adding a new bucket: ",
          //   localStorage.getItem("CartItemsintheapi")
          // );


    const newQuantity = oldQuantity + quantitypricevc;
    console.log("Old Quantity:", oldQuantity);
    console.log("Added Quantity:", quantitypricevc);
    console.log("New Quantity after addition:", newQuantity);
          if (newQuantity >= 11) {
            console.log("Quantity reached 11 or more, converting to Barrel.");
      
            const barrelDetails = priceList.find((item) => item.qty_type === "Barrel");
            if (!barrelDetails) {
              console.error("No barrel pricing found in priceList.");
              return;
            }
      
            itemsToUse[existingCartItemIndex] = {
              item_id: productIdtoCart,
              quantity: 1,
              bucket_id: barrelDetails.bucket_id,
              bucketPrice: barrelDetails.price,
              totalCartPriceocvf: barrelDetails.price * barrelDetails.bucket_size,
              bucket_size: barrelDetails.bucket_size,
              productType: "Barrel",
            };
      
            console.log("Updated cart item to Barrel:", itemsToUse[existingCartItemIndex]);
          } else {
            itemsToUse.push({
              item_id: productIdtoCart,
              quantity: newQuantity,
              bucket_id: bucketIded,
              bucketPrice: bucketPrice,
              totalCartPriceocvf: bucketPrice * bucketSize * newQuantity,
              bucket_size: bucketSize,
              productType: "Bucket",
            });
            console.log("Updated cart item to Bucket:", itemsToUse);
          }
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log("Cart Items saved to localStorage:", localStorage.getItem("CartItemsintheapi"));
        } else {
          console.log("Here adding a new item : ");
          itemsToUse.push({
            item_id: productIdtoCart,
            quantity: quantitypricevc,
            bucket_id: bucketIded,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceof,
            bucket_size: bucketSize,
            productType: productType,
          });
          console.log(" itemsToUse : ", itemsToUse);
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding a bucket: ",
            localStorage.getItem("CartItemsintheapi")
          );
        }
      } else if (productType === "Barrel") {
        if (existingBarrelItemIndex !== -1) {
          // Update the existing barrel item
          itemsToUse[existingBarrelItemIndex].quantity += quantitypricevc;
          itemsToUse[existingBarrelItemIndex].totalCartPriceocvf =
            totalCartPriceof;

          // Update localStorage immediately after modifying the cart
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding a new barrel: ",
            localStorage.getItem("CartItemsintheapi")
          );
        } else {
          // Add new barrel item

          console.log("Adding a new item  - barrel - quantityed : ",quantityed);
          console.log("Adding a new item  - barrel - modified barrel quantity ",quantityed/11);
          console.log("Adding a new item  - barrel - productType     :  ",productType);
          itemsToUse.push({
            item_id: productIdtoCart,
            quantity: quantityed/11,
            bucket_id: bucketId,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceof,
            bucket_size: bucketSize,
            productType: productType,
          });

          // Update localStorage immediately after adding the new item
          localStorage.setItem("CartItemsintheapi", JSON.stringify(itemsToUse));
          console.log(
            "Cart Items After adding barrel: ",
            localStorage.getItem("CartItemsintheapi")
          );
        }
      }

      var itemList = [];
      for (var item of itemsToUse) {
        var obj = {};

        obj["item_id"] = item.item_id;
        console.log("Set item_id:", item.item_id);
        // if (item.bucket_size === 210 || 180) {
        if ([210, 180].includes(item?.bucket_size)) {
          console.log("item's quantity - ", item.quantity);


          obj["quantity"] = item.quantity ;

          console.log(
            "Bucket size is 210 or 180, set quantity to:",
            obj["quantity"]
          );
        } else {
          console.log("Item quantity",item.quantity);
        
          obj["quantity"] = item.quantity;
          console.log(
            "Bucket size is not 210 or 180, set quantity to:",
            obj["quantity"]
          );
        }
        obj["bucket_id"] = item.bucket_id;
        console.log("Set bucket_id:", item.bucket_id);
        itemList.push(obj);
        console.log("Pushed object to itemList:", obj);
      }

      console.log("Final itemList:", itemList);

      try {
        await fetch(
          "https://ompextension.origa.market/sparesapi/create_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              items: itemList,
              ompuserId,
              customer_first_name,
              customer_last_name,
              customer_contact_no,
              order_status: "Draft",
              order_ref_number: "",
              customer_email,
              input: {
                city: "",
                cityArea: "",
                companyName: "",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "  ",
                phone: "",
                postalCode: "",
                streetAddress1: " ",
                streetAddress2: "",
              },
              businessaddress: {
                city: "",
                cityArea: "",
                companyName: " ",
                country: "",
                countryArea: "",
                firstName: "",
                lastName: "",
                phone: "",
                postalCode: "",
                streetAddress1: "",
                streetAddress2: "",
              },
              gst_no: "",
            }),
          }
        );
        console.log(`Item added to API cart. Product ID: ${productIdtoCart}`);
      } catch (error) {
        console.error("Error adding item to API cart:", error);
      }
    } else {
    
      const barrelIndex = itemsToUse.findIndex(
        (item) =>
          [210, 180].includes(item?.bucketSize) &&
          item.productId === productIdtoCart
      );

      if (barrelIndex !== -1) {
        // Remove the barrel
        itemsToUse.splice(barrelIndex, 1);
        console.log("Removed existing barrel for the same product ID.");
      }
      const existingCartItemIndex = itemsToUse.findIndex(
        (item) => item.productId === productIdtoCart
      );

      console.log("bucketSize : ", bucketSize);

      // if (bucketSize === 20) {
      if ([20, 18].includes(bucketSize)) {
        if (existingCartItemIndex !== -1) {
          console.log(
            "Old Quantity of the id : ",
            itemsToUse[existingCartItemIndex].quantity
          );
          console.log("New Quantity of the id : ", quantitypricevc);

          // Combine the quantities
          let totalQuantity =
            itemsToUse[existingCartItemIndex].quantity + quantitypricevc;
          console.log("Combined Quantity : ", totalQuantity);
          // If combined quantity is greater than 11, update accordingly
          if (totalQuantity >= 11) {
            console.log("Total Quantity: ", totalQuantity);

            let adjustedQuantity = Math.floor(totalQuantity / 11) * 11;
            console.log("Initial Adjusted Quantity: ", adjustedQuantity);

            console.log("Final Adjusted Quantity : ", adjustedQuantity);

            // Update the quantity and find corresponding price range
            // itemsToUse[existingCartItemIndex].quantity = adjustedQuantity;
            // console.log("Updated Quantity in itemsToUse: ", itemsToUse[existingCartItemIndex].quantity);

            console.log("Now going to iterate over  priceList :");
            const priceListForExisting =
              itemsToUse[existingCartItemIndex].priceList;
            console.log("Price List of existing item: ", priceListForExisting);

            console.log("There is already a priceList ");
            let updatedBucketDetails = null;

            console.log("Iterating over priceListforExisting...");
            priceListForExisting.forEach((priceRange) => {
              console.log("Checking price range: ", priceRange);
              if (
                priceRange.qty_type === "Barrel" &&
                adjustedQuantity >= priceRange.start_quantity &&
                (priceRange.end_quantity === null ||
                  adjustedQuantity <= priceRange.end_quantity)
              ) {
                console.log("Price range matched: ", priceRange);

                updatedBucketDetails = {
                  bucketId: priceRange.bucket_id,
                  bucketPrice: priceRange.price,
                  bucketSize: priceRange.bucket_size,
                };
                console.log(
                  "Updated Bucket Details set: ",
                  updatedBucketDetails
                );
              }
            });

            if (updatedBucketDetails) {
              console.log("Updated Bucket Details: ", updatedBucketDetails);

              // Update the bucket details
              itemsToUse[existingCartItemIndex].bucketId =
                updatedBucketDetails.bucketId;
              console.log("Updated Bucket ID: ", updatedBucketDetails.bucketId);

              itemsToUse[existingCartItemIndex].bucketPrice =
                updatedBucketDetails.bucketPrice;
              console.log(
                "Updated Bucket Price: ",
                updatedBucketDetails.bucketPrice
              );

              itemsToUse[existingCartItemIndex].bucketSize =
                updatedBucketDetails.bucketSize;
              console.log(
                "Updated Bucket Size: ",
                updatedBucketDetails.bucketSize
              );

              // Correctly update the totalCartPriceocvf with the new adjusted quantity

           
let adjustedQuantity = Math.ceil(totalQuantity / 11);
console.log("Final Adjusted Quantity:", adjustedQuantity);

itemsToUse[existingCartItemIndex].quantity = adjustedQuantity;

              // Correctly update the totalCartPriceocvf with the new adjusted quantity
              itemsToUse[existingCartItemIndex].totalCartPriceocvf =
                updatedBucketDetails.bucketPrice *
                updatedBucketDetails.bucketSize *
                adjustedQuantity;

              // Set endQuantity to null as it's a barrel
              itemsToUse[existingCartItemIndex].endQuantity = null;
              console.log("Updated endQuantity to null for barrel.");

              console.log(
                "Updated Total Cart Price (totalCartPriceocvf): ",
                itemsToUse[existingCartItemIndex].totalCartPriceocvf
              );
            } else {
              console.log(
                "No matching barrel range found for the updated quantity."
              );
              console.log(
                "Updated bucket item in cart after quantity > 11:",
                itemsToUse[existingCartItemIndex]
              );
            }

            console.log(
              "Updated bucket item in cart:",
              itemsToUse[existingCartItemIndex]
            );
          } else {
            // If combined quantity is less than or equal to 11, remove barrel
            // const barrelIndex = itemsToUse.findIndex(
            //   (item) =>
            //     item.bucketSize === 210 && item.productId === productIdtoCart
            // );
            const barrelIndex = itemsToUse.findIndex(
              (item) =>
                [210, 180].includes(item?.bucketSize) &&
                item.productId === productIdtoCart
            );

            if (barrelIndex !== -1) {
              itemsToUse.splice(barrelIndex, 1); // Remove the barrel
              console.log("Removed barrel item from cart.");
            }
            itemsToUse[existingCartItemIndex].quantity = totalQuantity;
            const priceListForExisting =
              itemsToUse[existingCartItemIndex].priceList;
            console.log("Price List of existing item: ", priceListForExisting);

            let updatedBucketDetails = null;

            // Loop through the price list to find the correct price range
            priceListForExisting.forEach((priceRange) => {
              if (
                priceRange.qty_type === "Bucket" &&
                totalQuantity >= priceRange.start_quantity &&
                (priceRange.end_quantity === null ||
                  totalQuantity <= priceRange.end_quantity)
              ) {
                updatedBucketDetails = {
                  bucketId: priceRange?.bucket_id,
                  bucketPrice: priceRange?.price,
                  bucketSize: priceRange?.bucket_size,
                };
              }
            });

            itemsToUse[existingCartItemIndex].bucketId =
              updatedBucketDetails?.bucketId;
            itemsToUse[existingCartItemIndex].bucketPrice =
              updatedBucketDetails?.bucketPrice;
            itemsToUse[existingCartItemIndex].bucketSize =
              updatedBucketDetails?.bucketSize;

            // Correctly update the totalCartPriceocvf with the new adjusted quantity
            itemsToUse[existingCartItemIndex].totalCartPriceocvf =
              updatedBucketDetails?.bucketPrice *
              updatedBucketDetails?.bucketSize *
              totalQuantity;

            // itemsToUse[existingCartItemIndex].totalCartPriceocvf =
            //   itemsToUse[existingCartItemIndex].bucketPrice *
            //   itemsToUse[existingCartItemIndex].bucketSize *
            //   totalQuantity;

            console.log(
              "Updated bucket item in cart (quantity <= 11):",
              itemsToUse[existingCartItemIndex]
            );
          }
        } else {
          // If the item doesn't exist in the cart, add it as a new item
          itemsToUse.push({
            productId: productIdtoCart,
            productName: productNamed,
            productBrand: productbrand,
            productImage: productImaged,
            salePrice: salePricess,
            quantity: quantitypricevc,
            bucketId: bucketId,
            bucketSize: bucketSize,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceof,
            endQuantity: endQuantity,
            priceList: priceList,
          });
          console.log("Added new bucket item to cart.");
        }
      } else if ([210, 180].includes(bucketSize)) {
        // Handling Barrel case where bucketSize is 210 or 180
        let matchingBucket = null;
        priceList.forEach((priceRange) => {
          // if (priceRange.bucket_size === 210) {
          if ([210, 180].includes(priceRange?.bucket_size)) {
            matchingBucket = priceRange.bucket_id;
          }
        });

        let adjustedQuantity;

        console.log("quantitypricevc : ", quantitypricevc);
        if (quantitypricevc % 11 === 0) {
          console.log("Now quantity is being % 11");
          adjustedQuantity = quantitypricevc / 11;
        } else {
          console.log("Now quantity is not % 11");
          adjustedQuantity = quantitypricevc;
        }

        console.log("Adjusted Quantity: ", adjustedQuantity);

        if (matchingBucket) {
          const existingBucketIndex = itemsToUse.findIndex(
            (item) =>
              [20, 18].includes(item?.bucketSize) &&
              item.productId === productIdtoCart
          );

          if (existingBucketIndex !== -1) {
            itemsToUse.splice(existingBucketIndex, 1);
            console.log("Removed existing bucket for the same product ID.");
          }

          console.log("Before pushing to the cart :  -  a barrel");
          console.log("adjustedQuantity for  a barrel: ", adjustedQuantity);
          console.log("Bucket id for a barrel  : ", matchingBucket);
          console.log("Product Id  : ", productIdtoCart);
          console.log("Product name  : ", productNamed);
          console.log("Bucket Size : ", bucketSize);

          itemsToUse.push({
            productId: productIdtoCart,
            productName: productNamed,
            productBrand: productbrand,
            productImage: productImaged,
            salePrice: salePricess,
            quantity: adjustedQuantity,
            bucketId: matchingBucket,
            bucketSize: bucketSize,
            bucketPrice: bucketPrice,
            totalCartPriceocvf: totalCartPriceoffff,
            endQuantity: endQuantity,
            priceList: priceList,
          });
          console.log("Added or replaced with barrel item in cart.");
        } else {
          console.log("No matching bucket found for barrel.");
        }
      }

      localStorage.setItem("cartItems", JSON.stringify(itemsToUse));
      console.log("Updated cart items:", itemsToUse);
    }

    setLoading(true);

    setTimeout(() => {
      setSuccessMessage("Added to Cart Successfully");
      setLoading(false);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }, 500);
  };

  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleAvailService = (
    product,
    position,
    queryID,
    isPincodeAvailableInPriceRange
  ) => {
    const state = {
      group_id: product.group_id,
      objectID: product.objectID || product.id,
      details: product,
      isPincodeAvailableInPriceRange: isPincodeAvailableInPriceRange,
      filteredHitsToRender: filteredHitsToRender,
    };


    const simplifiedData = filteredHitsToRender.map(item => ({
      group_name: item.group_name || "", 
      group_id: item.group_id || "",      
      productName: item.productName || "", 
      objectID: item.objectID || "",     
    }));
    
// Save the simplified array to localStorage
localStorage.setItem("filteredHitsToRender", JSON.stringify(simplifiedData));
    setSelectedPosition(position); // Store the position in state
    // console.log("Selected Position for state variable:", position);

    const objectIdforsimilartools = product.objectID;
    localStorage.setItem("ObjectIdToolforSimilar", objectIdforsimilartools);
    const timestamp = Date.now();
    const uuidUserToken = uuidv4();

    const postionfor = position;
    localStorage.setItem("uuidUserToken", uuidUserToken);
    localStorage.setItem("Positionforalgolia", postionfor);

    // console.log("UUID User Token stored in localStorage:", uuidUserToken);

    // console.log("object Id : ", product.objectID);
    // console.log("Product Name : ", product.productName);
    // console.log("Query Id : ", queryID);
    // console.log("Positions :", position);
    // console.log("timestamp :", timestamp);
    // console.log("UserToken : ", uuidUserToken);

    localStorage.setItem("queryID", queryID);
    // console.log(
    //   "QueryId in LocalStorage :   ",
    //   localStorage.getItem("queryID")
    // );

    const ALGOLIA_APP_IDs = "WMFH4GQ1HA";
    const ALGOLIA_API_KEYs = "26d776e16554d71413cb70ee9bc30fb6";
    const spareIndex = "zohoinventory_index";

    sendEvent(
      "viewedObjectIDs",
      {
        eventType: "view",
        eventName: "Product Viewed",
        index: spareIndex,
        objectIDs: [state.objectID],
        userToken: uuidUserToken,
        queryID: queryID,
        timestamp: timestamp,
      },
      {
        headers: {
          "X-Algolia-Application-Id": ALGOLIA_APP_IDs,
          "X-Algolia-API-Key": ALGOLIA_API_KEYs,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        console.log("Event sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending event:", error);
      });

    setLoading(true);
    setTimeout(() => {
      if (product?.group_id) {
        navigate(`/buy/spares-revised?group_id=${product.group_id}`, { state });
      } else {
        navigate(`/buy/spares-revised?id=${product.objectID}`, { state });
      }

      console.log(
        `Redirecting to ViewSpareProduct page: Group Id: ${product.group_id}, Item Id: ${state.objectID}`
      );
    }, 1000);
  };

  //   const handleModal = async (status, obj) => {

  //     const loggedin = !!localStorage.getItem("userToken");
  //     if (!loggedin) {

  //       setLoginPortal(true);
  //       return;
  //     }
  //     if (status === "phone-modal") {

  //       const loggedin = !!localStorage.getItem("userToken");
  //       if (!loggedin) {
  //         setLoginPortal(true);
  //         return;
  //       } else {
  //         navigate(`/manageaddresspart`);
  //       }
  //     }
  //     if (status) {
  //       setShowModal(status);
  //     } else {

  //       setShowModal(false);
  //       const id = localStorage.getItem("id");
  //       console.log("obj------------>", obj);
  //       setBuymachineId(obj?.buymachine_id);
  //       try {

  //         const { data } = await client.query({
  //           query: GET_SPARE_DETAILS,
  //           variables: { itemId: productId },
  //         });
  //         setProductDETAILS(data?.inventoryItem?.response);
  //         updateFormattedPrice();
  //       } catch (error) {

  //         console.error("Error fetching product details:", error);
  //       }
  //     }
  //   };

  const onHidePortal = () => {
    setLoginPortal(false);
  };

  const itemsPerPage = isGridView ? 15 : 12;

  //   const totalPages = Math.ceil(filteredHitsToRender.length / itemsPerPage);

  // Handle page rendering (could be controlled by pagination component)
  const renderCurrentPageItems = (currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredHitsToRender.slice(startIndex, endIndex);
  };

  useEffect(() => {
    setFilteredHitsToRender(hits);
  }, [hits, isGridView]);

  return (
    <>
      {loading && <Loader />}
      <div className="whole-container-products-section">
        {/* <div className="whole-container-products-section">
        <div
          className={`whole-product-section ${isGridView ? "grid-view" : ""}`}
        > */}
        <div
          // className={`whole-product-section ${
          //   isGridView || isGradeSelected  ? "grid-view" : ""
          // }`}
          className={`whole-product-section ${
            isGridView ||
            isGradeSelected ||
            isIndustrySelected ||
            isCategorySelected || query
              ? "grid-view"
              : ""
          }`}
        >
          {/* {console.log("Applying class to product section:", isGridView, isGradeSelected, isIndustrySelected, isCategorySelected)} */}
          {filteredHitsToRender?.map((product, index) => (
            
            <div
              className="individual-product"
              key={index}
              onClick={() =>
                handleAvailService(product, index + 1, product.__queryID)
              }
              style={{ cursor: "pointer" }}
            >
          
              {/* <div className="exclusive-btn">Exclusive Online</div>  */}
              <img
                className="individual-product-image"
                src={product.thumbnail}
                alt={product.name}
              />
              <div>
                <span className="brandsandproductname-wrapper">
                  <span className="brandsandproductname">
                    {product?.brands} <br />
                    {product?.productName}
                  </span>
                </span>

                <div>
                  <div>{product?.Phase}</div>
                  <div className="bucketsizepriceandltr">
                    {product?.bucketsize}
                    {product?.noofpacks && product?.noofpacks > 0 && (
                      <>(Pack of {product?.noofpacks})</>
                    )}
                    <br />
                    {product?.packprice && product?.packprice > 0 && (
                      <>₹{product?.packprice}</>
                    )}
                    (₹{product?.grossPrice} /{product.unit})
                    <br />
                    <span>
                      {product?.mrp && product?.mrp > 0 && (
                        <s style={{ color: "gray" }}>
                          ₹{product?.mrp}/{product.unit}
                        </s>
                      )}

                      {product?.mrp &&
                        product?.grossPrice &&
                        product?.mrp > 0 &&
                        product?.grossPrice > 0 && (
                          <span
                            style={{
                              color: "#58cb58",
                              marginLeft: "5px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {Math.ceil(
                              ((product?.mrp - product?.grossPrice) /
                                product?.mrp) *
                                100
                            )}
                            % off
                          </span>
                        )}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {deliveryAvailable ? (
                  <>
                    <div className="totaldiv-addtocard-buynow">
                      <button
                        className="addtocart-btn-oil"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCartNow(
                            product,
                            index + 1,
                            product.__queryID,
                            product.objectID,
                            quantity,
                            product.productName,
                            product,
                            product.thumbnail,
                            product.brands
                          );
                        }}
                      >
                        Add to Cart
                      </button>
                      <button
                        className="buynow-btn-oil"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleBuyNow(
                            product,
                            index + 1,
                            product.__queryID,
                            product.objectID,
                            product.productName,
                            product,
                            product.thumbnail,
                            product.brands
                          );
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  </>
                ) : (
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleAvailService(product, index + 1, product.__queryID);
                    }}
                  >
                    Request for Quotation
                  </button>
                )}
              </div>
            </div>
          ))}

          {/* {filteredHitsToRender?.map((product, index) => {
  const deliveryLocation = localStorage.getItem("deliveryLocation");
  const pricing = product?.pricing;
  const pricingKeys = pricing ? Object.keys(pricing) : [];
  console.log("Price for pincode : ",pricingKeys)
  
  const matchedPrice = deliveryLocation && pricing && pricing[deliveryLocation];
  console.log(`Matched Price : ${matchedPrice} for the pincode : ${deliveryLocation}`);

  const priceRangeMessage = pricingKeys.length > 0
  ? (
      <>
        Prices range - (
         
          ₹{Math.min(...pricingKeys.map(key => pricing[key]))}/ltr
          -
            ₹{Math.max(...pricingKeys.map(key => pricing[key]))}/ltr)*
     <br></br>   
     <span style={{ fontSize: "12px",fontWeight: "500" }}>
          {" "}<span className="Enter-Pincode-for-Exact-value" style={{fontSize:"12px",color:"#727D73", borderBottom: "1px solid gray"}}>(Enter Pincode for exact price )</span>
        </span>
      </>
    )
  : null;

  return (
    <div
      className="individual-product"
      key={index}
      onClick={() =>
        handleAvailService(product, index + 1, product.__queryID)
      }
      style={{ cursor: "pointer" }}
    >
      <img
        className="individual-product-image"
        src={product.thumbnail}
        alt={product.name}
      />
      <div>
        <span className="brandsandproductname-wrapper">
          <span className="brandsandproductname">
            {product?.brands} <br />
            {product?.productName}
          </span>
        </span>

        <div>
          <div>{product?.Phase}</div>
          <div className="bucketsizepriceandltr">
            {matchedPrice ? (
              <>
               {product?.noofpacks && product?.noofpacks > 0 && (
                  <>(Pack of {product?.noofpacks})</>
                )}
                <br />
                {product?.packprice && product?.packprice > 0 && (
                  <>₹{product?.packprice}</>
                )}
                (₹{matchedPrice} /Ltr)
                <br />
                {product?.mrp && product?.mrp > 0 && (
                  <s>₹{product?.mrp}/Ltr</s>
                )}

                {product?.mrp &&
                  product?.grossPrice &&
                  product?.mrp > 0 &&
                  product?.grossPrice > 0 && (
                    <span
                      style={{
                        color: "#58cb58",
                        marginLeft: "5px",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {Math.ceil(
                        ((product?.mrp - product?.grossPrice) /
                          product?.mrp) *
                          100
                      )}
                      % off
                    </span>
                  )}
              </>
            ) : (
              <>
               
               {product?.noofpacks && product?.noofpacks > 0 && (
                  <>(Pack of {product?.noofpacks})</>
                )}
                <br />
               

              </>
            )}
            {!matchedPrice && priceRangeMessage && (
              <span
                style={{
                  fontSize: "12px",
                  marginLeft: "5px",
                  fontWeight: "500",
                  paddingBottom:"2%"
                }}
              >
                {priceRangeMessage}
              </span>
            )}
          </div>
        </div>
      </div>
      <div>
        {deliveryAvailable ? (
          <>
            <div className="totaldiv-addtocard-buynow">
              <button
                className="addtocart-btn-oil"
                onClick={(event) => {
                  event.stopPropagation();
                  handleCartNow(
                    product,
                    index + 1,
                    product.__queryID,
                    product.objectID,
                    quantity,
                    product.productName,
                    product,
                    product.thumbnail,
                    product.brands
                  );
                }}
              >
                Add to Cart
              </button>
              <button
                className="buynow-btn-oil"
                onClick={(event) => {
                  event.stopPropagation();
                  handleBuyNow(
                    product,
                    index + 1,
                    product.__queryID,
                    product.objectID,
                    product.productName,
                    product,
                    product.thumbnail,
                    product.brands
                  );
                }}
              >
                Buy Now
              </button>
            </div>
          </>
        ) : (
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleAvailService(product, index + 1, product.__queryID);
            }}
          >
            Request for Quotation
          </button>
        )}
      </div>
    </div>
  );
})} */}

          {/* {filteredHitsToRender?.map((product, index) => {
  const deliveryLocation = localStorage.getItem("deliveryLocation");
  const pricing = product?.pricing;
  const pricingKeys = pricing ? Object.keys(pricing) : [];

  const matchedPrice = deliveryLocation && pricing && pricing[deliveryLocation];
  const isPincodeAvailableInPriceRange = pricingKeys.includes(deliveryLocation);

  const priceRangeMessage = pricingKeys.length > 0
    ? (
        <>
          Prices range-(
          ₹{Math.min(...pricingKeys.map(key => pricing[key]))}/ltr
          - ₹{Math.max(...pricingKeys.map(key => pricing[key]))}/ltr) *
          <br />
          <span style={{ fontSize: "12px", fontWeight: "500" }}>
           
          </span>
        </>
      )
    : null;

  const calculateDiscount = (price, mrp) => {
    return price && mrp ? Math.ceil(((mrp - price) / mrp) * 100) : 0;
  };

  const priceToUseForDiscount = matchedPrice || product?.mrp;
  const discount = calculateDiscount(priceToUseForDiscount, product?.mrp);


  return (
    <div
      className="individual-product"
      key={index}
      onClick={() => handleAvailService(product, index + 1, product.__queryID, isPincodeAvailableInPriceRange)}
      style={{ cursor: "pointer" }}
      
    >
      <img
        className="individual-product-image"
        src={product.thumbnail}
        alt={product.name}
      />
      <div>
        <span className="brandsandproductname-wrapper">
          <span className="brandsandproductname">
            {product?.brands} <br />
            {product?.productName}
          </span>
        </span>

        <div>
          <div>{product?.Phase}</div>
          <div className="bucketsizepriceandltr">
            {isPincodeAvailableInPriceRange ? (
              <>
              {product?.noofpacks && product?.noofpacks > 0 && (
  <>(Pack of {product?.noofpacks})</>
)}
<br />
{product?.packprice && product?.packprice > 0 && (
  <>₹{product?.packprice}</>
)}
(₹{matchedPrice} /Ltr)
<br />
{product?.mrp && product?.mrp > 0 && discount > 0 && (
  <s style={{color:"gray"}}>₹{product?.mrp}/Ltr</s>
)}
{discount > 0 && (
  <span style={{ color: "#58cb58", marginLeft: "5px", fontSize: "16px", fontWeight: "500" }}>
    {discount}% off
  </span>
)}

              </>
            ) : pricingKeys.length > 0 ? (
            <></>
            ) : (
              <>
               <span
                style={{
                  fontSize: "12px",
                  marginLeft: "5px",
                  fontWeight: "500",
                  paddingBottom: "2%",
                  color:"#727D73",
           
                }}
              >

                Enter Pincode for exact price
              </span>
              </>
             
            )}
            {!isPincodeAvailableInPriceRange && priceRangeMessage && (
             <>
              {product?.noofpacks && product?.noofpacks > 0 && (
                  <>(Pack of {product?.noofpacks})</>
                )}
                <br></br>
                <span
               style={{fontSize:"12px",whiteSpace:"nowrap",fontStyle:"italic"}}
              >
                {priceRangeMessage}
              </span>
             </>

            )}


            {!deliveryLocation && (
              <>
              <span
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    fontWeight: "500",
                    paddingBottom: "2%",
                    color:"#727D73",
             
                  }}
              >

                Enter Pincode for exact price
              </span>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        {deliveryLocation && isPincodeAvailableInPriceRange ? (
          <div className="totaldiv-addtocard-buynow">
            <button
              className="addtocart-btn-oil"
              onClick={(event) => {
                event.stopPropagation();
                handleCartNow(
                  product,
                  index + 1,
                  product.__queryID,
                  product.objectID,
                  quantity,
                  product.productName,
                  product,
                  product.thumbnail,
                  product.brands
                );
              }}
            >
              Add to Cart
            </button>
            <button
              className="buynow-btn-oil"
              onClick={(event) => {
                event.stopPropagation();
                handleBuyNow(
                  product,
                  index + 1,
                  product.__queryID,
                  product.objectID,
                  product.productName,
                  product,
                  product.thumbnail,
                  product.brands
                );
              }}
            >
              Buy Now
            </button>
          </div>
        ) : deliveryLocation && !isPincodeAvailableInPriceRange ? (
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleAvailService(product, index + 1, product.__queryID);
            }}
          >
            Request for Quotation
          </button>
        ) : (
          <div className="totaldiv-addtocard-buynow">
            <button
              className="addtocart-btn-oil"
              onClick={(event) => {
                event.stopPropagation();
                handleCartNow(
                  product,
                  index + 1,
                  product.__queryID,
                  product.objectID,
                  quantity,
                  product.productName,
                  product,
                  product.thumbnail,
                  product.brands
                );
              }}
            >
              Add to Cart
            </button>
            <button
              className="buynow-btn-oil"
              onClick={(event) => {
                event.stopPropagation();
                handleBuyNow(
                  product,
                  index + 1,
                  product.__queryID,
                  product.objectID,
                  product.productName,
                  product,
                  product.thumbnail,
                  product.brands
                );
              }}
            >
              Buy Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
})}
 */}
        </div>
        <CustomPagination />
      </div>

      {loginPortal && <LoginModel onHide={onHidePortal} />}

      {QuotationPopupforBuyNowinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForInSpare
                productDetails={productDETAILS}
                onSubmit={handleSubmit}
              />
              <div className="close-popup" onClick={closePopup2}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {QuotationPopupforAddtoCartinSpare && (
        <div className="popup-overlay">
          <div className="popup-banner">
            <div className="popup-content">
              <QuotationPopupForSpareAddtoCart
                productDetails={productDETAILS}
                onSubmit={handleSubmitForCart}
                position={selectedPosition}
              />
              <div className="close-popup" onClick={closePopup4}>
                {closeIcon({ width: 14, height: 14 })}
              </div>
            </div>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="success-popup">
          <div className="success-popup-content">
            <div className="success-icon">✓</div>
            <div className="success-message">
              <h3>Item added to Cart Successfully</h3>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default connectHits(SpareHitNotWithDoubleAPIS);
