import React, { useState } from 'react'
import { Container } from 'react-bootstrap'; 
import DelayedFormforMachines from '../CustomerModel/InfoPageforMachines';
const CustomerInforModelPageforMachines = ({FullUrl, machinename, setCustomerInfoForm,setShowThanksPopup}) => {

  return (
    <Container fluid className="p-fixed bg-blur hw-100 p-0 m-0 d-j-a">
    <DelayedFormforMachines  FullUrl={FullUrl}
        machinename={machinename}
        setCustomerInfoForm={setCustomerInfoForm}
        setShowThanksPopup={setShowThanksPopup}/>
    </Container>
         
  )
}

export default CustomerInforModelPageforMachines