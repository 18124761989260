import React, { useState, useEffect, useRef } from "react";
import "./ProductListing.css";
import "./StorePart.css";
import LoginModel from '../../Authentication/LoginModel/LoginModel';
import Breadcrumbs from "../../SubComponent/Breadcrumbs";
import DeliveryLocation from "../Modals/DeliveryLocation";
import Footer from '../../Footer/Footer';
import { shortIcon, filterIcon, closeIcon } from "../../../helpers/Icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ALGOLIA_ID, ALGOLIA_KEY, ALGOLIA_SELLER_INDEX, ALGOLIA_SPARE_INDEX } from '../../../constants'
import algoliasearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    connectRange,
    connectStateResults
} from "react-instantsearch-dom";
import FilterSection from "./FilterSection";
import Loader from "../../SubComponent/Loader";
import CategoryPopup from "../../Account/Popup/CategoryPopup";
import CustomHits from "./CustomHits"
import SpareHits from "./SpareHits"
import { filterAttribute } from './FilterAttribute';
import Slider from '@mui/material/Slider';
import CustomerInfo from './CustomerInformation/CustomerInfoModel';
import CombinedHits from "./CombinedHits";
import SpareFilterSection from "./FilterSection/SpareFilterSection";
import SpareHitNotWithDoubleAPIS from "./SpareHits/SpareHitNotWithDoubleAPIS";
import AliceCarousel from "react-alice-carousel";
import aa from "search-insights";



  aa("init", {
    appId: "ALGOLIA_ID",
    apiKey: "ALGOLIA_KEY",
  });
  
const MachinesAndStore = () => {
     const [showScrollbar, setShowScrollbar] = useState(false);
     const [isSticky, setIsSticky] = useState(true);
       const [showDeliveryPopup, setShowDeliveryPopup] = useState(false);
       const scrollPositionLimit = 12308.2000122070312;
     
       const handleScroll = () => {
         if (window.scrollY >= scrollPositionLimit) {
           setIsSticky(false);
         } else {
           setIsSticky(true);
         }
       };
     
       useEffect(() => {
         window.addEventListener("scroll", handleScroll);
         return () => {
           window.removeEventListener("scroll", handleScroll);
         };
       }, []);
     
    const [loading, setLoading] = useState(false);



      const breadcrumbsItems = [{ name: "Industrial Lubricants", link: "/" }];
      const [deliveryLocation, setDeliveryLocation] = useState("");
      const [deliveryAvailable, setDeliveryAvailable] = useState(true);


    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_KEY);
    const Seller_index = client.initIndex(ALGOLIA_SELLER_INDEX);
    const [filteredHits, setFilteredHits] = useState([]);
    const location = useLocation();
    const [isMobCategoryShow, setIsMobCategoryShow] = useState(window.innerWidth <= 767);
    const [activeTab, setActiveTab] = useState("machines");
    const [MainFilters, setMainFilters] = useState("");
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState({
        industry: [],
        category: [],
        subcategory: [],
        brand: [],
        Model: [],
        Price: [],
        Year: [],
        Controller: [],
        XAXIS: [],
        YAXIS: [],
        ZAXIS: [],
        spindlespeed: [],
        spindletaper: [],
        tailstock: [],
        barcapacity: [],
        chucksize: [],
        maxturninglength: [],
        swingoverbed: [],
        swingovercrossslide: [],
        subSpindle: [],
        liveTooling: [],
        orderBy: "",
        page: 1,
    });
    const token = localStorage.getItem('userToken');
    const [IndustryCheckBoxValue, setIndustryCheckBoxValue] = useState([]);
    const [CategoryCheckBoxValue, setCategoryCheckBoxValue] = useState([]);
    const [GradeCheckBoxValue,setGradeCheckBoxValue] = useState([]);
  
    const { SparefilterConfigurations } = filterAttribute(
      IndustryCheckBoxValue,
      CategoryCheckBoxValue,
      GradeCheckBoxValue
    );
    const [showShortList, setShowShortList] = useState(false);


     const [isGridView, setIsGridView] = useState(false);
      const [isGradeSelected, setIsGradeSelected] = useState(false);
      const toggleGridView = (value) => {
        console.log("The grid button being clicked ", value);
        setIsGridView(value);
      };
    


    const [activeFilters, setActiveFilters] = useState({
        industry: true, category: false, brand: false, model: false, Price: false, year: false, Controller: false,
        XAXIS: false, YAXIS: false, ZAXIS: false, spindlespeed: false, spindletaper: false, tailstock: false, barcapacity: false, chucksize: false, maxturninglength: false,
        swingoverbed: false, swingovercrossslide: false, subSpindle: false, liveTooling: false
    });
    const searchParams = new URLSearchParams(location.search);
    const searchInput = searchParams.get('searchInput');
    const [Sorting, setSorting] = useState(null);
    

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLocation = localStorage.getItem("deliveryLocation");
    if (storedLocation && !deliveryLocation) {
      setDeliveryLocation(storedLocation);
      checkDeliveryAvailability(storedLocation);
      // console.log("Delivery Location is SparesRevised.jsx : ", storedLocation);
    }
  }, [deliveryLocation]);


  const checkDeliveryAvailability = async (pincode) => {
    try {
      const response = await fetch(
        "https://ompextension.origa.market/api/getdeliverypincode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode }),
        }
      );
      const data = await response.json();
      setDeliveryAvailable(data.available_status === "available");
      if (data.available_status === "available") {
        setDeliveryLocation(pincode);
        localStorage.setItem("deliveryLocation", pincode);
      } else {
        setShowDeliveryPopup(false);
      }
    } catch (error) {
      console.error("Error checking delivery availability:", error);
      setDeliveryAvailable(false);
    }
  };
  const onGetPinCodeHandler = (pin) => {
    if (pin && pin.length === 6) {
      setDeliveryLocation(pin);
      localStorage.setItem("deliveryLocation", pin);
      checkDeliveryAvailability(pin);
    } else {
      console.error("Invalid pincode");
    }
  };

    const [seeMore, setSeeMore] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFilters = (name, value) => {
        setActiveFilters((prevState) => ({ ...prevState, [name]: value }));
    };
    const [combinedResults, setCombinedResults] = useState({ sellerResults: [], spareResults: [] });
    const [isSpareIndex, setIsSpareIndex] = useState(true);
    
    useEffect(() => {
        const fetchResults = async () => {
            try {
                if (!searchInput) {
                    // If searchInput is empty, directly show SpareHits
                    setIsSpareIndex(true);
                    setCombinedResults({ sellerResults: [], spareResults: [] });
                    return;
                }
    
                const searchTerms = searchInput.split(" ");
                const queries = searchTerms.flatMap(term => [
                    { indexName: ALGOLIA_SELLER_INDEX, query: term },
                    { indexName: ALGOLIA_SPARE_INDEX, query: term }
                ]);
    
                const { results } = await client.multipleQueries(queries);
    
                const sellerResults = results.find(result => result.index === ALGOLIA_SELLER_INDEX)?.hits || [];
                const spareResults = results.find(result => result.index === ALGOLIA_SPARE_INDEX)?.hits || [];
    
                setCombinedResults({
                    sellerResults,
                    spareResults
                });
    
                setIsSpareIndex(spareResults.length > sellerResults.length);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching combined results:", error);
            }
            finally{
                setLoading(false)
            }
        };
    
        fetchResults();
    }, [searchInput]);
    
    

    
    const handleChangeSort = (sort) => {
        setSorting(sort);
        const selectedFilters = Object.assign({}, filters);
        selectedFilters.orderBy = sort;
        selectedFilters.page = 1;
        setFilters(selectedFilters);
        setShowShortList(false);
    };

    const handleSeeMore = (status) => {
      console.log("First the delivery Location button is clicked in MachineStores.jsx : ");
      setSeeMore(status);
      document.body.classList.toggle("no-overflow", status);
    };



    const toggleDropdown = () => {
        const dropdownElement = document.querySelector('.p-fixed');
        const dropdownElement1 = document.querySelector('.fixed-category-bottom');
        if (dropdownElement) {
            dropdownElement.classList.remove('dropdownHidden');
            dropdownElement1.classList.remove('dropdownHidden');
        }
    };

    

    const RangeSlider = ({ min, max, currentRefinement, refine }) => {
        const handleChange = (event, newValue) => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            if (minAllowedYear === newValue[0]) {
                refine({ min: 1900, max: newValue[1] });
            } else {
                refine({ min: newValue[0], max: newValue[1] });
            }
        };
        const [minYear, setMinYear] = useState(1940);

        useEffect(() => {
            const currentYear = new Date().getFullYear();
            const minAllowedYear = currentYear - 25;
            setMinYear(minAllowedYear);
        }, []);
        const defaultValue = [currentRefinement.min || min, currentRefinement.max || max];
        return (
            <div>
                <Slider
                    min={minYear}
                    max={max}
                    value={defaultValue}
                    onChange={handleChange}
                    valueLabelDisplay="auto"
                    color="secondary"
                    aria-labelledby="range-slider"
                    getAriaValueText={(value) => `$${value}`}
                />
            </div>
        );
    };

    const CustomRangeInput = connectRange(RangeSlider);

     const images = [
        { original: "asset/Oil_Ads/oil_ads_image-svg1.svg" },
        { original: "asset/Oil_Ads/oil_ads_image-svg2.svg" },
        { original: "asset/Oil_Ads/oil_ads_image-svg3.svg" },
        { original: "asset/Oil_Ads/oil_ads_image-svg4.svg" },
        { original: "asset/Oil_Ads/oil_ads_image-svg5.svg" },
        { original: "asset/Oil_Ads/oil_ads_image-svg6.svg" },
        
      ];
    
      const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3, itemsFit: "contain" },
        1610: { items: 4 },
      };
    
      const carouselRef = useRef(null);
      const items = images.map((image, index) => (
        <div className="custom-image-container" key={index}>
          <div className="custom-image-wrapper">
            <img
              src={image.original}
              alt="Product"
              className="custom-image"
              draggable="false"
            />
          </div>
        </div>
      ));
      const isBrandSelected = filters.brand.length > 0;
      const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
    
      useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 576);
        window.addEventListener("resize", handleResize);
    
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
      // Step 2: Update window width on resize
      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    

    return (
        <>
          
            {seeMore && (
                <DeliveryLocation modalAction={handleSeeMore} pinvalue={onGetPinCodeHandler} />
            )}
            {/* <div className="container-fluid col-cust">
                <div className="max-container my-5">
                    <InstantSearch searchClient={client} indexName={ALGOLIA_SPARE_INDEX} insights={true}>
                        <Configure
                            maxValuesPerFacet={50}
                            query={searchInput || ''} 
                            filters={MainFilters}
                            hitsPerPage={12}
                        />


                        <div className="head-wrap">
                            <div className="heading-wrap">
                            <div className="sub-heading heading-400-16">Search Result for</div>
                            <div className="heading heading-600-20">{searchInput}</div>
                            </div>
                            <div className="btn-wrap">
<button onClick={() => { handleSeeMore(true) }} className="btn location">{deliveryLocation ? `Delivery Location : ${deliveryLocation}` : 'Set Delivery Location'} </button>

                            </div>
                        </div>

                        <div className='row layout-wrap'>
                            <div className='col col-lg-3 col-md-3 col-12'>
                                <div className="filter-wrap">
                                    <div className="head">
                                        <div className="heading heading-600-20">
                                            {filterIcon({ width: 18, height: 12 })}
                                            Filters
                                        </div>
                                    </div>
                                  
                                    
                                </div>
                            </div>
                          
                         
<div className='col col-lg-9 col-md-9 col-12'>
    {isSpareIndex ? (
        <SpareHitNotWithDoubleAPIS 
            setLoading={setLoading} 
            Sorting={Sorting} 
            hitComponent={filteredHits} 
            query={searchInput} 
        />
    ) : (
        <CombinedHits 
            sellerResults={combinedResults.sellerResults} 
            spareResults={combinedResults.spareResults} 
        />
    )}
</div>



                        </div>
                    </InstantSearch>
                </div>
            </div> */}



            <div className="container-fluid col-cust">
        {/* <div className="max-container my-3"> */}
        <div className={isMobile ? "max-container my-3" : "mx-5 my-3"}>
          <InstantSearch
            searchClient={client}
            indexName={ALGOLIA_SPARE_INDEX}
            insights={true}
          >
            {/* Step 3: Conditionally render Configure component based on screen width */}
            {/* {windowWidth > 1876 ? (
              <Configure
                maxValuesPerFacet={50}
                query={searchInput || ""}
                filters={MainFilters}
                hitsPerPage={17}
              />
            ) : windowWidth > 1547 ? (
              <Configure
                maxValuesPerFacet={50}
                query={searchInput || ""}
                filters={MainFilters}
                hitsPerPage={15}
              />
            ) : (
              <Configure
                maxValuesPerFacet={50}
                query={searchInput || ""}
                filters={MainFilters}
                hitsPerPage={12}
              />
            )} */}


{windowWidth >= 1887 ? (
  <Configure
    maxValuesPerFacet={50}
    query={searchInput || ""}
    filters={MainFilters}
    hitsPerPage={17}
  />
) : windowWidth >= 1400 ? (
  <Configure
    maxValuesPerFacet={50}
    query={searchInput || ""}
    filters={MainFilters}
    hitsPerPage={17}
  />
) : windowWidth >= 900 ? (
  <Configure
    maxValuesPerFacet={50}
    query={searchInput || ""}
    filters={MainFilters}
    hitsPerPage={12}
  />
) : null}


            <div className="d-md-none">
              <CategoryPopup
                filterConfigurations={SparefilterConfigurations}
                activeFilters={activeFilters}
                IndustryCheckBoxValue={IndustryCheckBoxValue}
                setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                CategoryCheckBoxValue={CategoryCheckBoxValue}
                setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                GradeCheckBoxValue={GradeCheckBoxValue}
                setGradeCheckBoxValue={setGradeCheckBoxValue}
                MainFilters={MainFilters}
                setMainFilters={setMainFilters}
              />
            </div>

            {/* BreadCrumbs */}
            <div className="Breadcrumbsanddeliverlocation">
              <div>
                <Breadcrumbs
                  backnavi={() => navigate("/")}
                  boldtitle={"Lubricants"}
                  items={breadcrumbsItems}
                />
              </div>
            </div>

            {/*----------------------------------- Delivery Location button adn Filters button---------------------------- */}
            <div className="filterAndLocationNotForDesktop d-flex ">
              <div className=" filterAndLocationWrapper head-wrap d-flex justify-content-end  ">
                <div className="btn-wrap">
                  <div>
                    {" "}
                    <button
                      onClick={() => handleSeeMore(true)}
                      className="filterAndLocationWrapper delveryloactionn"
                    >
                      {deliveryLocation
                        ? `Delivery Location : ${deliveryLocation}`
                        : "Set Delivery Location"}
                    </button>
                  </div>
                  <div className="short-wrap">
                    <button
                      className=" filterAndLocationWrapper btn short-btn "
                      onClick={toggleDropdown}
                    >
                      Filters
                      {filterIcon({ width: 22, height: 22, fill: "#73509E" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="filterAndLocationWrapper d-flex justify-content-center align-items-center  ">
              <div className="gridButtonformobile"></div>
              {/* */}
              <div
                className={`gridcontainerbuttonformobile ${
                  !isGridView ? "active" : ""
                }`}
                onClick={() => toggleGridView(false)}
              >
                <div
                  className={`icon-box ${!isGridView ? "icon-active" : ""}`}
                ></div>
              </div>
              &nbsp;
              <div
                className={`gridcontainerbuttonformobile ${
                  isGridView ? "active" : ""
                }`}
                onClick={() => toggleGridView(true)}
              >
                <div
                  className={`icon-box ${isGridView ? "icon-active" : ""}`}
                ></div>
                <div
                  className={`icon-box ${isGridView ? "icon-active" : ""}`}
                ></div>
              </div>
            </div>

            {/*---------------Main code starts here leftside  - filtersection  , rightside - products and banner------------*/}
            <div
              className={`row layout-wrap ${isGridView ? "grid-view-for-combined-search" : "nogridscssaplied"}`}
              style={{ position: "relative" }}
            >
              {/* <div
                className={`filtersectioncse col col-lg-2 col-md-3 col-12`}
                id="filterSection"
              >
                <div
                  className={`filter-wrap ${showScrollbar ? "scrollable" : ""}`}
                  style={{
                    position: isSticky ? "sticky" : "relative",
                    top: isSticky ? "205px" : "auto",
                  }}
                >
                  <div className="head">
                    <div className="heading heading-600-20">
                      {filterIcon({ width: 18, height: 12 })}
                      Filters
                    </div>
                    <div
                      id="ResetBtn"
                      className="heading-600-14 curser-pointer"
                    >
                      Reset
                    </div>
                  </div>
                  {SparefilterConfigurations?.map((config) => (
                    <SpareFilterSection
                      key={config.filterType}
                      title={config.title}
                      attribute={config.attribute}
                      filterType={config.filterType}
                      activeFilters={activeFilters}
                      IndustryCheckBoxValue={IndustryCheckBoxValue}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      GradeCheckBoxValue={GradeCheckBoxValue}
                      setGradeCheckBoxValue={setGradeCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                      setIsGridView={setIsGridView}
                      setIsGradeSelected={setIsGradeSelected}
                    />
                  ))}
                  {isBrandSelected && (
                    <SpareFilterSection
                      title="Grade"
                      attribute="grade"
                      filterType="grade"
                      activeFilters={activeFilters}
                      IndustryCheckBoxValue={IndustryCheckBoxValue}
                      setIndustryCheckBoxValue={setIndustryCheckBoxValue}
                      CategoryCheckBoxValue={CategoryCheckBoxValue}
                      setCategoryCheckBoxValue={setCategoryCheckBoxValue}
                      GradeCheckBoxValue={GradeCheckBoxValue}
                      setGradeCheckBoxValue={setGradeCheckBoxValue}
                      handleFilters={handleFilters}
                      MainFilters={MainFilters}
                      setMainFilters={setMainFilters}
                    />
                  )}
                </div>
              </div> */}
              <div
                className={`productcardsectioncse col col-lg-12 col-md-12 col-12`}
              >
                              {/*--------This div is for showing three structures in desktop such as - Delivery Location , grid and sort------*/}

                <div className="filters-delivery-grid d-flex justify-content-end align-items-center mt-1 mr-1">
                  {/* Div 1 - Align to the left */}
                  <div className="d-flex thegirdbuttonlogic ">
                    {/* <span className="gridtextfordesktop">Grid - &nbsp;</span>  */}
                    {/*Grid buttons 1 and 2 for small laptop screens */}
                    <div
                      className={`gridcontainerbuttonforsmallestdesktop ${
                        isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(true)}
                    >
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    &nbsp;
                    <div
                      className={`gridcontainerbuttonforsmallestdesktop ${
                        !isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(false)}
                    >
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    {/*Grid buttons 2 and 3 for middle laptop screens */}
                    <div
                      className={`gridcontainerbuttonforsmalldesktop ${
                        isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(true)}
                    >
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                       <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    &nbsp;
                    <div
                      className={`gridcontainerbuttonforsmalldesktop ${
                        !isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(false)}
                    >
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                       <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    {/*Grid buttons 3 and 4 for middle laptop screens */}
                    <div
                      className={`gridcontainerbuttonfordesktop ${
                        isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(true)}
                    >
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                       <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    &nbsp;
                    <div
                      className={`gridcontainerbuttonfordesktop ${
                        !isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(false)}
                    >
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                       <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    {/*Grid buttons 4 and 5 for large laptop screens */}
                    <div
                      className={`gridcontainerbuttonforbigdesktop ${
                        isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(true)}
                    >
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                       <div
                        className={`icon-box ${
                          isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                    &nbsp;
                    <div
                      className={`gridcontainerbuttonforbigdesktop ${
                        !isGridView ? "active" : ""
                      }`}
                      onClick={() => toggleGridView(false)}
                    >
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                      <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                                            <div
                        className={`icon-box ${
                          !isGridView ? "icon-active" : ""
                        }`}
                      ></div>
                    </div>
                  </div>

                  {/* Div 2 and Div 3 - Align to the right */}

                  {/* Div 2 */}

                  <div className="individual-div-filters-delivery-grid">
                    <button
                      onClick={() => handleSeeMore(true)}
                      className="delveryloactionned"
                    >
                      {deliveryLocation
                        ? `Delivery Location : ${deliveryLocation}`
                        : "Set Delivery Location"}
                    </button>
                  </div>

                  {/* Div 3 */}
                  {/* <div className="individual-div-filters-delivery-grid sortbutton">
                    <div style={{ position: "relative" }}>
                      <button
                        className="sortforstorejsxed"
                        onClick={() => setShowShortList(!showShortList)}
                      >
                        {shortIcon({ width: 22, height: 22 })}
                        <span>
                          {filters.orderBy ? filters.orderBy : "Sort Result"}
                        </span>
                      </button>
                      {showShortList && (
                        <div className="shorts">
                          <span
                            className="item"
                            onClick={() =>
                              handleChangeSort("Sort Alphabetically (A - Z)")
                            }
                          >
                            Sort Alphabetically (A - Z)
                          </span>
                          <span
                            className="item"
                            onClick={() =>
                              handleChangeSort(
                                "Sort by Price (Highest to Lowest)"
                              )
                            }
                          >
                            Sort by Price (Highest to Lowest)
                          </span>
                          <span
                            className="item"
                            onClick={() =>
                              handleChangeSort(
                                "Sort by Price (Lowest to Highest)"
                              )
                            }
                          >
                            Sort by Price (Lowest to Highest)
                          </span>
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>

                {/*-----------------------------------------------Showing Products----------------------------------------------*/}

                {isSpareIndex ? (
                  // <SpareHitsNewDesign
                  //   isGridView={isGridView}
                  //   isGradeSelected={isGradeSelected}
                  //   setLoading={setLoading}
                  //   Sorting={Sorting}
                  //   hitComponent={filteredHits}
                  //   query={searchInput}
                  //   deliveryAvailable={deliveryAvailable}
                  //   sendEvent={aa}
                  // />

                  <SpareHitNotWithDoubleAPIS
                  isGridView={isGridView}
                  isGradeSelected={isGradeSelected}
                  setLoading={setLoading}
                  Sorting={Sorting}
                  hitComponent={filteredHits}
                  query={searchInput}
                  deliveryAvailable={deliveryAvailable}
                  sendEvent={aa}
                />
                ) : (
                  <CombinedHits
                    isGridView={isGridView}
                    sellerResults={combinedResults.sellerResults}
                    spareResults={combinedResults.spareResults}
                    deliveryAvailable={deliveryAvailable}
                  />
                )}
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
            <Footer />

              {showDeliveryPopup && (
                    <div className="  popup-overlay ">
                      <div className="popup-bannavjer ">
                        <div className="popup-content ">
                          <div className="">
                            <h3 className="">Thank you for your quotation request!</h3>
                            <p className="wewillcontactu">We'll get back to you soon</p>
                            <br></br>
                            <div
                              className="close-popup"
                              onClick={() => setShowDeliveryPopup(false)}
                            >
                              {closeIcon({ width: 14, height: 14 })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
        </>
    );
}

export default MachinesAndStore